import { FC } from 'react';

import { OrderChangeSet } from 'admin/constants/OrderChangeSet';

import { OrderChangeSetContext } from './OrderChangeSetContext';

const OrderChangeSetProvider: FC<OrderChangeSet> = ({
  children,
  changeSet,
  setChangeSet,
  showSaveDialog,
  onChange,
  fetchOrderInfo,
  fetchOrderInfoWithoutRemovingSandbox,
  inFlight,
}) => {
  return (
    <OrderChangeSetContext.Provider
      value={{
        changeSet,
        setChangeSet,
        showSaveDialog,
        onChange,
        fetchOrderInfo,
        inFlight,
        fetchOrderInfoWithoutRemovingSandbox,
      }}
    >
      {children}
    </OrderChangeSetContext.Provider>
  );
};

export { OrderChangeSetProvider };
