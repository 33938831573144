import React, { useEffect, useState } from 'react';

import {
  BodyBigText,
  BodySmallText,
  BodyText,
  Button,
  ElromcoCircularProgress,
  Modal,
  Switch,
} from '@elromcoinc/react-shared';
import { Box, FormControlLabel, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/GetApp';
import { format, parseISO } from 'date-fns';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import fileAPI from 'admin/api/FileAPI';
import { blobToBase64 } from 'common/utils/blobToBase64';

import EditDescription from './EditDescription';
import FilePreview from './FilePreview';

const useStyles = makeStyles((theme) => ({
  fileContainer: {
    width: '100%',
    margin: '.5rem',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '0 1rem',
  },
  imageContainer: {
    minWidth: '30%',
    maxHeight: '100px',
    flexBasis: '30%',
    boxSizing: 'border-box',
    display: 'inline-block',
  },
  closeImage: {
    zIndex: '2001',
    color: theme.palette.common.white,
    position: 'absolute',
    right: '.5rem',
    top: '.5rem',
  },
  fileInfo: {
    flexGrow: 1,
    padding: '.5rem 1rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
  },
  label: {
    fontSize: '.75rem',
  },
  labelStart: {
    marginLeft: 0,
  },
  fileImage: {
    fontSize: '6rem',
  },
}));

const ExpandableImage = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  ${({ $src = null }) => ($src ? `background: url(${$src});` : '')}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  ${({ $expanded }) =>
    $expanded
      ? `
    position: fixed;
    z-index: 2000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
  `
      : ''}
`;
const FileSection = ({
  id,
  image,
  originalFileName,
  description,
  dateCreated,
  createdByName,
  progress,
  onDelete,
  showToCustomer,
  contentType,
  onChange,
  isDisabledInDelete,
  handleUpdateInfo,
  isDisabledInUpdate,
  signaturesIds,
}) => {
  const classes = useStyles();
  const formattedDate = dateCreated ? format(parseISO(dateCreated), 'Pp') : null;

  const [imageSrc, setImageSrc] = useState(null);
  const [expandImage, setExpandImage] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [openNoticeModal, setOpenNoticeModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [viewFile, setViewFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenNoticeModal = () => setOpenNoticeModal(true);
  const handleCloseNoticeModal = () => setOpenNoticeModal(false);
  const handleOpenEditModal = () => setShowEditDialog(true);

  const isSignature = signaturesIds.some((signaturesId) => signaturesId === id);

  useEffect(() => {
    if (image) {
      fileAPI.generatePresignedUrl(id).then(setImageSrc);
    } else {
      setImageSrc(null);
    }
  }, [id, image]);

  const handleFilePreview = () => {
    if (id && !image) {
      setLoading(true);
      fileAPI
        .downloadFile(id)
        .then(blobToBase64)
        .then(setFileUrl)
        .then(() => {
          setLoading(false);
          setViewFile(true);
        });
    }
  };

  const handleExpand = (flag) => (e) => {
    e.stopPropagation();
    if (image) {
      setExpandImage(flag);
    } else {
      handleFilePreview();
    }
  };

  const handleDownload = () => {
    if (id) {
      fileAPI.downloadFile(id).then((blob) => {
        fileDownload(blob, `${originalFileName || id}${contentType.includes('pdf') ? '.pdf' : ''}`);
      });
    }
  };

  const handleDescriptionChange = (data) => {
    handleUpdateInfo(data);
    setShowEditDialog(false);
  };

  const actionsNoticeModal = [
    {
      label: 'cancel',
      onClick: handleCloseNoticeModal,
    },
    {
      label: 'continue',
      onClick: () => onDelete(id),
      loading: isDisabledInDelete,
    },
  ];

  const handleCloseFilePreview = () => setViewFile(false);

  return (
    <Box className={classes.fileContainer}>
      <Box className={classes.imageContainer}>
        {imageSrc ? (
          <ExpandableImage
            $src={progress === null && id ? imageSrc : null}
            $expanded={expandImage}
            onClick={handleExpand(!expandImage)}
          >
            {expandImage && (
              <IconButton
                aria-label="close"
                size="small"
                classes={{ root: classes.closeImage }}
                onClick={handleExpand(false)}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            )}
          </ExpandableImage>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton onClick={handleExpand(!expandImage)}>
              <DescriptionIcon color="primary" className={classes.fileImage} />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box className={classes.fileInfo}>
        <BodyText>{originalFileName}</BodyText>
        {description && <BodySmallText wordBreak>{description}</BodySmallText>}
        {dateCreated && <BodySmallText>{`Added: ${formattedDate} by ${createdByName || 'Elromco'}`}</BodySmallText>}
        <Box display="flex" justifyContent="flex-start">
          <FormControlLabel
            classes={{ label: classes.label, labelPlacementStart: classes.labelStart }}
            labelPlacement="start"
            control={<Switch name="showToCustomer" checked={showToCustomer} onChange={onChange} color="primary" />}
            label="Show to Customer"
          />
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Button color="primary" startIcon={<DownloadIcon />} size="small" variant="text" onClick={handleDownload}>
            Download
          </Button>
          {!image && (
            <>
              {loading && <ElromcoCircularProgress />}
              <Box mr={1}>
                <Button variant="text" size="small" color="primary" onClick={handleFilePreview}>
                  View
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.actions}>
        {!isSignature && (
          <Tooltip title="Delete file">
            <IconButton size="small" color="secondary" onClick={handleOpenNoticeModal}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {openNoticeModal && (
          <Modal
            open
            actions={actionsNoticeModal}
            maxWidth="xs"
            disabledInProcessing={isDisabledInDelete}
            title="Warning"
            onClose={handleCloseNoticeModal}
          >
            <Box display="flex" justifyContent="center">
              <BodyBigText>Do you really want to delete this file?</BodyBigText>
            </Box>
          </Modal>
        )}
        <Tooltip title="Edit file">
          <IconButton size="small" color="primary" onClick={handleOpenEditModal}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {showEditDialog && (
        <EditDescription
          onClose={() => setShowEditDialog(false)}
          onSave={handleDescriptionChange}
          description={description}
          fileName={originalFileName}
          isDisabledInUpdate={isDisabledInUpdate}
          open
        />
      )}
      <FilePreview viewFile={viewFile} fileUrl={fileUrl} onClose={handleCloseFilePreview} />
    </Box>
  );
};

FileSection.propTypes = {
  id: PropTypes.number,
  image: PropTypes.bool,
  originalFileName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  dateCreated: PropTypes.string,
  progress: PropTypes.number,
  showToCustomer: PropTypes.bool,
  createdByName: PropTypes.string,
  description: PropTypes.string,
  isDisabledInDelete: PropTypes.bool,
  handleUpdateInfo: PropTypes.func,
  isDisabledInUpdate: PropTypes.bool,
  contentType: PropTypes.string.isRequired,
  signaturesIds: PropTypes.array.isRequired,
};

FileSection.defaultProps = {
  description: null,
  image: true,
  showToCustomer: false,
  createdByName: 'Elromco',
  id: null,
  dateCreated: null,
  progress: null,
  isDisabledInDelete: false,
  handleUpdateInfo: null,
  isDisabledInUpdate: false,
};

export default FileSection;
