import React, { useState } from 'react';

import { BodyText, Link, Order, Product } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { List } from 'immutable';

import { SalesToClosingComparising } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/SalesToClosingComparising';
import { calculateSalesClosingDifference } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/calculateSalesClosingDifference';
import { useOrderChangeSet, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import AdditionalServicesModal from 'admin/components/OrderWindow/modals/AdditionalServices';

const useStyles = makeStyles<Theme>(({ palette }) =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    depositReceived: {
      color: palette.success.main,
    },
  }),
);

enum Modals {
  ADDITIONAL_SERVICES = 'ADDITIONAL_SERVICES',
}

export const ClosingAdditionalServices = () => {
  const { order } = useOrderState() as { order: Order };
  const classes = useStyles();
  const { onChange } = useOrderChangeSet();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const getServicePropertyName = useGetServicePropertyName();
  const name = getServicePropertyName('additionalServices');
  const quote = order.getServiceQuote(serviceIndex);
  const additionalServices = order.getIn(name.split('.')) as List<Product>;
  const salesAddServTotal = order?.getAdditionalServicesTotal(serviceIndex, isSelectedAllServices);
  const closingAddServTotal = order?.closingOrderDetail?.getAdditionalServicesTotal(
    serviceIndex,
    isSelectedAllServices,
  );
  const serviceTitle = salesAddServTotal?.asHumanReadableString();
  const percent = calculateSalesClosingDifference(salesAddServTotal.maxValue, closingAddServTotal.maxValue);

  const handleAdditionalServicesChanges = (value: List<Product>) => {
    onChange({ name, value });
    setOpenModal(null);
  };

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.ADDITIONAL_SERVICES)}>
          Additional Services
        </Link>
        <SalesToClosingComparising sales={serviceTitle} percent={percent}>
          <BodyText>{closingAddServTotal?.asHumanReadableString()}</BodyText>
        </SalesToClosingComparising>
      </Box>
      {Modals.ADDITIONAL_SERVICES === openModal && (
        <AdditionalServicesModal
          open
          onSave={handleAdditionalServicesChanges}
          onCancel={toggleOpenModal(null)}
          moveType={order.moveType}
          serviceType={quote.serviceType}
          additionalServices={additionalServices}
        />
      )}
    </Grid>
  );
};
