import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core';

import { ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { useOrderClosingContext, useOrderSalesInfoContext } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '12px',
    opacity: 0.5,
    display: 'flex',
    alignItems: 'center',
  },
}));

interface ViewSalesInfoProps {
  label: number | string;
}

export const ViewSalesInfo: React.FC<ViewSalesInfoProps> = ({ label }) => {
  const classes = useStyles();
  const { viewMoveDetailsSalesInfo } = useOrderSalesInfoContext();
  const { isCompleted } = useOrderClosingContext();

  return (
    <ValueTableCell>
      {isCompleted && viewMoveDetailsSalesInfo ? (
        <BodyText className={classes.root}>
          <b>{label}</b>
        </BodyText>
      ) : (
        <></>
      )}
    </ValueTableCell>
  );
};
