import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  Link,
  LongDistanceTariffDetails,
  Order,
  OverridableValue,
  TextInput,
  usePrevious,
} from '@elromcoinc/react-shared';
import { TableCell, TableRow } from '@material-ui/core';

import { ViewSalesInfo } from 'admin/components/OrderWindow/blocks/MoveDetails/ViewSalesInfo';
import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import {
  useOrderChangeSet,
  useOrderServiceIndex,
  useOrderSettingUnits,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import LongDistanceMinSize from 'admin/components/OrderWindow/modals/LongDistanceMinSize';
import { getServicePropertyName as getPropertyName } from 'admin/utils';
import { OrderWindowModal } from 'common-types';

export const MinimumSizeRow = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { serviceIndex } = useOrderServiceIndex();
  const { moveUnitLabelsShort } = useOrderSettingUnits();
  const getServicePropertyName = useGetServicePropertyName();
  const [longDistanceMinSize, setLongDistanceMinSize] = useState(0);
  const [openModal, setOpenModal] = useState<OrderWindowModal | null>(null);
  const longDistanceTariffDetailsName = getServicePropertyName('longDistanceTariffDetails');
  const longDistanceTariffDetails = order.getIn(longDistanceTariffDetailsName.split('.')) as LongDistanceTariffDetails;

  const previousLongDistanceMinSize = usePrevious(longDistanceMinSize, longDistanceMinSize);

  const salesLongDistanceTariffDetailsName = getPropertyName(serviceIndex, 'longDistanceTariffDetails');
  const salesLongDistanceTariffDetails = order.getIn(
    salesLongDistanceTariffDetailsName.split('.'),
  ) as LongDistanceTariffDetails;

  useEffect(() => {
    setLongDistanceMinSize(longDistanceTariffDetails.minSize || 0);
  }, [longDistanceTariffDetails.minSize]);

  const handleOpenModal = (modalName: OrderWindowModal | null) => () => {
    setOpenModal(modalName);
  };

  const handleOnBlur =
    (value: OverridableValue | string | number, previousValue?: OverridableValue | string | number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name },
      } = event;

      if (value !== previousValue) {
        onChange({ name, value });
      }
    };

  const changeMinSize = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    return setLongDistanceMinSize(+value);
  };

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <Link onClick={handleOpenModal(OrderWindowModal.MIN_SIZE)}>{`Minimum ${moveUnitLabelsShort}`}</Link>
        {openModal === OrderWindowModal.MIN_SIZE && (
          <LongDistanceMinSize
            minSize={longDistanceTariffDetails.minSize}
            basePrice={longDistanceTariffDetails.basePrice}
            namePrefix={longDistanceTariffDetailsName}
            onChange={onChange}
            onClose={handleOpenModal(null)}
          />
        )}
      </TableCell>
      <ValueTableCell>
        <FlexWithNegativeMargin>
          <TextInput
            fullWidth={false}
            type="number"
            hiddenLabel
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{ min: 0 }}
            value={longDistanceMinSize}
            onChange={changeMinSize}
            name={`${longDistanceTariffDetailsName}.minSize`}
            onBlur={handleOnBlur(+longDistanceMinSize, previousLongDistanceMinSize)}
          />
        </FlexWithNegativeMargin>
      </ValueTableCell>
      <ViewSalesInfo label={salesLongDistanceTariffDetails?.minSize!} />
    </TableRow>
  );
};
