import {
  AddedWaypoint,
  ChargeProcessingFeeSettingsDto,
  ContactInfo,
  EstimatedPackingType,
  Order,
  OrderBillOfLadingDto,
  OrderClosingDto,
  OrderStatusIdType,
  OrderSummary,
  PaymentOptions,
  Quote,
  QuoteRequest,
  ServiceRosterBillOfLadingDto,
  SizingStrategy,
  Valuation,
  ValuationType,
} from '@elromcoinc/react-shared';
import axios from 'axios';
import { DateRange } from 'materialui-daterange-picker';

import { FeeType } from 'admin/constants/FeeType';
import { OrderInvoicesSummaryDto } from 'admin/entities';
import {
  AdditionalContactDTO,
  AdditionalDetailsForTheDistance,
  AdditionalServiceQuoteRequestDTO,
  ConfirmationSignature,
  PriceDTO,
  QuoteRequestDTO,
  SearchCriteria,
  WaypointDTO,
} from 'common-types';

const HEADER_NAME_UPDATES_TRACKER = 'updates-tracker';

function wrapAudit(updates: any) {
  if (!updates) {
    return {};
  }

  return {
    [HEADER_NAME_UPDATES_TRACKER]: JSON.stringify(updates),
  };
}

interface InventorySubmissionResponse {
  startDate: string;
  endDate: string;
  orderIdsPerUpdateInstance: PageDTO<number>;
  orders: OrderSummary[];
}

class OrderApi {
  save(data: QuoteRequestDTO) {
    return axios.post('/api/moving/quote', data);
  }

  generateOrder(data: QuoteRequestDTO) {
    return axios.post('/api/moving/quote', data);
  }

  generateOutOfStorageOrder(orderId: number, quoteRequest: QuoteRequestDTO) {
    return axios.post(`/api/moving/order/${orderId}/storage`, quoteRequest);
  }

  search(query: any, pageIndex = 0, pageSize = 25) {
    return axios.get(
      `/api/moving/search/?pageIndex=${pageIndex}&pageSize=${pageSize}&term=${encodeURIComponent(query)}`,
    );
  }

  getOrder(orderId: number) {
    return axios.get<Order, Order>(`/api/moving/order/${orderId}`);
  }

  loadSandbox(orderNumber: string) {
    return axios.get(`/api/moving/order/${encodeURIComponent(orderNumber)}/sandbox/`);
  }

  lockOrder(orderId: number) {
    return axios.post<void, void>(`/api/moving/order/${orderId}/lock`);
  }

  unlockOrder(orderId: number) {
    return axios.delete<void, void>(`/api/moving/order/${orderId}/lock`);
  }

  getCustomerOrderStats(customerId: number) {
    return axios.get(`/api/stats/orders/customer/${customerId}`);
  }

  updateOrderStatus(orderId: number, status: OrderStatusIdType) {
    return axios.put(`/api/moving/order/${orderId}/status/${status}`, {});
  }

  setOrderQuoteVisibilityToCustomer(orderId: number, isVisible: boolean, auditData: any) {
    return axios.put(
      `/api/moving/order/${orderId}/quoteVisibility/${isVisible}`,
      {},
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  setOrderBySmsStatus(orderId: number, flag: boolean, auditData: any) {
    return axios.put(
      `/api/moving/order/${orderId}/smsStatus/${flag}/`,
      {},
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  updateOrder(orderId: number, orderRequest: QuoteRequestDTO, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}`, orderRequest, {
      headers: wrapAudit(auditData),
    });
  }

  updateServiceType(
    orderId: number,
    serviceId: number,
    addedWaypoint: Partial<AddedWaypoint>,
    secondServiceDate: string | null,
  ) {
    const params = secondServiceDate ? { secondServiceDate } : {};

    return axios.put(`/api/moving/order/${orderId}/newServiceType/${serviceId}`, addedWaypoint, {
      headers: { 'Content-Type': 'application/json' },
      params,
    });
  }

  clone(orderId: number, shouldCloneInventory = true) {
    return axios.post(`/api/moving/order/clone/${orderId}`, shouldCloneInventory ? 'CLONE' : 'DROP', {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  savePendingOrderUpdates(orderId: number) {
    return axios.post(`/api/moving/order/updates/${orderId}`, {});
  }

  cancelOrderUpdates(orderId: number) {
    return axios.delete(`/api/moving/order/updates/${orderId}`);
  }

  updateService(serviceId: number, orderRequest: any, auditData: any) {
    return axios.put(`/api/moving/order/service/${serviceId}`, orderRequest, {
      headers: wrapAudit(auditData),
    });
  }

  getAllOrderSummariesForCompany({ pageIndex = 0, pageSize = 10, sortBy = 'id', sortOrder = 'desc' }) {
    const qs = `pageIndex=${pageIndex}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
    return axios.get(`/api/moving/orders/branch?${qs}`);
  }

  getOrderSummariesWithFilters<T = any>(
    params: Partial<PageParams | { orderNumber: string; 'account.id'?: number; created: string[] }> = {},
  ) {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    return {
      promise: axios.get<PageDTO<T>, PageDTO<T>>('/api/moving/orders/summaries', {
        params: { pageIndex: 0, pageSize: 10, sortBy: 'id', sortOrder: 'desc', ...params },
        cancelToken: source.token,
      }),
      cancel(message: string) {
        source.cancel(message);
      },
    } as CancelablePromise<PageDTO<T>>;
  }

  getOrderCalendarSummariesWithFilters<T = any>({ startDate, endDate }: { startDate: string; endDate: string }) {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    return {
      promise: axios.get<T, T>(`/api/moving/orders/calendar/summaries/startDate/${startDate}/endDate/${endDate}`, {
        cancelToken: source.token,
        data: {},
      }),
      cancel(message: string) {
        source.cancel(message);
      },
    } as CancelablePromise<T>;
  }

  getDeliveryOrderSummaries<T = OrderSummary>(params: {
    startDate: string;
    endDate: string;
    statuses: OrderStatusIdType[];
  }) {
    const controller = new AbortController();
    const signal = controller.signal;

    return {
      promise: axios.get<T[], T[]>('/api/moving/orders/summaries/delivery', {
        params: { ...params, signal },
      }),
      cancel(message: string) {
        controller.abort(message);
      },
    } as CancelablePromise<T[]>;
  }

  getAllUnpaidOrdersWithInvoicesForAccount(accountId: number) {
    return axios
      .get<OrderInvoicesSummaryDto[], PageDTO<OrderInvoicesSummaryDto>>(
        `/api/moving/invoices/account/${accountId}/orders`,
        { params: { pageSize: 1000 } },
      )
      .then(
        ({ pageElements }) =>
          pageElements.map((oisd) => ({
            ...oisd,
            orderSummary: OrderSummary.createOrderSummary(oisd.orderSummary),
          })) as OrderInvoicesSummaryDto[],
      );
  }

  getOrdersSubmissionsSummaries<T = any>(
    params: Partial<PageParams | { orderNumber: string }> = {},
    dateRange: DateRange,
  ) {
    const controller = new AbortController();
    const signal = controller.signal;
    const { startDate, endDate } = dateRange;

    return {
      promise: axios
        .get<PageDTO<T>, InventorySubmissionResponse>(
          `/api/stats/reports/submissions/startDate/${startDate}/endDate/${endDate}/orderSummaries`,
          {
            params: { pageIndex: 0, pageSize: 10, sortBy: 'id', sortOrder: 'desc', signal, ...params },
          },
        )
        .then((response) => {
          return {
            pageIndex: response.orderIdsPerUpdateInstance.pageIndex,
            totalPages: response.orderIdsPerUpdateInstance.totalPages,
            totalElements: response.orderIdsPerUpdateInstance.totalElements,
            pageElements: response.orders,
          } as PageDTO<OrderSummary>;
        }),
      cancel: () => controller.abort(),
    } as CancelablePromise<PageDTO<OrderSummary>>;
  }

  findAllOrdersByCriteria(criteria: SearchCriteria, { pageIndex = 0, pageSize = 10 }) {
    const params = { pageIndex, pageSize };
    return axios.post(`/api/moving/orders/branch`, criteria, { params });
  }

  // need to use Quote entity from react shared instead QuoteRequestDTO
  testQuote(quoteRequest: QuoteRequestDTO) {
    return axios.post<QuoteRequestDTO, QuoteRequestDTO>('/api/moving/quote/test', quoteRequest);
  }

  updateWaypoints(orderId: number, newWaypoints: { waypoints: WaypointDTO[] }, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/waypoints`, newWaypoints, {
      headers: wrapAudit(auditData),
    });
  }

  setSizingStrategy(orderId: number, sizingStrategy: SizingStrategy, auditData?: any) {
    return axios.put(
      `/api/moving/order/${orderId}/sizingStrategy/${sizingStrategy}`,
      {},
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  setSizingStrategyDirect(orderId: number, sizingStrategy: SizingStrategy, auditData?: any) {
    return axios.put(
      `/api/moving/order/${orderId}/sizingStrategy/direct/${sizingStrategy}`,
      {},
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  updateAdditionalContactInfo(orderId: number, additionalContactInfo: AdditionalContactDTO, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/additionalContact`, additionalContactInfo, {
      headers: wrapAudit(auditData),
    });
  }

  setOverriddenCuFt(orderId: number, cubicFeet: number, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/overriddenCuFt`, cubicFeet, {
      headers: wrapAudit(auditData),
    });
  }

  setPaymentType(orderId: number, paymentType: PaymentOptions, auditData: any) {
    return axios.put(
      `/api/moving/order/${orderId}/paymentOption/${paymentType}`,
      {},
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  setChargeProcessingFee(orderId: number, chargeProcessingFee: ChargeProcessingFeeSettingsDto, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/chargeProcessingFeeSetting/`, chargeProcessingFee, {
      headers: wrapAudit(auditData),
    });
  }

  setCuFtToPoundsRatio(orderId: number, conversionRatio: any, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/cuFtToPounds`, conversionRatio, {
      headers: wrapAudit(auditData),
    });
  }

  setFeeOnOff(orderId: number, serviceId: number, feeType: FeeType, isEnabled: boolean, auditData: any) {
    return axios.put(
      `/api/moving/order/${orderId}/service/${serviceId}/fee/${feeType}/enable/${isEnabled}`,
      {},
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  setOverriddenWeight(orderId: number, weight: any, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/overriddenWeight`, weight, {
      headers: wrapAudit(auditData),
    });
  }

  updateAdditionalServices(serviceRosterId: number, additionalServices: any) {
    return axios.put(`/api/moving/order/service/${serviceRosterId}/additionalServices`, {
      products: additionalServices,
    });
  }

  linkOrderToAccount(id: number, accountId: number) {
    return axios.put(`/api/moving/order/${id}/account/${accountId}`);
  }

  unlinkOrderFromAccount(orderId: number) {
    return axios.delete(`/api/moving/order/${orderId}/account`);
  }

  updateOrdersFlags(data: { entityIds: number[]; flagIds: number[] }) {
    return axios.put('/api/moving/orders/flags', data);
  }

  setFlagsOnOrder(orderId: number, flags: number[], auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/flags/`, flags, {
      headers: wrapAudit(auditData),
    });
  }

  updateContactInfo(orderId: number, contactInfo: ContactInfo, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/contact/`, contactInfo, {
      headers: wrapAudit(auditData),
    });
  }

  setAdditionalInfoNoteOnOrder(orderId: number, additionalInfo: string, auditData: any) {
    return axios.put(
      `/api/moving/order/${orderId}/note`,
      { value: additionalInfo || null },
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  setSecondaryIdOnOrder(orderId: number, secondaryId: string, auditData: any) {
    return axios.put(
      `/api/moving/order/${orderId}/secondaryId`,
      { value: secondaryId || null },
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  setLeadSource(orderId: number, leadSource: string, auditData: any) {
    return axios.put(
      `/api/moving/order/${orderId}/leadSource/${encodeURIComponent(leadSource)}`,
      {},
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  setDisableAutoCalculation(orderId: number, disabled: boolean = false, auditData: any) {
    return axios.put(
      `/api/moving/order/${orderId}/disableAutoCalculation/${disabled}`,
      {},
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  addServiceToOrder(orderId: number, newServiceQuoteRequest: AdditionalServiceQuoteRequestDTO, auditData: any) {
    return axios.post(`/api/moving/order/${orderId}/service`, newServiceQuoteRequest, {
      headers: wrapAudit(auditData),
    });
  }

  addService(orderId: number, request: QuoteRequest, auditData: any) {
    return axios.post(`/api/moving/order/${orderId}/service`, request, {
      headers: wrapAudit(auditData),
    });
  }

  deleteService(orderId: number, serviceId: number, auditData: any) {
    return axios.delete(`/api/moving/order/${orderId}/service/${serviceId}`, {
      headers: wrapAudit(auditData),
    });
  }

  assignEmployee(orderId: number, employeeId: number) {
    return axios.put(`/api/moving/order/${orderId}/assign/${employeeId}`, {});
  }

  getDistanceTypeAndAdditionalDetails(moveTypeAndTwoPostalCodes: AdditionalDetailsForTheDistance) {
    return axios.post('/api/moving/quote/service/distanceType/', moveTypeAndTwoPostalCodes);
  }

  setOverriddenFuelCharge(orderId: number, serviceDate: string, fuelCharge: PriceDTO, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/serviceDate/${serviceDate}/overriddenFuelCharge`, fuelCharge, {
      headers: wrapAudit(auditData),
    });
  }

  setDiscount(orderId: number, serviceDate: string, discount: PriceDTO, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/serviceDate/${serviceDate}/discount`, discount, {
      headers: wrapAudit(auditData),
    });
  }

  updatePackingServices(orderId: number, serviceDate: string, packingServices: any, auditData: any) {
    return axios.put(
      `/api/moving/order/${orderId}/serviceDate/${serviceDate}/packing`,
      { products: packingServices },
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  deleteOverriddenFuelCharge(orderId: number, serviceDate: string, auditData: any) {
    return axios.delete(`/api/moving/order/${orderId}/serviceDate/${serviceDate}/overriddenFuelCharge`, {
      headers: wrapAudit(auditData),
    });
  }

  setValuationChoice(orderId: number, valuation: Valuation, auditData: any) {
    const amount = valuation.defaultLiabilityAmount !== valuation.amount ? `/amount/${valuation.amount}` : '';
    const fullValueProtectionInformation =
      valuation.type === ValuationType.FULL ? `/deductible/${valuation.deductible}${amount}` : '';

    return axios.put(
      `/api/moving/order/${orderId}/valuation/valuationType/${valuation.type}${fullValueProtectionInformation}`,
      valuation,
      {
        headers: wrapAudit(auditData),
      },
    );
  }

  getConfirmationSignatureTemplates(orderId: number) {
    return axios.get<ConfirmationSignature[], ConfirmationSignature[]>(
      `/api/moving/order/${orderId}/confirmation-signature-documents`,
    );
  }

  updateOrderDeposit(orderId: string, amount: string, auditData: any) {
    return axios.put(`/api/moving/order/${orderId}/reservationAmount/${amount}`, null, {
      headers: wrapAudit(auditData),
    });
  }

  updateEstimatedPackingType(orderId: number, estimatedPackingType: EstimatedPackingType) {
    return axios.put(`/api/moving/order/${orderId}/packing/${estimatedPackingType}`);
  }

  importOrders(formData: FormData) {
    return axios.post('/api/file/import', formData);
  }

  getBookedOrdersByDate(date: string) {
    return axios.get<string, string[]>(`/api/moving/order/booked/${date}`);
  }

  updateOrderClosing(orderId: number, orderClosing: OrderClosingDto) {
    return axios.put(`/api/moving/order/${orderId}/closing`, orderClosing);
  }

  updateOrderBillOfLading(orderId: number, orderBillOfLading: OrderBillOfLadingDto) {
    return axios.put(`/api/moving/order/billOfLading`, orderBillOfLading);
  }

  getDispatchAvailableNotes(startDate: string, endDate: string) {
    return axios.get<{ startDate: string; endDate: string }, number[]>(
      `/api/moving/orders/ids/withAvailableNotes/startDate/${startDate}/endDate/${endDate}`,
    );
  }

  getOrderBillOfLading(orderId: number) {
    return axios.get<OrderBillOfLadingDto, OrderBillOfLadingDto>(`/api/moving/order/${orderId}/billOfLading`, {
      data: {},
    });
  }

  updateServiceRosterBillOfLading(orderId: number, serviceRoster: ServiceRosterBillOfLadingDto) {
    return axios.put(`/api/moving/order/${orderId}/serviceRosterBillOfLading`, serviceRoster);
  }

  linkStorageOrder(currentOrderId: number, linkedOrderId: number) {
    return axios.put(`/api/moving/order/${currentOrderId}/linkedOrder/${linkedOrderId}`);
  }

  unlinkStorageOrder(currentOrderId: number) {
    return axios.put(`/api/moving/order/${currentOrderId}/unlinkOrder`);
  }

  checkIsCalculatingDrivingTimeAsLaborTime(orderId: number, serviceRosterId: number) {
    return axios.post<boolean, boolean>(
      `/api/moving/order/${orderId}/serviceRoster/${serviceRosterId}/checkIsCalculatingDrivingTimeAsLaborTime`,
    );
  }

  getServiceRosterBillOfLadingQuote(orderId: number, serviceRosterId: number) {
    return axios.get<Quote, Quote>(
      `/api/moving/order/${orderId}/serviceRoster/${serviceRosterId}/serviceRosterBillOfLadingQuote`,
    );
  }
}

export default new OrderApi();
