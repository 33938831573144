import React, { useEffect, useState } from 'react';

import {
  ActivitySourceType,
  AdminPermissions,
  BodyText,
  Button,
  ContentActivityType,
  FileDTO,
  IconButton,
  JobType,
  LONG_DISTANCE_PLANNER,
  Link,
  isLongDistanceService,
  statusIds,
  storageUtil,
  useConfirm,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, Divider, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import fileAPI from 'admin/api/FileAPI';
import { getCurrentEmployee } from 'admin/autodux/CurrentEmployeeAutodux';
import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { CloseOrder } from 'admin/components/OrderWindow/blocks/Closing/CloseOrder';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { IframeModal } from 'admin/components/OrderWindow/modals/IframeModal';
import { sendMessagesToOrder } from 'admin/components/OrderWindow/sendMessagesToOrder';
import {
  EMAIL_SERVER_SETTINGS,
  USE_OUT_GOING_EMAIL,
} from 'admin/components/Settings/components/Users/ContentPanels/UserFormConstants';
import foremanPage from 'admin/config/ForemanPage';
import EmployeeRecord from 'admin/entities/Employee';
import { getServiceRosterClosingPropertyName } from 'admin/utils';
import { AutomationRule, AutomationRuleName, CommunicationModality, Template, TripPlanerType } from 'common-types';
import { getUrl } from 'common/utils';

const useStyles = makeStyles(() => ({
  closingButton: {
    width: '200px',
  },
}));

export const ClosingBar = () => {
  const { serviceIndex } = useOrderServiceIndex();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { fetchOrderInfo } = useOrderChangeSet();
  const { onChange } = useOrderChangeSet();
  const classes = useStyles();
  const [inFlight, setInFlight] = useState(false);
  const [isHoverButton, setIsHoverButton] = useState(false);
  const [billOfLadingPdfs, setBillOfLadingPdfs] = useState<FileDTO[]>([]);
  const { order } = useOrderState();
  const { isClosing, setIsClosing, hasAvailableContracts, showClosingButton, showClosingInOrderWindow, wasCompleted } =
    useOrderClosingContext();
  const [foremanUrl, setForemanUrl] = useState('');
  const canSeeClosingTab = useHasPermission(AdminPermissions.PERM_CLOSING_TAB) as boolean;
  const quote = order?.getServiceQuote(serviceIndex)!;
  const tripPlanerType = order?.closingOrderDetail?.getServiceQuote(serviceIndex)?.get(LONG_DISTANCE_PLANNER);
  const { enqueueSnackbar } = useSnackbar();
  const isLongDistance = isLongDistanceService(order?.getServiceType(serviceIndex));
  const { selectedTemplateDTO } = useActivityLogContext();
  const { automationRules, templates } = useComposeEmail();
  const showClosingInOrderWindowPropertyName = getServiceRosterClosingPropertyName(
    serviceIndex,
    'showClosingInOrderWindow',
  );
  const currentEmployee = useSelector(getCurrentEmployee) as EmployeeRecord | null;

  const { confirm: confirmWarning, renderDialog: renderDialogConfirmationWarning } = useConfirm({
    title: 'Warning',
    message:
      'You are about to activate closing. This will lock your sales and any future changes would be done in closing. Do you wish to proceed?',
    confirmTitle: 'Ok',
    cancelTitle: 'Cancel',
  });
  const { confirm: confirmDeactivateWarning, renderDialog: renderDialogDeactivateConfirmationWarning } = useConfirm({
    title: 'Warning',
    message:
      'You are about to deactivate closing and any changes made to either the closing tab or the electronic bill of lading will be lost. Your order will be reverted to the sales tab. Do you wish to continue?',
    confirmTitle: ' Proceed',
    cancelTitle: 'Cancel',
  });

  const { confirm: confirmSendPdf, renderDialog: renderSendPdf } = useConfirm({
    title: 'Sending Bill of Lading',
    message: `Would you like to send a Bill of Lading?`,
    confirmTitle: 'Yes',
    cancelTitle: 'No',
    maxWidth: 'xs',
  });

  const { confirm, renderDialog } = useConfirm({
    title: 'Sending Bill of Lading',
    message: `You do not have the automation rule to send the bill of lading currently set up. Please update this template and try again.`,
    maxWidth: 'xs',
    displayCancel: false,
    confirmTitle: 'Ok',
  });

  useEffect(() => {
    if (order?.orderId) {
      fileAPI
        .getAllFiles(order?.orderId, 0, 100, [], ActivitySourceType.ORDER, {
          contentActivityType: ContentActivityType.BILL_OF_LADING,
        })
        .then(({ pageElements }) => {
          setBillOfLadingPdfs(pageElements);
        })
        .catch(() => setBillOfLadingPdfs([]));
    }
  }, [order?.orderId]);

  const handleSendBillOfLading = async () => {
    const findDefaultTemplate = (automationRule: AutomationRule | undefined) => {
      const firstTemplate = templates.find(
        (t) => t.modality === CommunicationModality.EMAIL && t.automationRuleId === automationRule?.id && t.active,
      );
      return new Template(firstTemplate ?? {});
    };

    const billOfLadingAutomationRule = automationRules.find(
      (ar) => ar.shortName === AutomationRuleName.WHEN_JOB_STATUS_COMPLETED_SEND_TO_CUSTOMER_COPY_OF_BOL,
    );

    const defaultTemplate = findDefaultTemplate(billOfLadingAutomationRule);

    if (!defaultTemplate?.body) {
      await confirm();
      return;
    }

    if (billOfLadingPdfs?.length > 0 && (await confirmSendPdf())) {
      const createSendTemplateFromSelected = () => {
        return new Template(selectedTemplateDTO?.toDTO({ useExistingBody: true }) ?? {});
      };

      try {
        setInFlight(true);

        const sendTemplates = defaultTemplate || createSendTemplateFromSelected();

        await sendMessagesToOrder(
          {
            orderId: order?.orderId!,
            email: order?.contactInfo?.email,
            phoneNumber: order?.contactInfo?.primaryPhone?.number,
          },
          [sendTemplates],
          !!currentEmployee?.[EMAIL_SERVER_SETTINGS]?.[USE_OUT_GOING_EMAIL],
          billOfLadingPdfs,
        ).catch(() => {
          setInFlight(false);
        });
      } catch {
        enqueueSnackbar('Error whole sending Bill of Lading.', { variant: 'error' });
      } finally {
        enqueueSnackbar('Bill of Lading was sent successfully', { variant: 'success' });
        setInFlight(false);
      }
    }
  };

  if (!order?.closingOrderDetail || !canSeeClosingTab || order.status !== statusIds.BOOKED) {
    return null;
  }

  const clickUnderConstruction = () => {
    enqueueSnackbar('Under Construction', { variant: 'info' });
  };

  const handleChangeMode = (event: any, value: boolean) => setIsClosing(value);

  const handleReloadOrderInfo = async () => {
    try {
      setInFlight(true);
      await fetchOrderInfo();
    } catch {
    } finally {
      setInFlight(false);
    }
  };

  const handleOpenBillOfLading = (jobType: JobType) => () => {
    const jobsPath = jobType === JobType.PICK_UP ? 'jobs' : 'delivery-jobs';
    const jwt = storageUtil.getJwt()!;
    const orderId = order?.orderId;
    const url = getUrl(
      foremanPage.foremanPageUrl,
      jwt,
      orderId,
      `manager/order-jobs/order/${orderId}/${jobsPath}/${quote.dispatchJob?.id!}/move-details/contract/bill-of-lading`,
      true,
    );
    setForemanUrl(url);
  };

  const handleCloseModal = () => {
    setForemanUrl('');
  };

  const handleShowClosing = async () => {
    const confirm = await confirmWarning();

    if (confirm) {
      onChange({
        name: showClosingInOrderWindowPropertyName,
        value: true,
      });
    }
  };

  const handleDeactivateClosing = async () => {
    const confirm = await confirmDeactivateWarning();

    if (confirm) {
      onChange({
        name: showClosingInOrderWindowPropertyName,
        value: false,
      });
    }
  };

  const handleHoverButton = (value: boolean) => () => {
    setIsHoverButton(value);
  };

  return (
    <Box mx={0.5} className="print-remove">
      {showClosingButton ? (
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end">
              <Box display="flex" ml={1}>
                <Link
                  onClick={handleOpenBillOfLading(JobType.PICK_UP)}
                  disabled={!quote?.dispatchJob?.id || !hasAvailableContracts}
                >
                  <BodyText>Bill of lading</BodyText>
                </Link>
              </Box>
              <Box display="flex" ml={1}>
                <Link onClick={handleShowClosing}>
                  <BodyText>Activate Closing</BodyText>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Grid container alignItems="center">
            <Grid item>
              <Box mr={2}>
                <Button
                  className={classes.closingButton}
                  onClick={(event) => handleChangeMode(event, !isClosing)}
                  variant="outlined"
                  color={isClosing ? 'primary' : 'default'}
                  rounded
                >
                  <Box>
                    <BodyText>{isClosing ? 'View Sales Info' : 'View Closing Info'}</BodyText>
                  </Box>
                </Button>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
              position="relative"
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Box ml={1} flexGrow={1}>
                <Grid container alignItems="center">
                  <CloseOrder />
                  {isLongDistance && tripPlanerType !== TripPlanerType.TRIP_PLANNER && (
                    <CloseOrder type={JobType.DELIVERY} />
                  )}
                  {isClosing && billOfLadingPdfs?.length > 0 && (
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        rounded
                        fullWidth={isMobile}
                        loading={inFlight}
                        disabled={inFlight}
                        onMouseOver={handleHoverButton(true)}
                        onMouseLeave={handleHoverButton(false)}
                        onClick={handleSendBillOfLading}
                      >
                        {isHoverButton ? 'resend Bill of Lading' : 'Bill of Lading sent'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
              {isClosing && (
                <Box ml={1}>
                  <Grid container alignItems="center">
                    {showClosingInOrderWindow && !wasCompleted && (
                      <Box display="flex" ml={1}>
                        <Link onClick={handleDeactivateClosing}>
                          <BodyText>DEACTIVATE CLOSING</BodyText>
                        </Link>
                      </Box>
                    )}
                    {/*Hide on mobile, because we don't have functional*/}
                    {!isMobile && (
                      <Grid item>
                        <Button
                          variant="text"
                          color="primary"
                          rounded
                          disabled
                          fullWidth={isMobile}
                          onClick={clickUnderConstruction}
                        >
                          Items
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        rounded
                        disabled={!quote?.dispatchJob?.id || !hasAvailableContracts}
                        fullWidth={isMobile}
                        onClick={handleOpenBillOfLading(JobType.PICK_UP)}
                      >
                        Bill of lading
                      </Button>
                    </Grid>
                    {!isMobile && (
                      <Grid item>
                        <IconButton color="primary" disabled={inFlight} onClick={handleReloadOrderInfo}>
                          <RefreshIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
        </Box>
      )}
      <IframeModal open={!!foremanUrl.length} onCancel={handleCloseModal} title="Foreman Page" src={foremanUrl} />
      {renderDialogConfirmationWarning()}
      {renderDialogDeactivateConfirmationWarning()}
      {renderSendPdf()}
      {renderDialog()}
    </Box>
  );
};
