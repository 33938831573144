import React from 'react';

import {
  BodySmallText,
  BodyText,
  HeaderSmallText,
  Order,
  OrdersPermissions,
  Select,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, Grid, makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { format, parseISO } from 'date-fns';
import pt from 'prop-types';
import { useSelector } from 'react-redux';

import { getManagerList } from 'admin/autodux/UsersAutodux';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { UI_DATE_FORMAT_SALES_LEAD } from 'admin/constants/DateTimeFormats';
import SettingName from 'admin/constants/SettingName';
import { useFetchManagers } from 'admin/hooks/useFetchManagers';

import { useOrderClosingContext, useOrderState } from '../context';

const useStyles = makeStyles((theme) => ({
  select: {
    '& .MuiFilledInput-input': {
      padding: '0px 28px 0px 0px',
    },
    '& div > div ': {
      paddingRight: `${theme.spacing(3.5)}px!important`,
    },
  },
  rootContainer: {
    padding: '5px',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  itemPadding: {
    paddingBottom: '5px',
  },
}));

const emptyMenuItem = (label) => (
  <MenuItem value={0} key={0} disabled>
    {label}
  </MenuItem>
);

export default function ActivityLog({ onChange }) {
  const { isClosing, isCompleted, isLockSales } = useOrderClosingContext();
  const classes = useStyles();
  const settings = useOrderWindowSettings();
  const salesPersonOptions = useSelector(getManagerList).map((item) => [item.id, item.fullName]);
  const { order, originalOrder } = useOrderState();
  const disableInputs = isClosing || isCompleted || isLockSales;
  const canEditOtherUsersAccounts = useHasPermission(OrdersPermissions.PERM_ASSIGN_LEADS);

  useFetchManagers();

  if (!order) {
    return null;
  }

  const leadSources =
    settings?.[SettingName.LEAD_SOURCE]?.filter(
      (item) => !item.externalLeadDeliveryType || item.name === order.leadSource,
    ) || [];

  if (order.leadSource && !leadSources.find((item) => item.name === order.leadSource)) {
    leadSources.push({ name: order.leadSource, id: -1 });
  }

  const wasCreatedByCustomer =
    originalOrder.customerInfo.fullName() === originalOrder.createdBy || !originalOrder.createdBy;
  const wasUpdatedByCustomer =
    originalOrder.customerInfo.fullName() === originalOrder.lastUpdatedBy || !originalOrder.lastUpdatedBy;
  const isParsedLeadSource = leadSources.find((item) => item.name === order.leadSource)?.externalLeadDeliveryType;

  return (
    <Grid container className={classes.rootContainer} direction="column">
      <Grid item xs={12}>
        <HeaderSmallText>
          <b>Sales Lead</b>
        </HeaderSmallText>
      </Grid>
      <Grid container xs={12} alignItems="center">
        <Grid item xs={4}>
          <BodySmallText>Assign to:</BodySmallText>
        </Grid>
        <Grid item xs={8}>
          <Box ml={-1}>
            <Select
              data-testid="assignedTo"
              name="assignedTo"
              value={order.assignedTo || 0}
              hiddenLabel
              className={classes.select}
              InputProps={{
                disableUnderline: true,
              }}
              defaultValue={0}
              onChange={onChange}
              disabled={disableInputs || !canEditOtherUsersAccounts}
            >
              {emptyMenuItem('Unassigned')}
              {salesPersonOptions.map(([id, name]) => (
                <MenuItem key={id} value={id}>
                  <BodyText>{name}</BodyText>
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      </Grid>
      <Grid container xs={12} alignItems="center">
        <Grid item xs={4}>
          <BodySmallText>Source:</BodySmallText>
        </Grid>
        <Grid item xs={8}>
          <Box ml={-1}>
            <Select
              hiddenLabel
              className={classes.select}
              name="leadSource"
              value={order.leadSource || 0}
              InputProps={{
                disableUnderline: true,
              }}
              defaultValue={0}
              onChange={onChange}
              disabled={isParsedLeadSource || disableInputs}
            >
              {emptyMenuItem('None')}
              {leadSources.map(({ id, name }) => (
                <MenuItem key={id} value={name}>
                  <BodyText>{name}</BodyText>
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      </Grid>
      {!!originalOrder && !!originalOrder.created && (
        <Grid container xs={12} alignItems="center" className={classes.itemPadding}>
          <Grid item xs={4}>
            <BodySmallText>Created By:&nbsp;</BodySmallText>
          </Grid>
          <Grid item xs={8}>
            <BodyText>
              {wasCreatedByCustomer ? 'customer' : originalOrder.createdBy}
              &nbsp;
              {format(parseISO(originalOrder.created), UI_DATE_FORMAT_SALES_LEAD)}
            </BodyText>
          </Grid>
        </Grid>
      )}
      {!!originalOrder && !!originalOrder.lastUpdated && (
        <Grid container xs={12} alignItems="center" className={classes.itemPadding}>
          <Grid item xs={4}>
            <BodySmallText>Last Modified By:&nbsp;</BodySmallText>
          </Grid>
          <Grid item xs={8}>
            <BodyText>
              {wasUpdatedByCustomer ? 'customer' : originalOrder.lastUpdatedBy}
              &nbsp;
              {format(parseISO(originalOrder.lastUpdated), UI_DATE_FORMAT_SALES_LEAD)}
            </BodyText>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

ActivityLog.propTypes = {
  order: pt.instanceOf(Order),
  originalOrder: pt.instanceOf(Order),
  settings: pt.shape({
    LEAD_SOURCE: pt.arrayOf(
      pt.shape({
        id: pt.number,
        name: pt.string,
      }),
    ),
  }),
  onChange: pt.func.isRequired,
};

ActivityLog.defaultProps = {
  order: null,
  originalOrder: {},
  settings: {},
};
