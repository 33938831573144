import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  BodyText,
  LongDistanceTariffDetails,
  Order,
  OverridableValue,
  numberToCurrency,
  usePrevious,
} from '@elromcoinc/react-shared';
import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core';

import { ViewSalesInfo } from 'admin/components/OrderWindow/blocks/MoveDetails/ViewSalesInfo';
import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderSettingUnits,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import { getServicePropertyName as getPropertyName } from 'admin/utils';
import { renderLaborForRate } from 'admin/utils/renderLaborForRate';

const useStyles = makeStyles(() => ({
  bloomingDaleDiscount: {
    textDecorationLine: 'line-through',
  },
}));

export const LongDistanceRatePerSizeUnit = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { serviceIndex } = useOrderServiceIndex();
  const { isCompleted, isLockSales, isClosing } = useOrderClosingContext();
  const { moveUnitLabelsShort } = useOrderSettingUnits();
  const getServicePropertyName = useGetServicePropertyName();
  const longDistanceTariffDetailsName = getServicePropertyName('longDistanceTariffDetails');
  const longDistanceTariffDetails = order.getIn(longDistanceTariffDetailsName.split('.')) as LongDistanceTariffDetails;
  const [longDistanceRatePerCuFt, setLongDistanceRatePerCuFt] = useState<string>('0');
  const previousLongDistanceRatePerCuFt = usePrevious(longDistanceRatePerCuFt, longDistanceRatePerCuFt);
  const name = getServicePropertyName('longDistanceTariffDetails.longDistanceRatePerUnitOverride');
  const discountRatePerSizeUnit =
    order.beforeDiscountServiceQuotes.get(serviceIndex)?.longDistanceTariffDetails?.getEffectiveRate() ?? 0;

  const salesLongDistanceTariffDetailsName = getPropertyName(serviceIndex, 'longDistanceTariffDetails');
  const salesLongDistanceTariffDetails = order.getIn(
    salesLongDistanceTariffDetailsName.split('.'),
  ) as LongDistanceTariffDetails;

  useEffect(() => {
    setLongDistanceRatePerCuFt(longDistanceTariffDetails.getEffectiveRate().toString() ?? '0');
  }, [longDistanceTariffDetails.getEffectiveRate()]);

  const onChangeRate = (value: string) => {
    setLongDistanceRatePerCuFt(value !== '' ? value : '0');
  };

  const handleOnBlur =
    (value: OverridableValue | string | number, previousValue?: OverridableValue | string | number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name },
      } = event;

      if (value !== previousValue) {
        onChange({ name, value });
      }
    };

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>{renderLaborForRate(longDistanceTariffDetails, moveUnitLabelsShort)}</BodyText>
      </TableCell>
      <ValueTableCell>
        <Box width={140} display="flex" alignItems="center">
          <FlexWithNegativeMargin $manual={longDistanceTariffDetails.getOverrideRateOrNull() !== null}>
            <CurrencyInput
              disabled={isCompleted || isLockSales}
              onChange={onChangeRate}
              value={longDistanceRatePerCuFt}
              name={name}
              onBlur={handleOnBlur(longDistanceRatePerCuFt, previousLongDistanceRatePerCuFt)}
            />
          </FlexWithNegativeMargin>
          {!!discountRatePerSizeUnit && !isClosing && (
            <BodyText color="textSecondary" className={classes.bloomingDaleDiscount}>
              {numberToCurrency(discountRatePerSizeUnit)}
            </BodyText>
          )}
        </Box>
      </ValueTableCell>
      <ViewSalesInfo label={salesLongDistanceTariffDetails?.getEffectiveRate().toString() ?? '0'} />
    </TableRow>
  );
};
