import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  CustomPaymentSettingDto,
  PaymentFeeSettingDto,
  PriceAdjustment as PriceAdjustmentSetting,
} from '@elromcoinc/moveboard-setting-react';
import {
  Activity,
  ActivitySourceDescriptor,
  ActivitySourceType,
  Modal,
  PaymentActivityType,
  PaymentAdjustmentType,
  PaymentType,
  PriceAdjustmentType,
  Switch,
  numberToCurrency,
  roundNumberToFixedDigits,
  statusIds,
} from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { paymentAdjustmentApi } from 'admin/api';
import { CustomerPaymentProfileDto } from 'admin/api/PaymentActionsApi';
import {
  useOrderChangeSet,
  useOrderState,
  useOrderWindowSettings,
  usePaymentClientKey,
} from 'admin/components/OrderWindow/context';

import { usePaymentSourceContext } from '../PaymentsSourceContext';
import { CashPayment } from './CashPayment';
import { CheckPayment } from './CheckPayment';
import { CreditCardOnline } from './CreditCardOnline';
import { CreditCardRecord } from './CreditCardRecord';
import { CustomPayment } from './CustomPayment';
import { PaymentTypeSelector } from './PaymentTypeSelector';

interface ChargeDepositModalProps {
  onSave: () => void;
  onCancel: () => void;
  reservationAmountNeeded: number;
  amount: number | string;
  customerId: number;
  isDepositAvailable: boolean;
  ifDepositUnavailableHint: string;
  orderNumber: string;
  cardHolder?: string;
  changeStatusOnDeposit: boolean;
  customerPaymentProfile: null | CustomerPaymentProfileDto;
  allowToMakePaymentAvailableOnBOL: boolean;
  bolActivitySources: Activity[];
}

const getSuccessMsg = (type: PaymentType): string => {
  switch (type) {
    case PaymentType.CREDIT_CARD:
      return 'Credit card charge has been submitted.';
    case PaymentType.CREDIT_CARD_RECORD:
      return 'Credit Card Record payment created.';
    case PaymentType.CASH:
      return 'Cash payment created.';
    case PaymentType.CHECK:
      return 'Check payment created.';
    default:
      return 'Request was submitted successfully.';
  }
};
const getErrorMsg = (type: PaymentType): string => {
  switch (type) {
    case PaymentType.CREDIT_CARD:
      return 'There was an error charging credit card.';
    case PaymentType.CREDIT_CARD_RECORD:
      return 'There was an error saving credit card record payment.';
    case PaymentType.CASH:
      return 'There was an error saving cash payment.';
    case PaymentType.CHECK:
      return 'There was an error saving check payment.';
    default:
      return 'There was an error processing your request.';
  }
};

interface CalculateFeeArguments {
  category: PaymentType;
  amount: number;
  creditCardProcessingFee: PaymentFeeSettingDto | null;
  cashDiscount: PaymentFeeSettingDto | null;
  customPayment: CustomPaymentSettingDto | null;
  enableFee: boolean;
}

interface CalculateFeeResult {
  name: string;
  amount: number;
  type: PaymentAdjustmentType | null;
  rate: PriceAdjustmentSetting | null;
}

const calculateFee = ({
  category,
  amount,
  creditCardProcessingFee,
  cashDiscount,
  customPayment,
  enableFee,
}: CalculateFeeArguments) => {
  const result: CalculateFeeResult = {
    name: '',
    amount: 0,
    type: null,
    rate: null,
  };

  if (category === PaymentType.CREDIT_CARD && creditCardProcessingFee && enableFee) {
    result.name = creditCardProcessingFee.name;
    result.amount =
      creditCardProcessingFee.rate.type === PriceAdjustmentType.PERCENT
        ? (amount * creditCardProcessingFee.rate.amount!) / 100
        : creditCardProcessingFee.rate.amount!;
    result.type = PaymentAdjustmentType.CREDIT_CARD_FEE;
    result.rate = creditCardProcessingFee.rate;
  } else if (category === PaymentType.CASH && cashDiscount && enableFee) {
    result.name = cashDiscount.name;
    result.amount =
      cashDiscount.rate.type === PriceAdjustmentType.PERCENT
        ? (amount * cashDiscount.rate.amount!) / 100
        : cashDiscount.rate.amount!;
    result.type = PaymentAdjustmentType.CASH_DISCOUNT;
    result.rate = cashDiscount.rate;
  } else if (category === PaymentType.CUSTOM && customPayment?.fee && enableFee) {
    result.name = customPayment.fee.name;
    result.amount =
      customPayment.fee.rate.type === PriceAdjustmentType.PERCENT
        ? (amount * customPayment.fee.rate.amount!) / 100
        : customPayment.fee.rate.amount!;
    result.type = PaymentAdjustmentType.CUSTOM_FEE;
    result.rate = customPayment.fee.rate;
  }

  result.amount = roundNumberToFixedDigits(result.amount, 2);

  return result;
};

const ChargeDepositModal: FC<ChargeDepositModalProps> = ({
  onSave,
  onCancel,
  customerId,
  orderNumber,
  amount: defaultAmount,
  reservationAmountNeeded,
  changeStatusOnDeposit,
  customerPaymentProfile,
  isDepositAvailable: displayDeposit,
  ifDepositUnavailableHint,
  cardHolder,
  allowToMakePaymentAvailableOnBOL,
  bolActivitySources,
}) => {
  const { onChange, showSaveDialog } = useOrderChangeSet();
  const { order } = useOrderState();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useOrderWindowSettings();
  const { activitySource, sourceId, activitySources } = usePaymentSourceContext();

  const [type, setType] = useState<PaymentActivityType>(
    reservationAmountNeeded > 0 && displayDeposit ? PaymentActivityType.DEPOSIT : PaymentActivityType.REGULAR,
  );
  const [inFlight, setInFlight] = useState<boolean>(false);
  const [amount, setAmount] = useState(type === PaymentActivityType.DEPOSIT ? reservationAmountNeeded : defaultAmount);
  const [isDepositAvailable, setIsDepositAvailable] = useState(type === PaymentActivityType.DEPOSIT && displayDeposit);
  const [showPaymentOnBOL, setShowPaymentOnBOL] = useState(false);
  const publicClientKey = usePaymentClientKey();
  const onlinePaymentAvailable = !!publicClientKey?.clientKey && !!publicClientKey?.apiLoginId;
  const [category, setCategory] = useState<PaymentType>(
    onlinePaymentAvailable ? PaymentType.CREDIT_CARD : PaymentType.CASH,
  );
  const [paymentCustomSettings, setPaymentCustomSettings] = useState<CustomPaymentSettingDto | null>(null);
  const [creditCardProcessingFee = null, cashDiscount = null] = settings?.ProcessingFeeSettings ?? [];
  const [enableFee, setEnableFee] = useState(false);

  const getFeeByCategory = () => {
    if (category === PaymentType.CREDIT_CARD) {
      return creditCardProcessingFee;
    }
    if (category === PaymentType.CASH) {
      return cashDiscount;
    }
    if (category === PaymentType.CUSTOM) {
      return paymentCustomSettings?.fee;
    }
    return null;
  };

  const feeByCategory = getFeeByCategory();

  useEffect(() => {
    setEnableFee(!!feeByCategory?.enabled);
  }, [feeByCategory?.id]);

  const fee = calculateFee({
    category,
    amount: +amount || 0,
    creditCardProcessingFee,
    cashDiscount,
    customPayment: paymentCustomSettings,
    enableFee,
  });
  const feeAmount = fee.type === PaymentAdjustmentType.CASH_DISCOUNT ? -fee.amount : fee.amount;
  const finalAmount = +amount + feeAmount;

  const mountedHandler = useRef<(() => Promise<any>) | null>(null);

  useEffect(() => {
    if (reservationAmountNeeded > 0 && displayDeposit) {
      setIsDepositAvailable(true);
    }
  }, [reservationAmountNeeded, displayDeposit]);

  const setMountedHandler = useCallback((promise: () => Promise<any>) => (mountedHandler.current = promise), []);

  const onDepositPaymentComplete = useCallback(async () => {
    if (changeStatusOnDeposit) {
      try {
        if (order?.status !== statusIds.BOOKED) {
          onChange({ name: 'status', value: statusIds.RESERVED });
          showSaveDialog();
        }
        onSave();
      } catch (e) {
        enqueueSnackbar(`Order status was not updated.`, { variant: 'error' });
      }
    } else {
      onSave();
    }
  }, [activitySource, sourceId, changeStatusOnDeposit]);

  const handleOnCharge = () => {
    if (mountedHandler.current) {
      setInFlight(true);
      mountedHandler
        .current()
        .then((result) => {
          if (!fee.amount) {
            return result;
          }

          if (!activitySources.find((as) => as.activitySource === activitySource)) {
            activitySources.push(
              new ActivitySourceDescriptor({
                activitySource: activitySource,
                referencedEntityId: sourceId!,
              }),
            );
          }

          const orderActivitySource = ActivitySourceType.ORDER;
          const orderActivitySourceId = activitySources.find(
            (as) => as.activitySource === ActivitySourceType.ORDER,
          )?.referencedEntityId;

          // so far we can create payment adjustment only for orders
          if (!orderActivitySourceId) {
            return result;
          }

          return paymentAdjustmentApi
            .createPaymentAdjustment({
              id: null,
              sourceId: orderActivitySourceId!,
              activitySource: orderActivitySource,
              activitySources: activitySources,
              involvesForeman: true,
              activityType: '',
              branchId: order?.branchId || 1,
              name: fee.name,
              message: '',
              total: fee.amount,
              quote: +amount || 0,
              rate: fee.rate as any,
              paymentAdjustmentType: fee.type!,
              paymentId: result?.id,
            })
            .then(() => {
              return new Promise((resolve) => {
                setTimeout(resolve, 500);
              });
            })
            .catch(() => {})
            .then(() => result);
        })
        .then(() => {
          enqueueSnackbar(getSuccessMsg(category), { variant: 'success' });
          if (type === 'DEPOSIT') {
            onDepositPaymentComplete();
          } else {
            onSave();
          }
        })
        .catch((error) => {
          const message = error?.errors?.[0]?.message ?? '';
          enqueueSnackbar(getErrorMsg(category) + ' ' + message, { variant: 'error' });
        })
        .then(() => {
          setInFlight(false);
        });
    }
  };

  const amountToCharge = enableFee && +finalAmount ? numberToCurrency(finalAmount) : '';

  const actions = [
    { label: 'cancel', onClick: onCancel },
    {
      label: `Record ${amountToCharge}`,
      onClick: handleOnCharge,
      loading: inFlight,
    },
  ];

  const commonProps = useMemo(
    () => ({
      type,
      amount: roundNumberToFixedDigits(+amount, 2),
      finalAmount,
      isDepositAvailable,
      ifDepositUnavailableHint,
      onAmountChange: setAmount,
      onTypeChange: setType,
      cardHolder,
      onMount: setMountedHandler,
      activitySources: (showPaymentOnBOL
        ? [...activitySources, ...bolActivitySources]
        : activitySources) as ActivitySourceDescriptor[],
      paymentCustomSettings: paymentCustomSettings!,
    }),
    [
      type,
      amount,
      finalAmount,
      setMountedHandler,
      isDepositAvailable,
      showPaymentOnBOL,
      bolActivitySources?.length,
      activitySources?.length,
      paymentCustomSettings?.id,
    ],
  );

  function renderPaymentType() {
    const paymentDescription = `${
      type === PaymentActivityType.DEPOSIT ? 'Deposit charge' : 'Charge'
    } for ${activitySource.toLowerCase()} ${orderNumber}`;

    switch (category) {
      case PaymentType.CASH:
        return <CashPayment {...commonProps} />;
      case PaymentType.CHECK:
        return <CheckPayment {...commonProps} />;
      case PaymentType.CREDIT_CARD:
        return (
          <CreditCardOnline
            {...commonProps}
            customerPaymentProfile={customerPaymentProfile}
            customerId={customerId}
            description={paymentDescription}
          />
        );
      case PaymentType.CREDIT_CARD_RECORD:
        return <CreditCardRecord {...commonProps} />;
      case PaymentType.CUSTOM: {
        if (paymentCustomSettings) {
          return <CustomPayment {...commonProps} />;
        }
        return null;
      }
      default:
        return null;
    }
  }

  const handleChangeFee = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableFee(event.target.checked);
  };

  return (
    <Modal
      open
      color="grey"
      title="Create Payment"
      onClose={onCancel}
      disabledInProcessing={inFlight}
      actions={actions}
      maxWidth="sm"
    >
      <Box position="relative">
        <PaymentTypeSelector
          onlinePaymentAvailable={onlinePaymentAvailable}
          allowToMakePaymentAvailableOnBOL={allowToMakePaymentAvailableOnBOL}
          showPaymentOnBOL={showPaymentOnBOL}
          setShowPaymentOnBOL={setShowPaymentOnBOL}
          defaultValue={category}
          onChange={setCategory}
          paymentCustomSettings={paymentCustomSettings}
          setPaymentCustomSettings={setPaymentCustomSettings}
        />
        {!!feeByCategory && (
          <Box position="absolute" right={0} top={122}>
            <Switch
              color="primary"
              label={feeByCategory?.name ?? 'Fee'}
              checked={enableFee}
              onChange={handleChangeFee}
            />
          </Box>
        )}
        <Box mt={2}>{renderPaymentType()}</Box>
      </Box>
    </Modal>
  );
};

export default ChargeDepositModal;
