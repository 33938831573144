import React from 'react';

import { Checkbox, HeaderText, Select, TextInput } from '@elromcoinc/react-shared';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircleIcon from '@material-ui/icons/Brightness1';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FlagIcon from '@material-ui/icons/Flag';
import MapIcon from '@material-ui/icons/Map';
import RoomIcon from '@material-ui/icons/Room';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { statusById } from 'admin/constants/OrderStatus';

export const OverviewInfoTable = styled(Table)`
  && {
    td {
      padding: 0px 0;
      border: none;
      line-height: 0;
      > p {
        word-break: break-word;
      }
    }
    td: first-child {
      padding-right: 8px;
      width: ${(prop) => prop.firstCellWidth || 'unset'};
    }
    td: last-child {

    }
  }
`;

export const LabelFab = styled(Fab)`
  &&& {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    height: ${(props) => (props.height ? props.height : '36px')};
    border-style: solid;
    border-width: 1px;
    min-width: ${(props) => (props.minWidth ? `${props.minWidth}!important` : '34px')};
    color: ${(props) => (props.textColor ? props.textColor : 'white')};
    border-color: ${(props) => (props.borderColor ? props.borderColor : props.theme.palette.primary.main)};
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.palette.primary.main)};
    box-shadow: ${(props) =>
      props.disableBoxShadow
        ? 'none'
        : '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'};
    :hover {
      border-color: transparent;
    }
    &:disabled {
      opacity: 0.3;
    }
  }
`;

const StyledIconBlueFab = styled(Fab)`
  && {
    color: white;
    width: ${(props) => props.buttonSize};
    height: ${(props) => props.buttonSize};
    min-height: unset;
    box-shadow: ${(props) =>
      props.disableBoxShadow
        ? 'none'
        : '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'};
    svg {
      font-size: ${(props) => props.iconSize};
    }
  }
`;

export const IconBlueFab = ({ mode, buttonSize, iconSize, disableBoxShadow, color, dataTestId, ...others }) => (
  <StyledIconBlueFab
    color={color || 'primary'}
    buttonSize={buttonSize}
    iconSize={iconSize}
    data-testid={dataTestId}
    disableBoxShadow={disableBoxShadow}
    {...others}
  >
    {mode.toUpperCase() === 'ADD' && <AddIcon />}
    {mode.toUpperCase() === 'EDIT' && <EditIcon />}
    {mode.toUpperCase() === 'CLOSE' && <CloseIcon />}
    {!mode && <AddIcon />}
  </StyledIconBlueFab>
);
IconBlueFab.propTypes = {
  mode: PropTypes.string,
  buttonSize: PropTypes.string,
  iconSize: PropTypes.string,
  disableBoxShadow: PropTypes.bool,
};
IconBlueFab.defaultProps = {
  mode: 'ADD',
  buttonSize: null,
  iconSize: null,
  disableBoxShadow: false,
};

export const IconLabelButton = styled(Button)`
  && {
    color: ${(props) => props.color || 'black'};
    width: ${(props) => props.width || '80px'};
    @media (max-width: 400px) {
      width: 64px;
    }
    .MuiButton-label {
      line-height: initial;
      display: flex;
      flex-direction: ${(props) => props.direction || 'column'};
      align-items: center;
      font-weight: ${(props) => props.fontWeight || '400'};
      font-size: ${(props) => props.fontSize || '14px'};
      text-transform: none;
      .MuiButton-startIcon {
        margin-right: 0;
      }
      .MuiButton-iconSizeMedium > *:first-child {
        font-size: 30px;
      }
      .MuiBadge-root {
        position: absolute;
        top: 10px;
        right: 22px;
        .MuiBadge-anchorOriginTopRightRectangle {
          font-weight: normal;
          font-size: 12px;
          line-height: 25px;
          background-color: ${(props) => props.theme.palette.error.main};
          color: white;
          height: 16px;
          padding: 4px;
        }
      }
    }
  }
`;

export const CardsContainer = styled(Box)`
  && {
    width: 100%;
    display: flex;
    flex-wrap: ${({ $noWrap = false }) => ($noWrap ? 'nowrap' : 'wrap')};
  }
`;

export const FlexRowWithSpacing = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  > div + div {
    margin-left: ${(props) => (props.spacing ? `${props.spacing}px` : 0)};
  }
  > button + button {
    margin-left: ${(props) => (props.spacing ? `${props.spacing}px` : 0)};
  }
`;

export const DropDownIconLabelButton = styled(Button)`
  && {
    background: #ffffff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: ${(props) => (props.fontColor ? props.fontColor : '#363747' /* todo: use theme! */)};
    padding-left: 14px;
    min-width: 300px;
    max-width: 450px;
    .MuiButton-label {
      display: flex;
      align-items: center;
      font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
      font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
      line-height: 20px;
      text-transform: none;
      .MuiButton-startIcon {
        color: ${(props) => (props.startIconColor ? props.startIconColor : '#363747' /* todo: use theme! */)};
      }
      .MuiButton-startIcon.MuiButton-iconSizeMedium > *:first-child {
        font-size: ${(props) => (props.startIconFontSize ? `${props.startIconFontSize}px` : '20px')};
      }
      .MuiButton-endIcon {
        margin-left: 2px;
        color: ${(props) => (props.endIconColor ? props.endIconColor : '#363747' /* todo: use theme! */)};
      }
      .MuiButton-endIcon.MuiButton-iconSizeMedium > *:first-child {
        font-size: 26px;
      }
    }
  }
`;

export const DropDown = styled(Menu)`
  margin-top: 2px;
`;

export const DropDownItem = styled(MenuItem)`
  && {
    padding: 4px 8px;
  }
`;

export function ColoredCircleLabel(props) {
  const { circleColor, labelColor, title } = props;
  return (
    <StyledColoredCircleLabel circleColor={circleColor} labelColor={labelColor}>
      <CircleIcon />
      <Typography>
        <Box fontWeight="400" fontSize="14px">
          {title}
        </Box>
      </Typography>
    </StyledColoredCircleLabel>
  );
}

ColoredCircleLabel.propTypes = {
  circleColor: PropTypes.string.isRequired,
  labelColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
ColoredCircleLabel.defaultProps = {};

const StyledColoredCircleLabel = styled(Box)`
  && {
    color: ${(props) => (props.labelColor ? props.labelColor : props.theme.palette.primary.main)};
    display: flex;
    align-items: center;
    svg {
      font-size: 16px;
      margin-right: 6px;
      color: ${(props) => (props.circleColor ? props.circleColor : '#C5C5C8')};
    }
    p {
      line-height: 14px;
    }
  }
`;

export function CircleIconLabelDropDown(props) {
  const { onItemClick, data, value, disabled } = props;

  const [anchorElForStatus, setAnchorElForStatus] = React.useState(null);

  const handleClickOnStatus = (event) => {
    if (anchorElForStatus) setAnchorElForStatus(null);
    else setAnchorElForStatus(event.currentTarget);
  };

  const handleCloseOnStatus = (id) => () => {
    onItemClick && id && onItemClick(id);
    setAnchorElForStatus(null);
  };

  const currentStatus = statusById(value);

  return (
    <>
      <DropDownIconLabelButton
        startIcon={<CircleIcon />}
        endIcon={<ArrowDropDownIcon />}
        startIconColor={currentStatus.color}
        onClick={handleClickOnStatus}
        data-testid="Status"
        disabled={disabled}
      >
        <HeaderText>
          <b>{`Status: ${currentStatus.label || ''}`}</b>
        </HeaderText>
      </DropDownIconLabelButton>
      <DropDown
        id="status-menu"
        anchorEl={anchorElForStatus}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorElForStatus)}
        onClose={handleCloseOnStatus(null)}
      >
        {data.map((item) => (
          <DropDownItem onClick={handleCloseOnStatus(item.id)} key={item.id} data-testid={item.label}>
            <ColoredCircleLabel title={item.label} circleColor={item.color} labelColor="#00B0D7" />
          </DropDownItem>
        ))}
      </DropDown>
    </>
  );
}

CircleIconLabelDropDown.propTypes = {};

export const StyledColoredCheckLabel = styled(Checkbox)`
  && {
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin: 0;
    >span: first-child {
      padding: 0;
    }
    > span {
      font-family: inherit;
      color: ${(props) => (props.labelColor ? props.labelColor : '#363747' /* todo: use theme! */)};
      :last-child {
        line-height: 14px;
      }
    }
    svg {
      font-size: 24px;
      margin-right: 6px;
      color: ${(props) => (props.checkColor ? props.checkColor : 'rgba(0, 0, 0, 0.54)')};
    }
    .Mui-checked svg {
      color: ${(props) => (props.checkedColor ? props.checkedColor : props.theme.palette.primary.main)};
    }
  }
`;

export function CheckLabelDropDown(props) {
  const { onItemClick, data, onClose } = props;

  const [dropDownData, setDropDownData] = React.useState(null);

  React.useEffect(() => {
    if (data) {
      setDropDownData(data);
    }
  }, [data]);

  const [anchorElForActivity, setAnchorElForActivity] = React.useState(null);

  const handleClickOnActivity = (event) => {
    if (anchorElForActivity) setAnchorElForActivity(null);
    else setAnchorElForActivity(event.currentTarget);
  };

  const handleCloseOnActivity = () => {
    setAnchorElForActivity(null);
    onClose && onClose();
  };

  const onChange =
    (itemId) =>
    ({ target: { name, checked } }) => {
      setDropDownData(dropDownData.map((item) => (item.id === name ? { ...item, value: checked } : item)));
      onItemClick(itemId, checked);
    };

  function selectedValues() {
    return (
      <Box display="flex" flexWrap="wrap">
        {dropDownData &&
          dropDownData
            .filter((item) => item.value)
            .map((item, index, arr) => (
              <>
                <Typography>
                  <Box color={item.rgbColor} component="span">
                    {item.name}
                  </Box>
                  {index < arr.length - 1 ? ',' : ''}
                  &nbsp;
                </Typography>
              </>
            ))}
      </Box>
    );
  }

  return (
    <>
      <DropDownIconLabelButton
        startIcon={<FlagIcon />}
        startIconColor="black"
        startIconFontSize={24}
        fontColor="black"
        onClick={handleClickOnActivity}
        endIcon={<ArrowDropDownIcon />}
      >
        {selectedValues()}
      </DropDownIconLabelButton>
      <DropDown
        id="status-menu"
        anchorEl={anchorElForActivity}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElForActivity)}
        onClose={handleCloseOnActivity}
      >
        {dropDownData &&
          dropDownData.map((item) => (
            <DropDownItem>
              <StyledColoredCheckLabel
                fullWidth={false}
                type="checkbox"
                label={item.name}
                name={`${item.id}`}
                checked={item.value}
                labelColor={item.rgbColor}
                onChange={onChange(item.id, !item.value)}
              />
            </DropDownItem>
          ))}
      </DropDown>
    </>
  );
}

CheckLabelDropDown.propTypes = {};

export const FullWidthBox = styled(Box)`
  && {
    width: 100%;
    > div.highlight {
      &:nth-last-child(1) {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &:nth-last-child(3) {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
`;

export const BluePenIcon = styled(CreateIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`;
export const BlueDeleteIcon = styled(DeleteIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`;
export const BlueVisibilityIcon = styled(VisibilityIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`;
export const BlueRightArrorIcon = styled(ArrowForwardIcon)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 30px;
`;
export const BlueRoomIcon = styled(RoomIcon)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 30px;
`;
export const BlueMapIcon = styled(MapIcon)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 30px;
`;

const StyledWhiteInput = styled(TextInput)`
  && {
    width: ${(props) => props.width};
    label {
      color: rgba(0, 0, 0, 0.54);
      &.Mui-focused {
        color: #00b0d7;
      }
      span {
        color: black;
      }
    }
    label + div {
      background-color: white;
      :after {
        border-bottom-color: #00b0d7;
      }
      >div: nth-child(2):focus {
        background-color: unset;
      }
      >div: first-child:focus {
        background-color: white;
      }
    }
    svg {
      color: #00b0d7;
    }
    input {
      color: black;
      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.54);
      }
    }
    input + svg {
      color: rgba(0, 0, 0, 0.54);
    }
    > div {
      background-color: white;
    }
    .Mui-checked {
      color: #00bcd4;
    }
    .Mui-checked > span > svg {
      color: #00bcd4;
    }
  }
`;

const StyledWhiteSelect = styled(Select)`
  && {
    width: ${(props) => props.width};
    label {
      color: rgba(0, 0, 0, 0.54);
      &.Mui-focused {
        color: #00b0d7;
      }
      span {
        color: black;
      }
    }
    label + div {
      background-color: white;
      :after {
        border-bottom-color: #00b0d7;
      }
      >div: nth-child(2):focus {
        background-color: unset;
      }
      >div: first-child:focus {
        background-color: white;
      }
    }
    svg {
      color: #00b0d7;
    }
    input {
      color: black;
      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.54);
      }
    }
    input + svg {
      color: rgba(0, 0, 0, 0.54);
    }
    > div {
      background-color: white;
    }
    .Mui-checked {
      color: #00bcd4;
    }
    .Mui-checked > span > svg {
      color: #00bcd4;
    }
  }
`;

export const WhiteInput = (props) => {
  const { margin } = props;
  return (
    <StyledWhiteInput
      margin={margin || 'none'}
      InputProps={{
        disableUnderline: true,
      }}
      {...props}
    />
  );
};

export const WhiteSelect = (props) => {
  const { margin } = props;
  return <StyledWhiteSelect margin={margin || 'none'} {...props} />;
};

WhiteInput.propTypes = {
  margin: PropTypes.string,
};

WhiteInput.defaultProps = {
  margin: 'none',
};

export const TagTableContainer = styled(Paper)`
  && {
    width: 200px;
    height: fit-content;
    :last-child {
      margin-left: 8px;
    }
    :first-child {
      margin-left: 0px;
    }
    table {
      border-collapse: separate;
      thead > tr > th {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: white;
        border: none;
        padding: 0 12px;
        height: 29px;
      }
      thead > tr:first-child > th {
        border-right: 1px solid white;
        p {
          color: white;
          font-weight: 500;
          font-size: 20px;
        }
        :last-child {
          border: none;
        }
      }
      thead > tr:last-child > th {
        p {
          color: white;
          font-weight: 500;
          font-size: 16px;
        }
        font-weight: bold;
        font-size: 16px;
        color: white;
        :nth-child(3),
        :nth-child(5),
        :nth-child(7),
        :nth-child(8) {
          border-right: 1px solid white;
        }
      }
      tbody > tr > td {
        padding: 0;
        border: none;
      }
      tbody > tr:last-child > td > * {
        margin-bottom: 4px;
      }
      tbody > tr > td {
        p {
          font-weight: normal;
          font-size: 14px;
          background-color: #f5f5f6;
          margin: 4px 4px 0 4px;
          display: flex;
          align-items: center;
          padding: 2px 6px;
          line-height: unset;
        }
      }
    }
  }
`;

export const ContentTableContainer = styled(Paper)`
  && {
    height: fit-content;
    :last-child {
      margin-left: 8px;
    }
    :first-child {
      margin-left: 0px;
    }
    table {
      border-collapse: separate;
      button svg {
        color: #00b0d7;
      }
      thead > tr > th {
        background-color: #00b0d7;
        color: white;
        border: none;
        padding: 0 4px;
        height: 29px;
      }
      thead > tr:first-child > th {
        text-align: center;
        p {
          color: white;
          font-weight: 500;
          font-size: 20px;
        }
      }
      thead > tr:last-child > th {
        p {
          color: white;
          font-weight: 500;
          font-size: 15px;
        }
        font-weight: bold;
        font-size: 16px;
        color: white;
        text-align: ${({ $headerAlign }) => $headerAlign || 'center'};
      }
      tbody > tr > td {
        border-right: 1px solid #d7d7da;
        border-bottom: 1px solid #d7d7da;
        background-color: #f5f5f6;
        padding: 4px 6px;
        white-space: nowrap;
        :last-child {
          border-right: none;
        }
      }
    }
  }
`;

export const TableLabel = styled(Typography)`
  && {
    width: ${(props) => (props.width ? props.width : 'unset')};
    word-break: break-word;
  }
`;

export const ContentContainer = styled(Box)`
  && {
    position: relative;
    height: fit-content;
    > div:first-child {
      z-index: 1;
    }
    .totalTag {
      z-index: 0;
      position: absolute;
      bottom: -42px;
      width: 140px;
      padding: 8px 16px;
      right: 49px;
      display: flex;
      justify-content: space-between;
      height: 50px;
      align-items: flex-end;
    }
  }
`;

export const FormControlLabelWithRadio = styled(FormControlLabel)`
  && {
    width: ${(props) => (props.fullWidth === true || props.fullWidth === undefined ? '100%' : 'unset')};
    .MuiFormControlLabel-label {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: black;
    }
    .MuiRadio-root.Mui-checked {
      color: #00b0d7;
    }
  }
`;

FormControlLabelWithRadio.propTypes = {
  fullWidth: PropTypes.bool.isRequired,
};

export const RadioContentWrapper = styled(Box)`
  && {
    >div: first-child {

    }
    p {
      white-space: nowrap;
    }
  }
`;

export const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#b2dfdb',
  },
  barColorPrimary: {
    backgroundColor: '#00695c',
  },
  root: {
    borderRadius: 4,
  },
})(LinearProgress);
