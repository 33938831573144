import { GlobalVar } from 'GlobalVar';
import axios from 'axios';
import { getIn, setIn } from 'immutable';

import authAPI from 'admin/api/AuthAPI';

const handleExpiredSession = (response: any) => {
  if (response && (response.status === 401 || response.status === 403)) {
    if (!response.config.ignoreSessionCheck) {
      authAPI.readCurrentUser().catch(() => {
        GlobalVar.setValue('isSessionExpired', true);
      });
    }
  }
};

const responseErrorInterceptor: Parameters<typeof axios.interceptors.response.use>[1] = (error) => {
  handleExpiredSession(error.response);
  const referenceCode = getIn(error?.response, ['data', 'referenceCode'], null);

  if (referenceCode) {
    error.response = setIn(
      error.response,
      ['data', 'errors'],
      [
        {
          message: `There was an error processing your request. We apologize for any inconvenience. Please reference code ${referenceCode} when contacting support. `,
        },
      ],
    );
  }

  if (error?.config?.fullResponse) {
    return Promise.reject(error);
  }

  return Promise.reject(
    error.response
      ? {
          ...error.response.data, // @ts-ignore
          errors: (error.response.data.errors || []).map((item) => ({
            ...item,
            message: (item.message || '').replace('Invalid Request: ', ''),
          })),
        }
      : error,
  );
};
export { responseErrorInterceptor };
