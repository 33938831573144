import { Waypoint } from '@elromcoinc/react-shared';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto from './UpdatedValueDto';
import { extractServiceIndexUtil } from './extractServiceIndexUtil';

const addedAdditionalStop: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('waypoints') > -1) {
    return newOrder
      .getAddressWaypointsBasedOnService()
      .filter((wp: Waypoint) => wp.id === null)
      .map((wp: Waypoint) => wp.address.fullAddressLine() as string)
      .map((newValue: string) =>
        createUpdatedValueDto(
          '',
          newValue,
          'Additional Stop',
          extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
        ),
      )
      .toArray();
  }
  return null;
};

export default addedAdditionalStop;
