import React, { FC, useMemo, useState } from 'react';

import { SettingName as SharedSettingNames } from '@elromcoinc/moveboard-setting-react';
import {
  ActivitySourceType,
  AdminPermissions,
  BACKEND_DATE_FORMAT,
  BodyText,
  IconButton,
  InvoiceDto,
  InvoiceEntity,
  InvoiceItemEntity,
  InvoiceType,
  InvoicesStatusType,
  Modal,
  OverridableValue,
  PaymentActivityDto,
  PaymentAdjustmentDTO,
  PaymentAdjustmentType,
  PaymentPermissions,
  Service,
  UI_DATE_FORMAT_SHORT,
  ValuationType,
  getServicePropertyName,
  getServiceRosterClosingPropertyName,
  isLongDistanceService,
  useConfirm,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, List, ListItem, ListItemText, Paper, Popover, Tooltip, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import { format, parse } from 'date-fns';
import { List as ImmutableList, Map } from 'immutable';
import fileDownload from 'js-file-download';
import { OptionsObject, useSnackbar } from 'notistack';

import invoiceAPI from 'admin/api/InvoiceAPI';
import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { useOrderServiceIndex, useOrderState, useOrderWindowSettings } from 'admin/components/OrderWindow/context';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { EMAIL, FIRST_NAME, LAST_NAME } from 'admin/components/OrderWindow/modals/FullAddressesModal';
import { AccountInvoiceModal } from 'admin/components/OrderWindow/modals/Invoices/AccountInvoiceModal';
import EditInvoice from 'admin/components/OrderWindow/modals/Invoices/EditInvoice';
import { useInvoiceModalContext } from 'admin/components/OrderWindow/modals/Invoices/InvoiceModalContext';
import { ADDRESS } from 'admin/components/OrderWindow/modals/Invoices/InvoicesItemLabels';
import InvoicesTable from 'admin/components/OrderWindow/modals/Invoices/InvoicesTable';
import { getOrderPartInvoice } from 'admin/components/OrderWindow/modals/Invoices/getOrderPartInvoice';
import { sendInvoiceEmail } from 'admin/components/OrderWindow/modals/Invoices/sendInvoiceEmail';
import { CONTACT_INFO, PRIMARY_PHONE } from 'admin/constants/FieldNames';
import SettingName from 'admin/constants/SettingName';
import { InvoiceModalType, InvoicesPopoverName } from 'admin/entities/InoviceModalType';
import { AutomationRuleName, CommunicationModality, InvoiceActionType, Template } from 'common-types';
import { EmailFormDto } from 'common-types/EmailFormDto';
import { toEmailDTO } from 'common/utils';

import { getExportedInvoiceName } from './getExportedInvoiceName';

const useStyles = makeStyles(({ spacing }) => ({
  modal: {
    position: 'relative',

    '& .MuiPaper-root': {
      borderRadius: spacing(0.75),
    },
  },
  root: {
    position: 'relative',
    borderRadius: 0,
    marginBottom: '100px',
    '& table tbody': {
      '&  td': {
        border: 'none',
      },
      '& tr:nth-child(even)': {
        backgroundColor: '#f5f5f6',
      },
    },
  },
  addIcon: {
    position: 'absolute',
    marginTop: '-18px',
    right: -10,
  },
  export: {
    position: 'absolute',
    padding: '5px',
    top: 4,
    zIndex: 1000,
    right: 40,
  },
}));

const { TRAVEL_TIME_ENABLED, TRAVEL_TIME_IN_DOLLAR } = SettingName;
const { GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES } = SharedSettingNames;

const MOVING_CHARGE = 'Moving Charge';
const LINEHAU_CHARGE = 'Linehaul Charge';
const RECIPIENTS_NAME = 'recipients';

enum InvoicePaymentAdjustmentType {
  TIP = 'TIP',
  CASH_TIP = 'CASH_TIP',
  CASH_DISCOUNT = 'CASH_DISCOUNT',
  CREDIT_CARD_FEE = 'CREDIT_CARD_FEE',
  CUSTOM_FEE = 'CUSTOM_FEE',
}

const pickupNames = Map({
  [InvoicePaymentAdjustmentType.TIP]: 'pickupTips',
  [InvoicePaymentAdjustmentType.CREDIT_CARD_FEE]: 'pickupCreditCardFees',
  [InvoicePaymentAdjustmentType.CASH_TIP]: 'pickupCashTips',
  [InvoicePaymentAdjustmentType.CASH_DISCOUNT]: 'pickupCashDiscounts',
  [InvoicePaymentAdjustmentType.CUSTOM_FEE]: 'pickupCustomFees',
});

const deliveryNames = Map({
  [PaymentAdjustmentType.TIP]: 'deliveryTips',
  [PaymentAdjustmentType.CREDIT_CARD_FEE]: 'deliveryCreditCardFees',
  [PaymentAdjustmentType.CASH_TIP]: 'deliveryCashTips',
  [PaymentAdjustmentType.CASH_DISCOUNT]: 'deliveryCashDiscounts',
  [PaymentAdjustmentType.CUSTOM_FEE]: 'deliveryCustomFees',
});

const names = Map({
  [InvoicePaymentAdjustmentType.TIP]: 'Tips',
  [InvoicePaymentAdjustmentType.CREDIT_CARD_FEE]: 'Credit Card Fees',
  [InvoicePaymentAdjustmentType.CASH_TIP]: 'Cash Tips',
  [InvoicePaymentAdjustmentType.CASH_DISCOUNT]: 'Cash Discounts',
  [InvoicePaymentAdjustmentType.CUSTOM_FEE]: 'Custom Fees',
});

interface InvoicesProps {
  open: boolean;
  onCancel: () => void;
  sourceId: number;
  activitySource: ActivitySourceType;
}

enum InvoicesModal {
  INVOICE = 'INVOICE',
}

const Invoices: FC<InvoicesProps> = ({ open, onCancel, sourceId, activitySource }) => {
  const classes = useStyles();
  // Order will be working only when activitySource === ActivitySourceType.ORDER. Maybe need to pass order as optional prop
  const { order } = useOrderState() || {};
  const [anchorElOfInvoices, setAnchorElOfInvoices] = useState<Element | null>(null);
  const [invoice, setInvoice] = useState<InvoiceEntity | null>(null);
  const possibleAddresses = useMemo(() => [order?.fromAddress, order?.toAddress].filter(Boolean), [order?.orderId]);
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex() || {};
  const settings = useOrderWindowSettings();
  const [inFlight, setInFLight] = useState(false);
  const { fetchInvoices, account, invoices } = useInvoiceModalContext();
  const { invoiceTemplates, automationRules } = useComposeEmail();
  const { selectedTemplateDTO, newEmailDefault } = useActivityLogContext();
  const [defaultInvoiceActionType, setDefaultInvoiceActionType] = useState<InvoiceActionType>(InvoiceActionType.NEW);
  const canEditInvoice = useHasPermission(PaymentPermissions.PERM_CAN_EDIT_AN_INVOICE);
  const canCreateAnInvoice = useHasPermission(PaymentPermissions.PERM_CREATE_INVOICE);
  const canViewAnInvoice = useHasPermission(AdminPermissions.PERM_CAN_VIEW_AN_INVOICE);
  const { enqueueSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false);
  const errorVariant: OptionsObject = { variant: 'error' };
  const successVariant: OptionsObject = { variant: 'success' };
  const [openModal, setOpenModal] = useState<InvoicesModal | null>(null);
  const totalPaid =
    (
      (isSelectedAllServices
        ? order?.closingOrderDetail ?? order
        : (order?.closingOrderDetail ?? order)?.getServiceQuote(serviceIndex)
      )?.payments as ImmutableList<PaymentActivityDto>
    )
      ?.filter((p) => !p.activitySources.some((as) => as.activitySource === ActivitySourceType.INVOICE))
      ?.reduce((acc, p) => acc + p.settledAmount ?? 0, 0) ?? 0;

  const { confirm: confirmDelete, renderDialog: ConfirmationDeleteDialog } = useConfirm({
    title: 'Warning',
    message: 'You are about to remove this invoice. Do you wish to proceed?',
    confirmTitle: 'Yes, Delete',
    cancelTitle: 'No, Cancel',
  });

  const toggleOpenModal =
    (modal: InvoicesModal | null = null) =>
    () => {
      setOpenModal(modal);

      if (modal) {
        handleClosePopover();
      }
    };

  const actionsInvoicesModal = [
    {
      label: 'Cancel',
      onClick: onCancel,
    },
    {
      label: 'Done',
      onClick: onCancel,
    },
  ];

  const handleServicesClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOfInvoices(event.target as Element);
  };

  const handleCloseInvoiceModal = () => {
    setInvoice(null);
  };

  const handleClosePopover = () => {
    setAnchorElOfInvoices(null);
  };

  const handleCreateCustomInvoice = () => {
    setDefaultInvoiceActionType(InvoiceActionType.NEW);
    const orderPartInvoice: Partial<InvoiceDto> = getOrderPartInvoice({
      order: order!,
      quote: !isSelectedAllServices ? order?.getServiceQuote(serviceIndex) : undefined,
    });
    const accountPartInvoice: Partial<InvoiceDto> = account
      ? {
          accountId: account.id,
          accountName: account.name,
          customerId: account?.ownerCustomerInfo?.customerId,
          [ADDRESS]: account?.ownerCustomerInfo?.address ?? {},
          [CONTACT_INFO]: {
            [FIRST_NAME]: account?.ownerCustomerInfo?.firstName ?? '',
            [LAST_NAME]: account?.ownerCustomerInfo?.lastName ?? '',
            [EMAIL]: account?.ownerCustomerInfo?.email ?? '',
            [PRIMARY_PHONE]: account?.ownerCustomerInfo?.primaryPhone?.number ?? '',
          },
        }
      : {};

    setInvoice(
      new InvoiceEntity({
        sourceId,
        activitySource,
        ...(activitySource === ActivitySourceType.CUSTOMER_ACCOUNT ? accountPartInvoice : orderPartInvoice),
        items: [new InvoiceItemEntity()],
        type: InvoiceType.CUSTOM,
      }),
    );
    handleClosePopover();
  };

  const handleCreateOrderInvoice = (invoiceType: InvoiceModalType) => () => {
    setDefaultInvoiceActionType(InvoiceActionType.NEW);
    const isClosing = invoiceType === InvoiceModalType.CLOSING_INVOICE;
    const orderType = isClosing ? order?.closingOrderDetail! : order!;
    const orderPartInvoice: Partial<InvoiceDto> = getOrderPartInvoice({
      order: order!,
      quote: !isSelectedAllServices ? orderType?.getServiceQuote(serviceIndex) : undefined,
      includeTax: true,
      isClosing,
    });
    const service = orderType?.services.get(serviceIndex);
    const isLongDistance = isLongDistanceService(service?.getType());
    const { date } = service || {};
    const moveDate = format(parse(date, BACKEND_DATE_FORMAT, new Date()), UI_DATE_FORMAT_SHORT);
    const firstServiceQuote = orderType?.getServiceQuote();
    const isFlatRate = firstServiceQuote.isFlatRate();
    const quote = orderType?.getServiceQuote(serviceIndex);
    const isTravelRateTheSameAsLaborRate = settings?.[GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES];
    orderPartInvoice.paidAmount = totalPaid;

    const shouldDisplayTravelCharge =
      settings[TRAVEL_TIME_ENABLED] && settings[TRAVEL_TIME_IN_DOLLAR] && !quote.isFlatRate();
    const items: InvoiceItemEntity[] = [];

    if (isClosing) {
      const valuation = orderType?.valuation;
      const paymentAdjustmentDtos = orderType?.serviceRosterClosingsDto?.get(serviceIndex)?.paymentAdjustmentDtos;
      const additionalWeight = paymentAdjustmentDtos.filter(
        (pad: PaymentAdjustmentDTO) => pad.paymentAdjustmentType === PaymentAdjustmentType.ADDITIONAL_WEIGHT_CHARGE,
      );

      if (ValuationType.FULL === valuation?.type) {
        items.push(
          new InvoiceItemEntity({
            name: 'Valuation Charge',
            quantity: 1,
            description: moveDate,
            unitCost: valuation.cost,
            total: OverridableValue.createOverridableValue({
              calculated: valuation.cost,
            }),
          }),
        );
      }

      if (!additionalWeight.isEmpty()) {
        additionalWeight.forEach((pa: PaymentAdjustmentDTO) => {
          items.push(
            new InvoiceItemEntity({
              name: 'Additional Volume',
              quantity: pa?.rate?.amount,
              description: moveDate,
              unitCost: pa?.quote,
              total: OverridableValue.createOverridableValue({
                calculated: pa?.total,
              }),
            }),
          );
        });
      }
    }

    if (isFlatRate && !isLongDistance) {
      const flatRateCharge = isSelectedAllServices
        ? orderType?.services.reduce((accumulator: number, s: Service) => accumulator + s.quote.totalFlatRateCharge, 0)
        : quote.totalFlatRateCharge;

      if (flatRateCharge) {
        items.push(
          new InvoiceItemEntity({
            name: isLongDistance ? 'Flat Rate' : 'Flat Rate Charge',
            quantity: 1,
            description: moveDate,
            unitCost: flatRateCharge,
            total: OverridableValue.createOverridableValue({
              calculated: flatRateCharge,
            }),
          }),
        );
      }
    }

    if (isLongDistance) {
      const totalLinehaulCharge = (isSelectedAllServices ? orderType : quote).baseQuoteWithoutTaxes.maxValue;

      if (totalLinehaulCharge) {
        items.push(
          new InvoiceItemEntity({
            name: LINEHAU_CHARGE,
            quantity: 1,
            description: moveDate,
            unitCost: totalLinehaulCharge,
            total: OverridableValue.createOverridableValue({
              calculated: totalLinehaulCharge,
            }),
          }),
        );
      }
    }

    if ((isTravelRateTheSameAsLaborRate || !shouldDisplayTravelCharge) && !isFlatRate && !isLongDistance) {
      const movingCharge = (isSelectedAllServices ? orderType : quote).baseQuoteWithoutTaxes.maxValue;

      items.push(
        new InvoiceItemEntity({
          name: MOVING_CHARGE,
          quantity: 1,
          description: moveDate,
          unitCost: movingCharge,
          total: OverridableValue.createOverridableValue({
            calculated: movingCharge,
          }),
        }),
      );
    }

    if (!isLongDistance && !isFlatRate && !(isTravelRateTheSameAsLaborRate || !shouldDisplayTravelCharge)) {
      const totalLaborQuote = (isSelectedAllServices ? orderType : quote).totalLaborQuote.maxValue;
      const baseQuote = (isSelectedAllServices ? orderType : quote).baseQuoteWithoutTaxes.maxValue;
      const label = shouldDisplayTravelCharge ? 'Labor Charge' : MOVING_CHARGE;
      const value = shouldDisplayTravelCharge ? totalLaborQuote : baseQuote;
      const totalTravelQuote = isSelectedAllServices
        ? orderType?.services.reduce((accumulator: number, s: Service) => accumulator + s.quote.totalTravelQuote, 0)
        : quote.totalTravelQuote;

      items.push(
        new InvoiceItemEntity({
          name: label,
          quantity: 1,
          description: moveDate,
          unitCost: value,
          total: OverridableValue.createOverridableValue({
            calculated: value,
          }),
        }),
      );

      if (shouldDisplayTravelCharge) {
        items.push(
          new InvoiceItemEntity({
            name: 'Travel Time Charge',
            quantity: 1,
            description: moveDate,
            unitCost: totalTravelQuote,
            total: OverridableValue.createOverridableValue({
              calculated: totalTravelQuote,
            }),
          }),
        );
      }
    }

    const additionalFeesValue = orderType?.getCustomerFeesTotal(serviceIndex, isSelectedAllServices).maxValue;

    if (!!additionalFeesValue!) {
      items.push(
        new InvoiceItemEntity({
          name: 'Other Fees',
          quantity: 1,
          description: moveDate,
          unitCost: additionalFeesValue,
          total: OverridableValue.createOverridableValue({
            calculated: additionalFeesValue,
          }),
        }),
      );
    }

    const additionalServicesValue = orderType?.getAdditionalServicesTotal(serviceIndex, isSelectedAllServices).maxValue;

    if (!!additionalServicesValue!) {
      items.push(
        new InvoiceItemEntity({
          name: 'Additional Services',
          quantity: 1,
          description: moveDate,
          unitCost: additionalServicesValue,
          total: OverridableValue.createOverridableValue({
            calculated: additionalServicesValue,
          }),
        }),
      );
    }

    const basePackingQuote = orderType?.getMaterialsTotal(serviceIndex, isSelectedAllServices).maxValue;

    if (!!basePackingQuote!) {
      items.push(
        new InvoiceItemEntity({
          name: 'Materials',
          quantity: 1,
          description: moveDate,
          unitCost: basePackingQuote,
          total: OverridableValue.createOverridableValue({
            calculated: basePackingQuote,
          }),
        }),
      );
    }

    const fuelSurchargeValue = orderType?.getServiceFuelTotal(serviceIndex, isSelectedAllServices).maxValue;

    if (!!fuelSurchargeValue) {
      items.push(
        new InvoiceItemEntity({
          name: 'Fuel Surcharge',
          quantity: 1,
          description: moveDate,
          unitCost: fuelSurchargeValue,
          total: OverridableValue.createOverridableValue({
            calculated: fuelSurchargeValue,
          }),
        }),
      );
    }

    const storageValue = orderType?.getStorageTotal(serviceIndex).maxValue;

    if (!!storageValue) {
      items.push(
        new InvoiceItemEntity({
          name: 'Storage',
          quantity: 1,
          description: moveDate,
          unitCost: storageValue,
          total: OverridableValue.createOverridableValue({
            calculated: storageValue,
          }),
        }),
      );
    }

    Object.values(InvoicePaymentAdjustmentType).forEach((type) => {
      const currentPickupName = isClosing
        ? getServiceRosterClosingPropertyName(serviceIndex, pickupNames.get(type) ?? '')
        : getServicePropertyName(serviceIndex, pickupNames.get(type) ?? '');
      const currentValue = (order?.getIn(currentPickupName.split('.')) as number) ?? 0;

      if (currentValue !== 0) {
        items.push(
          new InvoiceItemEntity({
            name: names.get(type),
            quantity: 1,
            description: moveDate,
            unitCost: type === InvoicePaymentAdjustmentType.CASH_DISCOUNT ? -currentValue : currentValue,
            total: OverridableValue.createOverridableValue({
              calculated: type === InvoicePaymentAdjustmentType.CASH_DISCOUNT ? -currentValue : currentValue,
            }),
          }),
        );
      }

      if (isLongDistance) {
        const currentDeliveryName = isClosing
          ? getServiceRosterClosingPropertyName(serviceIndex, deliveryNames.get(type) ?? '')
          : getServicePropertyName(serviceIndex, deliveryNames.get(type) ?? '');
        const currentValue = (order?.getIn(currentDeliveryName.split('.')) as number) ?? 0;

        if (currentValue !== 0) {
          items.push(
            new InvoiceItemEntity({
              name: `${names.get(type)} (delivery)`,
              quantity: 1,
              description: moveDate,
              unitCost: type === InvoicePaymentAdjustmentType.CASH_DISCOUNT ? -currentValue : currentValue,
              total: OverridableValue.createOverridableValue({
                calculated: type === InvoicePaymentAdjustmentType.CASH_DISCOUNT ? -currentValue : currentValue,
              }),
            }),
          );
        }
      }
    });

    let type = isClosing ? InvoiceType.ORDER_CLOSING : InvoiceType.ORDER_SALES;

    setInvoice(new InvoiceEntity({ sourceId, activitySource, ...orderPartInvoice, items, type }));
    handleClosePopover();
  };

  const handleInvoiceAction = async (invoice: InvoiceDto, invoiceAction: InvoiceActionType) => {
    if (!canEditInvoice && invoiceAction === InvoiceActionType.EDIT) {
      enqueueSnackbar('You have no permission to edit invoices', errorVariant);
      return;
    }
    if (!canViewAnInvoice && invoiceAction === InvoiceActionType.VIEW) {
      enqueueSnackbar('You have no permission to view invoices', errorVariant);
      return;
    }
    if (invoiceAction === InvoiceActionType.DELETE) {
      const answer = await confirmDelete();
      if (answer) {
        setInFLight(true);
        invoiceAPI
          .delete(invoice.id!)
          .then(() => {
            enqueueSnackbar('Invoice has been deleted successfully', successVariant);
            fetchInvoices();
            setInFLight(false);
          })
          .catch(() => {
            enqueueSnackbar('Invoice was not deleted, please try again', errorVariant);
            setInFLight(false);
          });
      }
      return;
    }
    setDefaultInvoiceActionType(invoiceAction);
    setInvoice(new InvoiceEntity(invoice));
  };

  const changeInvoiceStatus = (invoice: InvoiceDto) => {
    return invoiceAPI
      .changeInvoicesStatus(invoice.status, [invoice.id!])
      .then((response) => {
        enqueueSnackbar('Invoice has been saved', successVariant);
        fetchInvoices();
        return response?.[0];
      })
      .catch(() => {
        enqueueSnackbar(`Invoice wasn't saved. Please try again later.`, errorVariant);
        setInFLight(false);
      })
      .then((response) => {
        return response;
      });
  };

  const handleSendOrResendInvoice = async (status: InvoicesStatusType, invoice: InvoiceDto) => {
    if (!canEditInvoice) {
      enqueueSnackbar('You have no permission to edit invoices', errorVariant);
      return;
    }

    setInFLight(true);

    const invoiceAutomationRule = automationRules.find(
      (ar) => ar.shortName === AutomationRuleName.WHEN_A_EMPLOYEE_CREATES_AN_INVOICE,
    );
    const firstTemplate = invoiceTemplates.find(
      (t) => t.modality === CommunicationModality.EMAIL && t.automationRuleId === invoiceAutomationRule?.id && t.active,
    );
    const defaultTemplate = new Template(firstTemplate?.toDTO({ useExistingBody: true }) ?? {});

    const updatedInvoice = {
      ...invoice,
      status,
    };
    const sendTemplates = defaultTemplate || new Template(selectedTemplateDTO?.toDTO({ useExistingBody: true }) ?? {});

    if (!sendTemplates) {
      enqueueSnackbar('Do not found template. Please check templates.', errorVariant);
      return;
    }

    const emailDTO = toEmailDTO(
      (newEmailDefault || {
        [RECIPIENTS_NAME]: updatedInvoice.contactInfo.email || '',
        ...defaultTemplate.toJS(),
      }) as EmailFormDto,
    );

    changeInvoiceStatus(updatedInvoice).then((invoice) => {
      sendInvoiceEmail({ templates: [sendTemplates], emailDTO }, invoice!)
        .then(() => {
          setInFLight(false);
          enqueueSnackbar(
            `Emails was successfully ${
              status === InvoicesStatusType.RESENT || status === InvoicesStatusType.PAID ? 'resend' : 'send'
            } to client.`,
            successVariant,
          );
        })
        .catch(() => {
          setInFLight(false);
          enqueueSnackbar(
            `Problem with ${
              status === InvoicesStatusType.RESENT || status === InvoicesStatusType.PAID ? 'resending' : 'sending'
            } emails please check SMTP setting.`,
            errorVariant,
          );
        });
    });
  };

  const handleExportInvoice = () => {
    setIsDownloading(true);
    const orderId = order?.orderId;

    return invoiceAPI
      .downloadOrderInvoice({ sortBy: 'id', sortOrder: 'desc', orderId })
      .then((res) => {
        fileDownload(res, getExportedInvoiceName());
        enqueueSnackbar('The CSV file with the all invoices has been successfully downloaded', successVariant);
      })
      .catch(() => {
        enqueueSnackbar(`Can't download a CSV file with the all invoices`, errorVariant);
      })
      .then(() => {
        setIsDownloading(false);
      });
  };

  const handleSaveAccountOrderInvoices = () => {
    fetchInvoices();
    toggleOpenModal()();
  };

  return (
    <Modal
      minimalPaddingTitle
      maxWidth="md"
      open={open}
      actions={actionsInvoicesModal}
      title="Invoices"
      className={classes.modal}
      onClose={onCancel}
    >
      <Box className={classes.export}>
        {!!invoices.length && (
          <Tooltip title="Export">
            <Box component="span">
              <IconButton color="inherit" onClick={handleExportInvoice} disabled={isDownloading}>
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Box>
      <Paper className={classes.root}>
        <InvoicesTable
          sendOrResendInvoice={handleSendOrResendInvoice}
          handleInvoiceAction={handleInvoiceAction}
          inFlight={inFlight}
        />
        {canCreateAnInvoice && (
          <Box className={classes.addIcon}>
            <IconButton color="primary" variant="default" size="medium" onClick={handleServicesClick}>
              <AddIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorElOfInvoices)}
              anchorEl={anchorElOfInvoices}
              id="services-list"
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <List disablePadding>
                {activitySource === ActivitySourceType.CUSTOMER_ACCOUNT && (
                  <ListItem button dense component="li" onClick={toggleOpenModal(InvoicesModal.INVOICE)}>
                    <ListItemText
                      primary={<BodyText>{InvoicesPopoverName[InvoiceModalType.ACCOUNT_INVOICE]}</BodyText>}
                    />
                  </ListItem>
                )}
                {activitySource === ActivitySourceType.ORDER && (
                  <ListItem
                    button
                    dense
                    component="li"
                    onClick={handleCreateOrderInvoice(InvoiceModalType.ORDER_INVOICE)}
                  >
                    <ListItemText
                      primary={<BodyText>{InvoicesPopoverName[InvoiceModalType.ORDER_INVOICE]}</BodyText>}
                    />
                  </ListItem>
                )}
                {!!order?.closingOrderDetail && (
                  <ListItem
                    button
                    dense
                    component="li"
                    onClick={handleCreateOrderInvoice(InvoiceModalType.CLOSING_INVOICE)}
                  >
                    <ListItemText
                      primary={<BodyText>{InvoicesPopoverName[InvoiceModalType.CLOSING_INVOICE]}</BodyText>}
                    />
                  </ListItem>
                )}
                <ListItem button dense component="li" onClick={handleCreateCustomInvoice}>
                  <ListItemText primary={<BodyText>{InvoicesPopoverName[InvoiceModalType.CUSTOM_INVOICE]}</BodyText>} />
                </ListItem>
              </List>
            </Popover>
          </Box>
        )}
      </Paper>
      {invoice && (
        <EditInvoice
          possibleAddresses={possibleAddresses}
          defaultInvoiceActionType={defaultInvoiceActionType}
          invoice={invoice}
          setInvoice={setInvoice}
          onClose={handleCloseInvoiceModal}
          fetchInvoices={fetchInvoices}
          open
          orderTotalPaid={totalPaid}
        />
      )}
      {openModal === InvoicesModal.INVOICE && (
        <AccountInvoiceModal onClose={toggleOpenModal()} onSave={handleSaveAccountOrderInvoices} />
      )}
      {ConfirmationDeleteDialog()}
    </Modal>
  );
};

export default Invoices;
