import axios from 'axios';

import { LocalDispatchServiceSummaryDto } from 'common-types/dispatch';

class ServiceSummaryApi {
  getLocalDispatchServices<T = LocalDispatchServiceSummaryDto>(startDate: string, endDate: string) {
    const controller = new AbortController();
    const signal = controller.signal;

    return {
      promise: axios.get<LocalDispatchServiceSummaryDto, LocalDispatchServiceSummaryDto[]>(
        `/api/moving/services/localDispatch/summaries/startDate/${startDate}/endDate/${endDate}`,
        {
          params: { signal },
          data: {},
        },
      ),
      cancel(message: string) {
        controller.abort(message);
      },
    } as CancelablePromise<T[]>;
  }
}

export default new ServiceSummaryApi();
