import React, { useState } from 'react';

import { BodyBigText, BodySmallText, IconButton, Truck, statusIds } from '@elromcoinc/react-shared';
import { Box, LinearProgress, Paper, createStyles, makeStyles } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';

import OrderDetailsTooltip from 'admin/components/Calendar/common/OrderDetailsTooltip';
import Header from 'admin/components/OrderWindow/SchedulerBox/Header';
import Job from 'admin/components/OrderWindow/SchedulerBox/Job';
import WeekView from 'admin/components/OrderWindow/SchedulerBox/WeekView/WeekView';
import { useOrderState } from 'admin/components/OrderWindow/context';
import { TripPlanerType } from 'common-types';

import DayView from './DayView/DayView';

const useStyles = makeStyles((theme) =>
  createStyles({
    schedulerPanel: {
      paddingTop: '1rem',
    },
    errorContent: {
      justifyContent: 'center',
      padding: 0,
    },
    outlinedError: {
      color: 'none',
      border: 'none',
    },
    errorMessage: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
    },
    alertIcon: {
      padding: '3px 0',
    },
    closing: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.action.disabledBackground,
      display: 'flex',
      justifyContent: 'center',
      zIndex: theme.zIndex.tooltip,
      pointerEvents: 'none',
    },
    closingText: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      boxShadow: theme.shadows[1],
      borderRadius: theme.spacing(1),
      userSelect: 'none',
      pointerEvents: 'none',
      height: theme.spacing(8),
      width: theme.spacing(10),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(5.5),
    },
    collapsedButton: {
      zIndex: theme.zIndex.tooltip,
      position: 'absolute',
      right: 0,
      top: -40,
    },
    collapsed: {
      height: '125px',
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        height: '165px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '185px',
      },
    },
  }),
);

interface SchedulerBoxProps {
  date: Date;
  onDateChange: (date: Date) => void;
  toggleViewMode: () => void;
  onTruckClick: (truckId: number, date: Date) => void;
  dayMode: boolean;
  dense: boolean;
  moveDate?: string;
  trucks: Truck[];
  jobs: Job[];
  orderId?: number;
  numberOfTrucks?: number | null;
  longDistancePlanner?: TripPlanerType;
  deliveryDate?: string;
  inFlight: boolean;
  warningMessage?: string | null;
  isClosing?: boolean;
  isAvailableDeliveryDates?: boolean;
  deliveryDateStart?: string;
  deliveryDateEnd?: string;
  isOnModal?: boolean;
}

const SchedulerBox = ({
  moveDate,
  longDistancePlanner,
  deliveryDate,
  orderId,
  date,
  onDateChange,
  numberOfTrucks = null,
  dayMode,
  toggleViewMode,
  onTruckClick,
  trucks,
  dense,
  jobs,
  inFlight,
  warningMessage,
  isClosing,
  isAvailableDeliveryDates = false,
  deliveryDateStart,
  deliveryDateEnd,
  isOnModal = false,
}: SchedulerBoxProps) => {
  const classes = useStyles();
  const { originalOrder } = useOrderState();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const isBookedClosingOrder = originalOrder?.status === statusIds.BOOKED && isClosing;

  const handleCollapseTruckScheduler = (collapsed: boolean) => () => {
    setIsCollapsed(collapsed);
  };

  const renderJobTooltip = (job: Job, jobComponent: JSX.Element) => (
    <OrderDetailsTooltip key={job.uuid} job={job}>
      {jobComponent}
    </OrderDetailsTooltip>
  );

  return (
    <Paper
      square
      classes={{ root: !warningMessage ? classes.schedulerPanel : '' }}
      className={clsx('applyRadius', { [classes.collapsed]: isCollapsed && isBookedClosingOrder && !isOnModal })}
    >
      {warningMessage && (
        <Alert
          severity="error"
          variant="outlined"
          classes={{
            root: classes.errorContent,
            outlinedError: classes.outlinedError,
            message: classes.errorMessage,
            icon: classes.alertIcon,
          }}
        >
          <BodySmallText color="error">{warningMessage}</BodySmallText>
        </Alert>
      )}
      <Header
        longDistancePlanner={longDistancePlanner}
        deliveryDate={deliveryDate}
        date={date}
        dayMode={dayMode}
        moveDate={moveDate}
        onDateChange={onDateChange}
        numberOfTrucks={numberOfTrucks}
        onMoveDate={(selectedDate) => onDateChange(selectedDate)}
        toggleViewMode={toggleViewMode}
        isAvailableDeliveryDates={isAvailableDeliveryDates}
        deliveryDateStart={deliveryDateStart}
        deliveryDateEnd={deliveryDateEnd}
      />
      {inFlight && (
        <Box height={8} mb={-1}>
          <LinearProgress />
        </Box>
      )}
      <Box position="relative">
        {isBookedClosingOrder && !isOnModal && (
          <Box className={classes.collapsedButton}>
            <IconButton color="primary" onClick={handleCollapseTruckScheduler(!isCollapsed)} disabled={inFlight}>
              {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </Box>
        )}
        {isClosing && (
          <Box className={classes.closing}>
            <Box className={classes.closingText}>
              <LockIcon color="primary" />
              <BodyBigText>Locked</BodyBigText>
            </Box>
          </Box>
        )}
        {dayMode ? (
          <DayView
            orderId={orderId}
            date={date}
            trucks={trucks}
            jobs={jobs}
            onTruckClick={onTruckClick}
            dense={dense}
            renderJobTooltip={renderJobTooltip}
          />
        ) : (
          <WeekView
            orderId={orderId}
            date={date}
            dense
            onTruckClick={onTruckClick}
            trucks={trucks}
            jobs={jobs}
            renderJobTooltip={renderJobTooltip}
          />
        )}
      </Box>
    </Paper>
  );
};

export default SchedulerBox;
