import { Discount } from '@elromcoinc/react-shared';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto from './UpdatedValueDto';
import { extractServiceIndexUtil } from './extractServiceIndexUtil';

const getHumanReadableValue = (discount?: Discount): string => {
  if (discount && discount.amount && +discount.amount > 0) {
    return discount.asHumanReadableDiscount();
  }
  return '';
};

const discountChange: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (
    fieldPath.indexOf('discount') > -1 &&
    oldOrder.getValueIn(fieldPath, null) &&
    newOrder.getValueIn(fieldPath, null)
  ) {
    const oldValue = getHumanReadableValue(oldOrder.getValueIn(fieldPath, null) as Discount);
    const newValue = getHumanReadableValue(newOrder.getValueIn(fieldPath, null) as Discount);
    if (oldValue !== newValue) {
      return [
        createUpdatedValueDto(
          oldValue,
          newValue,
          'Discount',
          extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
        ),
      ];
    }
  }
  return null;
};

export default discountChange;
