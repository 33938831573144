import React, { ChangeEvent, FC, FormEventHandler, Fragment, useEffect, useState } from 'react';

import {
  BodyBigText,
  BodySmallText,
  BodyText,
  Button,
  ChargeProcessingFeeSettingsDto,
  CurrencyInput,
  Modal,
  Order,
  PaymentOptions,
  PriceAdjustmentType,
  Radio,
  RadioGroup,
  RoleName,
  ServiceRosterBillOfLadingDto,
  SizingStrategy,
  Switch,
  Tab,
  Tabs,
  TextInput,
  hasRole,
  useCurrentUser,
} from '@elromcoinc/react-shared';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  FilledInputProps,
  FormControl,
  FormLabel,
  Grid,
  SvgIcon,
  TextField,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import fileDownload from 'js-file-download';
import styled from 'styled-components';

import { fileAPI } from 'admin/api';
import {
  useOrderClosingContext,
  useOrderServiceIndex,
  useValidateStorageOnBe,
} from 'admin/components/OrderWindow/context';
import useOrderSizing from 'admin/components/OrderWindow/hooks/useOrderSizing';
import { paymentFeesSettingsOptions } from 'admin/constants';
import { getServiceRosterClosingPropertyName, roundTo2 } from 'admin/utils';
import { OrderConfirmationDTO } from 'common-types';

interface StyledCardProps {
  $disabled: boolean;
  $selected: boolean;
  theme: Theme;
}

const StyledCard = styled(Card)`
  pointer-events: ${({ $disabled }: StyledCardProps) => ($disabled ? `none ` : 'auto')};
  width: 160px;
  height: 160px;
  color: ${({ $selected, theme }: StyledCardProps) =>
    $selected ? `${theme.palette.common.white} !important; ` : 'inherit'};
  background-color: ${({ $selected, theme, $disabled }: StyledCardProps) => {
      if ($disabled) {
        return `${theme.palette.grey['400']} !important;`;
      }

      if ($selected) {
        return `${theme.palette.primary.main} !important;`;
      }

      return theme.palette.common.white;
    }}
    & label {
    ${({ $selected, theme }: StyledCardProps) => ($selected ? `color: ${theme.palette.common.white} !important;` : '')}
  }
`;
const StyledActionArea = styled(CardActionArea)`
  width: 100%;
  height: 100%;
`;
const StyledCardContent = styled(CardContent)`
  && {
    height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
`;
const StyledTextField = styled(TextField)`
  && {
    .MuiInput-underline:before {
      border-bottom: 1px solid white !important;
    }
    .MuiInput-underline:after {
      border-bottom: 2px solid white !important;
    }
    div {
      background-color: transparent;
    }
    input {
      color: white;
      text-align: center;
      background-color: transparent;
    }
  }
`;

const useStyles = makeStyles(({ spacing }) => ({
  roundedModal: {
    '& .MuiPaper-root': {
      borderRadius: spacing(0.75),
    },
  },
  roundedTab: {
    '&.MuiButtonBase-root': {
      borderRadius: spacing(0.75, 0.75, 0, 0),
    },
  },
}));

interface OrderSettingsProps {
  onSave: (value: { name: string; value: any }[]) => void;
  onCancel: () => void;
  open: boolean;
  order: Order;
}

const getOverriddenWeightFromCuFt = (weight: number, cuFt: number, conversionRate: number) =>
  weight !== null ? weight : Math.round((conversionRate || 0) * cuFt);

const renderIcon = (Icon: typeof SvgIcon) => <Icon fontSize="inherit" />;

const settingsTabs = { paymentSettings: 'Payment settings', paymentFees: 'Payment fees' };

const totalPercentOfPaymentsDueAtPickupName = 'totalPercentOfPaymentsDueAtPickup';

const OrderSettings: FC<OrderSettingsProps> = ({ onSave, onCancel, open, order }) => {
  const classes = useStyles();
  const { isCompleted, isClosing, isLockSales } = useOrderClosingContext();
  const isElromcoAdmin = hasRole(useCurrentUser()!, RoleName.ROLE_ELROMCO_ADMIN);
  const [paymentType, setPaymentType] = useState(PaymentOptions.COD);
  const { serviceIndex } = useOrderServiceIndex();

  const [chargeProcessingFee, setChargeProcessingFee] = useState<ChargeProcessingFeeSettingsDto>(
    order?.chargeProcessingFeeSettings,
  );
  const [settingsTab, setSettingsTab] = useState<string>(settingsTabs.paymentSettings);

  const [closingServiceRoster, setClosingServiceRoster] = useState<ServiceRosterBillOfLadingDto | null>(
    order?.closingOrderDetail?.serviceRosterClosingsDto.get(serviceIndex),
  );

  const {
    inventoryCuFt,
    sizeType,
    iconsByType,
    inventoryWeight,
    overriddenCuFt,
    overriddenWeight,
    setOverriddenCuFt,
    setOverriddenWeight,
    cuFtToPounds,
    setCuFtToPoundsConversion,
    setSizeType,
    moveSizeCuFt,
    moveSizeWeight,
    activeCuFt,
  } = useOrderSizing(order);
  const { validateStorageMoveSizeOnBe } = useValidateStorageOnBe();
  const isCustom = SizingStrategy.EMPLOYEE_OVERRIDE_SIZING === sizeType;
  const isInventory = SizingStrategy.INVENTORY_SIZING === sizeType;
  const isDefault = SizingStrategy.MOVE_SIZING === sizeType;
  const isLongDistance = order.isLongDistance(serviceIndex);

  const handleChangeTab = (event: FormEventHandler<HTMLButtonElement>, newValue: string) => {
    setSettingsTab(newValue);
  };

  const handleChange = (newValue: SizingStrategy) => () => {
    if (isCompleted || isLockSales) {
      return;
    }
    setSizeType(newValue);
  };

  useEffect(() => {
    setPaymentType(order.paymentType || PaymentOptions.COD);
  }, [order.paymentType]);

  const closingTotalPercentOfPaymentsDueAtPickupName = getServiceRosterClosingPropertyName(
    serviceIndex,
    totalPercentOfPaymentsDueAtPickupName,
  );

  const handleOnSave = () => {
    const result = [
      { name: 'overriddenCuFt', value: isCustom ? overriddenCuFt : 0 },
      {
        name: 'overriddenWeight',
        value: isCustom
          ? getOverriddenWeightFromCuFt(overriddenWeight || 0, overriddenCuFt || 0, cuFtToPounds || 0)
          : 0,
      },
      { name: 'sizingStrategy', value: sizeType },
      { name: 'paymentType', value: paymentType },
      { name: 'cuFtToPounds', value: cuFtToPounds },
      ...Object.keys(chargeProcessingFee).map((chargeProcessingFeeName) => ({
        name: `chargeProcessingFeeSettings.${chargeProcessingFeeName}`,
        value: chargeProcessingFee[chargeProcessingFeeName as keyof typeof chargeProcessingFee],
      })),
    ];

    if (isClosing && isLongDistance) {
      onSave([
        {
          name: closingTotalPercentOfPaymentsDueAtPickupName,
          value: closingServiceRoster?.totalPercentOfPaymentsDueAtPickup ?? 0,
        },
      ]);

      return;
    }

    onSave(result);
    validateStorageMoveSizeOnBe(activeCuFt);
  };

  const handleCustomCuFtValueChanges = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {
      target: { value: customValue, name },
    } = event;

    if (customValue && +customValue < 0) {
      return;
    }

    setOverriddenCuFt(+customValue);

    if (name === 'overriddenWeight') {
      setOverriddenWeight(Math.round((customValue as any) * cuFtToPounds));
    }
  };

  const handleValueChanges =
    (func: { (value: any): void; (value: React.SetStateAction<string>): void; (arg0: any): void }) =>
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const {
        target: { value: customValue, name },
      } = event;

      if (name === 'overriddenCuFt') {
        handleCustomCuFtValueChanges({
          target: { value: Math.round((customValue as any) / cuFtToPounds), name: '' },
        } as unknown as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>);
      }

      func(customValue);
    };

  const handleDownloadPDF = (id: number, name: string) => () => {
    fileAPI.downloadFile(id).then((file: any) => {
      fileDownload(file, `${name || id}.pdf`);
    });
  };

  const handleChangeChargeProcessingFee = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
    setChargeProcessingFee((prevFee) => ({
      ...prevFee,
      [name]: checked,
    }));
  };

  const actions = [
    { label: 'Cancel', onClick: onCancel },
    {
      label: 'Save',
      onClick: handleOnSave,
    },
  ];

  const handleChangeTotalPercentOfPaymentsDueAtPickup = (value: number) => {
    setClosingServiceRoster((prev) => {
      return prev?.setIn([totalPercentOfPaymentsDueAtPickupName], value) ?? null;
    });
  };

  const onFocusPounds: FilledInputProps['onFocus'] = ({ target }) => target.select();

  return (
    <Modal className={classes.roundedModal} open={open} title="Settings" onClose={onCancel} actions={actions}>
      <Box mx={2} minHeight="500px">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Tabs inheritLightBackground value={settingsTab} onChange={handleChangeTab as any} indicatorColor="primary">
              {Object.values(settingsTabs).map((tab) => (
                <Tab
                  className={classes.roundedTab}
                  label={tab}
                  value={tab}
                  key={tab}
                  disabled={!isElromcoAdmin && tab === settingsTabs.paymentFees}
                />
              ))}
            </Tabs>
          </Grid>
          {settingsTab === settingsTabs.paymentSettings ? (
            <>
              <Grid item xs={12}>
                <BodyBigText>
                  <b>Calculate Cubit Feet results by</b>
                </BodyBigText>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextInput
                  type="number"
                  label="Conversion ratio"
                  value={cuFtToPounds}
                  disabled={
                    isClosing || isInventory || (isCustom && overriddenWeight !== null) || isCompleted || isLockSales
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setCuFtToPoundsConversion(+event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mt={2} mb={2} display="flex" justifyContent="space-between">
                  {/* @ts-ignore */}
                  <StyledCard
                    $disabled={isClosing}
                    onClick={handleChange(SizingStrategy.MOVE_SIZING)}
                    $selected={isDefault}
                    data-testid="defaultSizing"
                  >
                    <StyledActionArea>
                      <StyledCardContent>
                        <Box display="flex" justifyContent="space-between">
                          <BodySmallText align="left" color="inherit">
                            {moveSizeCuFt ? `${moveSizeCuFt} cbf` : ''}
                          </BodySmallText>
                          <BodySmallText align="right" color="inherit">
                            {moveSizeWeight ? `${moveSizeWeight} lbs` : ''}
                          </BodySmallText>
                        </Box>
                        {/* @ts-ignore */}
                        <Box fontSize="4rem" align="center" lineHeight={1}>
                          {renderIcon(iconsByType[SizingStrategy.MOVE_SIZING])}
                        </Box>
                        <BodyText align="center" color="inherit">
                          Default
                        </BodyText>
                      </StyledCardContent>
                    </StyledActionArea>
                  </StyledCard>
                  <StyledCard
                    onClick={inventoryCuFt <= 0 ? undefined : handleChange(SizingStrategy.INVENTORY_SIZING)}
                    $disabled={isClosing || inventoryCuFt <= 0}
                    $selected={isInventory}
                    data-testid="inventorySizing"
                  >
                    <StyledActionArea disabled={inventoryCuFt <= 0}>
                      <StyledCardContent>
                        <Box display="flex" justifyContent="space-between">
                          <BodySmallText align="left" color="inherit">
                            {inventoryCuFt ? `${roundTo2(inventoryCuFt)} cbf` : ''}
                          </BodySmallText>
                          <BodySmallText align="right" color="inherit">
                            {inventoryWeight ? `${roundTo2(inventoryWeight)} lbs` : ''}
                          </BodySmallText>
                        </Box>
                        {/* @ts-ignore */}
                        <Box fontSize="4rem" align="center" lineHeight={1}>
                          {renderIcon(iconsByType[SizingStrategy.INVENTORY_SIZING])}
                        </Box>
                        <Typography align="center" color="inherit">
                          Inventory
                        </Typography>
                      </StyledCardContent>
                    </StyledActionArea>
                  </StyledCard>
                  {/* @ts-ignore */}
                  <StyledCard
                    $disabled={isClosing}
                    onClick={handleChange(SizingStrategy.EMPLOYEE_OVERRIDE_SIZING)}
                    $selected={isCustom}
                    data-testid="customSizing"
                  >
                    <StyledActionArea>
                      <StyledCardContent>
                        {!isCustom && (
                          <>
                            <Typography align="right" color="inherit">
                              &nbsp;
                            </Typography>
                            {/* @ts-ignore */}
                            <Box fontSize="4rem" align="center" lineHeight={1}>
                              {renderIcon(iconsByType[SizingStrategy.EMPLOYEE_OVERRIDE_SIZING])}
                            </Box>
                          </>
                        )}
                        {isCustom && (
                          <>
                            <StyledTextField
                              type="number"
                              helperText={overriddenCuFt != null && overriddenCuFt < 0 ? 'Must be greater than 0' : ''}
                              autoFocus
                              value={overriddenCuFt}
                              /** @ts-ignore */
                              shrink
                              label="Cubic Feet"
                              name="overriddenWeight"
                              InputLabelProps={{ shrink: true }}
                              onChange={handleCustomCuFtValueChanges}
                              disabled={isCompleted || isLockSales}
                              data-testid="cubicFeetInput"
                            />
                            <StyledTextField
                              type="number"
                              helperText={
                                (overriddenWeight || (overriddenCuFt || 0) * cuFtToPounds) < 0
                                  ? 'Must be greater than 0'
                                  : ''
                              }
                              value={getOverriddenWeightFromCuFt(
                                overriddenWeight || 0,
                                overriddenCuFt || 0,
                                cuFtToPounds || 0,
                              )}
                              label="Pounds"
                              name="overriddenCuFt"
                              onBlur={({ target: { value: lastValue } }) => !lastValue && setOverriddenWeight(null)}
                              onFocus={onFocusPounds}
                              InputLabelProps={{ shrink: true }}
                              onChange={handleValueChanges(setOverriddenWeight as any)}
                              disabled={isCompleted || isLockSales}
                              data-testid="poundsInput"
                            />
                          </>
                        )}
                        <Typography align="center" color="inherit">
                          Custom Volume
                        </Typography>
                      </StyledCardContent>
                    </StyledActionArea>
                  </StyledCard>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" focused={false}>
                    <Typography>
                      <Box fontSize={16} fontWeight="bold" lineHeight="24px" mt={2}>
                        Payment Option
                      </Box>
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-label="payment-option-setting"
                    name="paymentOption"
                    value={paymentType}
                    onChange={handleValueChanges(setPaymentType as any)}
                  >
                    <Radio
                      disabled={isCompleted || isLockSales}
                      value={PaymentOptions.COD}
                      color="primary"
                      label="COD (Cash, check or credit card on delivery)"
                    />
                    <Radio
                      disabled={isCompleted || isLockSales}
                      value={PaymentOptions.IOD}
                      color="primary"
                      label="IOD (Invoice on delivery)"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {isLongDistance && isClosing && (
                <Grid item xs={12}>
                  <CurrencyInput
                    label="Total % of payment due at pickup"
                    name={closingTotalPercentOfPaymentsDueAtPickupName}
                    value={closingServiceRoster?.[totalPercentOfPaymentsDueAtPickupName] ?? 0}
                    color="primary"
                    onChange={handleChangeTotalPercentOfPaymentsDueAtPickup}
                    amountType={PriceAdjustmentType.PERCENT}
                    allowNegativeValue={false}
                  />
                </Grid>
              )}
              {!!order.orderConfirmationRecordDto.length && (
                <Grid item xs={12}>
                  <Box>Confirmation PDF</Box>
                  <Box display="flex" flexWrap="wrap">
                    {order.orderConfirmationRecordDto.map((ocrd: OrderConfirmationDTO) => (
                      <Box m={0.5}>
                        <Button
                          key={ocrd.fileId}
                          textTransform="capitalize"
                          color="primary"
                          onClick={handleDownloadPDF(ocrd.fileId, ocrd.templateName || '')}
                        >
                          {`${ocrd.templateName || ocrd.fileId}.pdf`}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <BodyBigText>
                  <b>Charge processing fees for payments made:</b>
                </BodyBigText>
              </Grid>
              <Grid item xs={6}>
                <BodyBigText>Payment type</BodyBigText>
              </Grid>
              <Grid item xs={6}>
                <BodyBigText>Enabled</BodyBigText>
              </Grid>
              {Object.keys(paymentFeesSettingsOptions).map((fee) => (
                <Fragment key={fee}>
                  <Grid item xs={6}>
                    {paymentFeesSettingsOptions[fee as keyof typeof paymentFeesSettingsOptions]}
                  </Grid>
                  <Grid item xs={6}>
                    <Switch
                      name={fee}
                      checked={chargeProcessingFee[fee as keyof typeof chargeProcessingFee]}
                      onChange={handleChangeChargeProcessingFee}
                      color="primary"
                    />
                  </Grid>
                </Fragment>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default OrderSettings;
