import React, { useEffect, useState } from 'react';

import { ManagerInventory, Order, SizingStrategy, commonNewInventory, useConfirm } from '@elromcoinc/react-shared';
import { fromJS, is } from 'immutable';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { newInventoryApi } from 'admin/api';
import orderApi from 'admin/api/OrderAPI';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import SettingName from 'admin/constants/SettingName';

const { INVENTORY_ROOM_SETTING, INVENTORY_ITEM_SETTING, INVENTORY_GROUPING_SETTING, SIZE_TO_WEIGHT_INDEX } =
  SettingName;

const InventoryModal = ({ onCancel, order }) => {
  const [inFlight, setInFlight] = useState(false);
  const [initialRooms, setInitialRooms] = useState([]);
  const { setOriginalOrder } = useOrderState();
  const { fetchOrderInfoWithoutRemovingSandbox, changeSet, onChange } = useOrderChangeSet();

  const { enqueueSnackbar } = useSnackbar();

  const settings = useOrderWindowSettings();

  const conversionRateForCuftToPounds = order?.cuFtToPounds || settings?.[SIZE_TO_WEIGHT_INDEX]?.[0]?.conversionRate;
  const inventoryItemsSettings =
    settings?.[INVENTORY_ITEM_SETTING]?.filter((item) => item.moveType === order?.moveType) || [];
  const inventoryGroupSettings =
    settings?.[INVENTORY_GROUPING_SETTING]?.filter((item) => item.moveType === order?.moveType) || [];
  const inventoryRoomSettings =
    settings?.[INVENTORY_ROOM_SETTING]?.filter((item) => item.moveType === order?.moveType) || [];

  const { isCompleted, isLockSales } = useOrderClosingContext();

  const { confirm, ConfirmationDialog: renderConfirm } = useConfirm({
    title: 'Save Changes',
    message: 'You have not saved your inventory changes. Do you wish to save your changes now?',
    confirmTitle: 'Yes, save',
    cancelTitle: 'No, cancel changes',
    showCloseButton: false,
  });

  useEffect(() => {
    if (order?.orderId) {
      setInFlight(true);
      newInventoryApi
        .getInventorySet(order?.orderId)
        .then(({ inventoryRooms }) => {
          setInitialRooms(inventoryRooms || []);
        })
        .catch(() => {})
        .finally(() => setInFlight(false));
    }
  }, [order?.orderId]);

  const handleOnSave = async (inventoryRooms) => {
    setInFlight(true);

    const inventoryRoomsWithItems = inventoryRooms.filter((room) => !!room?.inventoryItems?.length);
    const currentSizingStrategy = inventoryRoomsWithItems?.length
      ? SizingStrategy.INVENTORY_SIZING
      : SizingStrategy.MOVE_SIZING;

    try {
      await newInventoryApi.addInventorySet({
        orderId: order?.orderId,
        inventorySetSubmitted: true,
        inventoryRooms: inventoryRooms.filter(
          (room) => !!room?.inventoryItems?.length || !room?.inventoryRoomSettingId,
        ),
      });

      if (order?.sizingStrategy !== currentSizingStrategy) {
        setOriginalOrder((prev) => prev.set('sizingStrategy', currentSizingStrategy));
        await orderApi.setSizingStrategyDirect(order.orderId, currentSizingStrategy);

        if (!changeSet.isEmpty()) {
          onChange({ name: 'sizingStrategy', value: currentSizingStrategy });
        }
      }

      enqueueSnackbar('Inventory saved successfully', { variant: 'success' });

      fetchOrderInfoWithoutRemovingSandbox();

      setInFlight(false);
      onCancel();
    } catch {
      enqueueSnackbar('Cannot update inventory. Please, try again.', { variant: 'error' });
      setInFlight(false);
    }
  };

  const transformRoom = (room) =>
    room
      .remove('uuid')
      .update('inventoryItems', (items) => items.map((item) => item.remove('roomUUID').remove('uuid')));

  const createInitialRoomsDto = (initialRooms) =>
    fromJS(
      initialRooms.map((room) => ({
        ...room,
        inventoryItems: room?.inventoryItems?.map((item) =>
          commonNewInventory.InventoryItemDto.createInventoryItem(item, conversionRateForCuftToPounds),
        ),
      })),
    ).map(transformRoom);

  const handleOnClose = async (inventoryRooms) => {
    const initialRoomsDto = createInitialRoomsDto(initialRooms);
    const modifiedRoomsDto = fromJS(
      inventoryRooms.filter((room) => !!room?.inventoryItems?.length || !room?.inventoryRoomSettingId),
    ).map(transformRoom);

    if (!is(initialRoomsDto, modifiedRoomsDto)) {
      const confirmed = await confirm();
      if (confirmed) {
        handleOnSave(inventoryRooms);
      } else {
        onCancel();
      }
    }

    onCancel();
  };

  return (
    <>
      <ManagerInventory
        onSaveInventory={handleOnSave}
        initialRooms={initialRooms}
        conversionRateForCuftToPounds={conversionRateForCuftToPounds}
        inventoryItemsSettings={inventoryItemsSettings}
        inventoryGroupSettings={inventoryGroupSettings}
        inventoryRoomSettings={inventoryRoomSettings}
        disabled={isCompleted || isLockSales}
        onCloseInventory={handleOnClose}
        inFlight={inFlight}
      />
      {renderConfirm()}
    </>
  );
};

InventoryModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Order).isRequired,
};
export default InventoryModal;
