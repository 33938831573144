export default function timeToTextFormat({ minutes }) {
  const hours = Math.floor(minutes / 60);

  if (hours >= 24) {
    return `${Math.floor(hours / 24)} days`;
  }

  if (hours >= 1) {
    return `${hours} hours`;
  }

  return `${minutes} minutes`;
}

const durationToHoursMinutes = (jobDuration, alwaysIncludeHours = false, showTwoZeroInMinutes = false) => {
  const minutes = (+jobDuration / 60) % 60;
  const minsAt15Round = (Math.round(minutes / 15) * 15) % 60;
  let hours = parseInt(jobDuration / 3600, 10);
  if (minutes > 52) {
    hours += 1;
  }

  const displayMinutes = showTwoZeroInMinutes ? minsAt15Round.toString().padStart(2, '0') : minsAt15Round;

  return alwaysIncludeHours || hours > 0 ? `${hours}\xa0h\xa0${displayMinutes}\xa0min` : `${displayMinutes}\xa0min`;
};

export { durationToHoursMinutes };
