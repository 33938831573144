import { commonNewInventory } from '@elromcoinc/react-shared';
import { List, Map } from 'immutable';

import { durationToHoursMinutes } from 'admin/utils';

export const generateDescriptionForInventoryItem = (item: commonNewInventory.InventoryItemDto): string => {
  const attributes = [
    { count: item.extraServiceFee || 0, name: 'extra service fee' },
    { count: item.minMoversRequired || 0, name: 'required minimum movers' },
    { count: item.additionalLaborTime || 0, name: 'additional labor time' },
  ];

  const validAttributes = attributes.filter((attr) => attr.count > 0);

  const groupedAttributes = validAttributes.reduce((acc, { count, name }) => {
    return acc.update(count, List(), (list) => list.push(name));
  }, Map<number, List<string>>());

  return groupedAttributes
    .map((names, count) => {
      return `${names?.join(', ')?.includes('time') ? durationToHoursMinutes(count, false, true) : count} ${names.join(
        ', ',
      )}`;
    })
    .valueSeq()
    .join(', ');
};
