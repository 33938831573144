import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';

import {
  ActivitySourceType,
  Button,
  ElromcoCircularProgress,
  HeaderSmallText,
  IconButton,
  JoditTextEditor,
  Modal,
  Select,
  TextInput,
  emailYUP,
  useConfirm,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  LinearProgress,
  ListSubheader,
  Menu,
  MenuItem,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useSnackbar } from 'notistack';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';

import { emailAPI } from 'admin/api';
import userAPI from 'admin/api/UserAPI';
import templateApi from 'admin/api/templateApi';
import { CreateFollowUpForm } from 'admin/components/OrderWindow/blocks/ActivityManager/CreateFollowUpForm';
import { createFollowUpTask } from 'admin/components/OrderWindow/blocks/ActivityManager/createFollowUpTask';
import { CREATE_A_FOLLOW_UP, followUpSchema } from 'admin/components/OrderWindow/blocks/ActivityManager/followUpUtils';
import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { useDefaultFollowUpValues } from 'admin/components/OrderWindow/blocks/ActivityManager/useDefaultFollowUpValues';
import AttachmentsEmails from 'admin/components/OrderWindow/blocks/AttachmentsEmails';
import { useOrderChangeSet, useOrderState } from 'admin/components/OrderWindow/context';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import {
  EMAIL_SERVER_SETTINGS,
  USE_OUT_GOING_EMAIL,
} from 'admin/components/Settings/components/Users/ContentPanels/UserFormConstants';
import EmployeeRecord from 'admin/entities/Employee';
import { getAuthUser } from 'admin/selectors/auth';
import { BaseTemplate, SendEmailDto, Template } from 'common-types';
import { EmailFormDto } from 'common-types/EmailFormDto';
import { TemplateBuilderTokens } from 'common-types/TemplateBuilderTokens';
import { BlueVisibilityIcon } from 'common/components/Widgets';
import { getTemplateBuilderVariables, stripTags, toEmailDTO, toEmailList } from 'common/utils';
import { appendTemplateBuilderStyles } from 'common/utils/appendTemplateBuilderStyles';

const useStyles = makeStyles((theme) => ({
  subheader: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'capitalize',
  },
  tableCell: {
    borderBottom: 'none',
  },
  firstCell: {
    width: '50%',
  },
}));

const variables = getTemplateBuilderVariables();

enum ViewModes {
  ALERT = 'ALERT',
  EMAIL_PREVIEW = 'EMAIL_PREVIEW',
}

const RECIPIENTS_NAME = 'recipients';
const SUBJECT_NAME = 'subject';
const BODY_NAME = 'body';

const labels = {
  [RECIPIENTS_NAME]: 'To',
  [SUBJECT_NAME]: 'Subject',
  [BODY_NAME]: 'Body',
};

const emailSchema = emailYUP({ label: 'email' });

const testCommaSeparatedEmails = (emails: any) =>
  !emails || toEmailList(emails).every((event) => emailSchema.isValidSync(event));

const schema = object({
  [SUBJECT_NAME]: string().label(labels[SUBJECT_NAME]).nullable().required(),
  [BODY_NAME]: string()
    .label(labels[BODY_NAME])
    .nullable()
    .test('min-3', 'Body must be at least 3 characters', (value) => stripTags(value || '').trim().length > 3)
    .required(),
  [RECIPIENTS_NAME]: string()
    .label(labels[RECIPIENTS_NAME])
    .nullable()
    .required()
    .test('invalid-recipient-name', 'Invalid recipient email', testCommaSeparatedEmails),
  ...followUpSchema,
});

interface ComposeEmailProps {
  activitySource: ActivitySourceType;
  sourceId: number | null;
  initialTemplate?: Template;
  onSend?: (dto?: SendEmailDto) => void;
}

export const ComposeEmail: FC<ComposeEmailProps> = ({ activitySource, sourceId, initialTemplate, onSend }) => {
  const classes = useStyles();
  const { changeSet, showSaveDialog } = useOrderChangeSet();
  const changeSetRef = useRef(changeSet);
  changeSetRef.current = changeSet;
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const { reload, newEmailDefault, setNewEmailDefault, selectedTemplateDTO, setSelectedTemplateDTO } =
    useActivityLogContext();
  const { groupedEmailTemplatesByTemplateFolder, inFlightTemplates } = useComposeEmail();
  const defaultFollowUp = useDefaultFollowUpValues();
  const { order } = useOrderState();
  const email = order?.contactInfo?.email;
  const { enqueueSnackbar } = useSnackbar();
  const [defaultTemplate, setDefaultTemplate] = useState(
    initialTemplate || new Template(selectedTemplateDTO?.toDTO({ useExistingBody: true }) ?? {}),
  );
  const [inFlightEmployee, setInFlightEmployee] = useState(false);
  const [managerOptions, setManagerOptions] = useState<SelectOptions>([[0, 'Company']]);
  const user = useSelector(getAuthUser);
  const [from, setFrom] = useState(0);
  const [inFlight, setInFlight] = useState(false);
  const [openModal, setOpenModal] = useState<ViewModes | null>(null);
  const [templatePreview, setTemplatePreview] = useState('');
  const [inFlightPreview, setInFlightPreview] = useState(false);
  const [temporaryAlertTemplate, setTemporaryAlertTemplate] = useState<Template | null>(null);
  const [sendEmailFiles, setEmailFiles] = useState<File[]>([]);

  let defaultValues = newEmailDefault
    ? newEmailDefault
    : { [RECIPIENTS_NAME]: email || '', ...defaultFollowUp, subject: '', [BODY_NAME]: '' };

  if (activitySource === ActivitySourceType.INVOICE) {
    defaultValues = { [RECIPIENTS_NAME]: email || '', ...defaultFollowUp, ...defaultTemplate.toJS() };
  }

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { control, handleSubmit, getValues, setValue, reset, clearErrors } = methods;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { confirm: confirmClear, ConfirmationDialog: renderConfirmClearTemplate } = useConfirm({
    title: 'Clear Template',
    message: 'Are you sure you want to clear the template?',
  });

  const isVisibleTemplates =
    activitySource === ActivitySourceType.ORDER || activitySource === ActivitySourceType.INVOICE;
  const editorConfig = useMemo(() => ({ height: 350, variables: isVisibleTemplates ? variables : undefined }), []);

  useEffect(() => {
    return () => {
      if (activitySource !== ActivitySourceType.INVOICE) {
        setNewEmailDefault(methods.getValues());
      }
    };
  }, []);

  const openApplyTemplate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeApplyTemplate = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (user.id) {
      setInFlightEmployee(true);

      userAPI
        .retrieveEmployee(user.id)
        .then((employee: EmployeeRecord) => {
          const isUseOutGoingEmailCurrentUser = employee?.[EMAIL_SERVER_SETTINGS]?.[USE_OUT_GOING_EMAIL];

          if (isUseOutGoingEmailCurrentUser) {
            setManagerOptions((state) => [...state, [user.id, `${user.firstName} ${user.lastName}`]]);
            setFrom(user.id);
          }
        })
        .catch(() => {})
        .then(() => setInFlightEmployee(false));
    }
  }, [user?.id]);

  useEffect(() => {
    if (email) {
      setValue(RECIPIENTS_NAME, email);
    }
  }, [email]);

  const continueAlertHandler = () => {
    setValue(SUBJECT_NAME, temporaryAlertTemplate?.subject);
    setValue(BODY_NAME, temporaryAlertTemplate?.body);
    setSelectedTemplateDTO(temporaryAlertTemplate);
    setDefaultTemplate(temporaryAlertTemplate!);
    setTemporaryAlertTemplate(null);
    setOpenModal(null);
    closeApplyTemplate();
  };

  const closeHandler = () => setOpenModal(null);
  const handleChooseTemplate = (template: BaseTemplate) => () => {
    if (openModal === ViewModes.ALERT) {
      return;
    }

    setOpenModal(ViewModes.ALERT);

    templateApi.getTemplate(template?.id!).then((template) => {
      setTemporaryAlertTemplate(new Template(template));
    });
  };

  const showTemplatePreview = (template: Template) => () => {
    if (sourceId) {
      setOpenModal(ViewModes.EMAIL_PREVIEW);
      setTemplatePreview('');

      const body = stripTags(getValues()[BODY_NAME]);

      if (!body) {
        return;
      }

      setInFlightPreview(true);

      templateApi
        .preview(
          sourceId,
          activitySource,
          template.set(BODY_NAME, getValues()[BODY_NAME]).toDTO({
            wrapTemplateInDiv: true,
            useExistingBody: true,
          }),
        )
        .then((response) => {
          setTemplatePreview(response);
        })
        .catch(() => {})
        .then(() => setInFlightPreview(false));
    }
  };
  const handleChangeFrom = (event: ChangeEvent<HTMLInputElement>) => setFrom(+event.target.value || 0);

  const ifHasUnsavedChangesAndContainVariable = (text: string) => {
    if (
      !!showSaveDialog &&
      !changeSetRef.current?.isEmpty() &&
      TemplateBuilderTokens.some(({ simpleToken }) => text?.includes?.(simpleToken))
    ) {
      enqueueSnackbar('Please save changes.', { variant: 'warning' });
      showSaveDialog();
      return true;
    }

    return false;
  };

  const handleSaveClick = (data: EmailFormDto) => {
    if (ifHasUnsavedChangesAndContainVariable(data[BODY_NAME])) {
      return;
    }

    const templatesToSend = [
      defaultTemplate.set('subject', data.subject).set(BODY_NAME, appendTemplateBuilderStyles(data[BODY_NAME])),
    ];

    if (templatesToSend.some((t) => !t.body)) {
      enqueueSnackbar('Found template without a body. Please check templates.', { variant: 'warning' });
      return;
    }

    const form = toEmailDTO(data);

    if (activitySource === ActivitySourceType.INVOICE) {
      onSend?.({ emailDTO: form, templates: templatesToSend, fromCompany: !!from });

      // For invoice we'll send template only after saving invoice
      return;
    }

    if (!sourceId) {
      return;
    }

    createFollowUpTask(
      { ...(data as unknown as ReturnType<typeof useDefaultFollowUpValues>), subject: 'Follow up' },
      activitySource,
      sourceId,
    )
      .then(() => {
        setValue(CREATE_A_FOLLOW_UP, false);
        enqueueSnackbar('Follow up created successfully', { variant: 'success' });
      })
      .catch(() => {});

    if (activitySource === ActivitySourceType.ORDER || activitySource === ActivitySourceType.CUSTOMER_ACCOUNT) {
      setInFlight(true);

      onSend?.();

      (activitySource === ActivitySourceType.ORDER
        ? Promise.all(
            templatesToSend.map((t) =>
              templateApi
                .preview(sourceId!, activitySource, t.toDTO({ useExistingBody: true }))
                .then((preview) => t.set('body', preview)),
            ),
          )
        : Promise.resolve(templatesToSend)
      )
        .then((response) =>
          response.map((template: Template) => {
            const fd = new FormData();
            fd.append(
              'emailDto',
              new Blob([JSON.stringify(template.toEmailDto({ ...form, activitySource, sourceId: sourceId! }))], {
                type: 'application/json',
              }),
            );
            // @ts-ignore
            sendEmailFiles.forEach((file) => fd.append('attachments', file, file.name));
            return fd;
          }),
        )
        .then((emailsBody) =>
          emailsBody.map((e) => (from ? emailAPI.sendEmailFromCurrentEmployee(e) : emailAPI.sendEmailFromCompany(e))),
        )
        .then(() => {
          reload();
          setDefaultTemplate(new Template());
          setSelectedTemplateDTO(null);
          setEmailFiles([]);
          enqueueSnackbar('Emails was successfully sent to client.', { variant: 'success' });
          reset({ [RECIPIENTS_NAME]: email || '', ...defaultFollowUp, subject: '', [BODY_NAME]: '' });
          setTimeout(() => {
            setValue(BODY_NAME, '');
            clearErrors(BODY_NAME);
          }, 100);
        })
        .catch(() => {
          enqueueSnackbar('Problem with sending emails please check SMTP setting.', { variant: 'error' });
        })
        .then(() => setInFlight(false));

      return;
    }

    enqueueSnackbar('Coming soon!', { variant: 'warning' });
  };

  const clearTemplate = async () => {
    const result = await confirmClear();

    if (result) {
      setValue(BODY_NAME, '');
      clearErrors(BODY_NAME);
      setSelectedTemplateDTO(null);
    }
  };

  const handleChangeFiles = (files: File | File[]) => {
    if (Array.isArray(files)) {
      return setEmailFiles((prevFiles) => [...prevFiles, ...files]);
    }
    setEmailFiles((prevFiles) => [...prevFiles, files]);
  };

  const handleRemoveFiles = (file: File) => {
    setEmailFiles((prevFiles) => prevFiles.filter((f) => f.name !== file.name));
  };

  const applyTemplate = (
    <Grid item xs={12} sm={3}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="outlined"
          onClick={openApplyTemplate}
          loading={inFlightTemplates}
          disabled={inFlightTemplates}
        >
          Apply Template
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeApplyTemplate}>
          {groupedEmailTemplatesByTemplateFolder.map(([folderName, templates]) => [
            <ListSubheader
              aria-controls={`${folderName}-content`}
              id={`${folderName}-header`}
              classes={{ sticky: classes.subheader }}
            >
              {folderName}
            </ListSubheader>,
            templates
              .filter((it) => it.active)
              .map((item) => (
                <MenuItem key={item.id} onClick={handleChooseTemplate(item)}>
                  {item.name}
                </MenuItem>
              )),
          ])}
        </Menu>
      </Box>
    </Grid>
  );

  return (
    <>
      <Box height={8}>{inFlight && <LinearProgress color="primary" />}</Box>
      <Box>
        <Box mx={2}>
          <Grid container spacing={1}>
            {isVisibleTemplates && isMobile && activitySource !== ActivitySourceType.INVOICE && applyTemplate}
            <Grid item xs={12} sm={3}>
              <Select
                type="select"
                options={managerOptions}
                label="From"
                fullWidth
                value={from}
                disabled={inFlightEmployee}
                onChange={handleChangeFrom}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={isVisibleTemplates ? 6 : 9}>
              <TextInput
                fullWidth
                name={RECIPIENTS_NAME}
                label={labels[RECIPIENTS_NAME]}
                control={control}
                size="small"
              />
            </Grid>
            {isVisibleTemplates && !isMobile && activitySource !== ActivitySourceType.INVOICE && applyTemplate}
            <Grid item xs={12} sm={12}>
              <TextInput fullWidth name={SUBJECT_NAME} label={labels[SUBJECT_NAME]} control={control} size="small" />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box my={1}>
                  <HeaderSmallText>
                    <b>Message</b>
                  </HeaderSmallText>
                </Box>
                {!!sourceId && (
                  <Box display="flex">
                    <IconButton
                      data-testid="clearTemplate"
                      color="primary"
                      aria-label="clearTemplate"
                      onClick={clearTemplate}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                    <IconButton
                      data-testid="emailPreview"
                      color="primary"
                      aria-label="emailPreview"
                      onClick={showTemplatePreview(defaultTemplate)}
                    >
                      <BlueVisibilityIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <JoditTextEditor
                name={BODY_NAME}
                placeholder="Please enter your email message"
                control={control}
                config={editorConfig}
              />
              <AttachmentsEmails onChange={handleChangeFiles} onRemove={handleRemoveFiles} files={sendEmailFiles} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal
        open={openModal === ViewModes.ALERT}
        title="Alert"
        onClose={closeHandler}
        actions={[
          { label: 'cancel', onClick: closeHandler },
          { label: 'save', onClick: continueAlertHandler },
        ]}
        maxWidth="xs"
      >
        This will remove the existing subject and message text
      </Modal>
      <Modal
        open={openModal === ViewModes.EMAIL_PREVIEW}
        title="Email Preview"
        onClose={closeHandler}
        maxWidth="md"
        actions={[{ label: 'done', onClick: closeHandler }]}
      >
        {inFlightPreview ? (
          <Box display="flex" alignItems="center" justifyContent="center" height={400} position="relative" my={2}>
            <ElromcoCircularProgress />
          </Box>
        ) : (
          <Box dangerouslySetInnerHTML={{ __html: templatePreview || 'Nothing to load' }} />
        )}
      </Modal>
      {renderConfirmClearTemplate()}
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" my={1} pr={2}>
        <Box ml={2}>
          {activitySource !== ActivitySourceType.INVOICE /** @ts-ignore */ && (
            <FormProvider {...methods}>
              <CreateFollowUpForm />
            </FormProvider>
          )}
        </Box>
        <Button color="primary" onClick={handleSubmit(handleSaveClick as any)} variant="text" disabled={inFlight}>
          Send
        </Button>
      </Box>
    </>
  );
};
