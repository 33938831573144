import React, { ChangeEvent, useState } from 'react';

import { Button, Select, Tab, Tabs, TitleBigText } from '@elromcoinc/react-shared';
import { Box, Grid, LinearProgress, Paper, TabsProps, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PlusIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { List } from 'immutable';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { getInFlight, increaseReloadAmount } from 'admin/autodux/ReloadAutodux';
import { markAllAsCompleted } from 'admin/autodux/TaskAutodux';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import TasksModal from 'admin/components/OrderWindow/modals/Tasks';
import { EMPLOYEE_ID, STATUS, TaskFilters, TaskStatusLabel } from 'admin/components/Tasks/TaskStatusLabel';
import { TaskType, tasksTypeOptions } from 'admin/components/TasksPage/TaskConstants';
import { useFetchManagers } from 'admin/hooks/useFetchManagers';
import { SalesPerson } from 'common-types';
import { TaskStatus } from 'common-types/Task';
import { ReloadButton } from 'common/components/ReloadButton';

import { TasksTable } from './TasksTable';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    minHeight: 'calc(100% - 3.5rem)',
    position: 'relative',
    margin: '1.5rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      margin: '0.5rem',
    },
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    flexGrow: 1,
    padding: spacing(2),
    [breakpoints.down('md')]: {
      padding: spacing(1),
    },
  },
  taskHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${palette.grey[200]}`,
    padding: '5px',
    gap: 5,
  },
  taskHeaderItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 5,
    flexWrap: 'wrap',
    [breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
  },
  taskFilter: {
    display: 'flex',
    width: '150px',
    marginRight: '8px',
  },
  container: {
    marginTop: spacing(1),

    '& .el-datatable': {
      '& .MuiPaper-root': {
        borderRadius: spacing(0.75),
      },

      '& .MuiTable-root': {
        borderRadius: spacing(0.75, 0.75, 0, 0),

        '& .MuiTableFooter-root': {
          borderRadius: spacing(0, 0, 0.75, 0.75),

          '& tr': {
            borderRadius: spacing(0, 0, 0.75, 0.75),

            '& td': {
              borderRadius: spacing(0, 0, 0.75, 0.75),
            },
          },
        },
      },
    },
  },
  roundedTab: {
    '&.MuiTab-root': {
      borderRadius: spacing(0, 0.75, 0.75, 0),
    },
  },
}));

const Tasks = () => {
  const classes = useStyles();
  const dispatch = useDispatch<DispatchPromise>();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [taskFilters, setTaskFilters] = useState<TaskFilters | {}>({});
  const [selectedType, setSelectedType] = useState(TaskType.OPEN_TASKS);
  const inFlight = useSelector(getInFlight) as boolean;

  const handleChangeManager = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setTaskFilters({ ...taskFilters, [name]: value });
  };

  useFetchManagers();

  const salesPersonList: List<SalesPerson> = useSelector(getManagerList);
  const salesPersonOptions: SelectOptions = (salesPersonList.toJS() as SalesPerson[]).map((item) => [
    item.id,
    `${item.firstName} ${item.lastName}`,
  ]);

  const taskStatusOptions = [
    [TaskStatus.READ, TaskStatusLabel[TaskStatus.READ as keyof typeof TaskStatusLabel]],
    [TaskStatus.UNREAD, TaskStatusLabel[TaskStatus.UNREAD as keyof typeof TaskStatusLabel]],
  ] as [string, string][];

  const handleClearAllFiltersClick = () => {
    setTaskFilters({});
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleSaveTask = () => {
    enqueueSnackbar('New task successfully created', { variant: 'success' });
    handleCloseModal();
  };

  const openNewTaskModal = () => setOpenModal(true);

  const handleChange = (event: ChangeEvent<{ value: TaskType }>, newValue: TaskType) => {
    setSelectedType(newValue);
  };

  const handleClickMarkAllAsCompleted = () => {
    dispatch(markAllAsCompleted).then(() => {
      dispatch(increaseReloadAmount());
    });
  };

  const scrollableWhenMobile: Partial<Pick<TabsProps, 'variant' | 'scrollButtons'>> = isMobile
    ? { variant: 'scrollable', scrollButtons: 'off' }
    : {};

  return (
    <Box className={classes.root}>
      <Box className={classes.topBar}>
        <TitleBigText gutterBottom color="textPrimary">
          Tasks
        </TitleBigText>
      </Box>
      <Paper elevation={1} square className={clsx(classes.paper, 'applyRadius')}>
        <Box mt={-1} height={8}>
          {inFlight && <LinearProgress color="primary" />}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Tabs
              orientation={isMobile ? 'horizontal' : 'vertical'}
              indicatorColor="primary"
              inheritLightBackground
              value={selectedType}
              onChange={handleChange as any}
              {...scrollableWhenMobile}
            >
              {tasksTypeOptions.map(([value, label]) => (
                <Tab className={classes.roundedTab} value={value} key={value} label={label} />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={10}>
            <Box className={clsx(classes.taskHeader, 'applyRadius')}>
              <Box className={classes.taskHeaderItem}>
                {selectedType !== TaskType.COMPLETED && (
                  <Box className={classes.taskFilter}>
                    <Select
                      name={STATUS}
                      label="Status"
                      value={taskFilters[STATUS as keyof typeof taskFilters] || ''}
                      fullWidth
                      onChange={handleChangeManager}
                      options={taskStatusOptions}
                    />
                  </Box>
                )}
                <Box className={classes.taskFilter}>
                  <Select
                    name={EMPLOYEE_ID}
                    label="Assigned"
                    value={taskFilters[EMPLOYEE_ID as keyof typeof taskFilters] || ''}
                    fullWidth
                    onChange={handleChangeManager}
                    options={salesPersonOptions}
                  />
                </Box>
                <Box display="flex">
                  <Button
                    onClick={handleClearAllFiltersClick}
                    color="primary"
                    variant="text"
                    textTransform="capitalize"
                    startIcon={<Close />}
                  >
                    Clear All Filters
                  </Button>
                </Box>
              </Box>
              <Box className={classes.taskHeaderItem}>
                <Box mr={1}>
                  <Button variant="contained" color="primary" onClick={handleClickMarkAllAsCompleted}>
                    Mark All As Completed
                  </Button>
                </Box>
                <Button variant="contained" color="primary" startIcon={<PlusIcon />} onClick={openNewTaskModal}>
                  Add Task
                </Button>
                <Box ml={1}>
                  <ReloadButton />
                </Box>
              </Box>
            </Box>
            <Box className={classes.container}>
              <TasksTable type={selectedType} taskFilters={taskFilters} />
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {openModal && <TasksModal open onCancel={handleCloseModal} onSave={handleSaveTask} />}
    </Box>
  );
};

export default Tasks;
