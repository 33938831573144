import { createContext } from 'react';

import { ActivitySourceType } from '@elromcoinc/react-shared';

import { AutomationRule, BaseTemplate, Template } from 'common-types';

interface ComposeEmailContextProps {
  blocks: EmailBlock[];
  groupedEmailTemplatesByTemplateFolder: [string, BaseTemplate[]][];
  groupedTemplatesByTemplateFolder: [string, BaseTemplate[]][];
  groupedSMSTemplatesByTemplateFolder: [string, BaseTemplate[]][];
  inFlightTemplates: boolean;
  inFlightBlocks: boolean;
  templates: BaseTemplate[];
  invoiceTemplates: Template[];
  activitySource: ActivitySourceType;
  automationRules: AutomationRule[];
}

const ComposeEmailContext = createContext<ComposeEmailContextProps>({} as ComposeEmailContextProps);

export { ComposeEmailContext };
