import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import {
  ActivitySourceType,
  IconButton,
  Phone,
  PhoneCarrierType,
  PhoneInput,
  PhoneProps,
  PhoneType,
  PhoneTypeName,
  Select,
  SmsCapability,
  TextInput,
  UI_DATE_TIME_FORMAT,
  phoneNumberYUP,
  phoneYUP,
  toDateString,
  useDebounceCallback,
} from '@elromcoinc/react-shared';
import { Tooltip, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BlockIcon from '@material-ui/icons/Block';
import ChatIcon from '@material-ui/icons/Chat';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';

import communicationAPI from 'admin/api/CommunicationsAPI';
import { useOrderState, useUnsubscribeMessage } from 'admin/components/OrderWindow/context';
import OrderLog from 'admin/entities/OrderLog';
import useUpdatePhoneNumberType from 'admin/hooks/useUpdatePhoneNumberType';
import { BaseTemplate, CommunicationModality } from 'common-types';

import { FlexWithNegativeMargin } from './CommonComponents';
import PhoneCarrierIcon from './PhoneCarrierIcon';

const useStyles = makeStyles((theme) => ({
  valueTableCell: {
    position: 'relative',
    '& > p': {
      width: '100%',
      height: '22px',
    },
  },
  phoneInput: {
    '& input': {
      padding: '3px 8px !important',
    },
  },
  indicatorIcon: {
    position: 'absolute',
    top: '3px',
    left: '-28px',
  },
  displayOnHover: {
    '& .display-on-hover': {
      display: 'none',
      visibility: 'hidden',
    },
    '&:hover': {
      '& .display-on-hover': {
        display: 'block',
        visibility: 'visible',
      },
      '& .display-on-hover-hidden': {
        display: 'none',
        visibility: 'hidden',
      },
    },
  },
  successColor: {
    color: theme.palette.success.main,
  },
  nonClickCursor: {
    cursor: 'initial',
  },
}));

const typeOptions = Object.keys(PhoneTypeName).map((key) => [
  key,
  PhoneTypeName[key as keyof typeof PhoneTypeName],
]) as SelectOptions;
//@ts-ignore because i the schema required label in future need to changed in library
const optionalPhoneNumberYum = phoneNumberYUP().optional();
//@ts-ignore because i the schema required label in future need to changed in library
const requiredPhoneNumberYum = phoneNumberYUP().required();

interface PhoneRowProps {
  isOptional?: boolean;
  placeholder: string;
  onChange: (value: { name: string; value: Phone }) => void;
  namePrefix: string;
  phone: Phone;
  disabled: boolean;
  template?: BaseTemplate;
  handleSendWelcomeLetter?: (templates: BaseTemplate, type: CommunicationModality) => () => void;
  inFlightSendingTemplates?: boolean;
  sendSms?: OrderLog;
}

const PhoneRow: FC<PhoneRowProps> = ({
  placeholder,
  phone,
  namePrefix,
  onChange,
  isOptional,
  disabled = false,
  template,
  handleSendWelcomeLetter,
  inFlightSendingTemplates,
  sendSms,
}) => {
  const classes = useStyles();
  const phoneSchema = isOptional ? optionalPhoneNumberYum : requiredPhoneNumberYum;
  const [localPhone, setPhone] = useState<Phone>(phone);
  const [numberError, setNumberError] = useState(null);
  const { isUnsubscribe } = useUnsubscribeMessage();
  const primaryPhone = namePrefix === 'contactInfo.primaryPhone';
  const [inFlightNumberCheck, setInFlightNumberCheck] = useState(false);
  const [canSendSms, setCanSendSms] = useState(false);
  const { order } = useOrderState();
  const toPhoneNumber: string = phone?.number;
  const phoneType: PhoneType = phone?.type ?? PhoneType.MOBILE;
  const currentSMSCapability: SmsCapability = phone?.smsCapability;
  const currentPhoneCarrierType: PhoneCarrierType = phone?.phoneCarrierType;

  useEffect(() => {
    (async () => {
      if (toPhoneNumber && !isOptional) {
        try {
          await communicationAPI.checkConditionsBeforeSendSms(ActivitySourceType.ORDER, order?.orderId!, {
            number: toPhoneNumber,
            type: phoneType,
            smsCapability: currentSMSCapability,
            phoneCarrierType: currentPhoneCarrierType,
          } as Phone);
          setCanSendSms(true);
        } catch {
          setCanSendSms(false);
        }
      }
    })();
  }, [toPhoneNumber, order?.orderId]);

  useEffect(() => {
    setPhone(phone);
  }, [phone]);

  const handleOnExtensionChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setPhone((ph) => {
      const numericValue = value ? parseInt(value, 10) : '';
      if (`${numericValue}` !== value || numericValue > 99999 || numericValue < 0) return ph;
      return ph.set(name as keyof PhoneProps, numericValue || null);
    });
  };

  const phoneNumberChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setPhone((ph) => ph.set(name as keyof PhoneProps, value.replace(/\D/g, '')));

    phoneSchema
      .validate(value)
      .then(() => setNumberError(null))
      .catch((ex) => setNumberError(ex.message));
  };

  const phoneTypeChange = ({ target: { value, name } }: ChangeEvent<HTMLInputElement>) => {
    if (name !== PhoneType.OFFICE) {
      setPhone((ph) => ph.set(name as keyof PhoneProps, value));
      //@ts-ignore because null is not assign to string in future need to changed in library;
      setPhone((phone) => phone.set('extension', null));
    } else {
      setPhone((ph) => ph.set(name as unknown as keyof PhoneProps, value));
    }
  };

  const identifyPhoneNumber = useUpdatePhoneNumberType(localPhone.number, {
    shouldUpdate: () => localPhone.number !== phone.number,
    setCarrierType: (value: PhoneCarrierType) => setPhone((ph) => ph.set('phoneCarrierType', value)),
    setSmsCapability: (value: boolean) => setPhone((ph) => ph.set('smsCapability', value)),
  });

  const retestPhoneNumber = () => {
    setInFlightNumberCheck(true);

    identifyPhoneNumber(localPhone.number).then(() => {
      setInFlightNumberCheck(false);
    });
  };

  useDebounceCallback(() => {
    if (!localPhone.equals(phone)) {
      phoneYUP()
        .validate(localPhone)
        .then(() => {
          onChange({ name: namePrefix, value: localPhone });
        });
    }
  }, 300);

  const showRetestPhoneButton =
    !!phone.number &&
    optionalPhoneNumberYum.isValidSync(phone.number) &&
    phone.smsCapability !== SmsCapability.CAPABLE_ON;

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <FlexWithNegativeMargin
          //@ts-ignore because custom style prop
          $showSvg
          $textColor="#00B0D7"
          $fontWeight="normal"
        >
          <Select
            fullWidth
            value={localPhone.type}
            name="type"
            onChange={phoneTypeChange}
            options={typeOptions}
            hiddenLabel
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              tabIndex: -1,
            }}
            disabled={disabled}
          />
        </FlexWithNegativeMargin>
      </TableCell>
      <TableCell className={classes.valueTableCell}>
        {!!phone.number && (
          <>
            <Box className={classes.indicatorIcon}>
              <PhoneCarrierIcon carrierType={phone.phoneCarrierType} smsCapability={phone.smsCapability} />
            </Box>
            {isUnsubscribe && primaryPhone && (
              <Box className={classes.indicatorIcon}>
                <Tooltip title="Customer has been unsubscribed">
                  <BlockIcon fontSize="small" color="error" />
                </Tooltip>
              </Box>
            )}
          </>
        )}
        <FlexWithNegativeMargin>
          <Box
            display="flex"
            flexGrow={1}
            minWidth={showRetestPhoneButton ? 140 : 115}
            maxWidth={showRetestPhoneButton ? 140 : 130}
          >
            <PhoneInput
              placeholder={placeholder}
              value={localPhone.number}
              name="number"
              formError={numberError}
              onChange={phoneNumberChange}
              hiddenLabel
              size="small"
              classes={{
                root: classes.phoneInput,
              }}
              InputProps={{
                disableUnderline: true,
              }}
              disabled={disabled}
            />
            {showRetestPhoneButton && (
              <Tooltip title="Retest phone number" arrow>
                <Box className="display-on-hover">
                  <IconButton color="primary" size="tiny" onClick={retestPhoneNumber} disabled={inFlightNumberCheck}>
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
          </Box>
          {phone.type === PhoneType.OFFICE && (
            <Box display="flex" alignItems="center" flex={0}>
              :
              <TextInput
                placeholder="ext."
                value={localPhone.extension}
                name="extension"
                inputProps={{
                  style: { minWidth: '45px' },
                }}
                onChange={handleOnExtensionChange}
                hiddenLabel
                InputProps={{
                  disableUnderline: true,
                }}
                disabled={disabled}
              />
            </Box>
          )}
          {!isOptional && !!template && canSendSms && (
            <Box>
              <Tooltip
                title={
                  !!sendSms
                    ? `Sent welcome sms ${toDateString(sendSms?.dateTime!, UI_DATE_TIME_FORMAT)}`
                    : 'Send welcome sms'
                }
              >
                <Box>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={handleSendWelcomeLetter?.(template, CommunicationModality.SMS)}
                    className={clsx({
                      [classes.successColor]: !!sendSms,
                      [classes.nonClickCursor]: !!sendSms,
                    })}
                    disabled={inFlightSendingTemplates}
                  >
                    <ChatIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
          )}
        </FlexWithNegativeMargin>
      </TableCell>
    </TableRow>
  );
};

export default PhoneRow;
