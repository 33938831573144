import React, { useState } from 'react';

import {
  BodyText,
  Discount as DiscountEntity,
  Link,
  Order,
  PriceAdjustment,
  PriceAdjustmentType,
  Service,
  formatCurrency,
  numberToPercent,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { List } from 'immutable';

import { SalesToClosingComparising } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/SalesToClosingComparising';
import { useOrderChangeSet, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import DiscountModal from 'admin/components/OrderWindow/modals/Discount';
import { ZERO_MONEY } from 'admin/constants/DefaultValues';
import { getServiceClosingPropertyName } from 'admin/utils';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const formatMoney = (money: number) => `$${formatCurrency(money)}`;

enum Modals {
  DISCOUNT = 'DISCOUNT',
}

function calculateDiscountTotal(services: Immutable.List<Service>) {
  return services.some((s) => s.quote.discount.type === PriceAdjustmentType.PERCENT)
    ? services.map((s) => s.quote.discount.asHumanReadableDiscount() || ZERO_MONEY).join(', ')
    : formatMoney(services.reduce((accumulator, s) => accumulator + s.quote.discount.amount, 0));
}

export const ClosingDiscount = () => {
  const { order } = useOrderState() as { order: Order };
  const classes = useStyles();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { onChange } = useOrderChangeSet();
  const name = getServiceClosingPropertyName(serviceIndex, 'discount');
  const discount = order.getIn(name.split('.')) as DiscountEntity;
  const [openModal, setOpenModal] = useState<Modals | null>(null);

  const closingServices = order.closingOrderDetail.services as List<Service>;
  const closingQuote = closingServices.get(serviceIndex)?.quote!;

  const services = order.services as List<Service>;
  const quote = services.get(serviceIndex)?.quote!;

  const salesDiscount = quote.discount;
  const closingDiscount = closingQuote.discount;
  const percent =
    closingDiscount.amount === 0 && salesDiscount.amount === 0
      ? '0%'
      : numberToPercent(((salesDiscount.amount - closingDiscount.amount) / closingDiscount.amount) * 100);

  const handleDiscountChange = (value: PriceAdjustment) => {
    onChange({ name, value });
    setOpenModal(null);
  };

  const renderClosingDiscountValue = () => {
    if (isSelectedAllServices) {
      return calculateDiscountTotal(closingServices);
    }

    return closingQuote.discount.asHumanReadableDiscount() || ZERO_MONEY;
  };

  const discountValue = renderClosingDiscountValue();

  const toggleOpenModal = (modal: Modals | null) => () => {
    setOpenModal(modal);
  };

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link
          color={discountValue !== ZERO_MONEY && discountValue !== '0%' && !isSelectedAllServices ? 'error' : 'primary'}
          disabled={isSelectedAllServices}
          onClick={toggleOpenModal(Modals.DISCOUNT)}
        >
          Discount
        </Link>
        <SalesToClosingComparising sales={salesDiscount.asHumanReadableDiscount()} percent={percent}>
          <BodyText>{discountValue}</BodyText>
        </SalesToClosingComparising>
      </Box>
      {Modals.DISCOUNT === openModal && (
        <DiscountModal open onSave={handleDiscountChange} onCancel={toggleOpenModal(null)} discount={discount} />
      )}
    </Grid>
  );
};
