import React from 'react';

import { ActivitySourceType, Order, SurveyType } from '@elromcoinc/react-shared';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SchedulerBox from 'admin/components/OrderWindow/SchedulerBox';
import { ActivityManager, ChangeLog } from 'admin/components/OrderWindow/blocks';
import { AssignedCrews } from 'admin/components/OrderWindow/blocks/AssignedCrews';
import { ClosingBar } from 'admin/components/OrderWindow/blocks/Closing';
import MoveDetails from 'admin/components/OrderWindow/blocks/MoveDetails';
import { OrderCrewStatusLog } from 'admin/components/OrderWindow/blocks/OrderCrewStatusLog';
import { Services } from 'admin/components/OrderWindow/blocks/Services';
import { LONG_DISTANCE_PLANNER, REQUESTED_DELIVERY_DATE_START } from 'admin/constants/FieldNames';
import settingName from 'admin/constants/SettingName';
import { useMobile } from 'admin/hooks/useMobile';
import { TruckSchedulerWindowPlacement } from 'common-types/TruckSchedulerWindowPlacement';

import MultiDayView from './MultiDayView';
import AddressesBar from './blocks/Addresses';
import ClosingQuoteDetails from './blocks/ClosingQuoteDetails';
import Customer from './blocks/Customer';
import OrderDetails from './blocks/OrderDetails';
import QuoteDetails from './blocks/QuoteDetails';
import TopControlsBar from './blocks/TopControlsBar';
import { useOrderClosingContext, useOrderServiceIndex, useOrderWindowSettings } from './context';

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ $wrap }) => ($wrap ? 'flex-wrap: wrap;' : '')}
`;
export const Overlay = styled.div`
  position: absolute;
  z-index: 2000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
`;

const activitySource = ActivitySourceType.ORDER;

function OrderView(props) {
  const {
    order,
    onChange,
    windowWidth,
    onAddDay,
    isLocked,
    originalOrder,
    account,
    isLoading,
    setCurrentPage,
    isLoadingSurveyServices,
    surveyServices,
    reloadSurveyServices,
    setLoadedOrder,
    inFlightRecalculateOrder,
  } = props;
  const { serviceIndex, setServiceIndex } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();
  const settings = useOrderWindowSettings();
  const isMobile = useMobile();

  if (!order) {
    return null;
  }

  const truckScheduleWindowPlacement = settings?.[settingName.ORDERS_TRUCK_SCHEDULE_WINDOW_PLACEMENT];
  const isLg = windowWidth > 1200;
  const isMd = windowWidth > 1024;
  const isSm = windowWidth > 650;
  const cardWidth =
    (isLg && 'calc(25% - 4px)') || (isMd && 'calc(50% - 4px)') || (isSm && 'calc(50% - 24px)') || '100%';
  const isMultiDay = order.services.size > 1;
  const serviceDetailCardWidth = !isMultiDay || isLg ? cardWidth : (!isSm && '100%') || '33%';
  const sourceId = order.orderId;
  const inHomeEstimateService =
    surveyServices && surveyServices.find((item) => item.orderServiceType === SurveyType.IN_HOME_ESTIMATE);
  const virtualEstimateService =
    surveyServices && surveyServices.find((item) => item.orderServiceType === SurveyType.VIRTUAL_ESTIMATE);

  const serviceView = (
    <>
      <OrderDetails width={serviceDetailCardWidth} order={order} onChange={onChange} />
      <MoveDetails width={serviceDetailCardWidth} order={order} onChange={onChange} onAddDay={onAddDay} />
      {isClosing ? (
        <ClosingQuoteDetails width={serviceDetailCardWidth} />
      ) : (
        <QuoteDetails width={serviceDetailCardWidth} />
      )}
    </>
  );

  const service = order.services.get(serviceIndex);

  if (!service) {
    setServiceIndex(order.services.size - 1);
    return null;
  }

  return (
    <Box position="relative">
      <TopControlsBar
        onChange={onChange}
        order={order}
        originalOrder={originalOrder}
        account={account}
        sourceId={sourceId}
        activitySource={activitySource}
        setCurrentPage={setCurrentPage}
        surveyServices={surveyServices}
        reloadSurveyServices={reloadSurveyServices}
        inHomeEstimateService={inHomeEstimateService}
        virtualEstimateService={virtualEstimateService}
      />
      {truckScheduleWindowPlacement === TruckSchedulerWindowPlacement.TOP && (
        <Box className="print-remove" mb={2}>
          <SchedulerBox
            moveDate={service?.date}
            longDistancePlanner={service.quote.get(LONG_DISTANCE_PLANNER)}
            deliveryDate={service.quote.get(REQUESTED_DELIVERY_DATE_START)}
            numberOfTrucks={service?.quote.numberOfTrucks}
            orderId={order.orderId}
            startTimeEarliest={service?.quote.startTimeEarliest}
            arrivalWindow={service?.quote.arrivalWindow}
            onChange={onChange}
          />
        </Box>
      )}
      <ClosingBar />
      {isClosing && (
        <Box mb={1} p={1}>
          <OrderCrewStatusLog />
        </Box>
      )}
      <CardsContainer $wrap={!isLg} className="order-window-print__cards-container">
        <Customer
          width={cardWidth}
          order={order}
          onChange={onChange}
          account={account}
          isLoading={isLoading}
          setLoadedOrder={setLoadedOrder}
        />
        {isMultiDay ? <MultiDayView order={order}>{serviceView}</MultiDayView> : serviceView}
      </CardsContainer>
      <CardsContainer $wrap={!isLg} className="order-window-print__cards-container">
        <AddressesBar inFlightRecalculateOrder={inFlightRecalculateOrder} setLoadedOrder={setLoadedOrder} />
        {(isClosing || !!surveyServices.length) && (
          <Box maxWidth={isMobile ? '100%' : '30%'} width="100%">
            {isClosing && <AssignedCrews />}
            {!!surveyServices.length && (
              <Services
                isLoadingSurveyServices={isLoadingSurveyServices}
                moveType={order.moveType}
                surveyServices={surveyServices}
                order={order}
                reloadSurveyServices={reloadSurveyServices}
                inHomeEstimateService={inHomeEstimateService}
                virtualEstimateService={virtualEstimateService}
              />
            )}
          </Box>
        )}
      </CardsContainer>
      <CardsContainer $wrap={!isLg} className="order-window-print__cards-container">
        {isClosing ? (
          // here must be displayed Closing and BOL logs.
          <Grid container>
            <Grid item xs={12}>
              <ChangeLog sourceId={order.orderId} activitySource={activitySource} />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} md={8} lg={8}>
              <ActivityManager
                sourceId={order.orderId}
                activitySource={activitySource}
                orderNumber={order.orderNumber}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <ChangeLog sourceId={order.orderId} activitySource={activitySource} />
            </Grid>
          </Grid>
        )}
      </CardsContainer>
      {isLocked && <Overlay />}
      {truckScheduleWindowPlacement === TruckSchedulerWindowPlacement.BOTTOM && (
        <Box className="print-remove">
          <SchedulerBox
            moveDate={service?.date}
            longDistancePlanner={service.quote.get(LONG_DISTANCE_PLANNER)}
            deliveryDate={service.quote.get(REQUESTED_DELIVERY_DATE_START)}
            numberOfTrucks={service?.quote.numberOfTrucks}
            orderId={order.orderId}
            startTimeEarliest={service?.quote.startTimeEarliest}
            arrivalWindow={service?.quote.arrivalWindow}
            onChange={onChange}
          />
        </Box>
      )}
    </Box>
  );
}

OrderView.propTypes = {
  order: PropTypes.instanceOf(Order),
  originalOrder: PropTypes.instanceOf(Order),
  isLocked: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  fetchOrderInfo: PropTypes.func.isRequired,
  onAddDay: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.object,
  isLoading: PropTypes.bool,
  setCurrentPage: PropTypes.func.isRequired,
  isLoadingSurveyServices: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  surveyServices: PropTypes.array,
  reloadSurveyServices: PropTypes.func,
  setLoadedOrder: PropTypes.func.isRequired,
};

OrderView.defaultProps = {
  order: null,
  originalOrder: null,
  isLoading: null,
  account: null,
  isLoadingSurveyServices: false,
  surveyServices: [],
  reloadSurveyServices: null,
};

export default OrderView;
