import axios from 'axios';

import { DashboardStatistics } from 'common-types/statistic';

class DashboardApi {
  getStats({
    startDate,
    endDate,
    compareToRange,
    branchIds = [1],
  }: {
    startDate: string;
    endDate: string;
    compareToRange?: { startDate: string; endDate: string };
    branchIds?: number[];
  }) {
    return axios.post<DashboardStatistics, DashboardStatistics>('/api/stats', {
      dateRange: { startDate, endDate },
      compareToRange,
      branchIds,
    });
  }
  getSubmissionStatistic(startDate: string, endDate: string) {
    return axios.get<number, number>(`/api/stats/reports/submissions/startDate/${startDate}/endDate/${endDate}/count`);
  }
}

export default new DashboardApi();
