import React, { FC } from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { useOrderClosingContext, useOrderSalesInfoContext } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    percentage: {
      fontSize: '12px',
      marginLeft: '20px',
      opacity: '0.5',
    },
    costs: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '55%',
    },
    sales: {
      display: 'flex',
      justifyContent: 'end',
      width: '55%',
    },
    hideInfo: {
      justifyContent: 'end',
    },
  }),
);

interface SalesToClosingComparisingProps {
  sales: string;
  percent: string;
}

export const SalesToClosingComparising: FC<SalesToClosingComparisingProps> = ({ sales, percent, children }) => {
  const classes = useStyles();
  const { viewQuoteDetailsSalesInfo } = useOrderSalesInfoContext();
  const { isCompleted } = useOrderClosingContext();

  return (
    <Box className={clsx(classes.costs, { [classes.hideInfo]: !viewQuoteDetailsSalesInfo })}>
      {viewQuoteDetailsSalesInfo && isCompleted && (
        <Box className={classes.sales}>
          <BodyText className={classes.percentage}>{sales}</BodyText>
          <BodyText className={classes.percentage}>
            <b>{percent}</b>
          </BodyText>
        </Box>
      )}
      {children}
    </Box>
  );
};
