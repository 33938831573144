import React, { useEffect, useState } from 'react';

import { Button, ButtonGroup, CircularProgress, statusIds, statusesById } from '@elromcoinc/react-shared';
import { Box, Grid, Paper, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import orderApi from 'admin/api/OrderAPI';
import { fetchUnreadCount, getUnreadCount } from 'admin/autodux/MessageAutodux';
import { getReloadAmount } from 'admin/autodux/ReloadAutodux';
import { allNewStatuses } from 'admin/components/Calendar/orders/utils';
import { makeBookedFilters } from 'admin/components/Orders/config';
import {
  DASHBOARD_TAB_NAME,
  MESSAGES,
  MESSAGES_PATH_NAME,
  ORDERS_PATH_NAME,
  STATUS_QUERY_PARAM_NAME,
  TAB_QUERY_PARAM_NAME,
  bookedOptionsLabels,
  bookedOptionsLabelsSmall,
} from 'admin/components/Orders/config/OrdersConstants';
import { useQuery } from 'admin/hooks/useQuery';

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: '-240px',
    width: '180px',
  },
}));

const StyledButton = styled(Button)`
  &:after,
  &:before {
    border-color: ${({ $color }) => $color};
  }
  &.MuiButton-containedPrimary:hover {
    background-color: ${({ $color, $selected }) => ($selected ? 'white' : $color)};
    color: ${({ $color, $selected }) => ($selected ? $color : 'white')};
  }
  &.MuiButton-root {
    border-right-color: ${({ $color }) => $color}!important;
  }
  &.MuiButton-containedPrimary {
    background-color: ${({ $color, $selected }) => ($selected ? 'white' : $color)};
    color: ${({ $color, $selected }) => ($selected ? $color : 'white')};
    box-shadow: none;
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding-top: 5px;
    padding-bottom: 4px;
    border-color: ${({ $color }) => $color || 'transparent'};
  }
  &.MuiButton-containedPrimary:first-child {
    ${({ $selected }) => ($selected ? 'border-left: 1px solid;' : '')}
  }
  &.MuiButton-containedPrimary:last-child {
    ${({ $selected }) => ($selected ? 'border-right: 1px solid;' : '')}
  }
  &.MuiButton-sizeSmall.MuiButton-root:not(:last-child):after {
    top: ${({ $isMobile }) => ($isMobile ? '4px' : '3px')};
    right: ${({ $isMobile }) => ($isMobile ? '-14px' : '-11px')};
    width: ${({ $isMobile }) => ($isMobile ? '26px' : '19px')};
    height: ${({ $isMobile }) => ($isMobile ? '26px' : '19px')};
  }
  &.MuiButton-sizeSmall {
    padding-top: ${({ $isMobile }) => ($isMobile ? 0 : '5px')};
    padding-bottom: ${({ $isMobile }) => ($isMobile ? 0 : '5px')};
    .MuiButton-label {
      line-height: 1.4;
    }
  }
  &.MuiButton-root:not(:last-child):after {
    top: 5px !important;
    right: -12px !important;
    width: 22px !important;
    height: 22px !important;
  }
`;

const statusesByIds = [
  statusIds.NEW,
  statusIds.FOLLOW_UP,
  statusIds.CAN_BOOK_ONLINE,
  statusIds.RESERVED,
  statusIds.BOOKED,
].map((value) => statusesById[value]);

const overriddenStatusLabels = { [statusIds.CAN_BOOK_ONLINE]: 'can book', [statusIds.NEW]: 'new lead' };
const usualStatuses = statusesByIds.map((status) => ({
  ...status,
  label: overriddenStatusLabels[status.id] || status.label,
}));

const statusesSmall = statusesByIds.map((status) => ({ ...status, label: status.labelSmall }));

export const OrdersDashboardFilters = ({ onChangeSelectedButton, selectedButton, selectedBookedOption }) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [dashboardStatuses, setDashboardStatuses] = useState([0, 0, 0, 0, 0]);
  const totalUnread = useSelector(getUnreadCount);
  const [isLoadingStatistics, setIsLoadingStatistics] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const statuses = isMobile
    ? statusesSmall.map((status) =>
        status.id === statusIds.BOOKED ? { ...status, label: bookedOptionsLabelsSmall[selectedBookedOption] } : status,
      )
    : usualStatuses.map((status) =>
        status.id === statusIds.BOOKED
          ? { ...status, label: `${statusIds.BOOKED} (${bookedOptionsLabelsSmall[selectedBookedOption]})` }
          : status,
      );
  const buttonSize = isMobile ? 'small' : 'medium';
  const query = useQuery();
  const currentStatus = query.get(STATUS_QUERY_PARAM_NAME);
  const reloadAmount = useSelector(getReloadAmount);

  const handleSelectedButtonClick = (value) => () => {
    if (value === MESSAGES) {
      history.push(MESSAGES_PATH_NAME);
    } else {
      history.push(
        `${ORDERS_PATH_NAME}?${queryString.stringify({
          ...Object.fromEntries(query),
          [STATUS_QUERY_PARAM_NAME]: value,
          [TAB_QUERY_PARAM_NAME]: DASHBOARD_TAB_NAME,
        })}`,
      );
    }

    onChangeSelectedButton(value);
  };

  useEffect(() => {
    if (!currentStatus && location.pathname === ORDERS_PATH_NAME) {
      history.push({
        search: `?${STATUS_QUERY_PARAM_NAME}=${statusIds.NEW}`,
      });
      onChangeSelectedButton(statusIds.NEW);
    }
  }, [query]);

  useEffect(() => {
    if (currentStatus && location.pathname === ORDERS_PATH_NAME && !selectedButton) {
      onChangeSelectedButton(statusIds.NEW);
    }
  }, [selectedButton]);

  useEffect(() => {
    setIsLoadingStatistics(true);

    Promise.all([
      orderApi.getOrderSummariesWithFilters({
        pageSize: 1,
        status: allNewStatuses,
      }).promise,
      orderApi.getOrderSummariesWithFilters({
        pageSize: 1,
        status: statusIds.FOLLOW_UP,
      }).promise,
      orderApi.getOrderSummariesWithFilters({
        pageSize: 1,
        status: statusIds.CAN_BOOK_ONLINE,
      }).promise,
      orderApi.getOrderSummariesWithFilters({
        pageSize: 1,
        status: statusIds.RESERVED,
      }).promise,
      orderApi.getOrderSummariesWithFilters({ ...makeBookedFilters(selectedBookedOption), pageSize: 1 }).promise,
    ])
      .then(
        ([
          { totalElements: totalNew = 0 },
          { totalElements: totalFollowUp = 0 },
          { totalElements: totalCanBookOnline = 0 },
          { totalElements: totalReserved = 0 },
          { totalElements: bookedThisMonth = 0 },
        ]) => {
          setDashboardStatuses([totalNew, totalFollowUp, totalCanBookOnline, totalReserved, bookedThisMonth]);
        },
      )
      .catch(() => {
        enqueueSnackbar(`Can't get statistics by current status`, { variant: 'error' });
      })
      .then(() => {
        setIsLoadingStatistics(false);
      });

    dispatch(fetchUnreadCount());
  }, [reloadAmount, selectedBookedOption]);

  return (
    <Grid container spacing={isMobile ? 1 : 2} direction={isMobile ? 'column-reverse' : 'row'}>
      <Grid item md={2} xs={12} sm={12}>
        <Paper elevation={0}>
          <Button
            disableElevation="true"
            fullWidth
            variant={selectedButton === MESSAGES ? 'outlined' : 'contained'}
            size={buttonSize}
            color="primary"
            onClick={handleSelectedButtonClick(MESSAGES)}
          >
            {`NEW MESSAGES ${totalUnread}`}
          </Button>
        </Paper>
      </Grid>
      <Grid item md={10} xs={12} sm={12}>
        <ButtonGroup
          fullWidth
          variant="contained"
          disableElevation="true"
          size={buttonSize}
          separatorStyle="arrow-right"
        >
          {statuses.map((status, index) => (
            <StyledButton
              onClick={handleSelectedButtonClick(status.id)}
              disableTouchRipple
              $color={status.color}
              $selected={status.id === selectedButton}
              $isMobile={isSmallMobile}
              color="primary"
              variant="contained"
              key={status.id}
            >
              <Box display="flex" flexDirection={isSmallMobile ? 'column' : 'row'} position="relative">
                <Box>{`${status.label}`}</Box>
                {isLoadingStatistics ? (
                  <Box classes={{ root: classes.loading }}>
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <Box>&nbsp;{dashboardStatuses[index] || 0}</Box>
                )}
              </Box>
            </StyledButton>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

OrdersDashboardFilters.propTypes = {
  onChangeSelectedButton: PropTypes.func.isRequired,
  selectedButton: PropTypes.string.isRequired,
  selectedBookedOption: PropTypes.oneOf(Object.keys(bookedOptionsLabels)).isRequired,
};
