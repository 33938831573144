import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  ActivitySourceType,
  DatePicker,
  Select,
  Switch,
  TasksPermissions,
  TextInput,
  TimePicker,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  add,
  differenceInSeconds,
  format,
  getHours,
  getMinutes,
  getSeconds,
  isAfter,
  isSameDay,
  isSameMinute,
  parseISO,
  set as setD,
  startOfToday,
} from 'date-fns';
import { List, getIn } from 'immutable';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { boolean, date, number, object, string } from 'yup';

import tasksApi from 'admin/api/TasksApi';
import searchApi, { SearchDomains } from 'admin/api/searchApi';
import { reassignTask, saveTask, updateTask } from 'admin/autodux/TaskAutodux';
import { getIsFetching, getManagerList } from 'admin/autodux/UsersAutodux';
import { useFetchManagers } from 'admin/hooks/useFetchManagers';
import { getAuthUser } from 'admin/selectors/auth';
import { Task, TaskPriorityName, TaskTypeName } from 'common-types/Task';
import useDebounce from 'common/hooks/useDebounce';

import {
  ACTIVITY_SOURCE_PROPERTY,
  DATE,
  DUE,
  EMPLOYEE_ID,
  NOTES,
  ORDER_ID,
  PRIORITY,
  REMIND_ME,
  REMIND_TIME,
  SKIP_DATE_TIME_VALIDATION,
  SKIP_DATE_TIME_VALIDATION_YUP,
  SUBJECT,
  TASK_TYPE,
  TIME,
  labels,
} from './TaskConstants';
import { TaskSource } from './TaskSource';

const useStyles = makeStyles(({ spacing }) => ({
  roundedField: {
    '& .MuiInputBase-root': {
      borderRadius: spacing(0.75),

      '&:before': {
        borderRadius: spacing(0, 0, 0.75, 0.75),
      },

      '& .MuiInputBase-input': {
        borderRadius: spacing(0.75),
      },
    },
  },
}));

const TaskPriorityOptions = Object.entries(TaskPriorityName);

const MIN_TEXT_SIZE = 4;
const MAX_TEXT_SIZE = 1024;

const schema = object().shape({
  [DUE]: string().nullable(),
  [ORDER_ID]: number().label(labels[ORDER_ID]).nullable(),
  [EMPLOYEE_ID]: number().label(labels[EMPLOYEE_ID]).nullable().required(),
  [SUBJECT]: string().label(labels[SUBJECT]).min(MIN_TEXT_SIZE).max(MAX_TEXT_SIZE).nullable().required(),
  [DATE]: date()
    .label(labels[DATE])
    .when(SKIP_DATE_TIME_VALIDATION_YUP, (skipDateTimeValidation, s) => {
      if (skipDateTimeValidation) {
        return s;
      }

      return s.min(startOfToday(), 'Please choose date starting from today');
    })
    .nullable()
    .required(),
  [TIME]: date()
    .label(labels[TIME])
    .nullable()
    .required() // @ts-ignore
    .when([DATE, SKIP_DATE_TIME_VALIDATION_YUP], (dateValue: Date, skipDateTimeValidation: boolean, s: any) => {
      const currentDate = new Date();

      if (!skipDateTimeValidation && dateValue) {
        if (isSameDay(currentDate, dateValue)) {
          return s.min(
            currentDate,
            `Please choose time more than ${format(add(currentDate, { minutes: 5 }), 'hh:mm a')}`,
          );
        }

        if (isAfter(currentDate, dateValue)) {
          return s.min(currentDate, 'Please choose date more or equal today');
        }
      }

      return s;
    }),
  [REMIND_ME]: boolean(),
  [REMIND_TIME]: number()
    .label(labels[REMIND_TIME])
    .nullable()
    .when(
      [REMIND_ME, DATE, TIME, SKIP_DATE_TIME_VALIDATION_YUP], // @ts-ignore
      (remindMe: boolean, dateValue: Date, time: Date, skipDateTimeValidation: boolean, s: any) => {
        if (!skipDateTimeValidation && remindMe && dateValue && time) {
          const hours = getHours(time);
          const minutes = getMinutes(time);
          const seconds = getSeconds(time);
          const remindDate = setD(dateValue, { hours, minutes, seconds });
          const max = differenceInSeconds(remindDate, new Date());

          return s.max(max, 'We cannot notify you in past. Please check "Date", "Time" values');
        }

        if (remindMe) {
          return s.required();
        }

        return s;
      },
    ),
  [PRIORITY]: string().label(labels[PRIORITY]).nullable().required(),
  [NOTES]: string().label(labels[NOTES]).max(MAX_TEXT_SIZE).nullable(),
  [TASK_TYPE]: string().label(labels[TASK_TYPE]).required().nullable(),
  [ACTIVITY_SOURCE_PROPERTY]: string().nullable(),
});

const remindOptions = [5, 10, 15, 30, 60, 120, 180, 240, 300, 360].reduce((accumulator, item) => {
  const seconds = item * 60;
  const hours = Math.floor(item / 60);
  const text = hours >= 1 ? `${hours} hour before start` : `${item} minutes before start`;

  return [...accumulator, [seconds, text]] as [number, string][];
}, [] as [number, string][]);

const HALF_A_SECOND = 500;
const prepareName = (name?: string) => (name ? `${name} ` : '');
const taskTypeOptions = Object.entries(TaskTypeName);

interface TaskFormProps {
  source: Partial<TaskSource> | null;
  onSave?: () => void;
  saveClickedCount: number;
  onSelectedSource?: Dispatch<SetStateAction<Partial<TaskSource> | null>>;
  task?: Task;
  defaultActivitySource?: ActivitySourceType;
  setDefaultActivitySource: (s: ActivitySourceType) => void;
}

const TaskForm = ({
  source,
  onSave,
  saveClickedCount,
  onSelectedSource,
  task,
  defaultActivitySource,
  setDefaultActivitySource,
}: TaskFormProps) => {
  const classes = useStyles();
  const dispatch = useDispatch<DispatchPromise>();
  const defaultValues = (
    task || new Task({ activitySource: defaultActivitySource, sourceId: source?.orderId || source?.sourceId })
  ).toJS();
  const context = useMemo(() => ({ [SKIP_DATE_TIME_VALIDATION]: false }), []);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues,
    context,
    resolver: yupResolver(schema),
  });
  context[SKIP_DATE_TIME_VALIDATION] = !!(
    defaultValues.id &&
    defaultValues[DATE] &&
    isSameMinute(defaultValues[DATE] as Date, parseISO(watch(DUE)))
  );
  const [sourceOptions, setSourceOptions] = useState<[string, string][]>([]);
  const [sourceIdInputValue, setSourceIdInputValue] = useState<string | null>(null);
  const [selectedSourceId, setSelectedSourceId] = useState<ReturnType<typeof getCurrentSourceOption>>(null);
  const [inFlightSearch, setInFlightSearch] = useState(false);
  const [inFlight, setInFlight] = useState(false);
  const managersList = useSelector(getManagerList) as List<{ id: number; fullName: string }>;
  const currentUser = useSelector(getAuthUser);
  const inFlightManagers = useSelector(getIsFetching);
  const debouncedSourceIdInputValue = useDebounce(sourceIdInputValue, HALF_A_SECOND);
  const managers = managersList.map((item) => [item.id, item.fullName]).toJS() as [number, string][];
  const canCreateTaskForOtherUser = useHasPermission(TasksPermissions.PERM_CAN_CREATE_TASK_FOR_OTHER_USERS);

  const handleOnSave = (data: any) => {
    setInFlight(true);

    const { dueDate, dueTime } = data;
    const hours = getHours(dueTime);
    const minutes = getMinutes(dueTime);
    const due = setD(dueDate, { hours, minutes, seconds: 0, milliseconds: 0 }).toISOString();
    const taskToSave = new Task({ ...defaultValues, ...data, due });

    if (defaultValues.id) {
      dispatch(updateTask(taskToSave, task)).then(() => {
        setInFlight(false);

        if (task?.employeeId !== taskToSave.employeeId) {
          dispatch(reassignTask(taskToSave));
        }

        onSave?.();
      });
    } else {
      tasksApi
        .save(taskToSave.toDTO())
        .then((response) => {
          onSave?.();

          const isTaskForCurrentUser = response.employeeId === currentUser.id;

          if (isTaskForCurrentUser) {
            dispatch(saveTask(new Task(response)));
          }
        })
        .catch(() => false)
        .finally(() => setInFlight(false));
    }
  };

  useEffect(() => {
    if (saveClickedCount && !inFlight) {
      handleSubmit(handleOnSave)();
    }
  }, [saveClickedCount]);

  useFetchManagers();

  useEffect(() => {
    if (source && (source.orderId || source.sourceId)) {
      const options = getCurrentSourceOption() as [string, string];
      setSelectedSourceId([...options]);
      setSourceOptions([options]);
      setValue(ORDER_ID, source.orderId || source.sourceId);
    }
  }, []);

  useEffect(() => {
    if (debouncedSourceIdInputValue) {
      if (selectedSourceId && selectedSourceId[1] === debouncedSourceIdInputValue) {
        return;
      }

      setInFlightSearch(true);

      searchApi(debouncedSourceIdInputValue, SearchDomains.ALL, 0, 100)
        .then(({ accounts: { pageElements: accounts }, orders: { pageElements: orders } }) => {
          setSourceOptions(
            orders
              .map((item) => {
                const {
                  orderId,
                  sourceId,
                  orderNumber,
                  contactInfo: { firstName, lastName },
                } = item;
                const text = `${firstName} ${lastName} (${orderNumber})`.trim();

                return [`${ActivitySourceType.ORDER}|${orderId || sourceId}`, text] as [string, string];
              })
              .concat(
                accounts.map(
                  (account) =>
                    [
                      `${ActivitySourceType.CUSTOMER_ACCOUNT}|${account.id}`,
                      `${account.contactName} (#${account.id})`,
                    ] as [string, string],
                ),
              ),
          );
        })
        .catch()
        .then(() => {
          setInFlightSearch(false);
        });
    } else {
      const option = getCurrentSourceOption();

      if (option) {
        setSourceOptions([option]);
      }
    }
  }, [debouncedSourceIdInputValue]);

  useEffect(() => {
    const hasCurrentUser = managers.find(([id]) => id === currentUser.id);

    if (hasCurrentUser) {
      setValue(EMPLOYEE_ID, currentUser.id);
    }
  }, [managersList]);

  function getCurrentSourceOption() {
    if (source && (source.orderId || source.sourceId)) {
      const { orderId, sourceId, orderNumber, contactInfo: { firstName, lastName } = {} } = source;
      const text = `${prepareName(firstName)}${prepareName(lastName)}(${orderNumber ? '' : '#'}${
        orderNumber || sourceId
      })`.trim();
      return [`${defaultActivitySource}|${orderId || sourceId}`, text] as [string, string];
    }

    return null;
  }

  const handleAutocompleteInputChange = (event: ChangeEvent<HTMLElement>, value: string) => {
    setSourceIdInputValue(value);
  };

  const handleAutocompleteChange = (event: ChangeEvent<HTMLElement>, value: [string, string]) => {
    setSelectedSourceId(value);
    const sourceId = value && value[0];
    const [activityType, id] = (sourceId || '').split('|');

    if (onSelectedSource) {
      onSelectedSource(sourceId ? { sourceId: +id } : null);
    }

    if (id) {
      setValue(ORDER_ID, id);
      setValue(ACTIVITY_SOURCE_PROPERTY, activityType);
      setDefaultActivitySource?.(activityType as ActivitySourceType);
    } else {
      setValue(ORDER_ID, watch(EMPLOYEE_ID));
      setValue(ACTIVITY_SOURCE_PROPERTY, ActivitySourceType.EMPLOYEE);
      setDefaultActivitySource?.(ActivitySourceType.EMPLOYEE);
    }
  };

  return (
    <>
      <Box mx={2}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Box className={classes.roundedField}>
              <Select
                fullWidth
                label={labels[EMPLOYEE_ID]}
                name={EMPLOYEE_ID}
                options={managers}
                control={control}
                disabled={!canCreateTaskForOtherUser}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            {inFlightManagers && <LinearProgress />}
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box className={classes.roundedField}>
              <Autocomplete
                autoComplete
                selectOnFocus
                clearOnBlur
                includeInputInList
                options={sourceOptions}
                getOptionLabel={(option) => option[1]} // @ts-ignore we have error inside TextInput
                error={!!getIn(errors, [ORDER_ID, 'message'], '')}
                value={selectedSourceId}
                onChange={handleAutocompleteChange as any}
                onInputChange={handleAutocompleteInputChange as any}
                loading={inFlightSearch}
                renderInput={(params) => (
                  <TextInput {...params} placeholder="Search account name or number" label={labels[ORDER_ID]} />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.roundedField}>
              <TextInput fullWidth label={labels[SUBJECT]} name={SUBJECT} control={control} />
            </Box>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box className={classes.roundedField}>
              {/*@ts-ignore*/}
              <DatePicker fullWidth name={DATE} label={labels[DATE]} minDate={new Date()} control={control} />
            </Box>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box className={classes.roundedField}>
              {/*@ts-ignore*/}
              <TimePicker fullWidth label={labels[TIME]} name={TIME} control={control} />
            </Box>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box className={classes.roundedField}>
              <Select
                fullWidth
                label={labels[TASK_TYPE]}
                name={TASK_TYPE}
                options={taskTypeOptions}
                control={control}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {/*@ts-ignore*/}
            <Switch color="primary" name={REMIND_ME} value={false} label={labels[REMIND_ME]} control={control} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box className={classes.roundedField}>
              <Select
                fullWidth
                label={labels[REMIND_TIME]}
                name={REMIND_TIME}
                options={remindOptions}
                control={control}
              />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box className={classes.roundedField}>
              <Select
                fullWidth
                label={labels[PRIORITY]}
                name={PRIORITY}
                options={TaskPriorityOptions}
                defaultValue=""
                control={control}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={1}>
          <Box fontSize={18} fontWeight={600} lineHeight="18px" mb={1} mt={4}>
            {labels[NOTES]}
          </Box>
          <Box className={classes.roundedField}>
            <TextInput fullWidth name={NOTES} multiline rows="4" control={control} />
          </Box>
        </Box>
        {inFlight && (
          <Box mt={1}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default TaskForm;
