import { FC, useMemo } from 'react';

import { ActivitySourceType, InvoiceDto, Modal } from '@elromcoinc/react-shared';

import { ComposeEmail } from 'admin/components/OrderWindow/blocks/ActivityManager/ComposeEmail';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { AutomationRuleName, CommunicationModality, SendEmailDto, Template } from 'common-types';

interface SendInvoiceEmailModalProps {
  invoiceId: InvoiceDto['id'];
  onClose: () => void;
  onSend: (dto?: SendEmailDto) => void;
}

export const SendInvoiceEmailModal: FC<SendInvoiceEmailModalProps> = ({ onClose, onSend, invoiceId }) => {
  const { invoiceTemplates, automationRules } = useComposeEmail();
  const invoiceAutomationRule = automationRules.find(
    (ar) => ar.shortName === AutomationRuleName.WHEN_A_EMPLOYEE_CREATES_AN_INVOICE,
  );
  const invoiceTemplate = invoiceTemplates.find(
    (t) => t.modality === CommunicationModality.EMAIL && t.automationRuleId === invoiceAutomationRule?.id && t.active,
  );
  const defaultTemplate = useMemo(() => new Template(invoiceTemplate?.toDTO({ useExistingBody: true }) ?? {}), []);

  return (
    <Modal open title="Template preview" onClose={onClose} maxWidth="md" disableEnforceFocus>
      <ComposeEmail
        activitySource={ActivitySourceType.INVOICE}
        sourceId={invoiceId}
        initialTemplate={defaultTemplate}
        onSend={onSend}
      />
    </Modal>
  );
};
