import React, { FC, useState } from 'react';

import {
  ActivitySourceType,
  BodyBigText,
  CircularProgress,
  HeaderSmallText,
  IconButton,
  Modal,
  TextInput,
  useAlert,
} from '@elromcoinc/react-shared';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextsmsIcon from '@material-ui/icons/Textsms';
import { Map } from 'immutable';
import { useFieldArray, useFormContext } from 'react-hook-form';

import templateApi from 'admin/api/templateApi';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { EditEmailPreview } from 'admin/components/OrderWindow/modals/EditEmailPreview';
import { BaseTemplate, CommunicationModality, Template } from 'common-types';
import { BlueDeleteIcon, BlueVisibilityIcon } from 'common/components/Widgets';

const useStyles = makeStyles((theme) => ({
  menu: {
    width: '100%',
  },
  templateTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: 0,
  },
  accordionDetails: {
    padding: theme.spacing(1, 0, 2),
  },
  centeredError: {
    alignItems: 'center',
  },
}));

interface EditTemplatesDialogProps {
  modalProps: ReturnType<typeof useAlert>['alertProps'];
  sourceId?: number;
  activitySource: ActivitySourceType;
}

enum ViewModes {
  EMAIL_PREVIEW = 'EMAIL_PREVIEW',
  TEMPLATE_PREVIEW_EDIT = 'TEMPLATE_PREVIEW_EDIT',
}

const IconByModality = Map({
  [CommunicationModality.EMAIL]: <EmailIcon color="primary" />,
  [CommunicationModality.SMS]: <TextsmsIcon color="primary" />,
});

const EditTemplatesDialog: FC<EditTemplatesDialogProps> = ({
  modalProps,
  sourceId,
  activitySource = ActivitySourceType.ORDER,
}) => {
  const classes = useStyles();
  const [templatePreview, setTemplatePreview] = useState('');
  const { groupedTemplatesByTemplateFolder, inFlightTemplates } = useComposeEmail();
  const { getValues } = useFormContext();
  const {
    fields: templates,
    remove,
    append,
    update,
  } = useFieldArray({
    name: 'templates',
    keyName: 'nid',
  });
  const [openModal, setOpenModal] = useState<ViewModes | null>(null);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [templateToEdit, setTemplateToEdit] = useState<Template | null>(null);
  const [seletedTemplateIndex, setSeletedTemplateIndex] = useState<number | null>(null);

  const isEditTemplate =
    openModal === ViewModes.TEMPLATE_PREVIEW_EDIT && templateToEdit && seletedTemplateIndex !== null;

  const handleExpand = (index: number) => () => setExpandedIndex((state) => (state === index ? -1 : index));

  const handleChooseTemplate = (template: BaseTemplate) => () => {
    templateApi.getTemplate(template.id!).then((response) => {
      const template = new Template(response);
      append(template.toJS());
    });
  };

  const removeSelectedTemplate = (index: number) => () => {
    remove(index);
  };

  const showTemplatePreview = (index: number) => () => {
    setOpenModal(ViewModes.EMAIL_PREVIEW);
    const template = getValues().templates[index];

    if (sourceId) {
      setTemplatePreview('');
      templateApi
        .preview(sourceId, activitySource, new Template(template).toDTO({ useExistingBody: true }))
        .then((response) => {
          setTemplatePreview(response);
        });
    }
  };

  const editTemplate = (mode: ViewModes, index: number) => () => {
    const template = getValues().templates[index];
    setOpenModal(mode);
    setTemplateToEdit(new Template(template));
    setSeletedTemplateIndex(index);
  };

  const closeHandler = () => setOpenModal(null);

  const handleCloseUpdateTemplate = () => {
    setTemplateToEdit(null);
    setSeletedTemplateIndex(null);
    closeHandler();
  };

  const handleUpdateTemplate = (template: Template) => {
    if (template.id) {
      update(seletedTemplateIndex!, template.toJS());
    }

    handleCloseUpdateTemplate();
  };

  return (
    <Modal title="Templates for sending" fullWidth maxWidth="md" {...modalProps}>
      <Box display="flex">
        <Box>
          <Box width={240}>
            <Paper className={classes.templateTitle}>
              <BodyBigText>Click to Use Template</BodyBigText>
              <Box mb={-1} height={8}>
                {inFlightTemplates && <LinearProgress color="primary" />}
              </Box>
            </Paper>
          </Box>
          {groupedTemplatesByTemplateFolder.map(([folderName, templates], index) => (
            <Box key={folderName} width={240}>
              <Accordion expanded={expandedIndex === index} onChange={handleExpand(index)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${folderName}-content`}
                  id={`${folderName}-header`}
                >
                  <BodyBigText text>{folderName}</BodyBigText>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <List component="nav" className={classes.menu}>
                    {templates.map((item) => (
                      <ListItem button key={item.id} onClick={handleChooseTemplate(item)}>
                        <Box display="flex" alignItems="center">
                          <Box ml={-2} px={0.5}>
                            {IconByModality.get(item.modality)}
                          </Box>
                          <BodyBigText>{item.name}</BodyBigText>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Box>
        <Box mx={2} width="100%" minHeight={500}>
          <Grid container spacing={2}>
            {templates.length ? (
              <Box width="100%">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <HeaderSmallText>
                          <b>Template Name</b>
                        </HeaderSmallText>
                      </TableCell>
                      <TableCell align="left">
                        <HeaderSmallText>
                          <b>Subject/Body</b>
                        </HeaderSmallText>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {templates.map((row, index) => {
                      const template = row as any as Template;

                      return (
                        <TableRow key={row.nid}>
                          <TableCell scope="row" size="small">
                            <BodyBigText>{template.name}</BodyBigText>
                          </TableCell>
                          <TableCell align="right" size="small">
                            {template.modality === CommunicationModality.EMAIL ? (
                              <TextInput fullWidth label="Subject" name={`templates.${index}.subject`} />
                            ) : (
                              <Box minWidth={350}>
                                <TextInput
                                  fullWidth
                                  label="Body"
                                  multiline
                                  minRows={3}
                                  name={`templates.${index}.body`}
                                />
                              </Box>
                            )}
                          </TableCell>
                          <TableCell align="right" size="small">
                            <Box>
                              {template.modality === CommunicationModality.EMAIL && (
                                <IconButton
                                  data-testid="editEmail"
                                  color="primary"
                                  aria-label="edit"
                                  onClick={editTemplate(ViewModes.TEMPLATE_PREVIEW_EDIT, index)}
                                >
                                  <EditIcon />
                                </IconButton>
                              )}
                              {!!sourceId && (
                                <IconButton color="primary" aria-label="preview" onClick={showTemplatePreview(index)}>
                                  <BlueVisibilityIcon />
                                </IconButton>
                              )}
                              <IconButton color="primary" aria-label="delete" onClick={removeSelectedTemplate(index)}>
                                <BlueDeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <BodyBigText>No templates selected</BodyBigText>
            )}
          </Grid>
        </Box>
      </Box>
      {isEditTemplate && (
        <EditEmailPreview template={templateToEdit} onClose={handleCloseUpdateTemplate} onSave={handleUpdateTemplate} />
      )}
      <Modal
        open={openModal === ViewModes.EMAIL_PREVIEW}
        title="Email Preview"
        onClose={closeHandler}
        maxWidth="md"
        actions={[{ label: 'done', onClick: closeHandler }]}
      >
        {!templatePreview ? (
          <Box display="flex" alignItems="center" justifyContent="center" my={2}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box dangerouslySetInnerHTML={{ __html: templatePreview }} />
        )}
      </Modal>
    </Modal>
  );
};

export { EditTemplatesDialog };
