import React, { useEffect, useMemo, useState } from 'react';

import {
  ActivitySourceDescriptor,
  ActivitySourceType,
  BaseAddressInputs,
  BodyBigText,
  BodyText,
  Button,
  Checkbox,
  InvoiceDto,
  InvoiceEntity,
  InvoiceItemEntity,
  InvoicesStatusType,
  Modal,
  OverridableValue,
  PhoneInput,
  Switch,
  TextInput,
  emailYUP,
  formatCurrency,
  getFormErrorMessages,
  phoneNumberYUP,
  zipCodeAsyncYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, LinearProgress, Tooltip, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert, Skeleton } from '@material-ui/lab';
import { getIn } from 'immutable';
import { useSnackbar } from 'notistack';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { array, bool, object, string } from 'yup';

import invoiceAPI from 'admin/api/InvoiceAPI';
import orderAPI from 'admin/api/OrderAPI';
import { getCompanyInfo } from 'admin/autodux/CompanyInfoAutodux';
import { ADDRESS_STREET } from 'admin/components/AccountWindow/config/AccountWindowLabels';
import { COMPANY_NAME } from 'admin/components/OrderWindow/OrderWindowLabels';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import {
  CITY,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  POSTAL_CODE,
  STATE,
  STREET,
} from 'admin/components/OrderWindow/modals/FullAddressesModal';
import { InvoicesTableTooltip } from 'admin/components/OrderWindow/modals/Invoices/AccountInvoiceModal/InvoicesTableTooltip';
import { OrderInvoice } from 'admin/components/OrderWindow/modals/Invoices/AccountInvoiceModal/OrderInvoice';
import { useInvoiceModalContext } from 'admin/components/OrderWindow/modals/Invoices/InvoiceModalContext';
import { ADDRESS } from 'admin/components/OrderWindow/modals/Invoices/InvoicesItemLabels';
import { sendInvoiceEmail } from 'admin/components/OrderWindow/modals/Invoices/sendInvoiceEmail';
import { CONTACT_INFO, PHONE, PRIMARY_PHONE } from 'admin/constants/FieldNames';
import { statusById } from 'admin/constants/OrderStatus';
import { useMobile } from 'admin/hooks/useMobile';
import { AccountDto, AutomationRuleName, CommunicationModality, SendEmailDto, Template } from 'common-types';
import { CompanyInfo } from 'common-types/CompanyInfo';
import Status from 'common/components/Status';
import { toEmailDTO } from 'common/utils';

const ADDITIONAL_CONTACT = 'additionalContact';

const useStyles = makeStyles((theme) => ({
  sendTo: {
    display: 'flex',
    alignItems: 'center',
  },
  subheader: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'capitalize',
  },
  primarySelectedBackground: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  serviceInvoice: {
    paddingLeft: theme.spacing(4),
  },
  evenRowColor: {
    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  popper: {
    pointerEvents: 'unset',
  },
  tooltip: {
    backgroundColor: 'transparent',
    boxShadow: 'transparent',
    minWidth: theme.spacing(50),
  },
  arrow: {
    color: theme.palette.background.paper,
    borderColor: theme.palette.background.paper,
  },
  errorContent: {
    justifyContent: 'center',
  },
  outlinedError: {
    color: 'none',
    border: 'none',
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
}));

const addressFieldNames = {
  zipCode: POSTAL_CODE,
  city: CITY,
  state: STATE,
};

const addressLabels = {
  [CITY]: 'City',
  [STATE]: 'State',
  [POSTAL_CODE]: 'ZIP',
};

const labels = {
  [ADDITIONAL_CONTACT]: {
    [EMAIL]: 'Additional Email',
    [PHONE]: 'Additional Phone Number',
  },
  [COMPANY_NAME]: 'Company Name',
  [CONTACT_INFO]: {
    [FIRST_NAME]: 'First Name',
    [LAST_NAME]: 'Last Name',
    [EMAIL]: 'Email',
    [PRIMARY_PHONE]: 'Phone Number',
  },
  [ADDRESS]: {
    [STREET]: 'Address',
    [CITY]: addressLabels[CITY],
    [STATE]: addressLabels[STATE],
    [POSTAL_CODE]: addressLabels[POSTAL_CODE],
  },
};

const SEND_TO_ORDER_CONTACT = 'sendToOrderContact';
const ORDERS_INVOICE = 'ordersInvoice';

const schema = object().shape({
  [CONTACT_INFO]: object().when(SEND_TO_ORDER_CONTACT, (sendToOrderContact, s) =>
    sendToOrderContact
      ? s
      : s.shape({
          [FIRST_NAME]: string().label(labels[CONTACT_INFO][FIRST_NAME]).required().min(2).max(32),
          [LAST_NAME]: string().label(labels[CONTACT_INFO][LAST_NAME]).required().min(2).max(32),
          [EMAIL]: emailYUP({
            label: labels[CONTACT_INFO][EMAIL],
            name: EMAIL,
          }),
          [PRIMARY_PHONE]: phoneNumberYUP('Phone'),
        }),
  ),
  [ADDRESS]: object().when(SEND_TO_ORDER_CONTACT, (sendToOrderContact, s) =>
    sendToOrderContact
      ? s
      : s.shape(
          {
            [ADDRESS_STREET]: string().label(labels[ADDRESS][STREET]).nullable(),
            [POSTAL_CODE]: zipCodeAsyncYUP(POSTAL_CODE)
              .label(addressLabels[POSTAL_CODE])
              .transform((v) => v || null)
              .nullable()
              .required(),
          },
          [[POSTAL_CODE, STREET]],
        ),
  ),
  [SEND_TO_ORDER_CONTACT]: bool(),
  [ORDERS_INVOICE]: array().label('Orders').of(string()).min(1).required(),
});

const contactInfoFullAddressPath = `${ADDRESS}`;
const contactInfoStreetAddressPath = `${ADDRESS}.${STREET}`;
const contactInfoFirstNamePath = `${CONTACT_INFO}.${FIRST_NAME}`;
const contactInfoLastNamePath = `${CONTACT_INFO}.${LAST_NAME}`;
const contactInfoEmailPath = `${CONTACT_INFO}.${EMAIL}`;
const contactInfoPrimaryPhoneNumberPath = `${CONTACT_INFO}.${PRIMARY_PHONE}`;
const companyNamePath = COMPANY_NAME;

const ID_DELIMITER = '.';

const asArray = (path: string) => path.split('.');

interface AccountInvoiceModalProps {
  onClose: () => void;
  onSave: () => void;
}

const getDefaultValues = (account?: AccountDto) => {
  return {
    [ADDRESS]: account?.ownerCustomerInfo?.address ?? {},
    [CONTACT_INFO]: {
      [FIRST_NAME]: account?.ownerCustomerInfo?.firstName ?? '',
      [LAST_NAME]: account?.ownerCustomerInfo?.lastName ?? '',
      [EMAIL]: account?.ownerCustomerInfo?.email ?? '',
      [PRIMARY_PHONE]: account?.ownerCustomerInfo?.primaryPhone?.number ?? '',
    },
    [ORDERS_INVOICE]: [],
    [SEND_TO_ORDER_CONTACT]: false,
  };
};

export const AccountInvoiceModal = ({ onClose, onSave }: AccountInvoiceModalProps) => {
  const classes = useStyles();
  const { account } = useInvoiceModalContext();
  const companyInfo = useSelector(getCompanyInfo) as CompanyInfo;
  const [inFlight, setInFlight] = useState(false);
  const isMobile = useMobile();
  const { enqueueSnackbar } = useSnackbar();
  const [orderInvoices, setOrderInvoices] = useState<OrderInvoice[]>([]);
  const formMethods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(account),
    mode: 'onChange',
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;
  const sendToOrderContact = watch(SEND_TO_ORDER_CONTACT);
  const ordersInvoiceIds = watch(ORDERS_INVOICE) as string[];
  const { invoiceTemplates, automationRules } = useComposeEmail();
  const invoiceAutomationRule = automationRules.find(
    (ar) => ar.shortName === AutomationRuleName.WHEN_A_EMPLOYEE_CREATES_AN_INVOICE,
  );
  const invoiceTemplate = invoiceTemplates.find(
    (t) => t.modality === CommunicationModality.EMAIL && t.automationRuleId === invoiceAutomationRule?.id && t.active,
  );
  const defaultTemplate = useMemo(() => new Template(invoiceTemplate?.toDTO({ useExistingBody: true }) ?? {}), []);
  const [isLoading, setIsLoading] = useState(false);
  const selectedOrderInvoices = orderInvoices.filter((oo) => ordersInvoiceIds.includes(oo.key));
  const errorMessages = getFormErrorMessages(errors);

  const getCurrentMoves = () => {
    setInFlight(true);
    setIsLoading(true);

    orderAPI
      .getAllUnpaidOrdersWithInvoicesForAccount(account?.id!)
      .then((ordersAndInvoices) => {
        const ordersOptions: OrderInvoice[] = [];

        ordersAndInvoices.forEach(({ orderSummary: order, invoices }) => {
          const [city, state, postalCode] = (order.fromAddress || order.toAddress).split(', ');
          const [firstName = '', lastName = ''] = order.customerName.split(' ') || [];
          const orderPartInvoice: Partial<InvoiceDto> = {
            orderId: order.orderId,
            orderNumber: order.orderNumber,
            branchId: 1,
            contactInfo: {
              firstName: firstName,
              lastName: lastName,
              primaryPhone: order.customerPhone,
              email: order.contactEmail || order.customerEmail,
            },
            address: {
              street1: '',
              city,
              state,
              postalCode,
              country: 'US',
            },
            discount: 0,
          };
          const orderActivitySource = new ActivitySourceDescriptor({
            activitySource: ActivitySourceType.ORDER,
            referencedEntityId: order.orderId,
          });

          ordersOptions.push({
            key: `${ActivitySourceType.ORDER}${ID_DELIMITER}${order.orderId}`,
            label: `${order.status} ${order.orderNumber} ${order.customerName} $${formatCurrency(
              order.totalEstimatedBalance,
            )}`,
            activitySource: ActivitySourceType.ORDER,
            sourceId: order.orderId,
            activitySources: [orderActivitySource],
            balance: order.totalEstimatedBalance ?? 0,
            orderStatus: order.status,
            orderNumber: order.orderNumber,
            orderId: order.orderId,
            fullName: order.customerName,
            orderPartInvoice,
            invoices: invoices.filter(
              (i) =>
                i.activitySources.find(
                  (as) => as.referencedEntityId === order.orderId && as.activitySource === ActivitySourceType.ORDER,
                ) && !i.activitySources.find((as) => as.activitySource === ActivitySourceType.SERVICE),
            ),
          });

          if (order.serviceSummaries.size > 1) {
            order.serviceSummaries.forEach((service, index) => {
              const balance = service.estimatedQuoteRange?.maxValue ?? 0 - order.totalPaid;
              ordersOptions.push({
                key: `${ActivitySourceType.SERVICE}${ID_DELIMITER}${order.orderId}${ID_DELIMITER}${service.originalId}${ID_DELIMITER}${index}`,
                label: `${order.status} ${order.orderNumber} ${order.customerName} $${formatCurrency(balance)} MD-${
                  index + 1
                }`,
                activitySource: ActivitySourceType.SERVICE,
                sourceId: service.originalId,
                activitySources: [
                  new ActivitySourceDescriptor({
                    activitySource: ActivitySourceType.SERVICE,
                    referencedEntityId: service.originalId,
                  }),
                  orderActivitySource,
                ],
                balance,
                orderStatus: order.status,
                orderNumber: `${order.orderNumber} MD-${index + 1}`,
                orderId: order.orderId,
                fullName: order.customerName,
                orderPartInvoice,
                invoices: invoices.filter((i) =>
                  i.activitySources.find(
                    (as) =>
                      as.referencedEntityId === service.originalId && as.activitySource === ActivitySourceType.SERVICE,
                  ),
                ),
              });
            });
          }
        });

        setOrderInvoices(ordersOptions);
      })
      .catch(() => {
        enqueueSnackbar(`Can't get orders for current account`, { variant: 'error' });
      })
      .then(() => {
        setInFlight(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (account?.id) {
      getCurrentMoves();
    }
  }, [account?.id]);

  const onSendAllInvoices = (data: ReturnType<typeof getDefaultValues>) => {
    setInFlight(true);

    const invoicesToSave = selectedOrderInvoices.map((orderInvoice) => {
      return new InvoiceEntity({
        contactInfo: sendToOrderContact ? orderInvoice.orderPartInvoice?.contactInfo : data[CONTACT_INFO],
        address: sendToOrderContact ? orderInvoice.orderPartInvoice?.address : data[ADDRESS],
        status: InvoicesStatusType.SENT,
        items: [
          new InvoiceItemEntity({
            name: 'Balance Due',
            description: 'Balance Due',
            quantity: 1,
            unitCost: orderInvoice.balance,
            total: OverridableValue.createOverridableValue({
              calculated: orderInvoice.balance,
            }),
          }),
        ],
        activitySources: orderInvoice.activitySources,
        activitySource: orderInvoice.activitySource,
        sourceId: orderInvoice.sourceId,
        accountId: account?.id,
      }).toDto();
    });

    invoiceAPI
      .createInvoices(invoicesToSave)
      .then((invoices) => {
        return Promise.all(
          invoices.map((invoice) => {
            const emailDTO = toEmailDTO({
              recipients: invoice.contactInfo?.email ?? '',
              ccList: '',
              bccList: '',
              subject: defaultTemplate.subject ?? '',
            });
            const resultOfSendInvoice: SendEmailDto = { emailDTO, templates: [defaultTemplate], fromCompany: false };
            return sendInvoiceEmail(resultOfSendInvoice, invoice!);
          }),
        );
      })
      .catch(() => {})
      .then(() => {
        setInFlight(false);
        onSave();
      });
  };

  const handleChangeOrderInvoice = (key: string) => (_: any, checked: boolean) => {
    setValue(ORDERS_INVOICE, checked ? [...ordersInvoiceIds, key] : ordersInvoiceIds.filter((k) => k !== key), {
      shouldValidate: true,
    });
  };

  return (
    <Modal
      open
      title="New Account Invoice"
      maxWidth="md"
      disabledInProcessing={inFlight}
      fullScreen={isMobile}
      minimizeContent={isMobile}
      minimalPaddingTitle={!isMobile}
      onClose={onClose}
    >
      <Box mt={-1} height={8}>
        {inFlight && <LinearProgress color="primary" />}
      </Box>
      <FormProvider {...(formMethods as any)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end">
              <Button color="primary" rounded variant="contained" onClick={handleSubmit(onSendAllInvoices as any)}>
                Send All Invoices
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.sendTo}>
              <BodyBigText color={!sendToOrderContact ? 'primary' : 'default'}>Send To Account Contact</BodyBigText>
              <Switch name={SEND_TO_ORDER_CONTACT} color="primary" />
              <BodyBigText color={sendToOrderContact ? 'primary' : 'default'}>Send To Order Contact</BodyBigText>
            </Box>
          </Grid>
          {!sendToOrderContact && (
            <>
              <Grid item xs={12}>
                <BodyBigText>
                  <b>Send To</b>
                </BodyBigText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextInput
                      fullWidth
                      data-testid="firstName"
                      label={getIn(labels, asArray(contactInfoFirstNamePath), '')}
                      name={contactInfoFirstNamePath}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      fullWidth
                      data-testid="lastName"
                      label={getIn(labels, asArray(contactInfoLastNamePath), '')}
                      name={contactInfoLastNamePath}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      fullWidth
                      data-testid="email"
                      name={contactInfoEmailPath}
                      label={getIn(labels, asArray(contactInfoEmailPath), '')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInput
                      fullWidth
                      data-testid="phoneNumber"
                      label={getIn(labels, asArray(contactInfoPrimaryPhoneNumberPath), '')}
                      name={contactInfoPrimaryPhoneNumberPath}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <TextInput
                              fullWidth
                              data-testid="street"
                              label={getIn(labels, asArray(contactInfoStreetAddressPath), '')}
                              name={contactInfoStreetAddressPath}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <BaseAddressInputs
                              fieldNames={addressFieldNames}
                              labels={addressLabels}
                              name={contactInfoFullAddressPath}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item container xs={12} sm={12} spacing={1}>
                  <Grid item xs={12}>
                    <TextInput
                      fullWidth
                      data-testid="companyName"
                      label={getIn(labels, asArray(companyNamePath), '')}
                      name={companyNamePath}
                      value={companyInfo.basicInfo?.companyName ?? ''}
                      size="small"
                      skipControl
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      fullWidth
                      data-testid="companyEmail"
                      value={companyInfo.basicInfo?.email ?? ''}
                      label="Company Email"
                      size="small"
                      skipControl
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInput
                      fullWidth
                      data-testid="companyPhone"
                      value={companyInfo.basicInfo?.phone ?? ''}
                      label="Company Phone"
                      size="small"
                      skipControl
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Box height={36} mt={-1}>
              {!!errorMessages[ORDERS_INVOICE] && (
                <Alert
                  severity="error"
                  variant="outlined"
                  classes={{
                    root: classes.errorContent,
                    outlinedError: classes.outlinedError,
                    message: classes.errorMessage,
                  }}
                >
                  <BodyText color="error">{errorMessages[ORDERS_INVOICE]}</BodyText>
                </Alert>
              )}
            </Box>
          </Grid>
          {isLoading && (
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" height={150} justifyContent="space-between">
                <Skeleton variant="rect" width="100%" height={40} />
                <Skeleton variant="rect" width="100%" height={40} />
                <Skeleton variant="rect" width="100%" height={40} />
              </Box>
            </Grid>
          )}
          {!!orderInvoices.length && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <BodyBigText>
                    <b>Order Status</b>
                  </BodyBigText>
                </Grid>
                <Grid item xs={3}>
                  <BodyBigText>
                    <b>Order #</b>
                  </BodyBigText>
                </Grid>
                <Grid item xs={3}>
                  <BodyBigText>
                    <b>Customer Name</b>
                  </BodyBigText>
                </Grid>
                <Grid item xs={3}>
                  <BodyBigText>
                    <b>Balance Due</b>
                  </BodyBigText>
                </Grid>
              </Grid>
              <Box mt={0.5}>
                {orderInvoices.map((orderInvoice) => {
                  const disabled = !!selectedOrderInvoices.find((soi) => {
                    return (
                      soi.orderId === orderInvoice.orderId &&
                      ((orderInvoice.activitySource === ActivitySourceType.SERVICE &&
                        soi.activitySource === ActivitySourceType.ORDER) ||
                        (orderInvoice.activitySource === ActivitySourceType.ORDER &&
                          soi.activitySource === ActivitySourceType.SERVICE))
                    );
                  });

                  return (
                    <Grid
                      container
                      key={orderInvoice.key}
                      spacing={1}
                      className={classes.evenRowColor}
                      alignItems="center"
                    >
                      <Grid item xs={3}>
                        <Box ml={orderInvoice.activitySource === ActivitySourceType.SERVICE ? 2 : 0}>
                          <Checkbox
                            color="primary"
                            disabled={disabled}
                            checked={ordersInvoiceIds.includes(orderInvoice.key)}
                            onChange={handleChangeOrderInvoice(orderInvoice.key)}
                          />
                          <Status
                            label={statusById(orderInvoice.orderStatus)?.label}
                            title={orderInvoice.orderStatus?.replace(/_/g, ' ')}
                            size="small"
                            disabled={disabled}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <BodyBigText>{orderInvoice.orderNumber}</BodyBigText>
                      </Grid>
                      <Grid item xs={3}>
                        <BodyBigText>{orderInvoice.fullName}</BodyBigText>
                      </Grid>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="space-between">
                          <Box mr={1}>
                            <BodyBigText>{`$${formatCurrency(orderInvoice.balance)}`}</BodyBigText>
                          </Box>
                          {!!orderInvoice.invoices.length && (
                            <Box display="flex">
                              <BodyBigText>Sent</BodyBigText>
                              <Tooltip
                                title={<InvoicesTableTooltip invoices={orderInvoice.invoices} />}
                                classes={{ popper: classes.popper, tooltip: classes.tooltip, arrow: classes.arrow }}
                                arrow
                                interactive
                              >
                                <Box component="span" ml={1}>
                                  <HelpOutlineIcon color="primary" />
                                </Box>
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </Modal>
  );
};
