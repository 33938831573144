import { ReactNode, useEffect, useState } from 'react';

import { ForemanPageJobStatuses, JobDto, JobStatus as JobStatusType, JobType } from '@elromcoinc/react-shared';

import { OrderClosingContext } from 'admin/components/OrderWindow/context/OrderClosingContext';
import { useOrderServiceIndex } from 'admin/components/OrderWindow/context/useOrderServiceIndex';
import { useOrderState } from 'admin/components/OrderWindow/context/useOrderState';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { useMobile } from 'admin/hooks/useMobile';

const loadingStatusIndex = ForemanPageJobStatuses.indexOf(JobStatusType.LOADING);

export const OrderClosingProvider = ({ children }: { children: ReactNode }) => {
  const setting = useOrderWindowSettings();
  const contractTemplates = setting?.ContractTemplate ?? [];
  const { order } = useOrderState();
  const { serviceIndex } = useOrderServiceIndex();
  const salesQuote = order?.getServiceQuote(serviceIndex);
  const currentJobStatus = salesQuote?.dispatchJob?.jobStatus ?? JobStatusType.NOT_ASSIGNED;
  const isCompleted = currentJobStatus === JobStatusType.COMPLETED ?? false;
  const wasCompleted = ((salesQuote?.dispatchJob as JobDto)?.jobStatuses ?? []).some(
    (js) => js.jobStatus === JobStatusType.COMPLETED && js.jobType === JobType.PICK_UP,
  );
  const isMobile = useMobile();
  const serviceAvailableContracts = contractTemplates.filter((el) =>
    el.services.some((s) => s.id === salesQuote?.generalService?.id),
  );
  const serviceRosterClosing = order?.closingOrderDetail?.serviceRosterClosingsDto?.get(serviceIndex);
  const currentStatusIndex = ForemanPageJobStatuses.findIndex((el) => el === currentJobStatus);
  const isStartedJob = !(currentStatusIndex < loadingStatusIndex);
  const showClosingInOrderWindow = serviceRosterClosing?.showClosingInOrderWindow ?? false;
  const [isClosing, setIsClosing] = useState(isStartedJob);
  // we should lock sales when the job status is Loading(have first sign) or other status after Loading.
  const isLockSales = !isClosing && (isStartedJob || showClosingInOrderWindow);
  const showClosingButton = !isStartedJob && !showClosingInOrderWindow;
  const isCanChangeStatus =
    !order?.services?.some((s) => s?.quote?.dispatchJob?.jobStatus === JobStatusType.COMPLETED) ?? true;

  useEffect(() => {
    if (order?.orderId) {
      setIsClosing(!showClosingButton);
    }
  }, [order?.orderId, showClosingInOrderWindow]);

  const closingTitle = isClosing && isMobile ? ' (Closing)' : '';

  return (
    <OrderClosingContext.Provider
      value={{
        isClosing,
        setIsClosing,
        closingTitle,
        isCompleted,
        isLockSales,
        // so far we'll not use this value since I don't know exact logic to define that company don't use BOL
        hasAvailableContracts: !!serviceAvailableContracts.length,
        showClosingButton,
        showClosingInOrderWindow,
        wasCompleted,
        isCanChangeStatus,
      }}
    >
      {children}
    </OrderClosingContext.Provider>
  );
};
