import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => {
  return {
    root: {
      display: 'flex',
      width: '100%',
    },
    demoLink: ({ buttonColor }: { buttonColor: string }) => ({
      flexGrow: 1,
      textAlign: 'center',
      color: buttonColor,
      padding: spacing(1),
      textDecoration: 'unset',
      border: `1px solid ${buttonColor}`,
      borderRadius: 4,
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
    }),
  };
});

export const RequestDemoButton = ({ buttonColor }: { buttonColor: string }) => {
  const classes = useStyles({ buttonColor });

  return (
    <Box className={classes.root}>
      <a
        className={classes.demoLink}
        rel="noreferrer"
        href="https://www.elromcosoftware.com/book-online-moving-software-demo"
        target="_blank"
      >
        Request a Demo
      </a>
    </Box>
  );
};
