import React, { useEffect, useMemo } from 'react';

import {
  AdditionalContact,
  ContactInfo,
  Link,
  Modal,
  Order,
  PhoneFormGroup,
  PhoneInput,
  Select,
  Switch,
  TextInput,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getIn } from 'immutable';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { object, string } from 'yup';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import { THIS_FUNCTIONAL_IS_COMING_SOON } from 'admin/constants';
import {
  ADDITIONAL_CONTACT,
  ADDITIONAL_INFO,
  BEST_TIME_TO_CONTACT,
  BEST_WAY_TO_CONTACT,
  CONTACT_INFO,
  CUSTOMER_INFO,
  EMAIL,
  ENABLE_SMS_STATUS_UPDATES,
  FIRST_NAME,
  LAST_NAME,
  OTHER_PHONE,
  PHONE,
  PHONE_CARRIER_TYPE,
  PHONE_EXTENSION,
  PHONE_NUMBER,
  PHONE_TYPE,
  PRIMARY_PHONE,
  SEND_NOTIFICATIONS,
  SMS_CAPABILITY,
} from 'admin/constants/FieldNames';
import useUpdatePhoneNumberType from 'admin/hooks/useUpdatePhoneNumberType';

const SwitchContainer = styled(Box)`
  && {
    label {
      width: unset;
    }
  }
`;

const asArray = (path) => path.split('.');

const firstNameLabel = 'First Name';
const lastNameLabel = 'Last Name';
const emailAddressLabel = 'Email Address';
const phoneLabel = 'Phone';
const extension = 'Ext';

const primaryPhoneLabels = {
  [PHONE_TYPE]: 'Phone Type',
  [PHONE_NUMBER]: phoneLabel,
  [PHONE_EXTENSION]: extension,
};
const otherPhoneLabels = {
  [PHONE_TYPE]: 'Phone Type',
  [PHONE_NUMBER]: phoneLabel,
  [PHONE_EXTENSION]: extension,
};
const labels = {
  [ADDITIONAL_INFO]: 'Additional Info',
  [CUSTOMER_INFO]: {
    [ENABLE_SMS_STATUS_UPDATES]: 'Enable SMS',
  },
  [CONTACT_INFO]: {
    [FIRST_NAME]: firstNameLabel,
    [LAST_NAME]: lastNameLabel,
    [EMAIL]: emailAddressLabel,
    [BEST_WAY_TO_CONTACT]: 'Best Way to Contact',
    [BEST_TIME_TO_CONTACT]: 'Best Time to Contact',
    [PRIMARY_PHONE]: primaryPhoneLabels,
    [OTHER_PHONE]: otherPhoneLabels,
  },
  [ADDITIONAL_CONTACT]: {
    [FIRST_NAME]: firstNameLabel,
    [LAST_NAME]: lastNameLabel,
    [EMAIL]: emailAddressLabel,
    [PHONE]: phoneLabel,
    [SEND_NOTIFICATIONS]: 'Receive Emails',
  },
};

const getNameValue = (name, value) => ({ name, value });

const contactInfoFirstNamePath = `${CONTACT_INFO}.${FIRST_NAME}`;
const contactInfoLastNamePath = `${CONTACT_INFO}.${LAST_NAME}`;
const contactInfoEmailPath = `${CONTACT_INFO}.${EMAIL}`;
const contactInfoPrimaryPhoneNumberPath = `${CONTACT_INFO}.${PRIMARY_PHONE}.${PHONE_NUMBER}`;
const contactInfoOtherPhoneNumberPath = `${CONTACT_INFO}.${OTHER_PHONE}.${PHONE_NUMBER}`;
const bestWayToContactPath = `${CONTACT_INFO}.${BEST_WAY_TO_CONTACT}`;
const bestTimeToContactPath = `${CONTACT_INFO}.${BEST_TIME_TO_CONTACT}`;
const additionalContactFirstNamePath = `${ADDITIONAL_CONTACT}.${FIRST_NAME}`;
const additionalContactLastNamePath = `${ADDITIONAL_CONTACT}.${LAST_NAME}`;
const additionalContactEmailPath = `${ADDITIONAL_CONTACT}.${EMAIL}`;
const additionalContactPhonePath = `${ADDITIONAL_CONTACT}.${PHONE}`;
const primaryPhonePath = `${CONTACT_INFO}.${PRIMARY_PHONE}`;
const otherPhonePath = `${CONTACT_INFO}.${OTHER_PHONE}`;
const primaryPhoneSmsCapabilityPath = `${CONTACT_INFO}.${PRIMARY_PHONE}.${SMS_CAPABILITY}`;
const primaryPhoneCarrierTypePath = `${CONTACT_INFO}.${PRIMARY_PHONE}.${PHONE_CARRIER_TYPE}`;
const otherPhoneSmsCapabilityPath = `${CONTACT_INFO}.${OTHER_PHONE}.${SMS_CAPABILITY}`;
const otherPhoneCarrierTypePath = `${CONTACT_INFO}.${OTHER_PHONE}.${PHONE_CARRIER_TYPE}`;

const schema = object().shape({
  [CONTACT_INFO]: ContactInfo.getValidationSchema(labels[CONTACT_INFO]),
  [ADDITIONAL_CONTACT]: AdditionalContact.getValidationSchema(labels[ADDITIONAL_CONTACT]),
  [ADDITIONAL_INFO]: string().nullable().label(labels[ADDITIONAL_INFO]).max(2000),
});

const CustomerInfo = ({ onChange, order, onSave, onCancel, shortForm, ...rest }) => {
  const [hasChanges, setHasChanges] = React.useState(false);
  const context = useMemo(
    () => ({
      currentEmail: getIn(order, contactInfoEmailPath.split('.'), null),
    }),
    [],
  );
  const { closingTitle, isClosing } = useOrderClosingContext();
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: order.toJS(),
    context,
  });
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = formMethods;
  const disableInputs = isClosing;

  useEffect(() => {
    if (isDirty && !hasChanges) {
      setHasChanges(isDirty);
    }
  }, [isDirty]);

  const primaryPhoneNumberValue = watch(contactInfoPrimaryPhoneNumberPath);
  const otherPhoneNumberValue = watch(contactInfoOtherPhoneNumberPath);

  useUpdatePhoneNumberType(primaryPhoneNumberValue, {
    shouldUpdate: () => hasChanges,
    setCarrierType: (value) => setValue(primaryPhoneCarrierTypePath, value),
    setSmsCapability: (value) => setValue(primaryPhoneSmsCapabilityPath, value),
  });

  useUpdatePhoneNumberType(otherPhoneNumberValue, {
    shouldUpdate: () => hasChanges,
    setCarrierType: (value) => setValue(otherPhoneCarrierTypePath, value),
    setSmsCapability: (value) => setValue(otherPhoneSmsCapabilityPath, value),
  });

  const onSubmit = (data) => {
    const newCustomerInfo = order.get(CUSTOMER_INFO).mergeDeep(data[CUSTOMER_INFO]);
    const newContactInfo = order.get(CONTACT_INFO).mergeDeep(data[CONTACT_INFO]);
    const newAddContact = order.get(ADDITIONAL_CONTACT).mergeDeep(data[ADDITIONAL_CONTACT]);
    const changeList = [
      !newCustomerInfo.equals(order.get(CUSTOMER_INFO)) && getNameValue(CUSTOMER_INFO, newCustomerInfo),
      !newContactInfo.equals(order.get(CONTACT_INFO)) && getNameValue(CONTACT_INFO, newContactInfo),
      !newAddContact.equals(order.get(ADDITIONAL_CONTACT)) && getNameValue(ADDITIONAL_CONTACT, newAddContact),
      order.get(ADDITIONAL_INFO) !== data[ADDITIONAL_INFO] && getNameValue(ADDITIONAL_INFO, data[ADDITIONAL_INFO]),
    ];
    changeList.filter(Boolean).forEach(onChange);
    onSave();
  };
  const commonProps = { control, disabled: disableInputs };

  return (
    <Modal
      title={`Customer Information${closingTitle}`}
      onClose={onCancel}
      color="grey"
      actions={[
        { label: 'cancel', onClick: onCancel },
        {
          label: 'save',
          onClick: handleSubmit(onSubmit),
          disabled: !hasChanges,
          color: !hasChanges ? 'default' : 'primary',
        },
      ]}
      maxWidth="md"
      {...rest}
    >
      <FormProvider {...formMethods}>
        <Box mx={2}>
          <Typography>
            <Box fontSize={14} fontWeight={600} lineHeight="14px" mb={1.5}>
              Primary Contact
            </Box>
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextInput
                autoFocus
                fullWidth
                label={getIn(labels, asArray(contactInfoFirstNamePath), '')}
                name={contactInfoFirstNamePath}
                {...commonProps}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextInput
                fullWidth
                label={getIn(labels, asArray(contactInfoLastNamePath), '')}
                name={contactInfoLastNamePath}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                type="email"
                fullWidth
                label={getIn(labels, asArray(contactInfoEmailPath), '')}
                name={contactInfoEmailPath}
                {...commonProps}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <PhoneFormGroup
                labels={primaryPhoneLabels}
                name={primaryPhonePath}
                displayPhoneCarrierIcon
                disabled={disableInputs}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <PhoneFormGroup
                labels={otherPhoneLabels}
                name={otherPhonePath}
                displayPhoneCarrierIcon
                disabled={disableInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                label={getIn(labels, asArray(bestWayToContactPath), '')}
                name={bestWayToContactPath}
                {...commonProps}
                options={[
                  ['Email', 'Email'],
                  ['Text', 'Text'],
                  [phoneLabel, phoneLabel],
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                label={getIn(labels, asArray(bestTimeToContactPath), '')}
                name={bestTimeToContactPath}
                {...commonProps}
                options={[
                  ['Morning', 'Morning'],
                  ['Afternoon', 'Afternoon'],
                  ['Evening', 'Evening'],
                ]}
              />
            </Grid>
            {!shortForm && (
              <Grid item xs={12}>
                <TextInput fullWidth label={labels[ADDITIONAL_INFO]} name={ADDITIONAL_INFO} {...commonProps} />
              </Grid>
            )}
          </Grid>
          {!shortForm && (
            <>
              <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
                <Box mt={2} mb={1} maxWidth="300px">
                  <Link className="disabled">Send reset password via SMS</Link>
                </Box>
              </Tooltip>
              <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
                <Box mt={1} mb={1} maxWidth="300px">
                  <Link className="disabled">Send reset password via Email</Link>
                </Box>
              </Tooltip>
              <SwitchContainer display="flex" justifyContent="flex-start" mb={4} flexWrap="wrap">
                <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
                  <Box>
                    <Switch color="primary" disabled label="Receive Emails" />
                  </Box>
                </Tooltip>
                <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
                  <Box>
                    <Switch
                      color="primary"
                      control={control}
                      name={`${CUSTOMER_INFO}.${ENABLE_SMS_STATUS_UPDATES}`}
                      label={labels[CUSTOMER_INFO][ENABLE_SMS_STATUS_UPDATES]}
                      disabled={disableInputs}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
                  <Box>
                    <Switch color="primary" disabled label="Account Page Active" />
                  </Box>
                </Tooltip>
              </SwitchContainer>
              <Typography component="div">
                <Box fontSize={14} fontWeight={600} lineHeight="14px" mb={1.5}>
                  Additional Contact
                </Box>
              </Typography>
              <Grid container spacing="2">
                <Grid item sm={6} xs={12}>
                  <TextInput
                    fullWidth
                    label={getIn(labels, asArray(additionalContactFirstNamePath), '')}
                    name={additionalContactFirstNamePath}
                    {...commonProps}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInput
                    fullWidth
                    label={getIn(labels, asArray(additionalContactLastNamePath), '')}
                    name={additionalContactLastNamePath}
                    {...commonProps}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInput
                    fullWidth
                    label={getIn(labels, asArray(additionalContactEmailPath), '')}
                    name={additionalContactEmailPath}
                    {...commonProps}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <PhoneInput
                    fullWidth
                    label={getIn(labels, asArray(additionalContactPhonePath), '')}
                    name={additionalContactPhonePath}
                    {...commonProps}
                  />
                </Grid>
                <SwitchContainer display="flex" justifyContent="flex-start" mb={3} ml={1} flexWrap="wrap">
                  <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
                    <Box>
                      <Switch
                        color="primary"
                        control={control}
                        name={`${ADDITIONAL_CONTACT}.${SEND_NOTIFICATIONS}`}
                        label={labels[ADDITIONAL_CONTACT][SEND_NOTIFICATIONS]}
                        disabled={disableInputs}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
                    <Box>
                      <Switch color="primary" label="Receive SMSs" fullWidth disabled />
                    </Box>
                  </Tooltip>
                </SwitchContainer>
              </Grid>
            </>
          )}
        </Box>
      </FormProvider>
    </Modal>
  );
};

CustomerInfo.propTypes = {
  order: PropTypes.instanceOf(Order).isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  [CUSTOMER_INFO]: PropTypes.shape({
    [ENABLE_SMS_STATUS_UPDATES]: PropTypes.bool,
  }),
  [CONTACT_INFO]: PropTypes.shape({
    [FIRST_NAME]: PropTypes.string,
    [LAST_NAME]: PropTypes.string,
    [EMAIL]: PropTypes.string,
    [PRIMARY_PHONE]: PropTypes.shape({
      [PHONE_NUMBER]: PropTypes.string,
    }),
    [OTHER_PHONE]: PropTypes.shape({
      [PHONE_NUMBER]: PropTypes.string,
    }),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  [ADDITIONAL_CONTACT]: PropTypes.shape({
    [FIRST_NAME]: PropTypes.string,
    [LAST_NAME]: PropTypes.string,
    [EMAIL]: PropTypes.string,
    [PHONE]: PropTypes.string,
  }),
  shortForm: PropTypes.bool,
};

CustomerInfo.defaultProps = {
  shortForm: false,
  [CUSTOMER_INFO]: {},
  [CONTACT_INFO]: {},
  [ADDITIONAL_CONTACT]: {},
};

export default CustomerInfo;
