import React from 'react';

import {
  Badge,
  BodySmallText,
  BodyText,
  IconButton,
  MoveTypeName,
  Select,
  StorageMoveStage,
  UI_DATE_FORMAT_SHORT,
  UI_DATE_TIME_FORMAT,
  getMoveSizeMainDesc,
  isMovingAndStorageService,
  isOvernightService,
  toBackEndDate,
} from '@elromcoinc/react-shared';
import { Box, Tooltip } from '@material-ui/core';
import { Email as EmailIcon } from '@material-ui/icons';
import TruckIcon from '@material-ui/icons/LocalShipping';
import { format, isDate, parseISO } from 'date-fns';

import { FlagCell } from 'admin/components/FlagCell';
import LeadScore from 'admin/components/LeadScore';
import { MultiDayIndicator } from 'admin/components/MultiDayIndicator';
import { CustomerColumn } from 'admin/components/Orders/config/CustomerColumn';
import { EstimateTypeName } from 'admin/constants/EstimateType';
import { statusById } from 'admin/constants/OrderStatus';
import { roundTo2 } from 'admin/utils';
import { toDateString } from 'admin/utils/DateUtils';
import Status from 'common/components/Status';
import { StorageIcon } from 'common/components/icons';

const renderDate = (date) => {
  if (!date) {
    return null;
  }
  if (isDate(date)) {
    return toDateString(date, UI_DATE_FORMAT_SHORT);
  }

  return format(parseISO(date), UI_DATE_TIME_FORMAT);
};

const makeColumns = (
  rowsData,
  {
    setSelectedMessageOrder,
    setOpenEmailModal,
    setOpenFlagsModal,
    leadScoreSettings,
    branch = '',
    salesPersonOptions,
    onChangeOrderSales,
    isMobile,
    classes,
    displayBookedColumn = true,
    canEditOtherUsersAccounts = false,
    day = null,
  },
) => {
  return [
    {
      name: 'id',
      label: 'ORDER #',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { orderNumber } = rowsData.get(rowIndex);
          return (
            <Box className={classes.flexContainer} maxWidth={isMobile ? 70 : 85}>
              <BodyText wordBreak>{orderNumber}</BodyText>
            </Box>
          );
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: 'status',
      label: 'STATUS',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData.get(rowIndex);
          const { status } = row;
          const statusInfo = statusById(status);

          if (!statusInfo) {
            return null;
          }

          return <Status label={statusInfo.label} title={status.replace(/_/g, ' ')} size="small" />;
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: 'customer',
      label: 'CUSTOMER',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData.get(rowIndex);
          return <CustomerColumn row={row} isMobile={isMobile} setOpenEmailModal={setOpenEmailModal} />;
        },
      },
    },
    ...(leadScoreSettings.enabled
      ? [
          {
            name: 'leadScore',
            label: 'LS',
            options: {
              customBodyRender: (_, { rowIndex }) => {
                const getRow = rowsData.get(rowIndex);
                const { leadScore } = getRow;

                return <LeadScore score={leadScore || 0} leadScoreSettings={leadScoreSettings} />;
              },
              showOnMobileAsExpanded: true,
            },
          },
        ]
      : []),
    {
      name: 'branch',
      label: 'BRANCH',
      options: {
        customBodyRender: () => {
          return <BodyText>{branch}</BodyText>;
        },
        showOnMobileAsExpanded: true,
        sort: false,
      },
    },
    {
      name: 'moveType',
      label: 'MOVE TYPE',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData.get(rowIndex);
          let index = 0;
          const { moveType, serviceSummaries, primaryEstimateType } = row;
          if (day) {
            const matchedDateIndex = serviceSummaries.findIndex((ser) => ser.serviceDate === toBackEndDate(day));
            if (matchedDateIndex) {
              index = matchedDateIndex;
            }
          }
          return (
            <Box className={classes.flexContainer} maxWidth={80}>
              <Box component="span">
                <BodySmallText>{MoveTypeName[moveType]}</BodySmallText>
              </Box>
              {serviceSummaries.get(index) && (
                <Box component="span">
                  <BodySmallText component="span">
                    <b>
                      {serviceSummaries.get(index).serviceName}
                      <MultiDayIndicator visible={serviceSummaries.size > 1} />
                    </b>
                  </BodySmallText>
                </Box>
              )}
              <Box component="span">
                <BodySmallText>{EstimateTypeName[primaryEstimateType]}</BodySmallText>
              </Box>
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'flags',
      label: 'FLAGS',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData.get(rowIndex);
          const { flags } = row;

          if (!flags) {
            return null;
          }

          const handleOpenFlagsModal = (event) => {
            event.stopPropagation();
            setOpenFlagsModal(row);
          };

          return <FlagCell flags={flags} onClick={handleOpenFlagsModal} />;
        },
        showOnMobileAsExpanded: true,
        sort: false,
      },
    },
    {
      name: 'moveStartDate',
      label: 'MOVE DATE',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { serviceSummaries, startDate } = rowsData.get(rowIndex);
          let date = startDate;
          if (day) {
            const service = serviceSummaries.find((ser) => ser.serviceDate === toBackEndDate(day));
            if (service) {
              date = new Date(service.serviceDate);
            }
          }
          return renderDate(date);
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'location',
      label: 'LOCATION',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData.get(rowIndex);
          let index = 0;
          const { serviceSummaries } = row;
          if (day) {
            const matchedDateIndex = serviceSummaries.findIndex((ser) => ser.serviceDate === toBackEndDate(day));
            if (matchedDateIndex) {
              index = matchedDateIndex;
            }
          }
          const service = serviceSummaries.get(index);

          if (!service) {
            return null;
          }

          const { toAddress, fromAddress } = service;
          const fromAddressString = fromAddress.postalCode
            ? `${fromAddress.city}, ${fromAddress.state}, ${fromAddress.postalCode}`
            : null;
          const toAddressString = toAddress.postalCode
            ? `${toAddress.city}, ${toAddress.state}, ${toAddress.postalCode}`
            : null;
          const isOvernight = isOvernightService(serviceSummaries.get(index).generalService.serviceType);
          const outMoveStorage =
            isMovingAndStorageService(serviceSummaries.get(index).generalService.serviceType) &&
            row.storageMoveStage === StorageMoveStage.OUT;
          const inMoveStorage =
            isMovingAndStorageService(serviceSummaries.get(index).generalService.serviceType) &&
            row.storageMoveStage === StorageMoveStage.IN;

          let from = fromAddressString ? (
            <Box component="span">
              <BodySmallText>
                {toAddressString && 'From:\xa0'}
                {`${fromAddress.city}, ${fromAddress.state}, ${fromAddress.postalCode}`}
              </BodySmallText>
            </Box>
          ) : null;

          let to = toAddressString ? (
            <Box component="span">
              <BodySmallText>
                {fromAddressString && 'To:\xa0'}
                {`${toAddress.city}, ${toAddress.state}, ${toAddress.postalCode}`}
              </BodySmallText>
            </Box>
          ) : null;

          if (outMoveStorage) {
            from = (
              <Box component="span" display="flex" alignItems="center">
                <BodySmallText>From:&nbsp;</BodySmallText>
                <StorageIcon fontSize="small" color="primary" />
                <BodySmallText>&nbsp;storage</BodySmallText>
              </Box>
            );
          }
          if (inMoveStorage) {
            to = (
              <Box component="span" display="flex" alignItems="center">
                <BodySmallText>To:&nbsp;</BodySmallText>
                <StorageIcon fontSize="small" color="primary" />
                <BodySmallText>&nbsp;storage</BodySmallText>
              </Box>
            );
          }
          if (isOvernight) {
            to = (
              <Box component="span" display="flex" alignItems="center">
                <BodySmallText>To:&nbsp;</BodySmallText>
                <TruckIcon color="primary" />
                <BodySmallText>&nbsp;overnight</BodySmallText>
              </Box>
            );
          }

          return (
            <Box className={classes.flexContainer} maxWidth={140}>
              {from}
              {to}
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
        sort: false,
      },
    },
    {
      name: 'activeCuFt',
      label: 'MOVE SIZE',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData.get(rowIndex);
          const { sizeDescription, activeCuFt, activeWeight, hasInventory } = row;
          const sizeDescriptionWithoutRooms = getMoveSizeMainDesc(sizeDescription);
          return (
            <Box className={classes.flexContainer} maxWidth={90}>
              <Box>
                <BodySmallText wordBreak>{sizeDescriptionWithoutRooms}</BodySmallText>
              </Box>
              <Box component="span">
                <BodySmallText color={hasInventory ? 'primary' : 'initial'}>
                  {roundTo2(activeCuFt)}
                  &nbsp;Cu Ft
                </BodySmallText>
              </Box>
              <Box component="span">
                <BodySmallText color={hasInventory ? 'primary' : 'initial'}>
                  {roundTo2(activeWeight || Math.floor(activeCuFt * 7))}
                  &nbsp;Pounds
                </BodySmallText>
              </Box>
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'crewSize',
      label: 'DETAILS',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData.get(rowIndex);
          const { crewSize, numberOfTrucks } = row;
          return (
            <Box className={classes.flexContainer}>
              <Box component="span">
                <BodySmallText>
                  Crew:&nbsp;
                  {crewSize}
                </BodySmallText>
              </Box>
              <Box component="span">
                <BodySmallText>
                  Trucks:&nbsp;
                  {numberOfTrucks}
                </BodySmallText>
              </Box>
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'messages',
      label: ' ',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData.get(rowIndex);

          const handleSendMessages = (event) => {
            event.stopPropagation();
            setSelectedMessageOrder(row);
          };

          return (
            <Tooltip title="Send message">
              <Box minWidth="40px">
                <Badge badgeContent={row.totalEmployeeUnreadMessages || null}>
                  <IconButton color="primary" onClick={handleSendMessages}>
                    <EmailIcon />
                  </IconButton>
                </Badge>
              </Box>
            </Tooltip>
          );
        },
        showOnMobileAsExpanded: true,
        sort: false,
      },
    },
    {
      name: 'created',
      label: 'CREATION',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { createDate } = rowsData.get(rowIndex);
          return renderDate(createDate);
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'updated',
      label: 'LAST UPDT.',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { updatedDate } = rowsData.get(rowIndex);
          return renderDate(updatedDate);
        },
        showOnMobileAsExpanded: true,
      },
    },
    ...(displayBookedColumn
      ? [
          {
            name: 'bookingDate',
            label: 'BOOKED',
            options: {
              customBodyRender: (_, { rowIndex }) => {
                const { bookingDate } = rowsData.get(rowIndex);
                return renderDate(bookingDate);
              },
              showOnMobileAsExpanded: true,
            },
          },
        ]
      : []),
    {
      name: 'leadSource',
      label: 'SOURCE',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { leadSource } = rowsData.get(rowIndex);
          return (
            <Box maxWidth={isMobile ? 150 : 125}>
              {isMobile ? <BodySmallText noWrap>{leadSource}</BodySmallText> : <BodyText noWrap>{leadSource}</BodyText>}
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'estimatedQuoteRange',
      label: 'EST. QUOTE',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const {
            estimatedQuoteRange: { humanReadable },
          } = rowsData.get(rowIndex);
          return <Box maxWidth={isMobile ? 150 : 80}>{humanReadable}</Box>;
        },
        showOnMobileAsExpanded: true,
        sort: false,
      },
    },
    {
      name: 'assignedTo',
      label: 'SALES PERSON',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { assignedTo, id } = rowsData.get(rowIndex);
          return (
            <Box maxWidth={isMobile ? '100%' : 135}>
              <Select
                className={classes.select}
                fullWidth
                hiddenLabel
                options={salesPersonOptions}
                onChange={onChangeOrderSales(rowIndex, id)}
                value={assignedTo ? assignedTo.id : '0'}
                primaryBackgroundOnSelectedItem
                InputProps={{
                  disableUnderline: true,
                }}
                disabled={!canEditOtherUsersAccounts}
              />
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
  ];
};

export { makeColumns };
