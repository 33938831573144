import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { Modal, PaymentActivityDto } from '@elromcoinc/react-shared';
import { useSnackbar } from 'notistack';

import { RefundCashPayment } from './RefundCashPayment';
import { RefundCheckPayment } from './RefundCheckPayment';
import { RefundCreditCardOnline } from './RefundCreditCardOnline';
import { RefundCreditCardRecord } from './RefundCreditCardRecord';
import { RefundCustomPayment } from './RefundCustomPayment';

interface RefundPaymentProps {
  onSave: () => void;
  onCancel: () => void;
  payment: PaymentActivityDto;
}

const RefundPayment: FC<RefundPaymentProps> = ({ onSave, onCancel, payment }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [inFlight, setInFlight] = useState(false);
  const mountedHandler = useRef<(() => Promise<any>) | null>(null);

  const handleOnSave = useCallback(() => {
    if (mountedHandler.current) {
      setInFlight(true);
      mountedHandler
        .current()
        .then(() => {
          enqueueSnackbar('Payment was updated.', { variant: 'success' });
          onSave();
        })
        .catch(() => {
          enqueueSnackbar('There was an error updating your payment information.', { variant: 'error' });
        })
        .then(() => {
          setInFlight(false);
        });
    }
  }, [enqueueSnackbar, mountedHandler.current]);

  const actions = useMemo(
    () => [
      { label: 'Cancel', onClick: onCancel },
      {
        label: 'Create',
        onClick: handleOnSave,
        loading: inFlight,
      },
    ],
    [inFlight, onCancel, handleOnSave],
  );

  function renderEditForm() {
    if (payment.activityType === 'PaymentCash') {
      return <RefundCashPayment payment={payment} onMount={(trigger) => (mountedHandler.current = trigger)} />;
    } else if (payment.activityType === 'PaymentCheck') {
      return <RefundCheckPayment payment={payment} onMount={(trigger) => (mountedHandler.current = trigger)} />;
    } else if (payment.activityType === 'PaymentCreditCardRecord') {
      return <RefundCreditCardRecord payment={payment} onMount={(trigger) => (mountedHandler.current = trigger)} />;
    } else if (payment.activityType === 'PaymentCustom') {
      return <RefundCustomPayment payment={payment} onMount={(trigger) => (mountedHandler.current = trigger)} />;
    }
    return null;
  }

  if (payment.activityType === 'OnlinePaymentTransaction') {
    return <RefundCreditCardOnline payment={payment} onSave={onSave} onCancel={onCancel} />;
  }

  return (
    <Modal
      open
      color="grey"
      title="Refund Payment"
      onClose={onCancel}
      disabledInProcessing={inFlight}
      actions={actions}
      maxWidth="sm"
    >
      {renderEditForm()}
    </Modal>
  );
};

export default RefundPayment;
