import React, { useState } from 'react';

import { BodyText, Fee, Link, Order } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { List } from 'immutable';

import { SalesToClosingComparising } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/SalesToClosingComparising';
import { calculateSalesClosingDifference } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/calculateSalesClosingDifference';
import { useOrderChangeSet, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import AdditionalFeesModal from 'admin/components/OrderWindow/modals/AdditionalFees';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    percentage: {
      fontSize: '12px',
      marginLeft: '10px',
      opacity: '0.5',
    },
    costs: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '55%',
    },
  }),
);

enum Modals {
  ADDITIONAL_FEES = 'ADDITIONAL_FEES',
}

export const ClosingAdditionalFees = () => {
  const { order } = useOrderState() as { order: Order };
  const classes = useStyles();
  const { onChange } = useOrderChangeSet();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const getServicePropertyName = useGetServicePropertyName();
  const name = getServicePropertyName('fees');
  const fees = order.getIn(name.split('.')) as List<Fee>;
  const closingFeesTotal = order.closingOrderDetail?.getFeesTotal(serviceIndex, isSelectedAllServices);
  const salesFeesTotal = order.getFeesTotal(serviceIndex, isSelectedAllServices);
  const salesFeesTotalTitle = salesFeesTotal?.asHumanReadableString();
  const percent = calculateSalesClosingDifference(salesFeesTotal.maxValue, closingFeesTotal.maxValue);

  const handleAdditionalFeesChange = (value: List<Fee>) => {
    onChange({ name, value });
    setOpenModal(null);
  };

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.ADDITIONAL_FEES)}>
          Other Fees
        </Link>
        <SalesToClosingComparising sales={salesFeesTotalTitle} percent={percent}>
          <BodyText>{closingFeesTotal?.asHumanReadableString()}</BodyText>
        </SalesToClosingComparising>
      </Box>
      {Modals.ADDITIONAL_FEES === openModal && (
        <AdditionalFeesModal fees={fees} onSave={handleAdditionalFeesChange} onCancel={toggleOpenModal(null)} open />
      )}
    </Grid>
  );
};
