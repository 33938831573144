import { JobLoadingUnloadingTimeDto, parseISO, toDateString } from '@elromcoinc/react-shared';
import { addMinutes, startOfDay } from 'date-fns';

import { JobTrackerLevels, TrackerTimeLine, TravelTimeLog } from 'admin/components/CrewStatusLog/types';
import { convertLocalTimeToMinutes } from 'admin/components/CrewStatusLog/utils/convertLocalTimeToMinutes';

interface MakeTrackerTimeLineProps {
  firstLaborTime: number;
  lastLaborTime: number | null;
  actualTIme: number;
  durationInMinutes: number;
  times: JobLoadingUnloadingTimeDto<string>[];
  overtime: number;
  firstLoggedStartTime: Date;
  travelTimes: TravelTimeLog[];
  completedStatusTime: Date | null;
  hasAnyTime: boolean;
  isCalculateDrivingTimeAsLabor: boolean;
}

const makeTitle = (date: Date, durationInMinutes: number) => {
  return durationInMinutes <= 30
    ? (toDateString(date, `mm`) as string)
    : (toDateString(date, ` H 'h' mm 'm'`) as string);
};
const approximateOneCharWidth = 6.25;

const overlapLevels = [
  JobTrackerLevels.LABOR_TIME,
  JobTrackerLevels.TRAVEL_TIME,
  JobTrackerLevels.OVERTIME_TRAVEL_TIME,
];

const positiveOrZero = (value: number) => (value < 0 ? 0 : value);

export const makeTrackerTimeLine = ({
  firstLaborTime,
  lastLaborTime,
  actualTIme,
  durationInMinutes,
  times,
  overtime,
  firstLoggedStartTime,
  travelTimes,
  completedStatusTime,
  hasAnyTime,
  isCalculateDrivingTimeAsLabor,
}: MakeTrackerTimeLineProps) => {
  const result: TrackerTimeLine[] = [];

  const firstTravelTime = travelTimes[0];
  const secondTravelTime = travelTimes[1];
  const firstTravelTimeMinutes =
    (firstTravelTime?.durationInMinutes ?? 0) + positiveOrZero(firstTravelTime?.overtimeInMinutes ?? 0);
  const secondTravelTimeMinutes = overtime
    ? (secondTravelTime?.durationInMinutes ?? 0) + positiveOrZero(secondTravelTime?.overtimeInMinutes ?? 0)
    : 0;

  const overestimatedTime = durationInMinutes - (actualTIme + secondTravelTimeMinutes);

  if (lastLaborTime && overestimatedTime > 0) {
    const width = overestimatedTime;
    const title = makeTitle(addMinutes(startOfDay(new Date()), width), width) as string;

    result.push({
      startTime: firstLaborTime + actualTIme + secondTravelTimeMinutes,
      endTime: firstLaborTime + actualTIme + overestimatedTime,
      title,
      width: overestimatedTime,
      level: JobTrackerLevels.OVER_ESTIMATED_LABOR_TIME,
      noPaddingLeft: true,
    });
  }

  if (lastLaborTime) {
    const width = actualTIme + overtime + firstTravelTimeMinutes + secondTravelTimeMinutes;
    const title = makeTitle(addMinutes(startOfDay(new Date()), width), width);

    result.push({
      startTime: firstLaborTime - firstTravelTimeMinutes,
      endTime: lastLaborTime,
      title: title,
      width,
      level: JobTrackerLevels.WHOLE_JOB,
      noPaddingLeft: !!firstTravelTimeMinutes,
    });
  } else if (!overtime && durationInMinutes) {
    const width = durationInMinutes;
    const title = makeTitle(addMinutes(startOfDay(new Date()), width), width) as string;

    result.push({
      startTime: firstLaborTime,
      endTime: firstLaborTime + width,
      title,
      width: width,
      level: JobTrackerLevels.TRAVEL_TIME,
      noPaddingLeft: true,
    });
  }

  if (lastLaborTime && (firstTravelTimeMinutes || secondTravelTimeMinutes)) {
    const width = actualTIme + overtime;
    const title = makeTitle(addMinutes(startOfDay(new Date()), width), width);

    result.push({
      startTime: firstLaborTime,
      endTime: lastLaborTime,
      title: title,
      width,
      level: JobTrackerLevels.LABOR_TIME,
    });
  }

  if (isCalculateDrivingTimeAsLabor) {
    times.forEach((t, index) => {
      if (t.startDateTime && t.endDateTime) {
        const start = convertLocalTimeToMinutes(parseISO(t.startDateTime)!, firstLoggedStartTime);
        const end = convertLocalTimeToMinutes(parseISO(t.endDateTime)!, firstLoggedStartTime);
        const width = Math.abs(end - start);
        const title = makeTitle(addMinutes(startOfDay(new Date()), width), width) as string;

        result.push({
          startTime: start,
          endTime: end,
          title,
          width,
          level: JobTrackerLevels.LABOR_TIME,
        });
      }

      const nextTime = times[index + 1];

      if (t.endDateTime && nextTime?.startDateTime) {
        const start = convertLocalTimeToMinutes(parseISO(t.endDateTime)!, firstLoggedStartTime);
        const end = convertLocalTimeToMinutes(parseISO(nextTime.startDateTime)!, firstLoggedStartTime);
        const width = Math.abs(end - start);
        const title = makeTitle(addMinutes(startOfDay(new Date()), width), width) as string;

        result.push({
          startTime: start,
          endTime: end,
          title,
          width,
          level: JobTrackerLevels.LABOR_TIME,
        });
      }

      if (nextTime?.startDateTime && !nextTime.endDateTime && !completedStatusTime) {
        const start = convertLocalTimeToMinutes(parseISO(nextTime.startDateTime)!, firstLoggedStartTime);
        const end = convertLocalTimeToMinutes(new Date(), firstLoggedStartTime);
        const width = Math.abs(end - start);
        const title = makeTitle(addMinutes(startOfDay(new Date()), width), width) as string;

        result.push({
          startTime: start,
          endTime: end,
          title,
          width,
          level: JobTrackerLevels.LABOR_TIME,
        });
      }
    });
  }

  travelTimes.forEach((travelTime) => {
    const { date, durationInMinutes, time, overtimeInMinutes, overtimeStart, startTotalTimeline } = travelTime;
    const showTimeAmount = !hasAnyTime;

    if (durationInMinutes) {
      const timeDate = addMinutes(startOfDay(date), durationInMinutes);
      result.push({
        startTime: time,
        endTime: time + durationInMinutes,
        title: makeTitle(timeDate, durationInMinutes),
        width: durationInMinutes,
        level: JobTrackerLevels.TRAVEL_TIME,
        noPaddingLeft: true,
      });
    }

    if (overtimeInMinutes) {
      const timeDate = addMinutes(startOfDay(date), Math.abs(overtimeInMinutes));
      result.push({
        startTime: overtimeStart,
        endTime: time + durationInMinutes + overtimeInMinutes,
        title: makeTitle(timeDate, overtimeInMinutes),
        width: overtimeInMinutes,
        level:
          overtimeInMinutes < 0 ? JobTrackerLevels.OVERTIME_TRAVEL_TIME_LESS : JobTrackerLevels.OVERTIME_TRAVEL_TIME,
        noPaddingLeft: true,
      });
    }

    if (startTotalTimeline && durationInMinutes && overtimeInMinutes > 0 && showTimeAmount) {
      const timeDate = addMinutes(startOfDay(date), durationInMinutes + overtimeInMinutes);
      result.push({
        startTime: startTotalTimeline,
        endTime: startTotalTimeline + durationInMinutes + overtimeInMinutes,
        title: makeTitle(timeDate, durationInMinutes + overtimeInMinutes),
        width: durationInMinutes + overtimeInMinutes,
        level: JobTrackerLevels.TRAVEL_TIME,
        noPaddingLeft: true,
      });
    }
  });

  if (overtime) {
    const title = makeTitle(addMinutes(startOfDay(new Date()), overtime), overtime) as string;

    result.push({
      startTime: firstLaborTime + actualTIme,
      endTime: firstLaborTime + actualTIme + overtime,
      title,
      width: overtime,
      level: JobTrackerLevels.OTHER,
      noPaddingLeft: true,
    });

    if (firstLaborTime) {
      const width = actualTIme;
      const title = makeTitle(addMinutes(startOfDay(new Date()), width), width) as string;

      result.push({
        startTime: firstLaborTime,
        endTime: firstLaborTime + width,
        title,
        width: width,
        level: JobTrackerLevels.OTHER,
      });
    }
  }

  return result
    .map((t) => ({
      ...t,
      title: t.title.replace('00 m', '').replace(' 0 h', ''),
    }))
    .reduce((accumulator, t) => {
      if (
        overlapLevels.includes(t.level) &&
        accumulator.some((time) => {
          const approximateFirstTextWidth = approximateOneCharWidth * time.title.length;
          const approximateSecondTextWidth = approximateOneCharWidth * t.title.length;

          const firstStartTime = time.startTime + time.width / 2 - approximateFirstTextWidth / 2;
          const secondStartTime = t.startTime + t.width / 2 - approximateSecondTextWidth / 2;
          const firstEndTime = time.startTime + time.width / 2 + approximateFirstTextWidth / 2;
          const secondEndTime = t.startTime + t.width / 2 + approximateSecondTextWidth / 2;

          return overlapLevels.includes(time.level) && firstStartTime < secondEndTime && firstEndTime > secondStartTime;
        })
      ) {
        const level =
          t.level === JobTrackerLevels.TRAVEL_TIME || t.level === JobTrackerLevels.TRAVEL_TIME_OVERLAP
            ? JobTrackerLevels.TRAVEL_TIME_OVERLAP
            : JobTrackerLevels.LABOR_TIME_OVERLAP;
        return [
          ...accumulator,
          {
            ...t,
            level,
          },
        ];
      }

      return [...accumulator, t];
    }, [] as TrackerTimeLine[]);
};
