import React, { useState } from 'react';

import {
  BodyText,
  Link,
  Order,
  Valuation as ValuationEntity,
  ValuationType,
  formatCurrency,
  getValuationSetting,
  isLongDistanceService,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { SalesToClosingComparising } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/SalesToClosingComparising';
import { calculateSalesClosingDifference } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/calculateSalesClosingDifference';
import {
  useOrderChangeSet,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import ValuationModal from 'admin/components/OrderWindow/modals/Valuation';
import { ZERO_MONEY } from 'admin/constants/DefaultValues';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    percentage: {
      fontSize: '12px',
      marginLeft: '10px',
      opacity: '0.5',
    },
    costs: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '55%',
    },
  }),
);

const valuationLabel = 'Valuation';

enum Modals {
  VALUATION = 'VALUATION',
}

const defaultTitleBasicValuation = '60 cents per pound';

export const ClosingValuation = () => {
  const classes = useStyles();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const settings = useOrderWindowSettings();
  const service = order.services.get(serviceIndex);
  const isLongDistance = isLongDistanceService(service.getType());
  const titleBasicValuation = getValuationSetting(
    settings,
    isLongDistance,
    order.moveType,
    'titleBasicValuation',
    defaultTitleBasicValuation,
  );
  const titleThirdOption = getValuationSetting(settings, isLongDistance, order.moveType, 'titleThirdOption');
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const closingValuation = order.closingOrderDetail?.valuation;
  const salesValuation = order.valuation;
  const percent = calculateSalesClosingDifference(salesValuation.maxValue, closingValuation.maxValue);

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  const handleOrderChange = (name: string, value: any) => {
    onChange({ name, value });
    setOpenModal(null);
  };

  const handleValuationChange = (value: ValuationEntity) => handleOrderChange('closingOrderDetail.valuation', value);

  function getValuationValue(titleForBasicValuation: string) {
    if (ValuationType.FULL === closingValuation.type) {
      return `$${formatCurrency(closingValuation.cost)}`;
    }

    if (ValuationType.THIRD_OPTION === closingValuation.type) {
      return titleThirdOption;
    }

    return titleForBasicValuation || defaultTitleBasicValuation;
  }

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.VALUATION)}>
          {valuationLabel}
        </Link>
        {ValuationType.FULL === closingValuation.type ? (
          <SalesToClosingComparising sales={`$${salesValuation?.cost}`} percent={percent}>
            <BodyText>{getValuationValue(titleBasicValuation || ZERO_MONEY)}</BodyText>
          </SalesToClosingComparising>
        ) : (
          <BodyText>{getValuationValue(titleBasicValuation || ZERO_MONEY)}</BodyText>
        )}
      </Box>
      {Modals.VALUATION === openModal && (
        <ValuationModal
          open
          onSave={handleValuationChange}
          onCancel={toggleOpenModal(null)}
          orderId={order.orderId}
          moveType={order.moveType}
          isLocal={!isLongDistance}
          valuation={order.closingOrderDetail?.valuation}
        />
      )}
    </Grid>
  );
};
