import React, { useCallback, useEffect, useState } from 'react';

import { HeaderBigText, Modal, PaymentPermissions, useConfirm, useHasPermission } from '@elromcoinc/react-shared';
import { Box, ButtonBase, LinearProgress, Paper, makeStyles } from '@material-ui/core';
import produce from 'immer';
import { useSnackbar } from 'notistack';

import paymentActionsApi, { CustomerPaymentProfileDto, PaymentProfileDto } from 'admin/api/PaymentActionsApi';

import AddCard from './CreateCharge/AddCard';
import EditCard from './CreateCharge/EditCard';
import { CreditCard } from './CreditCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    '& > *': {
      marginBottom: 16,
      [theme.breakpoints.up('sm')]: {
        marginRight: 16,
      },
    },
  },
  addButton: {
    height: 180,
    width: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
  },
}));

interface CardsOnFileModalProps {
  customerId: number;
  email: string;
  customerPaymentProfile: null | CustomerPaymentProfileDto;
  phoneNumber: string;

  onClose(): void;

  refreshPaymentMethods(): void;
}

const CardsOnFileModal: React.FC<CardsOnFileModalProps> = ({
  onClose,
  refreshPaymentMethods,
  customerId,
  email,
  phoneNumber,
  customerPaymentProfile: initialCustomerPaymentProfile,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [inFlight, setInFlight] = useState(false);
  const [customerPaymentProfile, setCustomerPaymentProfile] = useState(initialCustomerPaymentProfile);
  const [addCard, setAddCard] = useState(false);
  const [cardToEdit, setCardToEdit] = useState<PaymentProfileDto | null>(null);
  const canAddCard = useHasPermission(PaymentPermissions.PERM_CAN_ADD_A_CARD_ON_FILE);

  const cards = customerPaymentProfile?.paymentMethods || [];

  const handleEditCard = useCallback((cc: PaymentProfileDto) => () => setCardToEdit(cc), []);
  const handleCloseEditCard = useCallback((dto?: PaymentProfileDto | null) => {
    if (dto) {
      setCustomerPaymentProfile(
        produce((draft) => {
          const paymentMethodId = draft?.paymentMethods?.findIndex((it) => it.id === dto.id) ?? -1;
          if (paymentMethodId > -1) {
            draft!.paymentMethods[paymentMethodId] = dto;
          }
        }),
      );
    }
    setCardToEdit(null);
  }, []);

  useEffect(() => {
    setCustomerPaymentProfile(initialCustomerPaymentProfile);
  }, [initialCustomerPaymentProfile]);

  const markDefault = (paymentMethodId: number) => () => {
    if (customerPaymentProfile) {
      paymentActionsApi.setDefaultPaymentMethod(customerId, paymentMethodId).then(setCustomerPaymentProfile);
    }
  };

  const deleteCreditCard = (paymentMethodId: number) => {
    setInFlight(true);
    paymentActionsApi
      .deletePaymentMethod(customerId, paymentMethodId)
      .then((success) => {
        if (success) {
          setCustomerPaymentProfile((p) => {
            if (p) {
              return { ...p, paymentMethods: p.paymentMethods.filter((it) => it.id === paymentMethodId) };
            }
            return null;
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(
          'There was an an error processing your request to delete credit card. Please try again at a later time.',
          {
            variant: 'error',
          },
        );
      })
      .then(() => setInFlight(false));
  };

  const { confirm, ConfirmationDialog: renderConfirmationDialog } = useConfirm({
    message: `Do you want to delete this credit card?`,
    maxWidth: 'xs',
  });

  const handleCloseAddCard = () => {
    setAddCard(false);
    refreshPaymentMethods();
  };

  const handleDeleteCard = (paymentMethodId: number) => async () => {
    const isConfirmDelete = await confirm();

    if (isConfirmDelete) {
      deleteCreditCard(paymentMethodId);
    }
  };

  return (
    <Modal maxWidth="md" fullWidth={false} open title="Cards on File" onClose={onClose}>
      {inFlight && <LinearProgress />}
      <Box className={classes.root}>
        {cards.map((cc) => (
          <CreditCard
            useByDefault={cc.id === customerPaymentProfile?.defaultPaymentMethodId}
            key={cc.id}
            value={cc}
            onDelete={handleDeleteCard(cc.id)}
            onEdit={handleEditCard(cc)}
            onSetDefault={markDefault(cc.id)}
            canEdit={canAddCard}
          />
        ))}
        {canAddCard && (
          <ButtonBase component={Paper} className={classes.addButton} onClick={() => setAddCard(true)}>
            <HeaderBigText color="primary">+ Add new Credit Card</HeaderBigText>
          </ButtonBase>
        )}
      </Box>
      {addCard && canAddCard && (
        <AddCard email={email} phoneNumber={phoneNumber} onClose={handleCloseAddCard} customerId={customerId} />
      )}
      {cardToEdit && canAddCard && <EditCard onClose={handleCloseEditCard} customerId={customerId} card={cardToEdit} />}
      {renderConfirmationDialog()}
    </Modal>
  );
};

export { CardsOnFileModal };
