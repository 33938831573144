import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import {
  Badge,
  BodySmallText,
  BodyText,
  HeaderBigText,
  MoveUnit,
  Order,
  commonNewInventory,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { makeStyles, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListComponent from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';

import { newInventoryApi } from 'admin/api';
import { useOrderSettingUnits } from 'admin/components/OrderWindow/context';
import useOrderSizing from 'admin/components/OrderWindow/hooks/useOrderSizing';
import { generateDescriptionForInventoryItem } from 'admin/components/OrderWindow/utils';
import { IconLabelButton } from 'common/components/Widgets';

const useStyles = makeStyles((theme) => ({
  specialItemContainer: {
    minWidth: '20rem',
    maxWidth: '100vw',
    maxHeight: '50vh',
    padding: '1rem',
  },
  listItem: {
    marginTop: '4px',
    backgroundColor: theme.palette.action.hover,
  },
}));

function generateName(item: commonNewInventory.InventoryItemDto) {
  const postfix = item?.quantity! > 1 ? ` (${item.quantity} items)` : '';
  return `${item.name}${postfix}`;
}

interface InventorySettingButtonProps {
  order: Order;
  onClick: () => void;
}

const InventorySettingButton: FC<InventorySettingButtonProps> = ({ order, onClick }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { inventoryCuFt, inventoryWeight } = useOrderSizing(order);
  const [initialRooms, setInitialRooms] = useState<commonNewInventory.InventoryRoom[]>([]);
  const { moveUnitLabelsShort, moveUnit } = useOrderSettingUnits();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const totalItemsCount = initialRooms.reduce(
    (total, room) => total + (room.inventoryItems || []).reduce((count, item) => count + (item?.quantity || 0), 0),
    0,
  );

  useEffect(() => {
    if (order?.orderId) {
      newInventoryApi
        .getInventorySet(order?.orderId)
        .then(({ inventoryRooms }) => {
          setInitialRooms(
            inventoryRooms
              .map((room) => ({
                ...room,
                inventoryItems: room?.inventoryItems?.filter(
                  (item) => !!item?.extraServiceFee || !!item?.minMoversRequired || !!item?.additionalLaborTime,
                ),
              }))
              .filter((room) => !!room?.inventoryItems?.length) || [],
          );
        })
        .catch(() => {});
    }
  }, [order?.orderId, inventoryCuFt]);

  const handleOnBadgeClick = (event: ChangeEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasInventory = !!inventoryCuFt;
  const TextComponent = hasInventory ? BodySmallText : BodyText;
  const weight = moveUnit === MoveUnit.CUBIC_FEET ? inventoryCuFt : inventoryWeight;
  const formattedWeight = weight ? `${formatCurrency(weight, 0)} ${moveUnitLabelsShort} ` : '';

  return (
    <>
      <IconLabelButton
        data-testid="InventoryButton"
        //@ts-ignore
        color={hasInventory ? theme.palette.error.main : 'default'}
        startIcon={<LocalShippingOutlinedIcon />}
        onClick={onClick}
      >
        <Badge
          //@ts-ignore
          onClick={handleOnBadgeClick}
          badgeContent={totalItemsCount}
        />
        <TextComponent textAlign="center">{`${formattedWeight} Inventory`}</TextComponent>
      </IconLabelButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.specialItemContainer}>
          <HeaderBigText>
            <strong>Special Item</strong>
          </HeaderBigText>
          <ListComponent>
            {initialRooms.map((item) => (
              <>
                <BodyText>
                  <b>{item?.name}</b>
                </BodyText>
                {item?.inventoryItems?.map((item) => (
                  <ListItem className={classes.listItem} key={item.id}>
                    <ListItemText
                      primaryTypographyProps={{ color: 'initial' }}
                      primary={generateName(item)}
                      secondary={generateDescriptionForInventoryItem(item)}
                    />
                  </ListItem>
                ))}
              </>
            ))}
          </ListComponent>
        </Box>
      </Popover>
    </>
  );
};

export default InventorySettingButton;
