/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';

import {
  ActivitySourceType,
  BodyText,
  Button,
  ElromcoCircularProgress,
  HeaderSmallText,
  HeaderText,
} from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import Compressor from 'compressorjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import DropzoneComponent from 'react-dropzone-component';
import styled from 'styled-components';

import fileAPI from 'admin/api/FileAPI';
import { useOrderState } from 'admin/components/OrderWindow/context';
import { UploaderTitle } from 'admin/constants';
import { fileToFormData } from 'common/utils';

import FileContainer from './FileContainer';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 1rem',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  centered: {
    margin: '0 auto',
  },
}));

const StyledDropzone = styled(DropzoneComponent)`
  border: 1px dashed;
  border-radius: 4px;
  cursor: pointer;
  padding: ${({ $dense }) => ($dense ? 8 : 32)}px 8px;
  margin: 16px 8px;
  text-align: center;
  > .dz-preview {
    display: none;
  }
`;

const pageSize = 10;

const ACTIVITY_SOURCES = { CUSTOMER_ACCOUNT: ActivitySourceType.CUSTOMER_ACCOUNT, ORDER: ActivitySourceType.ORDER };

const activity = {
  [ACTIVITY_SOURCES.CUSTOMER_ACCOUNT]: 'account',
  [ACTIVITY_SOURCES.ORDER]: 'order',
};

const FileSection = ({ title, acceptedFiles, sourceId, extensions, description, activitySource }) => {
  const classes = useStyles();
  const { order } = useOrderState();
  const signaturesIds = order?.orderConfirmationRecordDto?.map((file) => file?.fileId) ?? [];
  const [files, setFiles] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [inFlight, setInFlight] = useState(false);
  const [isDisabledInDelete, setIsDisabledInDelete] = useState(false);
  const [isDisabledInUpdate, setIsDisabledInUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const errorMessage = { variant: 'error' };
  const successMessage = { variant: 'success' };

  useEffect(() => {
    if (sourceId) {
      setInFlight(true);
      fileAPI
        .getAllFiles(sourceId, pageIndex, pageSize, extensions, activitySource)
        .then((res) => {
          setFiles([...files, ...(res.pageElements || [])]);
          setTotalPages(res.totalPages);
        })
        .catch(() => enqueueSnackbar(`Can't get files for current ${activity[activitySource]}`, errorMessage))
        .then(() => setInFlight(false));
    }
  }, [sourceId, pageIndex, activitySource]);

  const componentConfig = {
    iconFiletypes: ['.jpg', '.png', '.gif'],
    showFiletypeIcon: true,
    postUrl: `/api/file/${sourceId}`,
  };

  const djsConfig = {
    autoProcessQueue: false,
    createImageThumbnails: false,
    acceptedFiles,
  };

  const uploadFile = (uploadFile) => {
    fileAPI
      .uploadFile(fileToFormData(uploadFile, { activitySource, sourceId }))
      .then((dto) => {
        setFiles((f) => [dto, ...f]);
      })
      .catch(() => {
        enqueueSnackbar(`Can't upload file for current ${activity[activitySource]}`, errorMessage);
      })
      .then(() => {
        setInFlight(false);
        enqueueSnackbar(`File for current ${activity[activitySource]} uploaded successfully`, successMessage);
      });
  };

  function addedFileHandler(file) {
    setInFlight(true);

    const fileType = file.name.split('.').pop().toLocaleLowerCase();

    if (!acceptedFiles.includes(fileType)) {
      enqueueSnackbar(`We support next file types: ${acceptedFiles.split(',').join(', ')}`, errorMessage);
      setInFlight(false);
      return;
    }

    title === UploaderTitle.PHOTOS
      ? new Compressor(file, {
          quality: 0.6,

          success(compressedFile) {
            uploadFile(compressedFile);
          },
          error() {
            enqueueSnackbar('Unable to compress the file. Please choose a file of a different size.', errorMessage);
          },
        })
      : uploadFile(file);
  }

  const eventHandlers = {
    addedfile: addedFileHandler,
  };

  const handleFileDelete = (id) => {
    setIsDisabledInDelete(true);

    fileAPI
      .deleteFile(id)
      .then(() => {
        setFiles(files.filter((it) => it.id !== id));
      })
      .catch(() => enqueueSnackbar(`Can't delete file from current ${activity[activitySource]}`, errorMessage))
      .then(() => {
        setIsDisabledInDelete(false);
        enqueueSnackbar(`File for current ${activity[activitySource]} deleted successfully`, successMessage);
      });
  };

  const handleOnChange =
    (id) =>
    ({ target: { checked, name } }) => {
      const file = files.filter((it) => it.id === id)[0];
      if (file) {
        fileAPI.updateFileInfo(id, { ...file, [name]: checked }).then((dto) => {
          setFiles((f) => f.reduce((acc, item) => [...acc, item.id === dto.id ? dto : item], []));
        });
      }
    };

  const handleUpdateInfo =
    (id) =>
    ({ value, name }) => {
      setIsDisabledInUpdate(true);
      const file = files.filter((it) => it.id === id)[0];
      if (file) {
        fileAPI
          .updateFileInfo(id, { ...file, [name]: value })
          .then((dto) => {
            setFiles((f) => f.reduce((acc, item) => [...acc, item.id === dto.id ? dto : item], []));
          })
          .catch(() => {
            enqueueSnackbar(`Can't updated file information`, errorMessage);
          })
          .then(() => {
            setIsDisabledInUpdate(false);
            enqueueSnackbar('File information updated successfully', successMessage);
          });
      }
    };

  return (
    <Box className={classes.wrapper}>
      <HeaderText>{title}</HeaderText>
      <StyledDropzone
        config={componentConfig}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
        $dense={!!files.length}
      >
        <Box className="dz-message" display="flex" justifyContent="center">
          <HeaderSmallText>
            {description}
            &nbsp;
            <HeaderSmallText color="primary" component="span">
              Upload
            </HeaderSmallText>
          </HeaderSmallText>
        </Box>
        <BodyText>{`Only ${acceptedFiles.replace(/,/g, ', ').toUpperCase()} files allowed.`}</BodyText>
      </StyledDropzone>
      {files.map((it) => (
        <FileContainer
          {...it}
          onDelete={handleFileDelete}
          onChange={handleOnChange(it.id)}
          key={it.id}
          isDisabledInDelete={isDisabledInDelete}
          handleUpdateInfo={handleUpdateInfo(it.id)}
          isDisabledInUpdate={isDisabledInUpdate}
          signaturesIds={signaturesIds}
        />
      ))}
      {!inFlight && totalPages - pageIndex > 1 && (
        <Box className={classes.centered}>
          <Button varinat="text" onClick={() => setPageIndex(pageIndex + 1)}>
            Load More
          </Button>
        </Box>
      )}
      {inFlight && <ElromcoCircularProgress />}
    </Box>
  );
};

FileSection.propTypes = {
  sourceId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  extensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  acceptedFiles: PropTypes.string.isRequired,
  activitySource: PropTypes.string.isRequired,
};

FileSection.defaultProps = {
  description: 'Drag Files here or',
};

export default FileSection;
