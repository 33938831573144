import React, { useState } from 'react';

import { Button, HeaderBigText, Modal, Switch } from '@elromcoinc/react-shared';
import { Box, FormControlLabel } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import pt from 'prop-types';

import { ChargeCustomerForExistCard } from 'admin/components/Settings/components/SmsSettings/components';
import { ChargeAmountForm, EnterCardNumberForm } from 'admin/components/Settings/components/SmsSettings/forms';
import { formatCurrency } from 'common/utils/Formatter';

export const BuySmsAndPhoneNumberModal = ({
  handleCloseModal,
  allStripeCustomers,
  stripeKey,
  allSmsSettings,
  twilioPhoneNumbers,
}) => {
  const [stepModalIndex, setStepModalIndex] = useState(0);
  const [firstStepValues, setFirstStepValues] = useState(allSmsSettings);
  const [secondStepValues, setSecondStepValues] = useState({});
  const [isDisabledInProcessing, setIsDisabledInProcessing] = useState(false);
  const [isUpdatePaymentMethod, setIsUpdatePaymentMethod] = useState(false);

  const existStripeCustomer = allStripeCustomers.length > 0 || twilioPhoneNumbers.length > 0;
  const stripePromise = loadStripe(stripeKey);

  const handleToggleSwitch = (event) => {
    const targetChecked = event.target.checked;
    setIsUpdatePaymentMethod(targetChecked);
  };
  const handleNextStep = () => setStepModalIndex((state) => state + 1);
  const handlePreviousStep = () => setStepModalIndex((state) => state - 1);

  const chargeAmountStep = 0;
  const enterCardNumberStep = 1;

  const modalTitles = {
    [chargeAmountStep]: 'How much money do you want to charge?',
    [enterCardNumberStep]: 'Payment',
  };

  const action = (
    <Button color="primary" variant="text" disabled={isDisabledInProcessing} size="small" onClick={handlePreviousStep}>
      back
    </Button>
  );

  const modalAction = {
    [chargeAmountStep]: null,
    [enterCardNumberStep]: action,
  };

  const closeModal = {
    [chargeAmountStep]: handleCloseModal,
    [enterCardNumberStep]: handleCloseModal,
  };

  const onSubmitFirstStep = (data) => {
    setFirstStepValues(data);
    handleNextStep();
  };

  const onSubmitSecondStep = (data) => {
    setSecondStepValues(data);
  };

  return (
    <Elements stripe={stripePromise}>
      <Modal
        open
        title={modalTitles[stepModalIndex]}
        onClose={closeModal[stepModalIndex]}
        disabledInProcessing={isDisabledInProcessing}
        childrenAction={modalAction[stepModalIndex]}
      >
        {stepModalIndex === chargeAmountStep && (
          <Box p="0 30px" display="flex" flexDirection="column" justifyContent="space-between">
            <ChargeAmountForm
              onSubmit={onSubmitFirstStep}
              defaultValues={firstStepValues}
              allSmsSettings={allSmsSettings}
              allStripeCustomers={allStripeCustomers}
            />
          </Box>
        )}
        {stepModalIndex === enterCardNumberStep && (
          <Box p="0 30px" display="flex" flexDirection="column" justifyContent="space-between">
            <Box textAlign="center">
              <HeaderBigText>
                <b>{`$ ${formatCurrency(firstStepValues.amountToCharge, 2)}`}</b>
              </HeaderBigText>
            </Box>
            <Box position="relative">
              {existStripeCustomer && (
                <Box display="flex" justifyContent="flex-end">
                  <FormControlLabel
                    control={<Switch color="primary" checked={isUpdatePaymentMethod} onChange={handleToggleSwitch} />}
                    label="Update Payment Method"
                  />
                </Box>
              )}
              {!isUpdatePaymentMethod && existStripeCustomer && (
                <ChargeCustomerForExistCard
                  allStripeCustomers={allStripeCustomers}
                  firstStepValues={firstStepValues}
                  handleCloseModal={handleCloseModal}
                  isDisabledInProcessing={isDisabledInProcessing}
                  setIsDisabledInProcessing={setIsDisabledInProcessing}
                />
              )}
              {(!existStripeCustomer || isUpdatePaymentMethod) && (
                <EnterCardNumberForm
                  onSubmit={onSubmitSecondStep}
                  defaultValues={secondStepValues}
                  firstStepValues={firstStepValues}
                  allStripeCustomers={allStripeCustomers}
                  handleCloseModal={handleCloseModal}
                  isDisabledInProcessing={isDisabledInProcessing}
                  setIsDisabledInProcessing={setIsDisabledInProcessing}
                />
              )}
            </Box>
          </Box>
        )}
      </Modal>
    </Elements>
  );
};

BuySmsAndPhoneNumberModal.propTypes = {
  handleCloseModal: pt.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allStripeCustomers: pt.array,
  // eslint-disable-next-line react/forbid-prop-types
  twilioPhoneNumbers: pt.array,
  stripeKey: pt.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allSmsSettings: pt.object.isRequired,
};

BuySmsAndPhoneNumberModal.defaultProps = {
  allStripeCustomers: null,
};
