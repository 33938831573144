import { InventoryTotals, commonNewInventory } from '@elromcoinc/react-shared';
import axios from 'axios';

interface InventoryDto {
  orderId: number;
  inventorySetSubmitted: boolean;
  inventoryRooms: commonNewInventory.InventoryRoom[];
}

class NewInventoryApi {
  addInventorySet(inventory: InventoryDto) {
    return axios.put<InventoryDto, InventoryDto>(`/api/moving/inventory/inventory_set`, inventory);
  }
  getInventorySet(orderId: number) {
    return axios.get<InventoryDto, InventoryDto>(`/api/moving/inventory/inventory_set/order/${orderId}`);
  }
  getTotals(id: number) {
    return axios.get<InventoryTotals, InventoryTotals>(`/api/moving/inventory/inventory_totals/${id}`);
  }
}

export default new NewInventoryApi();
