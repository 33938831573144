import React, { useEffect, useRef, useState } from 'react';

import { ActivitySourceType, Modal } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { List, Set } from 'immutable';
import pt from 'prop-types';
import { useAppSelector } from 'redux-conf';
import { selectLeadScoreSettings } from 'redux-conf/leadScoreSettings/leadScoreSettings-selectors';

import { ExportModal } from 'admin/components/Accounts/ExportModal';
import { SendMassTextModal } from 'admin/components/Accounts/SendMassTextModal';
import { ComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail';
import { ComposeEmailProvider } from 'admin/components/OrderWindow/modals/ComposeEmail/ComposeEmailProvider';
import OrdersTableToolbar from 'admin/components/Orders/OrdersTableToolbar';
import OrdersTable from 'admin/components/Orders/components';
import {
  ADD_FLAG,
  ASSIGN_SALES_PERSON,
  BOOKED_THIS_MONTH,
  BOOKING_DATE,
  CHANGE_STATUS,
  CREATED,
  DATE_TYPE,
  EXPORT,
  MOVE_START_DATE,
  ORDERS_TAB_NAME,
  OrdersActionType,
  SEND_MASS_EMAIL,
  SEND_MASS_TEXT,
  STATUS_UPDATED,
} from 'admin/components/Orders/config';
import { ChangeStatusModal, OrderMessageModal } from 'admin/components/Orders/modals';
import { EditFlagsModal } from 'admin/components/modal';
import { OrdersTypeSummary } from 'admin/constants';
import { useCompanyBranchName } from 'admin/hooks';
import { AssignSalesPersonModal } from 'common/components/AssignSalesPersonModal';

const source = 'order(s)';

const composeInitialFilters = (status, moveStartDate, id, created, bookingDate, statusUpdated, defaultFilters) => {
  let filters = defaultFilters || {};
  if (status) {
    filters = { ...filters, status };
  }
  if (moveStartDate) {
    filters = { ...filters, 'serviceRosters.serviceStartDate': moveStartDate, [DATE_TYPE]: MOVE_START_DATE };
  }
  if (id) {
    filters = { ...filters, id };
  }
  if (created) {
    filters = { ...filters, created, [DATE_TYPE]: CREATED };
  }
  if (bookingDate) {
    filters = { ...filters, bookingDate, [DATE_TYPE]: BOOKING_DATE };
  }
  if (statusUpdated) {
    filters = { ...filters, statusUpdated, [DATE_TYPE]: STATUS_UPDATED };
  }
  return filters;
};

export const OrdersListModal = ({
  onClose,
  status,
  moveStartDate,
  id,
  created,
  bookingDate,
  reservedDate,
  filters,
  type,
  day,
}) => {
  const leadScoreSettings = useAppSelector(selectLeadScoreSettings);
  const [ordersFilters, setOrdersFilters] = useState(
    composeInitialFilters(status, moveStartDate, id, created, bookingDate, reservedDate, filters),
  );
  const [rowsChecked, setRowsChecked] = useState([]);
  const ordersRef = useRef(List());
  const [actionForSelected, setActionForSelected] = useState('');
  const [openEmailModal, setOpenEmailModal] = useState(null);
  const [openFlagsModal, setOpenFlagsModal] = useState(null);
  const [newOrderIds, setNewOrderIds] = useState(null);
  const [selectedMessageOrder, setSelectedMessageOrder] = useState(null);
  const [reloadTable, setReloadTable] = useState(false);
  const branch = useCompanyBranchName();
  const openSendMassEmailModal = actionForSelected === SEND_MASS_EMAIL;
  const openSendMassTextModal = actionForSelected === SEND_MASS_TEXT;
  const openChangeStatusModal = actionForSelected === CHANGE_STATUS;
  const openAssignSalesPersonModal = actionForSelected === ASSIGN_SALES_PERSON;
  const openAddFlagModal = actionForSelected === ADD_FLAG;
  const openExportModal = actionForSelected === EXPORT;
  const [isReload, setIsReload] = useState(false);

  const options = {
    onRowCheckChange: (value) => setRowsChecked(value),
    showExpandableRowsOnMobile: true,
    rowsChecked,
  };

  const handleCloseModal = () => setActionForSelected(null);
  const handleCloseOrderMessageModal = () => setSelectedMessageOrder(null);
  const handleCloseEmailModal = () => setOpenEmailModal(null);
  const handleReloadTable = () => setReloadTable((state) => !state);
  const handleClearRowsSelection = () => setRowsChecked([]);
  const handleCloseFlagsModal = () => setOpenFlagsModal(null);
  const handleReload = () => {
    setOrdersFilters((state) => ({ ...state }));
  };

  const selectedOrdersIds = rowsChecked
    .map((idx) => ordersRef.current.get(idx))
    .filter(Boolean)
    .map((order) => order.orderId);

  const selectedOrdersMessages = rowsChecked
    .map((idx) => ordersRef.current.get(idx))
    .filter(Boolean)
    .map((order) => ({ sourceId: order.orderId, phoneNumber: order.customerPhone, sourceName: order.orderNumber }));

  const selectedIdEmails = rowsChecked
    .map((idx) => ordersRef.current?.get(idx))
    .filter(Boolean)
    .map((item) => ({ id: item.orderId, email: item.customerEmail }));

  const selectedOrdersEmails = Set(selectedIdEmails.map(({ email }) => email)).join(',');

  useEffect(() => {
    if (openFlagsModal) {
      setNewOrderIds([openFlagsModal.orderId]);
    }
  }, [openFlagsModal]);

  return (
    <ComposeEmailProvider>
      <Modal
        open
        maxWidth={false}
        title="Orders"
        onClose={onClose}
        size="small"
        style={{ zIndex: 1240 }}
        disableEnforceFocus
      >
        <Box display="flex" justifyContent="center" flexDirection="column">
          <OrdersTableToolbar
            ordersFilters={ordersFilters}
            setOrdersFilters={setOrdersFilters}
            leadScoreSettings={leadScoreSettings}
            rowsChecked={rowsChecked}
            actionForSelected={actionForSelected}
            setActionForSelected={setActionForSelected}
            clearRowsSelection={handleClearRowsSelection}
            handleReload={handleReload}
            isInFlight={isReload}
          />
          <OrdersTable
            options={options}
            setOpenEmailModal={setOpenEmailModal}
            setOpenFlagsModal={setOpenFlagsModal}
            setSelectedMessageOrder={setSelectedMessageOrder}
            filters={ordersFilters}
            leadScoreSettings={leadScoreSettings}
            branch={branch}
            ordersRef={ordersRef}
            reloadTable={reloadTable}
            selectedBookedOption={BOOKED_THIS_MONTH}
            setRowsChecked={setRowsChecked}
            selectedButton={null}
            currentTab={ORDERS_TAB_NAME}
            useLocalInFlight
            setIsReload={setIsReload}
            useSortByQuery={false}
            type={type}
            day={day}
          />
        </Box>
        {openSendMassEmailModal && (
          <ComposeEmail
            email={selectedOrdersEmails}
            sourcesIdEmails={selectedIdEmails}
            onCancel={handleCloseModal}
            onSave={handleCloseModal}
            open
          />
        )}
        {selectedMessageOrder && (
          <OrderMessageModal onClose={handleCloseOrderMessageModal} order={selectedMessageOrder} />
        )}
        {openSendMassTextModal && (
          <SendMassTextModal
            onClose={handleCloseModal}
            title={OrdersActionType[SEND_MASS_TEXT]}
            sources={selectedOrdersMessages}
            activitySource={ActivitySourceType.ORDER}
          />
        )}
        {openChangeStatusModal && (
          <ChangeStatusModal
            title={OrdersActionType[CHANGE_STATUS]}
            onClose={handleCloseModal}
            onSave={handleReloadTable}
            currentStatus={ordersFilters.status}
            rowsChecked={rowsChecked}
            orderIds={selectedOrdersIds}
          />
        )}
        {openAssignSalesPersonModal && (
          <AssignSalesPersonModal
            title={OrdersActionType[ASSIGN_SALES_PERSON]}
            onClose={handleCloseModal}
            onSave={handleReloadTable}
            rowsChecked={rowsChecked}
            source={source}
            orderIds={selectedOrdersIds}
          />
        )}
        {openAddFlagModal && (
          <EditFlagsModal
            title={OrdersActionType[ADD_FLAG]}
            onClose={handleCloseModal}
            onSave={handleReloadTable}
            sourceIds={selectedOrdersIds}
          />
        )}
        {openExportModal && (
          <ExportModal
            activitySource={ActivitySourceType.ORDER}
            selectedActivityIds={selectedOrdersIds}
            title={OrdersActionType[EXPORT]}
            filters={ordersFilters}
            onClose={handleCloseModal}
            rowsChecked={rowsChecked}
            source={source}
          />
        )}
        {openFlagsModal && (
          <EditFlagsModal
            onClose={handleCloseFlagsModal}
            onSave={handleReloadTable}
            title="Edit Flags"
            sourceIds={newOrderIds}
            flags={openFlagsModal.flags.map((item) => item.id).toJS()}
          />
        )}
        {openEmailModal && <ComposeEmail onCancel={handleCloseEmailModal} onSave={handleCloseEmailModal} open />}
      </Modal>
    </ComposeEmailProvider>
  );
};

OrdersListModal.propTypes = {
  onClose: pt.func.isRequired,
  id: pt.oneOfType([pt.arrayOf(pt.number), pt.instanceOf(List)]),
  status: pt.string,
  moveStartDate: pt.instanceOf(Date),
  created: pt.arrayOf(Date),
  bookingDate: pt.arrayOf(Date),
  reservedDate: pt.arrayOf(Date),
  filters: pt.object,
  type: pt.string,
  day: Date,
};

OrdersListModal.defaultProps = {
  id: null,
  status: null,
  moveStartDate: null,
  created: null,
  bookingDate: null,
  reservedDate: null,
  filters: null,
  type: OrdersTypeSummary.ALL_ORDERS,
  day: null,
};
