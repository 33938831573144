import { Dispatch, SetStateAction, createContext, useContext } from 'react';

interface OrderClosingContextProps {
  isClosing: boolean;
  isCompleted: boolean;
  isLockSales: boolean;
  hasAvailableContracts: boolean;
  showClosingButton: boolean;
  showClosingInOrderWindow: boolean;
  wasCompleted: boolean;
  setIsClosing: Dispatch<SetStateAction<boolean>>;
  closingTitle: string;
  isCanChangeStatus: boolean;
}

export const OrderClosingContext = createContext<OrderClosingContextProps>({
  isClosing: false,
  setIsClosing: () => {},
  closingTitle: '',
  isCompleted: false,
  isLockSales: false,
  hasAvailableContracts: false,
  showClosingButton: false,
  showClosingInOrderWindow: false,
  wasCompleted: false,
  isCanChangeStatus: false,
});

export const useOrderClosingContext = () => useContext(OrderClosingContext);
