import { FC } from 'react';

import { BodySmallText } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  estimatedBlock: {
    position: 'absolute',
    zIndex: 2,
    bottom: theme.spacing(1),
    height: theme.spacing(10),
    borderRadius: 5,
    backgroundColor: theme.palette.grey[600],
    pointerEvents: 'none',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%)',
  },
  text: {
    color: theme.palette.common.white,
    textAlign: 'center',
  },
}));

interface TimeOffBlockProps {
  start: number;
  width: number;
  widthCoefficient: number;
}

export const TimeOffBlock: FC<TimeOffBlockProps> = ({ start, width: widthInPixels, widthCoefficient }) => {
  const classes = useStyles();
  const width = widthInPixels * widthCoefficient;
  const left = start * widthCoefficient;

  return (
    <Box left={left} width={width} className={classes.estimatedBlock}>
      <Box mt={-3.5}>
        <BodySmallText className={classes.text}>Time Off</BodySmallText>
      </Box>
    </Box>
  );
};
