import { Dispatch, SetStateAction, createContext, useContext } from 'react';

interface OrderWindowSalesInfoContextProps {
  viewMoveDetailsSalesInfo: boolean;
  setViewMoveDetailsSalesInfo: Dispatch<SetStateAction<boolean>>;
  viewQuoteDetailsSalesInfo: boolean;
  setViewQuoteDetailsSalesInfo: Dispatch<SetStateAction<boolean>>;
}

export const OrderWindowSalesInfoContext = createContext<OrderWindowSalesInfoContextProps>({
  viewMoveDetailsSalesInfo: false,
  setViewMoveDetailsSalesInfo: () => {},
  viewQuoteDetailsSalesInfo: false,
  setViewQuoteDetailsSalesInfo: () => {},
});

export const useOrderSalesInfoContext = () => useContext(OrderWindowSalesInfoContext);
