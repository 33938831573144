import React from 'react';

import {
  BodyText,
  Checkbox,
  IconButton,
  LeadTemperatureOptions,
  OrdersPermissions,
  Select,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, Paper, Tooltip, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { HighlightOff as HighlightOffIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import {
  ACTION_FOR_SELECTED,
  ASSIGN_SALES_PERSON,
  LEAD_SCORE,
  LEAD_SCORE_MAX,
  LEAD_SCORE_MIN,
  OrdersActionType,
  OrdersLabels,
} from 'admin/components/Orders/config';
import { ModalReloadButton } from 'common/components/ModalReloadButton';

const useStyles = makeStyles(() => ({
  root: {},
  grow: {
    flexGrow: 1,
  },
  offIcon: {
    padding: 0,
  },
}));

const actionForSelectedOptions = Object.entries(OrdersActionType);

function OrdersTableToolbar({
  actionForSelected,
  setActionForSelected,
  ordersFilters,
  setOrdersFilters,
  leadScoreSettings,
  rowsChecked,
  clearRowsSelection,
  handleReload,
  isInFlight,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const canEditOtherUsersAccounts = useHasPermission(OrdersPermissions.PERM_ASSIGN_LEADS);

  const handleActionForSelectedChange = ({ target: { value } }) => {
    setActionForSelected(value);
  };

  const handleLeadScoreFilterChange = ({ target: { value } }) => {
    const leadScore = { leadScoreMin: null, leadScoreMax: null };

    if (value === 'COLD') {
      leadScore.leadScoreMax = leadScoreSettings.warmThreshold;
    }

    if (value === 'WARM') {
      leadScore.leadScoreMin = leadScoreSettings.warmThreshold;
      leadScore.leadScoreMax = leadScoreSettings.hotThreshold;
    }

    if (value === 'HOT') {
      leadScore.leadScoreMin = leadScoreSettings.hotThreshold;
    }

    setOrdersFilters((state) => ({ ...state, [LEAD_SCORE]: value, ...leadScore }));
    clearRowsSelection();
  };

  const handleOffIconClick = () =>
    setOrdersFilters((state) => ({ ...state, [LEAD_SCORE]: '', [LEAD_SCORE_MIN]: null, [LEAD_SCORE_MAX]: null }));

  return (
    <Paper className="applyTopRadius" square elevation={0}>
      <Box display="flex" flexWrap="wrap" justifyContent={isMobile ? 'flex-start' : 'space-between'}>
        <Box ml={-0.05} mt={0.7} width="35px">
          <Checkbox disabled inputProps={{ 'aria-label': 'disabled checkbox related to action for selected items' }} />
        </Box>
        <Box width="230px" display="flex" alignItems="center">
          <Select
            InputProps={{
              disableUnderline: true,
            }}
            fullWidth
            size="small"
            disabled={!rowsChecked.length}
            label={OrdersLabels[ACTION_FOR_SELECTED]}
            name={ACTION_FOR_SELECTED}
            value={actionForSelected || ''}
            onChange={handleActionForSelectedChange}
            options={actionForSelectedOptions.filter(
              ([key]) => canEditOtherUsersAccounts || key !== ASSIGN_SALES_PERSON,
            )}
          />
        </Box>
        <Box width="135px" display="flex" alignItems="center">
          <Tooltip
            disableFocusListener={leadScoreSettings.enabled}
            disableHoverListener={leadScoreSettings.enabled}
            disableTouchListener={leadScoreSettings.enabled}
            title={
              <>
                <Box>
                  <BodyText>Lead Scoring is disabled</BodyText>
                  <BodyText>To enable: Go to Settings/General/Lead Scoring</BodyText>
                </Box>
              </>
            }
            arrow
          >
            <Box width="135px" display="flex">
              <Select
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                disabled={!leadScoreSettings.enabled}
                size="small"
                label={OrdersLabels[LEAD_SCORE]}
                name={LEAD_SCORE}
                value={ordersFilters[LEAD_SCORE] || ''}
                onChange={handleLeadScoreFilterChange}
                options={LeadTemperatureOptions}
              />
            </Box>
          </Tooltip>
          {!!ordersFilters[LEAD_SCORE] && (
            <IconButton size="tiny" variant="text" color="default" onClick={handleOffIconClick}>
              <HighlightOffIcon className={classes.offIcon} />
            </IconButton>
          )}
        </Box>
        {props.children}
        <div className={classes.grow} />
        <Box mt={1} mr={1}>
          <ModalReloadButton handleReload={handleReload} isDisabled={isInFlight} />
        </Box>
        {/* (not MVP)
        <Box mr={2} display={{ xs: 'none', sm: 'none', md: 'flex' }} justifyContent="center" flexDirection="column">
          <BodyText component="div">
            <b>Total Estimates Showing: $56567 / Total Estimates on All Pages: $156678</b>
          </BodyText>
        </Box>
        */}
      </Box>
    </Paper>
  );
}

OrdersTableToolbar.propTypes = {
  ordersFilters: PropTypes.object,
  setOrdersFilters: PropTypes.func,
  leadScoreSettings: PropTypes.object,
  rowsChecked: PropTypes.array,
  actionForSelected: PropTypes.string,
  setActionForSelected: PropTypes.func,
  clearRowsSelection: PropTypes.func,
  handleReload: PropTypes.func,
  isInFlight: PropTypes.bool,
};

export default OrdersTableToolbar;
