import React, { useCallback, useMemo, useState } from 'react';

import {
  ActivitySourceDescriptor,
  ActivitySourceType,
  ForemanPageJobStatuses,
  JobStatus,
  Link,
  Order,
  PaymentActivityDto,
  PaymentActivityType,
  PaymentAdjustmentDTO,
  PaymentAdjustmentType,
  Service,
  getServicePropertyName,
} from '@elromcoinc/react-shared';
import { TableCell } from '@material-ui/core';
import { List } from 'immutable';

import orderAPI from 'admin/api/OrderAPI';
import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { Payments } from 'admin/components/OrderWindow/modals/Payments';
import { isReservedOrBooked } from 'admin/constants/OrderStatus';

enum Modals {
  PAYMENT = 'PAYMENT',
}

const updateOrderIfExist = (updates: Order) => (targetOrder: Order | null) => {
  if (targetOrder) {
    return targetOrder
      .set('payments', updates.payments)
      .set('pendingPayments', updates.pendingPayments)
      .set('totalPaid', updates.totalPaid)
      .set('reservationAmountNeeded', updates.reservationAmountNeeded)
      .set('totalDepositPaid', updates.totalDepositPaid)
      .set(
        'services',
        targetOrder.services.map((s, index) => {
          const updatedService = updates.services.get(index) as Service;

          if (updatedService) {
            return s
              .setIn(['quote', 'pickupCashDiscounts'], updatedService.quote.pickupCashDiscounts)
              .setIn(['quote', 'pickupCreditCardFees'], updatedService.quote.pickupCreditCardFees)
              .setIn(['quote', 'pickupCustomFees'], updatedService.quote.pickupCustomFees)
              .setIn(['quote', 'paymentAdjustmentDtos'], updatedService.quote.paymentAdjustmentDtos)
              .setIn(['quote', 'totalPaid'], updatedService.quote.totalPaid)
              .setIn(['quote', 'reservationAmountNeeded'], updatedService.quote.reservationAmountNeeded)
              .setIn(['quote', 'totalDepositPaid'], updatedService.quote.totalDepositPaid);
          }

          return s;
        }),
      );
  }
  return null;
};

const startedJobIndex = ForemanPageJobStatuses.indexOf(JobStatus.LOADING);

export const Payment = () => {
  const { isSelectedAllServices, serviceIndex } = useOrderServiceIndex();
  const { changeSet, onChange } = useOrderChangeSet();
  const { order, setOrder, setOriginalOrder } = useOrderState();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const { isClosing, isLockSales } = useOrderClosingContext();
  const quote = order?.getServiceQuote(serviceIndex);
  const currentStatus = quote?.dispatchJob?.jobStatus ?? JobStatus.NOT_ASSIGNED;
  const isStartedJob = ForemanPageJobStatuses.indexOf(currentStatus) >= startedJobIndex;
  const activitySources: ActivitySourceDescriptor[] = useMemo(
    () =>
      isClosing
        ? order?.getClosingPaymentActivitySources(serviceIndex)!
        : order?.getSalesPaymentActivitySources(serviceIndex)!,
    [isClosing, serviceIndex, quote?.serviceRosterId],
  ) as ActivitySourceDescriptor[];

  const closePaymentModal = useCallback(() => {
    setOpenModal(null);
    if (order?.orderId) {
      const oldPaymentAdjustmentDtos = order.services.reduce(
        (acc, s) => acc.push(s.quote.paymentAdjustmentDtos),
        List(),
      );
      orderAPI
        .getOrder(order!.orderId)
        .then(Order.createOrder)
        .then((order) => {
          const newPaymentAdjustmentDtos = order.services.reduce(
            (acc, s) => acc.push(s.quote.paymentAdjustmentDtos),
            List(),
          );
          if (!changeSet.isEmpty() && oldPaymentAdjustmentDtos.join() !== newPaymentAdjustmentDtos.join()) {
            const changes = [
              ...order.services
                .map((s, index) => ({
                  name: getServicePropertyName(index, 'pickupCashDiscounts'),
                  value: s.quote.pickupCashDiscounts,
                }))
                .toArray(),
              ...order.services
                .map((s, index) => ({
                  name: getServicePropertyName(index, 'pickupCreditCardFees'),
                  value: s.quote.pickupCreditCardFees,
                }))
                .toArray(),
              ...order.services
                .map((s, index) => ({
                  name: getServicePropertyName(index, 'pickupCustomFees'),
                  value: s.quote.pickupCustomFees,
                }))
                .toArray(),
            ].filter((change) => change.value);

            if (changes.length > 0) {
              onChange(changes);
              setTimeout(() => {
                // only update payment related info to keep all the order updates which were not yet saved
                setOrder(updateOrderIfExist(order));
                setOriginalOrder(updateOrderIfExist(order));
              });
              return;
            }
          }

          // only update payment related info to keep all the order updates which were not yet saved
          setOrder(updateOrderIfExist(order));
          setOriginalOrder(updateOrderIfExist(order));
        })
        .catch(() => {});
    }
  }, [order?.orderId, changeSet?.size]);

  if (!order) {
    return null;
  }

  const {
    email,
    primaryPhone: { number: phoneNumber },
  } = order.contactInfo;

  const paymentAdjustments = (quote?.paymentAdjustmentDtos ?? List()) as List<PaymentAdjustmentDTO>;
  const payments = (quote?.payments ?? List()) as List<PaymentActivityDto>;
  const calculatedDeposit = payments.reduce((total, payment) => {
    if (payment.type === PaymentActivityType.DEPOSIT && payment.settledAmount) {
      const adjustmentsTotal = paymentAdjustments
        .filter((pa) => pa.paymentId === payment.id)
        .reduce(
          (accumulator, pa) =>
            pa.paymentAdjustmentType === PaymentAdjustmentType.CASH_DISCOUNT
              ? accumulator + pa.total
              : accumulator - pa.total,
          0,
        );
      return total + payment.settledAmount + adjustmentsTotal;
    }

    return total;
  }, 0);
  const totalDepositPaid = paymentAdjustments.isEmpty() ? quote?.totalDepositPaid || 0 : calculatedDeposit;
  const currentDeposit = totalDepositPaid ? 0 : (quote?.reservationAmountNeeded || 0) - totalDepositPaid;
  const depositToPay = currentDeposit > 0 ? currentDeposit : 0;

  return (
    <HighlightedTableRow data-testId="payment" highlight>
      <TableCell size="small" padding="none">
        <Link disabled={isSelectedAllServices} onClick={() => setOpenModal(Modals.PAYMENT)}>
          {isClosing ? 'Payments Made' : 'Payment'}
        </Link>
        {Modals.PAYMENT === openModal && (
          <Payments
            open
            activitySources={activitySources}
            sourceId={order.orderId}
            activitySource={ActivitySourceType.ORDER}
            orderNumber={order.orderNumber}
            email={email}
            amount={''}
            phoneNumber={phoneNumber}
            customerId={order.customerInfo.customerId}
            cardHolder={order?.contactInfo?.fullName()}
            reservationAmountNeeded={depositToPay}
            changeStatusOnDeposit={!isReservedOrBooked(order.status)}
            onClose={closePaymentModal}
            disabled={isLockSales && isStartedJob}
            allowToMakePaymentAvailableOnBOL={isClosing && isStartedJob}
          />
        )}
      </TableCell>
      <TableCell>
        <Text
          value={
            (isClosing ? order?.closingOrderDetail : order)
              ?.getTotalPaid(serviceIndex, isSelectedAllServices)
              ?.asHumanReadableString() ?? ''
          }
          data-testId={'paymentValue'}
        />
      </TableCell>
    </HighlightedTableRow>
  );
};
