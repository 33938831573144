import React, { useMemo } from 'react';

import {
  BodyBigText,
  BodyText,
  HorizontalLine,
  IconButton,
  PriceAdjustmentType,
  formatCurrency,
  getFormErrorMessages,
  roundNumberToFixedDigits,
  useHoverTooltip,
} from '@elromcoinc/react-shared';
import { Box, Grid, ListSubheader, MenuItem, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { List } from 'immutable';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getDepartments } from 'admin/autodux/UserDepartmentAutodux';
import { getBasicEmployee } from 'admin/autodux/UsersAutodux';
import { OrderPayrollTotals } from 'admin/components/OrderWindow/modals/Payroll/OrderPayrollTotals';
import { OrderPayroll } from 'admin/components/OrderWindow/modals/Payroll/Payroll';
import {
  OWNER_DEPARTMENT_ID,
  PayrollPositionTypesByNamesToChoose,
  payrollForeman,
  payrollHelper,
  payrollName,
  payrollSales,
  payrolls,
} from 'admin/components/OrderWindow/modals/Payroll/PayrollConstants';
import { PayrollEmployee } from 'admin/components/OrderWindow/modals/Payroll/PayrollEmployee';
import { getCommission } from 'admin/components/OrderWindow/modals/Payroll/getCommission';
import { PositionTypes } from 'admin/constants/PositionTypes';
import { useMobile } from 'admin/hooks/useMobile';
import { BasicEmployeeDto, EmployeeCommissionType } from 'common-types';
import { BaseDepartmentDto } from 'common-types/department';
import { EmployeeJobPayrollCompensationDto, EmployeeJobPayrollDto } from 'common-types/payroll';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      overflowY: 'auto',
      maxHeight: theme.spacing(50),
    },
    arrow: {
      color: theme.palette.background.paper,
      borderColor: theme.palette.background.paper,
    },
    subheader: {
      backgroundColor: theme.palette.common.white,
      textTransform: 'capitalize',
      pointerEvents: 'none',
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    employeeItem: {
      color: theme.palette.grey.A400,
    },
    payrollHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(1),
    },
    totalPayroll: {
      marginBottom: theme.spacing(1),
    },
  }),
);

interface PayrollByPositionTypeProps {
  name: typeof payrollSales | typeof payrollForeman | typeof payrollHelper;
  totals: OrderPayrollTotals;
}

export const PayrollByPositionType = ({ name, totals }: PayrollByPositionTypeProps) => {
  const isMobile = useMobile();
  const classes = useStyles();
  const methods = useFormContext<OrderPayroll>();
  const newEmployeeTooltip = useHoverTooltip();
  const errors = getFormErrorMessages(methods.formState.errors);
  const employees = useSelector(getBasicEmployee) as List<BasicEmployeeDto>;
  const allDepartments = useSelector(getDepartments) as List<BaseDepartmentDto>;
  const departments = useMemo(
    () => allDepartments.filter((d) => d.id !== OWNER_DEPARTMENT_ID).toArray(),
    [allDepartments.size],
  );
  const {
    fields: employeeFields,
    append: appendEmployee,
    remove,
  } = useFieldArray({
    control: methods.control,
    name,
    keyName: 'uid',
  });

  const formValue = methods.getValues();
  const allEmployees = payrolls.reduce(
    (accumulator, p) => [...accumulator, ...formValue[p]],
    [] as EmployeeJobPayrollDto[],
  );

  const activeEmployeeAndNotOwners = employees.filter(
    (employee) =>
      employee.active &&
      employee.departmentId !== OWNER_DEPARTMENT_ID &&
      !allEmployees.find((field) => field.employeeId === employee.id),
  );

  const currentPositionTypes = PayrollPositionTypesByNamesToChoose[payrollName[name]];

  const options = departments
    .map((department) => ({
      name: department.name,
      employees: activeEmployeeAndNotOwners
        .filter((employee) => employee.departmentId === department.id)
        .sort((a, b) => a.firstName.localeCompare(b.firstName)),
      positionType: currentPositionTypes[0],
    }))
    .filter((opt) => opt.employees.size);

  const total = roundNumberToFixedDigits(
    employeeFields.reduce(
      (employeeAccumulator, employee) =>
        employeeAccumulator +
        employee.employeeJobPayrollCompensations.reduce(
          (compensationAccumulator, compensation) => compensationAccumulator + compensation.total,
          0,
        ),
      0,
    ) ?? 0,
  );

  const calculateEmployeeJobPayrollCompensations = (
    employee: BasicEmployeeDto,
    totals: OrderPayrollTotals,
    positionType: PositionTypes,
  ): EmployeeJobPayrollCompensationDto[] => {
    return employee.employeeCommissions
      .map((commission) => {
        const c = getCommission(commission, totals, {
          positionType,
          hasHourlyRateAsHelper: employee.employeeCommissions.some(
            (c) => c.commissionType === EmployeeCommissionType.HOURLY_RATE_AS_HELPER,
          ),
        });

        if (!c) {
          return null;
        }

        const coefficient = c.rate.type === PriceAdjustmentType.PERCENT ? c.rate.amount / 100 : c.rate.amount;
        c.total = roundNumberToFixedDigits(coefficient * c.value);

        return c;
      })
      .filter(Boolean) as EmployeeJobPayrollCompensationDto[];
  };

  const onClickAddNewEmployee = (employee: BasicEmployeeDto, positionType: PositionTypes) => () => {
    const employeeJobPayrollCompensations = calculateEmployeeJobPayrollCompensations(employee, totals, positionType);

    appendEmployee({
      employeeId: employee.id,
      employeeJobPayrollCompensations,
      positionType,
      total: 0,
    });
  };

  const newEmployeeButtons = newEmployeeTooltip.renderTooltip(
    <Box display="flex" flexDirection="column" p={1}>
      <Box my={1}>
        {options.map((option) => [
          <ListSubheader classes={{ root: classes.subheader }} disableSticky>
            <b>{option.name}</b>
          </ListSubheader>,
          option.employees.map((employee) => (
            <MenuItem
              key={employee.id!}
              onClick={onClickAddNewEmployee(employee, option.positionType as PositionTypes)}
              className={classes.employeeItem}
            >
              {`${employee.firstName} ${employee.lastName}`}
            </MenuItem>
          )),
        ])}
      </Box>
    </Box>,
  );

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid container item xs={12} spacing={1} alignItems="center" className={classes.payrollHeader}>
        <Grid item xs={3}>
          <BodyBigText>
            <b>{payrollName[name]}</b>
          </BodyBigText>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" ml={isMobile ? 3 : 14}>
            <BodyBigText>
              <b>Name</b>
            </BodyBigText>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end">
            <BodyBigText>
              <b>Total</b>
            </BodyBigText>
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {!!errors?.[name] && (
        <Alert className="mt-h" severity="error">
          <BodyText color="inherit">{errors?.[name]}</BodyText>
        </Alert>
      )}
      {employeeFields.map((field, index) => (
        <PayrollEmployee
          key={field.uid}
          name={name}
          index={index}
          remove={remove}
          totals={totals}
          departments={departments}
          calculateEmployeeJobPayrollCompensations={calculateEmployeeJobPayrollCompensations}
        />
      ))}
      <Grid item xs={12}>
        {((name === payrollForeman && employeeFields.length < 1) || name !== payrollForeman) && (
          <Box display="flex" justifyContent="flex-end">
            <Tooltip
              {...newEmployeeTooltip.tooltipProps}
              title={newEmployeeButtons}
              enterTouchDelay={0}
              leaveTouchDelay={10000}
              arrow
              classes={classes}
            >
              <IconButton variant="default" color="primary" size="medium">
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} className={classes.totalPayroll}>
        <Box display="flex" justifyContent="flex-end">
          <BodyBigText>
            <b>{`${payrollName[name]} Total: $${formatCurrency(total)}`}</b>
          </BodyBigText>
        </Box>
        <Box className="mt-1 mb-h">
          <HorizontalLine color="black" />
        </Box>
      </Grid>
    </Grid>
  );
};
