import React, { ChangeEvent } from 'react';

import { BodyText, DurationRange, Order, Select, useConfirm } from '@elromcoinc/react-shared';
import { MenuItem, TableCell, TableRow } from '@material-ui/core';

import { ViewSalesInfo } from 'admin/components/OrderWindow/blocks/MoveDetails/ViewSalesInfo';
import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import useDurations from 'admin/components/OrderWindow/hooks/useDurations';
import { getClosingLaborTimeName } from 'admin/components/OrderWindow/utils/getClosingLaborTimeName';
import { getServicePropertyName } from 'admin/utils';

export const LaborTimeRow = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex } = useOrderServiceIndex();
  const { onChange } = useOrderChangeSet();
  const { confirm, renderDialog } = useConfirm({
    title: 'Are you sure you want to set time manually?',
    message: 'Calculator will be turned off.',
    confirmTitle: "Yes, let's do it!",
    cancelTitle: 'No, cancel pls!',
    maxWidth: 'xs',
  });
  const laborDurationRangeOverride = order.getIn(
    getServicePropertyName(serviceIndex, 'laborDurationRangeOverride').split('.'),
  ) as DurationRange;
  const laborDurationRange = order.getIn(
    getServicePropertyName(serviceIndex, 'laborDurationRange').split('.'),
  ) as DurationRange;
  const [, durationOptions15mins] = useDurations(laborDurationRangeOverride.durationMin.originalSeconds);
  const {
    durationMin: { originalSeconds: laborSecondsMin },
    durationMax: { originalSeconds: laborSecondsMax },
  } = laborDurationRangeOverride;
  const { durationMin: calculatedMin, durationMax: calculatedMax } = laborDurationRange;
  const nameMin = getServicePropertyName(serviceIndex, 'laborDurationRangeOverride.durationMin.originalSeconds');
  const nameMax = getServicePropertyName(serviceIndex, 'laborDurationRangeOverride.durationMax.originalSeconds');
  const closingName = getClosingLaborTimeName(serviceIndex);
  const closingLaborTime = order.getIn(closingName.split('.')) as number;
  const { isClosing, isCompleted, isLockSales } = useOrderClosingContext();

  const handleOnLaborTimeChange =
    (calculatedValue: number, disableAutoCalculation: boolean) => (event: ChangeEvent<HTMLInputElement>) => {
      const changes = {
        name: event.target.name,
        value: +event.target.value,
      };
      if (!isClosing && !disableAutoCalculation && +calculatedValue !== +event.target.value) {
        confirm().then((result) => {
          if (result) {
            onChange({ name: 'disableAutoCalculation', value: true });
            onChange(changes);
          }
        });
      } else {
        onChange(changes);
      }
    };

  const getSalesLaborTimeLabel = durationOptions15mins.filter(([value]) => value === laborSecondsMin)?.[0]?.[1];

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>Labor Time</BodyText>
        {renderDialog()}
      </TableCell>
      <ValueTableCell>
        <FlexWithNegativeMargin $wrap>
          {isClosing ? (
            <Select
              disabled={isCompleted || isLockSales}
              value={closingLaborTime}
              hiddenLabel
              InputProps={{
                disableUnderline: true,
              }}
              name={closingName}
              onChange={handleOnLaborTimeChange(calculatedMin.originalSeconds, order.disableAutoCalculation)}
            >
              {durationOptions15mins.map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <BodyText>
                    <b>{label}</b>
                  </BodyText>
                </MenuItem>
              ))}
            </Select>
          ) : (
            <>
              <Select
                disabled={isCompleted || isLockSales}
                value={laborSecondsMin}
                hiddenLabel
                InputProps={{
                  disableUnderline: true,
                }}
                name={nameMin}
                onChange={handleOnLaborTimeChange(calculatedMin.originalSeconds, order.disableAutoCalculation)}
              >
                {durationOptions15mins.map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    <BodyText color={value !== calculatedMin.originalSeconds ? 'error' : 'default'}>
                      <b>{label}</b>
                    </BodyText>
                  </MenuItem>
                ))}
              </Select>
              <span>-</span>
              <Select
                disabled={isCompleted || isLockSales}
                value={laborSecondsMax}
                hiddenLabel
                InputProps={{
                  disableUnderline: true,
                }}
                name={nameMax}
                onChange={handleOnLaborTimeChange(calculatedMax.originalSeconds, order.disableAutoCalculation)}
              >
                {durationOptions15mins
                  .filter((it) => it[0] >= laborSecondsMin)
                  .map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      <BodyText color={value !== calculatedMax.originalSeconds ? 'error' : 'default'}>
                        <b>{label}</b>
                      </BodyText>
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
        </FlexWithNegativeMargin>
      </ValueTableCell>
      <ViewSalesInfo label={getSalesLaborTimeLabel} />
    </TableRow>
  );
};
