import React, { ChangeEvent, useRef } from 'react';

import { BodyText, useConfirm } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import { DropzoneComponent } from 'react-dropzone-component';

import { IconLabelButton } from 'common/components/Widgets';

const useStyles = makeStyles(() => ({
  dropzone: {
    height: '62px',
    padding: 0,
    minHeight: 0,
    '& .dz-preview': {
      display: 'none',
      background: 'none !important',
    },
    '& .dz-message': {
      display: 'none',
    },
  },
  button: {
    position: 'relative',
    height: '58px',
    display: 'flex',
    width: '100%',
    backgroundColor: '#efefef',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    flexWrap: 'wrap',
    '& svg': {
      fontSize: '1rem',
    },
  },
  preview: {
    marginRight: '10px',
    marginTop: '5px',
    marginBottom: '5px',
    maxHeight: '60px',
  },
  fileName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100px',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%',
    width: '120px',
  },
}));

const dropzoneConfig = {
  postUrl: 'no-url',
  maxFiles: 10,
  acceptedFiles: 'image/*,application/pdf',
  maxFilesize: 20,
};

interface AttachmentsEmailsProps {
  onChange: (file: File | File[]) => void;
  onRemove: (file: File) => void;
  files: File[];
}

const AttachmentsEmails: React.FC<AttachmentsEmailsProps> = ({ onChange, onRemove, files }) => {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { confirm: confirmWarning, renderDialog: renderConfirmationWarningDialog } = useConfirm({
    message: 'You are about to remove your attachment.  Do you wish to proceed?',
    confirmTitle: 'Remove',
    cancelTitle: 'Cancel',
    maxWidth: 'xs',
  });

  const handleRemove = async (file: File) => {
    const confirmed = await confirmWarning();

    if (!confirmed) {
      return;
    }

    onRemove(file);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    onChange(selectedFiles);
  };

  return (
    <>
      <DropzoneComponent
        className={classes.dropzone}
        config={dropzoneConfig}
        djsConfig={{
          addRemoveLinks: true,
          autoProcessQueue: false,
        }}
        eventHandlers={{
          removedfile: (file: File) => handleRemove(file),
          addedfile: (file: File) => {
            onChange(file);
          },
          init: (dropzone: Dropzone) => {
            dropzone.on('removedfile', (file: File) => handleRemove(file));
          },
        }}
      >
        <Box className={classes.button}>
          <IconLabelButton
            onClick={handleButtonClick}
            color="default"
            startIcon={<AttachFileIcon />}
            data-testid="attachmentsButton"
          >
            <BodyText>Attach</BodyText>
            <input ref={fileInputRef} style={{ display: 'none' }} type="file" onChange={handleFileChange} />
          </IconLabelButton>
          <Box className={classes.previewContainer}>
            {files.map((file) => (
              <Box className={classes.fileContainer} key={file.name}>
                <BodyText className={classes.fileName}>{file.name}</BodyText>
                <CloseIcon onClick={() => handleRemove(file)} />
              </Box>
            ))}
          </Box>
        </Box>
      </DropzoneComponent>
      {renderConfirmationWarningDialog()}
    </>
  );
};

export default AttachmentsEmails;
