import React, { FC } from 'react';

import { PaymentAdjustmentType } from '@elromcoinc/react-shared';
import { Grid, createStyles, makeStyles } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import clsx from 'clsx';

import { AdditionalWeight } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/AdditionalWeight';
import { ClosingAdditionalCharge } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingAdditionalCharge';
import { ClosingAdditionalFees } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingAdditionalFees';
import { ClosingAdditionalServices } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingAdditionalServices';
import { ClosingBalance } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingBalance';
import { ClosingCharges } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingCharges';
import { ClosingDeposit } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingDeposit';
import { ClosingDiscount } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingDiscount';
import { ClosingFuelSurcharge } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingFuelSurcharge';
import { ClosingGrandTotal } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingGrandTotal';
import { ClosingMaterials } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingMaterials';
import { ClosingPayments } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingPayments';
import { ClosingTaxes } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingTaxes';
import { ClosingTips } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingTips';
import { ClosingValuation } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/ClosingValuation';
import { CustomFees } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/CustomFees';
import { SalesInfoCheckbox } from 'admin/components/OrderWindow/blocks/MoveDetails/SalesInfoCheckbox';
import { useOrderClosingContext, useOrderSalesInfoContext } from 'admin/components/OrderWindow/context';
import Card from 'common/components/Card';
import { FullWidthBox } from 'common/components/Widgets';

const useStyles = makeStyles((theme) =>
  createStyles({
    inputWithColorBorder: {
      '& .el-text_input input': {
        border: `1px solid ${theme.palette.primary.main}!important`,
        borderRadius: '0px!important',
        textAlign: 'right',
        maxWidth: '85px!important',
      },
    },
    salesInfo: {
      display: 'flex',
      justifyContent: 'end',
    },
  }),
);

interface ClosingQuoteDetailsProps {
  width?: string | null;
}

const ClosingQuoteDetails: FC<ClosingQuoteDetailsProps> = ({ width }) => {
  const classes = useStyles();
  const { closingTitle } = useOrderClosingContext();
  const { setViewQuoteDetailsSalesInfo, viewQuoteDetailsSalesInfo } = useOrderSalesInfoContext();

  return (
    <Card
      title={`Quote Details${closingTitle}`}
      icon={<MonetizationOnIcon />}
      className={clsx('use-input-fixes', classes.inputWithColorBorder)}
      width={width}
    >
      <SalesInfoCheckbox onChange={setViewQuoteDetailsSalesInfo} checked={viewQuoteDetailsSalesInfo} />
      <FullWidthBox my={2} data-testId="quoteDetailsSection">
        <Grid container alignItems="center" spacing={1}>
          <ClosingCharges />
          <ClosingFuelSurcharge />
          <ClosingDiscount />
          <ClosingValuation />
          <ClosingAdditionalServices />
          <ClosingAdditionalFees />
          <ClosingMaterials />
          <AdditionalWeight />
          <ClosingTips />
          <ClosingAdditionalCharge mainType={PaymentAdjustmentType.CASH_DISCOUNT} />
          <ClosingAdditionalCharge mainType={PaymentAdjustmentType.CREDIT_CARD_FEE} />
          <CustomFees />
          <ClosingTaxes />
          <ClosingGrandTotal />
          <ClosingDeposit />
          <ClosingBalance />
          <ClosingPayments />
        </Grid>
      </FullWidthBox>
    </Card>
  );
};

export default ClosingQuoteDetails;
