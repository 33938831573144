import React, { ChangeEvent, useEffect, useState } from 'react';

import { BodyText, LongDistanceTariffDetails, Order, OverridableValue, usePrevious } from '@elromcoinc/react-shared';
import { TableCell, TableRow } from '@material-ui/core';

import { ViewSalesInfo } from 'admin/components/OrderWindow/blocks/MoveDetails/ViewSalesInfo';
import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderSettingUnits,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import { getServicePropertyName as getPropertyName, getServiceRosterClosingPropertyName } from 'admin/utils';
import { renderLaborForRate } from 'admin/utils/renderLaborForRate';

const propertyName = 'additionalRatePerMoveSize';

export const AdditionalLongDistanceRatePerSizeUnit = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { serviceIndex } = useOrderServiceIndex();
  const { isCompleted, isLockSales, isClosing } = useOrderClosingContext();
  const { moveUnitLabelsShort } = useOrderSettingUnits();
  const getServicePropertyName = useGetServicePropertyName();
  const longDistanceTariffDetailsName = getServicePropertyName('longDistanceTariffDetails');
  const additionalLongDistanceRatePerSizeUnitName = isClosing
    ? getServiceRosterClosingPropertyName(serviceIndex, propertyName)
    : getServicePropertyName(propertyName);
  const additionalRate = order.getIn(additionalLongDistanceRatePerSizeUnitName.split('.')) as number;
  const longDistanceTariffDetails = order.getIn(longDistanceTariffDetailsName.split('.')) as LongDistanceTariffDetails;
  const [longDistanceRatePerCuFt, setLongDistanceRatePerCuFt] = useState<string>('0');
  const previousLongDistanceRatePerCuFt = usePrevious(longDistanceRatePerCuFt, longDistanceRatePerCuFt);
  const additionalCurrentRate = (additionalRate ?? longDistanceTariffDetails.getEffectiveRate()).toString() ?? '0';

  const salesLongDistanceTariffDetailsName = getPropertyName(serviceIndex, 'longDistanceTariffDetails');
  const salesLongDistanceTariffDetails = order.getIn(
    salesLongDistanceTariffDetailsName.split('.'),
  ) as LongDistanceTariffDetails;

  const salesAdditionalLongDistanceRatePerSizeUnitName = getPropertyName(serviceIndex, propertyName);
  const salesAdditionalRate = order.getIn(salesAdditionalLongDistanceRatePerSizeUnitName.split('.')) as number;
  const salesAdditionalCurrentRate =
    (salesAdditionalRate ?? salesLongDistanceTariffDetails.getEffectiveRate()).toString() ?? '0';

  useEffect(() => {
    setLongDistanceRatePerCuFt(additionalCurrentRate);
  }, [additionalCurrentRate]);

  const onChangeRate = (value: string) => {
    setLongDistanceRatePerCuFt(value !== '' ? value : '0');
  };

  const handleOnBlur =
    (value: OverridableValue | string | number, previousValue?: OverridableValue | string | number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name },
      } = event;

      if (value !== previousValue) {
        onChange({ name, value });
      }
    };

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>{renderLaborForRate(longDistanceTariffDetails, moveUnitLabelsShort, 'Add. ')}</BodyText>
      </TableCell>
      <ValueTableCell>
        <FlexWithNegativeMargin>
          <CurrencyInput
            disabled={isCompleted || isLockSales}
            onChange={onChangeRate}
            value={longDistanceRatePerCuFt}
            name={additionalLongDistanceRatePerSizeUnitName}
            onBlur={handleOnBlur(longDistanceRatePerCuFt, previousLongDistanceRatePerCuFt)}
          />
        </FlexWithNegativeMargin>
      </ValueTableCell>
      <ViewSalesInfo label={salesAdditionalCurrentRate} />
    </TableRow>
  );
};
