import React, { ChangeEvent, Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import { CustomPaymentSettingDto } from '@elromcoinc/moveboard-setting-react';
import {
  ActivitySourceType,
  BodyText,
  Checkbox,
  PaymentPermissions,
  PaymentType,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, Tooltip } from '@material-ui/core';
import CreditIcon from '@material-ui/icons/CreditCard';

import { usePaymentSourceContext } from 'admin/components/OrderWindow/modals/Payments/PaymentsSourceContext';

import CustomPaymentSelector from '../AddCustomPayment/CustomPaymentSelector';
import PaymentTypeButton from '../PaymentTypeButton';

interface PaymentTypeSelectorProps {
  defaultValue: PaymentType;
  onlinePaymentAvailable: boolean;
  onChange: (value: PaymentType) => void;
  allowToMakePaymentAvailableOnBOL: boolean;
  setShowPaymentOnBOL: Dispatch<SetStateAction<boolean>>;
  showPaymentOnBOL: boolean;
  paymentCustomSettings: CustomPaymentSettingDto | null;
  setPaymentCustomSettings: Dispatch<SetStateAction<CustomPaymentSettingDto | null>>;
}

const toolTipOnlinePayment = 'Unable to process online payments. Please connect your authorize.net account.';

const PaymentTypeSelector: FC<PaymentTypeSelectorProps> = ({
  onlinePaymentAvailable,
  defaultValue = PaymentType.CREDIT_CARD,
  onChange,
  allowToMakePaymentAvailableOnBOL = false,
  showPaymentOnBOL,
  setShowPaymentOnBOL,
  paymentCustomSettings,
  setPaymentCustomSettings,
}) => {
  const { activitySources } = usePaymentSourceContext();
  const [category, setCategory] = useState<PaymentType>(defaultValue);
  const canChargeOnlinePayment = useHasPermission(PaymentPermissions.PERM_CAN_CHARGE_AN_ONLINE_PAYMENT);

  const handleOnChange = (value: PaymentType) => {
    setCategory(value);
    onChange(value);
  };

  const tooltipText: string = useMemo(() => {
    if (!onlinePaymentAvailable) {
      return toolTipOnlinePayment;
    } else if (!canChargeOnlinePayment) {
      return 'Do not have permissions to create online payment.';
    }
    return '';
  }, [onlinePaymentAvailable, canChargeOnlinePayment]);

  const handleShowBOLpayment = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setShowPaymentOnBOL(checked);
  };

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      <Tooltip title={tooltipText} placement="top">
        <Box>
          <PaymentTypeButton
            selected={category === PaymentType.CREDIT_CARD}
            disabled={!(canChargeOnlinePayment && onlinePaymentAvailable)}
            icon={<CreditIcon />}
            onClick={() => handleOnChange(PaymentType.CREDIT_CARD)}
          >
            Credit Online
          </PaymentTypeButton>
        </Box>
      </Tooltip>
      <Box border="1px solid gray">
        <CustomPaymentSelector
          value={category}
          onChange={(category) => handleOnChange(category)}
          paymentCustomSettings={paymentCustomSettings}
          setPaymentCustomSettings={setPaymentCustomSettings}
          my={0}
        />
      </Box>
      {allowToMakePaymentAvailableOnBOL && (
        <Box ml={2}>
          <Checkbox
            color="primary"
            size="small"
            checked={showPaymentOnBOL}
            disabled={!activitySources.some((as) => as.activitySource === ActivitySourceType.ORDER_CLOSING)}
            onChange={handleShowBOLpayment}
            label={
              <Box>
                <BodyText>Show on BOL</BodyText>
              </Box>
            }
          />
        </Box>
      )}
    </Box>
  );
};

export { PaymentTypeSelector };
