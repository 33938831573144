import { FC } from 'react';

import SchedulerManager from 'admin/components/OrderWindow/SchedulerBox/SchedulerManager';
import { useOrderState } from 'admin/components/OrderWindow/context/useOrderState';

const OrderSchedulerManagerProvider: FC = ({ children }) => {
  const { originalOrder } = useOrderState();

  return <SchedulerManager originalOrder={originalOrder!}>{children!}</SchedulerManager>;
};

export { OrderSchedulerManagerProvider };
