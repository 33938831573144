import React from 'react';

import {
  BodyText,
  PaymentStatus,
  UI_DATE_TIME_FORMAT,
  createPaymentActivity,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

import { PaymentStatsReport } from 'admin/components/Reports/PaymentsCollectedTab';
import { DataTableColumnAttributes, DataTableColumnNames } from 'admin/components/Reports/config';
import { makePaymentDescription } from 'admin/utils';
import { renderDate } from 'admin/utils/renderDate';

const totalLabel = 'TOTALS';
const isTotalIncome = (moveDate: string) => moveDate === totalLabel;

const makePaymentsCollectedColumns = (rowsData: PaymentStatsReport[]) => [
  {
    name: DataTableColumnAttributes.ORDER_NUMBER,
    label: DataTableColumnNames[DataTableColumnAttributes.ORDER_NUMBER],
    options: {
      customBodyRender: (_: PaymentStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { orderNumber } = rowsData[rowIndex];
        return (
          <BodyText color="primary">
            <b>{orderNumber}</b>
          </BodyText>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.CUSTOMER_NAME,
    label: DataTableColumnNames[DataTableColumnAttributes.CUSTOMER_NAME],
    options: {
      customBodyRender: (_: PaymentStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { customerName } = rowsData[rowIndex];
        return (
          <Box style={{ textTransform: 'capitalize' }}>
            <BodyText>{customerName}</BodyText>
          </Box>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.PAYMENT_TYPE,
    label: DataTableColumnNames[DataTableColumnAttributes.PAYMENT_TYPE],
    options: {
      customBodyRender: (_: PaymentStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { paymentActivityType } = rowsData[rowIndex];
        if (!paymentActivityType) return null;
        return (
          <BodyText>{makePaymentDescription(createPaymentActivity({ activityType: paymentActivityType })!)}</BodyText>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.PAYMENT_BY,
    label: DataTableColumnNames[DataTableColumnAttributes.PAYMENT_BY],
    options: {
      customBodyRender: (_: PaymentStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { paidDate, createdByCustomer } = rowsData[rowIndex];
        const isTotal = isTotalIncome(paidDate);

        return isTotal ? <BodyText /> : <BodyText>{createdByCustomer ? 'Customer' : 'Employee'}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.TRANSACTION_ID,
    label: DataTableColumnNames[DataTableColumnAttributes.TRANSACTION_ID],
    options: {
      customBodyRender: (_: PaymentStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { transactionId } = rowsData[rowIndex];
        return <BodyText>{transactionId}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.PAID_DATE,
    label: DataTableColumnNames[DataTableColumnAttributes.PAID_DATE],
    options: {
      customBodyRender: (_: PaymentStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { paidDate } = rowsData[rowIndex];
        const isTotal = isTotalIncome(paidDate);

        return isTotal ? (
          <BodyText align="center" className="total-column">
            {paidDate}
          </BodyText>
        ) : (
          renderDate(paidDate, UI_DATE_TIME_FORMAT)
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.AMOUNT,
    label: DataTableColumnNames[DataTableColumnAttributes.AMOUNT],
    options: {
      customBodyRender: (_: PaymentStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { amount, status } = rowsData[rowIndex];
        const prefix = status === PaymentStatus.VOIDED || status === PaymentStatus.REFUND ? '-' : '';

        return <BodyText>{`${prefix}$${formatCurrency(amount, 2)}`}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.NOTES,
    label: DataTableColumnNames[DataTableColumnAttributes.NOTES],
    options: {
      customBodyRender: (_: PaymentStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { notes } = rowsData[rowIndex];
        return (
          <Box minWidth="12.5rem">
            <BodyText>{notes}</BodyText>
          </Box>
        );
      },
      showOnMobileAsExpanded: true,
    },
  },
];

export { makePaymentsCollectedColumns };
