import React from 'react';

import { UniqueIdentifier, useDndContext } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import clsx from 'clsx';

import { DayButton } from 'admin/components/OrderWindow/MultiDayView/DayButton';
import { MultiDayButtonData } from 'admin/components/OrderWindow/MultiDayView/MultiDayButtonData';
import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import { DragAndDropType } from 'common-types';
import { DraggableType } from 'common-types/dnd';

const useStyles = makeStyles((theme) =>
  createStyles({
    multiDay: {
      '&:not(:last-child)': {
        marginRight: '.5rem',
      },
      position: 'relative',
      border: '1px solid transparent',
    },
    dragHandle: {
      position: 'absolute',
      bottom: theme.spacing(-2.5),
      left: theme.spacing(0.5),
      zIndex: 10,
      cursor: 'grab',
    },
    grabbing: {
      cursor: 'grabbing',
      zIndex: 1000,
      border: '1px solid #000',
      borderRadius: '50%',
    },
    hovered: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
    },
  }),
);

interface SortableMultiDayButtonProps {
  index: number;
  serviceIndex: number;
  id: number;
  onClick: () => void;
}

const supports = [DragAndDropType.MOVE_SERVICE];

export const SortableMultiDayButton = ({ index, serviceIndex = 0, id, onClick }: SortableMultiDayButtonProps) => {
  const classes = useStyles();
  const { isClosing, isLockSales } = useOrderClosingContext();
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: id as UniqueIdentifier,
    data: { supports, data: { index: serviceIndex, activeIndex: index, id } } as DraggableType<MultiDayButtonData>,
  });
  const selected = id === active?.id;
  const { collisions } = useDndContext();
  const isHovered = collisions?.find((collision) => collision.id === id)?.data?.hovered ?? false;

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Box
      style={style} // @ts-ignore
      ref={setNodeRef}
      onClick={onClick}
      className={clsx({ [classes.grabbing]: selected, [classes.hovered]: isHovered })}
      classes={{ root: classes.multiDay }}
    >
      <DayButton index={index} serviceIndex={serviceIndex} />
      {!isClosing && !isLockSales && (
        <Box {...listeners} {...attributes} className={classes.dragHandle}>
          <MoreHoriz />
        </Box>
      )}
    </Box>
  );
};
