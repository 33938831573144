import { OverridableValue, formatCurrency } from '@elromcoinc/react-shared';

import { TRAVEL_RATE_OVERRIDE } from 'admin/constants/FieldNames';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto from './UpdatedValueDto';
import { extractServiceIndexUtil } from './extractServiceIndexUtil';

const travelRateChange: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf(TRAVEL_RATE_OVERRIDE) > -1) {
    const travelTimeFiledPath = fieldPath.replace(/travelRateOverride.*$/, TRAVEL_RATE_OVERRIDE);
    const oldTravelRate: OverridableValue = oldOrder.getValueIn(travelTimeFiledPath) as OverridableValue;
    const newTravelRate: OverridableValue = newOrder.getValueIn(travelTimeFiledPath) as OverridableValue;

    if (!oldTravelRate || !newTravelRate) {
      return null;
    }

    const oldValue = oldTravelRate ? `$${formatCurrency(+(oldTravelRate.value() || 0))}` : '';
    const newValue = newTravelRate ? `$${formatCurrency(+(newTravelRate.value() || 0))}` : '';
    return [
      createUpdatedValueDto(
        oldValue,
        newValue,
        'Travel Rate',
        extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
      ),
    ];
  }
  return null;
};

export default travelRateChange;
