import { SettingName as SharedSettingNames } from '@elromcoinc/moveboard-setting-react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderWindowSettings } from 'redux-conf/orderWindowSettings/orderWindowSettings-state';

import { settingsAPI } from 'admin/api';
import SettingName from 'admin/constants/SettingName';

const { GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES, CONTRACT_TEMPLATE } = SharedSettingNames;

const {
  GENERAL_SERVICES,
  UNIT_SIZE_SPEC,
  LEAD_SOURCE,
  SIZE_TO_WEIGHT_INDEX,
  ACCEPT_RESIDENTIAL_ORDERS,
  ACCEPT_COMMERCIAL_ORDERS,
  ACCEPT_MILITARY_ORDERS,
  COMPANY_CALENDAR,
  TRAVEL_TIME_IN_DOLLAR,
  TRAVEL_TIME_ENABLED,
  TRAVEL_TIME_LABEL,
  DRIVING_DOUBLE_TRAVEL_NAME,
  MOVERS_SPEED_REDUCTION,
  MOVERS_SPEED_PARKING_DISTANCE_REDUCTION,
  ORDERS_VALUATION,
  ORDERS_VALUATION_FOR_LONG_DISTANCE,
  ORDER_COI,
  EXTRA_FEE,
  CUSTOMER_HOST_DNS_NAME,
  TEMPLATE_FOLDER,
  GOOGLE_API_KEY,
  ORDERS_TRUCK_SCHEDULE_WINDOW_PLACEMENT,
  OVERNIGHT,
  PACKING_SERVICE_TYPE_NODE,
  LABOR_ONLY,
  PROPERTY_TYPE,
  ADDITIONAL_SERVICE,
  PAYMENT_CUSTOM_SETTINGS,
  PROCESSING_FEE_SETTINGS,
  MOVING_AND_STORAGE_SETTINGS,
  INVENTORY,
} = SettingName;

const settingsQueries = [
  GENERAL_SERVICES,
  SIZE_TO_WEIGHT_INDEX,
  UNIT_SIZE_SPEC,
  ACCEPT_RESIDENTIAL_ORDERS,
  ACCEPT_COMMERCIAL_ORDERS,
  ACCEPT_MILITARY_ORDERS,
  LEAD_SOURCE,
  COMPANY_CALENDAR,
  TRAVEL_TIME_IN_DOLLAR,
  TRAVEL_TIME_ENABLED,
  TRAVEL_TIME_LABEL,
  DRIVING_DOUBLE_TRAVEL_NAME,
  MOVERS_SPEED_REDUCTION,
  MOVERS_SPEED_PARKING_DISTANCE_REDUCTION,
  ORDER_COI,
  EXTRA_FEE,
  ORDERS_VALUATION,
  ORDERS_VALUATION_FOR_LONG_DISTANCE,
  CUSTOMER_HOST_DNS_NAME,
  TEMPLATE_FOLDER,
  GOOGLE_API_KEY,
  ORDERS_TRUCK_SCHEDULE_WINDOW_PLACEMENT,
  'inhome_survey',
  GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES,
  OVERNIGHT.ALL_SETTINGS,
  PACKING_SERVICE_TYPE_NODE,
  LABOR_ONLY,
  PROPERTY_TYPE,
  ADDITIONAL_SERVICE,
  PAYMENT_CUSTOM_SETTINGS,
  PROCESSING_FEE_SETTINGS,
  CONTRACT_TEMPLATE,
  MOVING_AND_STORAGE_SETTINGS,
  INVENTORY,
];

export const getOrderWindowSettings = createAsyncThunk<OrderWindowSettings, undefined, { rejectValue: string }>(
  'orderWindowSettings/getOrderWindowSettings',
  async (_, { rejectWithValue }) => {
    try {
      return await settingsAPI.getDataFromQuery<OrderWindowSettings>({ companyId: 1, queries: settingsQueries });
    } catch (err: any) {
      return rejectWithValue(err.errors);
    }
  },
);
