import { OrderSummary, statusIds, toBackEndDate } from '@elromcoinc/react-shared';
import { endOfMonth, startOfMonth } from 'date-fns';
import { List, Map, Set } from 'immutable';

import orderApi from 'admin/api/OrderAPI';

const allLikeNewStatuses = Set([
  statusIds.NEW_DATE,
  statusIds.NEW_LEAD_INFO,
  statusIds.NEW_LEAD_PAST,
  statusIds.NEW_LEAD_TRUCK,
]);

const allNewStatuses = [
  statusIds.NEW,
  statusIds.NEW_DATE,
  statusIds.NEW_LEAD_INFO,
  statusIds.NEW_LEAD_PAST,
  statusIds.NEW_LEAD_TRUCK,
];

const convertStatusToNew = (summary) =>
  allLikeNewStatuses.has(summary.status) ? summary.set('status', statusIds.NEW) : summary;

const getStartEndDate = (date) => [toBackEndDate(startOfMonth(date)), toBackEndDate(endOfMonth(date))];
const getOrderSummariesForDate = (date, onSuccess, onFailure, always) => {
  const moveStartDate = getStartEndDate(date);
  const { promise, cancel } = orderApi.getOrderCalendarSummariesWithFilters({
    startDate: moveStartDate[0],
    endDate: moveStartDate[1],
  });
  promise
    .then((pageElements) =>
      List(pageElements)
        .map(OrderSummary.createOrderSummary)
        .map(convertStatusToNew)
        .reduce(
          (resultMap, summary) =>
            summary.serviceSummaries
              .map((service) => [toBackEndDate(service.serviceDate), summary])
              .reduce((innerMap, [date, summary]) => {
                if (innerMap.get(date, List()).find((s) => s.orderNumber === summary.orderNumber)) {
                  return innerMap;
                }

                return innerMap.set(date, innerMap.get(date, List()).push(summary));
              }, resultMap),
          Map(),
        ),
    )
    .then(onSuccess)
    .catch(onFailure || (() => {}))
    .then(() => {
      always && always();
    });
  return () => cancel && cancel('cleanup');
};

const isBookedOrReserved = ({ status }) => status === statusIds.BOOKED || status === statusIds.RESERVED;

export { getOrderSummariesForDate, isBookedOrReserved, allLikeNewStatuses, allNewStatuses };
