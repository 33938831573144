import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ActivitySourceType,
  BodyBigText,
  Flag,
  LeadTemperatureColor,
  Order,
  SizingStrategy,
  SurveyType,
  SurveyTypeName,
  useUpdateEffect,
} from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BuildIcon from '@material-ui/icons/Build';
import EventIcon from '@material-ui/icons/Event';
import { differenceInCalendarDays, isFuture, parseISO } from 'date-fns';
import { List } from 'immutable';
import PropTypes from 'prop-types';

import { flagsApi } from 'admin/api';
import { FlagsAutocomplete } from 'admin/components/AccountWindow/components';
import { OrderCustomerOnline } from 'admin/components/OrderWindow/OrderCustomerOnline';
import { CustomerStatsButton, InvoicesButton, ServicesButton, TasksButton } from 'admin/components/OrderWindow/buttons';
import { useOrderClosingContext, useValidateStorageOnBe } from 'admin/components/OrderWindow/context';
import { SurveyModal } from 'admin/components/OrderWindow/modals/SurveyModal';
import useInHomeEstimatePermissions from 'admin/hooks/useInHomeEstimatePermissions';
import { IconLabelButton } from 'common/components/Widgets';

import ClaimsModal from '../modals/Claims';
import InventoryModal from '../modals/InventoryModal';
import Invoices from '../modals/Invoices';
import SettingsModal from '../modals/OrderSettings';
import TasksModal from '../modals/Tasks';
import Attachments from './Attachments';
import InventorySettingButton from './InventorySettingButton';
import StatusSelector from './StatusSelector';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '30px',
    marginRight: '6px',
  },
  topBarContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  topSelectorsContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'start',
      maxWidth: '500px',
      flexDirection: 'column',
    },
  },
  flagsContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

function getNumberOfDaysBeforeMove(dateString) {
  const date = parseISO(dateString);
  if (isFuture(date)) {
    return differenceInCalendarDays(date, new Date());
  }
  return 0;
}

const MODALS = {
  INVENTORY: 0,
  TASKS: 1,
  CLAIMS: 2,
  SETTINGS: 3,
  INVOICES: 4,
  IN_HOME_ESTIMATE: SurveyType.IN_HOME_ESTIMATE,
  VIRTUAL_ESTIMATE: SurveyType.VIRTUAL_ESTIMATE,
};

const sortFlagsDataBySequence = (data) => data.sort((a, b) => a.sequenceOrder - b.sequenceOrder);

export default function TopControlsBar({
  order,
  originalOrder,
  onChange,
  sourceId,
  activitySource,
  setCurrentPage,
  reloadSurveyServices,
  account,
  inHomeEstimateService,
  virtualEstimateService,
}) {
  const { moveType, flags, orderId } = originalOrder;
  const { status: orderStatus } = order;
  const [openModal, setOpenModal] = useState(null);
  const [statusFlagsData, setStatusFlagsData] = useState(null);
  const [allFlagsSortedBySequence, setAllFlagsSortedBySequence] = useState([]);
  const flagsDefaultValue = useMemo(() => flags.toJS(), []);
  const classes = useStyles();
  const { canEdit, canAssign } = useInHomeEstimatePermissions();
  const { isCompleted, isCanChangeStatus } = useOrderClosingContext();
  const editOrViewEstimateService = canEdit && canAssign ? 'Edit' : 'View';
  const addOrViewEstimateService = canAssign ? 'Add' : 'View';
  const isSelectedFlags = order.flags.size > 1;
  const { validateStorageMoveSizeOnBe } = useValidateStorageOnBe();

  useUpdateEffect(() => {
    if (
      originalOrder.sizingStrategy === SizingStrategy.INVENTORY_SIZING &&
      originalOrder?.inventoryTotals?.inventoryCuFt
    ) {
      validateStorageMoveSizeOnBe(originalOrder?.inventoryTotals?.inventoryCuFt);
    }
  }, [originalOrder?.inventoryTotals?.inventoryCuFt, originalOrder.sizingStrategy]);

  useEffect(() => {
    flagsApi
      .getFlagsByActivitySource(ActivitySourceType.ORDER)
      .then(sortFlagsDataBySequence)
      .then(setAllFlagsSortedBySequence)
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (moveType) {
      setStatusFlagsData(allFlagsSortedBySequence.filter((item) => item.moveType === moveType));
    }
  }, [moveType, allFlagsSortedBySequence]);

  const handleStatusChange = (value) => {
    onChange({ name: 'status', value });
  };

  const handleSettingChanges = (changes) => {
    changes.forEach(onChange);
    setOpenModal(null);
  };

  const handleFlagsUpdate = useCallback((_, value) => {
    onChange({
      name: 'flags',
      value: new List(value.map((flag) => new Flag(flag))).sortBy((it) => it.sequenceOrder),
    });
  }, []);

  const closeModal = () => setOpenModal(null);

  const handleSaveEstimate = () => {
    setOpenModal(null);
    reloadSurveyServices();
  };

  const hasInHomeService =
    inHomeEstimateService?.status === 'SCHEDULED' || virtualEstimateService?.status === 'SCHEDULED';

  const renderModal = () => {
    switch (openModal) {
      case MODALS.IN_HOME_ESTIMATE:
        return (
          <SurveyModal
            onSave={handleSaveEstimate}
            onCancel={closeModal}
            open
            surveyType={SurveyType.IN_HOME_ESTIMATE}
            title={SurveyTypeName[SurveyType.IN_HOME_ESTIMATE]}
            subtitle={
              inHomeEstimateService
                ? `${editOrViewEstimateService} ${SurveyTypeName[SurveyType.IN_HOME_ESTIMATE]}`
                : `${addOrViewEstimateService} ${SurveyTypeName[SurveyType.IN_HOME_ESTIMATE]}`
            }
            moveType={moveType}
            currentService={openModal}
            order={order}
            existingEstimateService={inHomeEstimateService}
          />
        );
      case MODALS.VIRTUAL_ESTIMATE:
        return (
          <SurveyModal
            onSave={handleSaveEstimate}
            onCancel={closeModal}
            open
            title={SurveyTypeName[SurveyType.VIRTUAL_ESTIMATE]}
            subtitle={
              virtualEstimateService
                ? `${editOrViewEstimateService} ${SurveyTypeName[SurveyType.VIRTUAL_ESTIMATE]}`
                : `${addOrViewEstimateService} ${SurveyTypeName[SurveyType.VIRTUAL_ESTIMATE]}`
            }
            moveType={moveType}
            currentService={openModal}
            order={order}
            existingEstimateService={virtualEstimateService}
          />
        );
      case MODALS.TASKS:
        return <TasksModal onSave={closeModal} onCancel={closeModal} open source={order} />;
      case MODALS.INVENTORY:
        return <InventoryModal order={order} onCancel={closeModal} open />;
      case MODALS.SETTINGS:
        return <SettingsModal order={order} onSave={handleSettingChanges} onCancel={closeModal} open />;
      case MODALS.CLAIMS:
        return <ClaimsModal onSave={closeModal} onCancel={closeModal} open />;
      case MODALS.INVOICES:
        return (
          <Invoices
            open
            onCancel={closeModal}
            sourceId={sourceId}
            activitySource={ActivitySourceType.ORDER}
            onChange={onChange}
            source={order}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box className={classes.topBarContainer}>
        <Box display="flex" flexWrap="wrap" alignItems="center" flexBasis="calc(50% - 110px)">
          <Box className={isSelectedFlags ? classes.topSelectorsContainer : classes.flagsContainer}>
            <Box m={1} display="flex">
              <OrderCustomerOnline />
              <StatusSelector
                onChange={handleStatusChange}
                value={orderStatus}
                originalStatus={originalOrder.status}
                disabled={isCompleted || !isCanChangeStatus}
              />
            </Box>
            <Box sx={{ mt: '8px', mb: '8px' }}>
              <FlagsAutocomplete data={statusFlagsData} onChange={handleFlagsUpdate} defaultValue={flagsDefaultValue} />
            </Box>
          </Box>
        </Box>
        <Box display="flex" width="220px" alignItems="center">
          <Box marginRight="auto" color={LeadTemperatureColor[order.temperature] || LeadTemperatureColor.COLD}>
            <Box display="flex" alignItems="center">
              <AssessmentIcon fontSize="large" classes={{ fontSizeLarge: classes.icon }} />
              <BodyBigText align="center">
                <b>{order.leadScore}</b>
              </BodyBigText>
            </Box>
          </Box>
          <Box marginRight="auto">
            <Box display="flex" alignItems="center">
              <EventIcon fontSize="large" classes={{ fontSizeLarge: classes.icon }} color="primary" />
              <BodyBigText align="center" color="primary">
                <b>{getNumberOfDaysBeforeMove(order.getIn(['services', 0, 'date']))}</b>
              </BodyBigText>
            </Box>
          </Box>
          <CustomerStatsButton
            account={account}
            order={order}
            customerId={order.customerInfo.customerId}
            setCurrentPage={setCurrentPage}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" flexBasis="calc(50% - 110px)">
          <ServicesButton hasInHomeService={hasInHomeService} setOpenModal={setOpenModal} />
          <TasksButton onClick={() => setOpenModal(MODALS.TASKS)} sourceId={orderId} />
          <InventorySettingButton order={order} onClick={() => setOpenModal(MODALS.INVENTORY)} />
          <Attachments sourceId={sourceId} activitySource={activitySource} />
          <InvoicesButton
            sourceId={sourceId}
            activitySource={activitySource}
            onClick={() => setOpenModal(MODALS.INVOICES)}
          />
          <IconLabelButton
            startIcon={<BuildIcon />}
            onClick={() => setOpenModal(MODALS.SETTINGS)}
            color="default"
            data-testid="orderSettings"
          >
            Settings
          </IconLabelButton>
        </Box>
      </Box>
      {renderModal()}
    </>
  );
}

TopControlsBar.propTypes = {
  order: PropTypes.instanceOf(Order).isRequired,
  originalOrder: PropTypes.instanceOf(Order).isRequired,
  onChange: PropTypes.func.isRequired,
  sourceId: PropTypes.number.isRequired,
  activitySource: PropTypes.string.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  reloadSurveyServices: PropTypes.func,
  inHomeEstimateService: PropTypes.object,
  virtualEstimateService: PropTypes.object,
  account: PropTypes.object,
};

TopControlsBar.defaultProps = {
  reloadSurveyServices: null,
  inHomeEstimateService: null,
  virtualEstimateService: null,
  account: null,
};
