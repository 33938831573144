import React, { FC } from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';
import { List } from 'immutable';

import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import ACTIVITY_TYPE from 'admin/constants/ActivityType';
import OrderLog from 'admin/entities/OrderLog';

import ActivityList from './ActivityList';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    height: theme.spacing(70),
  },
}));

interface ActivityLogViewProps {
  nextSteps: List<OrderLog>;
  pastActions: List<OrderLog>;
  onEdit: (id: number, activityType: ACTIVITY_TYPE) => void;
}

export const ActivityLogView: FC<ActivityLogViewProps> = ({ nextSteps, pastActions, onEdit }) => {
  const classes = useStyles();
  const { totalActivities, uniqueActivities, loadMore } = useActivityLogContext();

  return (
    <Box className={classes.root}>
      <ActivityList title="Next Steps" items={nextSteps} emptyLabel="There are no next steps." onEdit={onEdit} />
      <ActivityList title="Past Actions" items={pastActions} emptyLabel="There are no past actions." onEdit={onEdit} />
      {totalActivities > 100 && uniqueActivities.length !== totalActivities && (
        <Box display="flex" width="100%" justifyContent="center">
          <Button color="primary" variant="outlined" onClick={loadMore}>
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};
