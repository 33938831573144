import axios from 'axios';

import { CreditCardDto } from '../dtos/CreditCardDto';

class PaymentAPI {
  getThirdPartyPublicKey() {
    return axios.get('/api/payment/key');
  }

  createCashCheckCustomPayment(orderId: number, data: any) {
    return axios.post(`/api/payments/order/${orderId}/manual`, data);
  }

  addCardOnFile(orderId: number, data: any) {
    return axios.post<CreditCardDto, CreditCardDto>(`/api/payments/order/${orderId}/cardsOnFile`, data);
  }

  createManualPayment(orderId: number, data: any) {
    return axios.post(`/api/payments/order/${orderId}/manual`, data);
  }

  updatePayment(orderPaymentId: number, payment: any) {
    return axios.put(`/api/payments/order/payment/${orderPaymentId}`, payment);
  }

  deletePayment(paymentId: number) {
    return axios.delete(`/api/moving/order/payment/${paymentId}`);
  }

  deleteCard(orderId: number, paymentCardId: number) {
    return axios.delete(`/api/payments/order/${orderId}/cardsOnFile/${paymentCardId}`);
  }

  makeCardDefault(orderId: number, paymentCardId: number) {
    return axios.put(`/api/payments/order/${orderId}/cardsOnFile/${paymentCardId}/default`);
  }

  getPublicClientKey() {
    return axios.get<AuthorizeClientPublicKey, AuthorizeClientPublicKey>(
      '/api/payments/customer/authorizenet/publicClientKeys',
    );
  }

  getAuthorizePublicKeys<T>() {
    return axios.get<T, T>('/api/payments/customer/authorizenet/authorizeNetCredential');
  }

  saveAuthorizePublicKeys(apiLoginId: string, transactionKey: string) {
    return axios.put(
      '/api/payments/customer/authorizenet/authorizeNetCredential',
      {},
      { params: { apiLoginId, transactionKey } },
    );
  }

  createCreditCardPayment(serviceRosterId: number, data: any) {
    return axios.post(`/api/moving/order/service/${serviceRosterId}/payment/creditCard/`, data);
  }

  getCardsOnFileList(orderId: number) {
    return axios.get<CreditCardDto[], CreditCardDto[]>(`/api/payments/order/${orderId}/cardsOnFile`);
  }

  getDefaultCard(orderId: number) {
    return axios.get<CreditCardDto, CreditCardDto>(`/api/payments/order/${orderId}/cardsOnFile/default`);
  }

  createCreditCardPaymentUsingCardOnFile(
    orderId: number,
    cardOnFileId: number,
    { amount, notes, category, status }: any,
  ) {
    return axios.post(`/api/payments/order/${orderId}/payment/cardOnFile/${cardOnFileId}`, {
      notes,
      amount,
      category,
      status,
    });
  }

  isCreditCardPaymentVoidable(orderPaymentId: number) {
    return axios.get<boolean, boolean>(`/api/payments/order/payment/${orderPaymentId}/voidable`);
  }

  voidCreditCardPayment(orderPaymentId: number) {
    return axios.delete<void, void>(`/api/payments/order/payment/${orderPaymentId}/void`);
  }
}

export default new PaymentAPI();
