/* eslint-disable react/prop-types, react/no-unescaped-entities */
import React, { useState } from 'react';

import { DataTable, MoveTypeName, OrderSummary, getMoveSizeMainDesc } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { format, isDate, parse } from 'date-fns';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { openOrder } from 'admin/autodux/WindowsAutodux';
import { toDateString } from 'admin/utils/DateUtils';
import defaultCompanyLogo from 'common/imgs/logo_spinner.png';

const Line = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.palette.grey.A100};
`;

const FlexColumnData = styled.div`
  display: flex;
  flex-direction: column;
  .email {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const OrderNumber = styled(Box)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`;

const TableContainer = styled(Box)`
  && {
    position: relative;
    .MuiToolbar-root {
      position: relative !important;
    }
    .MuiPaper-root > :nth-child(1) {
      display: none;
    }
    .MuiTableRow-head .MuiTableCell-root {
      font-size: 12px;
      line-height: 12px;
      font-weight: bold;
      color: white;
      background: ${({ theme }) => theme.palette.primary.main};
      padding: 0.6rem 1rem 0.6rem 0.5rem !important;
    }
    .MuiTableSortLabel-root.MuiTableSortLabel-active {
      color: white;
    }
    .MuiTableCell-root {
      font-size: 14px;
      line-height: 14px;
    }
    .MuiPaper-root {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }
    .MuiTable-root {
      max-height: 380px;
    }
    .Mui-selected .white-color-when-select {
      color: white;
    }
    .MuiTablePagination-root {
      position: static;
    }
    .MuiCircularProgress-svg {
      background-image: url(${() => defaultCompanyLogo});
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent;
    }
  }
`;

const makeColumns = (data) => {
  function renderCustomer({ customerName, customerPhone, customerEmail }) {
    return (
      <FlexColumnData>
        {customerName}
        {customerPhone && <a href={`tel:${customerPhone}`}>{customerPhone}</a>}
        <span className="email">{customerEmail}</span>
      </FlexColumnData>
    );
  }

  function renderMoveSize({ sizeDescription, activeCuFt, activeWeight }) {
    return (
      <FlexColumnData>
        <span>{getMoveSizeMainDesc(sizeDescription)}</span>
        <span>
          {activeCuFt}
          &nbsp;Cu Ft&nbsp;
          {activeWeight || Math.floor(activeCuFt * 7)}
          &nbsp;Pounds
        </span>
      </FlexColumnData>
    );
  }

  function renderMoveType({ moveType, serviceSummaries }) {
    return (
      <FlexColumnData>
        <div>{MoveTypeName[moveType]}</div>
        <div>{serviceSummaries.get(0).serviceName}</div>
      </FlexColumnData>
    );
  }

  function renderDate(date) {
    if (isDate(date)) {
      return toDateString(date, 'MM/dd/yyyy');
    }
    return format(parse(date.split('T')[0], 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
  }

  return [
    {
      name: 'id',
      label: 'ORDER #',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { orderNumber } = data[rowIndex];
          return <OrderNumber>{orderNumber}</OrderNumber>;
        },
      },
    },
    {
      name: 'customerFirstName',
      label: 'CUSTOMER',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = data[rowIndex];
          return renderCustomer(row);
        },
      },
    },
    {
      name: 'moveStartDate',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { startDate } = data[rowIndex];
          return renderDate(startDate);
        },
      },
      label: 'MOVE DATE',
    },
    {
      name: 'moveType',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = data[rowIndex];
          return renderMoveType(row);
        },
      },
      label: 'MOVE TYPE',
    },
    {
      name: 'loadSize',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = data[rowIndex];
          return renderMoveSize(row);
        },
      },
      label: 'SIZE',
    },
    {
      name: 'createdDate',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { createDate, updatedDate } = data[rowIndex];
          return `${renderDate(createDate)}/\n${renderDate(updatedDate)}`;
        },
      },
      label: 'Created/Updated',
    },
  ];
};

const CustomerOrders = ({ onSelectOrder, data, inFlight, count, page, onPageChange }) => {
  const dispatch = useDispatch();
  const [currentOrderId, setCurrentOrderId] = useState();

  const options = {
    onRowSelectionChangeOverridden: ([rowIndex]) => {
      const { orderId } = data[rowIndex] || {};
      onSelectOrder(data[rowIndex] || null);

      if (orderId && orderId === currentOrderId) {
        dispatch(openOrder(orderId));
      } else if (orderId) {
        setCurrentOrderId(orderId);
      }
    },
    onChangeRowsPerPage: (pageSize) => onPageChange((state) => ({ ...state, pageSize, pageIndex: 1 })),
    onChangePage: (pageIndex) => onPageChange((state) => ({ ...state, pageIndex: pageIndex + 1 })),
    smallPadding: true,
    rowsPerPage: page.pageSize,
    inFlight,
    serverSide: true,
    page: page.pageIndex - 1,
    alternateTableRowColors: true,
    count,
    sort: false,
    selectableRowsHideCheckboxesCustom: true,
    disableClickAway: true,
  };

  const columns = makeColumns(data);

  return (
    <>
      <Box position="relative" mb={4}>
        <Box position="absolute" left={0} right={0} mx={-3} mt={2}>
          <Line />
        </Box>
      </Box>
      <Box>
        <Box my={2}>Some existing orders match the information you entered, select an order or click "Continue"</Box>
        <TableContainer data-testid="customerOrdersTable">
          <DataTable data={data} columns={columns} options={options} />
        </TableContainer>
      </Box>
    </>
  );
};

CustomerOrders.propTypes = {
  onSelectOrder: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  inFlight: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(OrderSummary).isRequired,
  page: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  }).isRequired,
};

export default CustomerOrders;
