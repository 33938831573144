import React, { FC } from 'react';

import { Checkbox } from '@elromcoinc/react-shared';
import { Box, createStyles, makeStyles } from '@material-ui/core';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles(() =>
  createStyles({
    salesInfo: {
      display: 'flex',
      justifyContent: 'end',
    },
  }),
);

interface SalesInfoCheckboxProps {
  onChange: (arg: boolean) => void;
  checked: boolean;
}

export const SalesInfoCheckbox: FC<SalesInfoCheckboxProps> = ({ onChange, checked }) => {
  const classes = useStyles();
  const { isCompleted } = useOrderClosingContext();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <>
      {isCompleted ? (
        <Box className={classes.salesInfo}>
          <Checkbox checked={checked} label={'View sales info'} onChange={handleCheckboxChange} />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
