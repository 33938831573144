import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

import { BodyText, Button, Link, SizingStrategy, TextInput } from '@elromcoinc/react-shared';
import { FilledInputProps } from '@material-ui/core/FilledInput';
import Grid from '@material-ui/core/Grid';
import LabelTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import MoveSizeInfo from 'admin/components/OrderWindow/blocks/MoveSizeInfo';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderState,
  useValidateStorageOnBe,
} from 'admin/components/OrderWindow/context';
import useOrderSizing from 'admin/components/OrderWindow/hooks/useOrderSizing';
import Popper from 'common/components/Popper';

import { ValueTableCell } from './CommonComponents';

const label = 'CuFt/Lbs';

const cuftName = 'overriddenCuFt';
const weightName = 'overriddenWeight';

const MoveSizeRow = () => {
  const { onChange } = useOrderChangeSet();
  const { order } = useOrderState();
  const { isClosing, isCompleted } = useOrderClosingContext();
  const [accountAnchorEl, setAccountAnchorEl] = useState<(EventTarget & HTMLSpanElement) | null>(null);
  const [weightInputInFocus, setWeightInputInFocus] = useState(false);
  const {
    activeCuFt,
    activeWeight,
    sizeType,
    iconsByType,
    overriddenCuFt,
    setOverriddenCuFt,
    overriddenWeight,
    setOverriddenWeight,
    cuFtToPounds,
  } = useOrderSizing(order!);
  const { validateStorageMoveSizeOnBe } = useValidateStorageOnBe();
  const isCustom = sizeType === SizingStrategy.EMPLOYEE_OVERRIDE_SIZING;
  const isEditable = isCustom && !isClosing;
  const disableInputs = isCompleted;

  const currentOverrideCuFt = overriddenCuFt ?? 0;
  const currentOverrideWeight = overriddenWeight ?? 0;

  const handleCustomValueChanges =
    (setter: Dispatch<SetStateAction<number | null>>) => (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;

      const numberValue = +value;

      if (+value && numberValue < 0) {
        return;
      }

      setter(numberValue);
    };

  const handleOnSave = () => {
    [
      { name: 'overriddenCuFt', value: isCustom ? currentOverrideCuFt : 0 },
      { name: 'overriddenWeight', value: isCustom ? currentOverrideWeight : 0 },
    ].forEach(onChange);
    validateStorageMoveSizeOnBe(isCustom ? currentOverrideCuFt : 0);
    setAccountAnchorEl(null);
  };

  const onFocusCuft: FilledInputProps['onFocus'] = ({ target }) => target.select();
  const onFocusPounds: FilledInputProps['onFocus'] = ({ target }) => {
    target.select();
    setWeightInputInFocus(true);
  };

  const actions = (
    <Button variant="text" color="primary" onClick={handleOnSave}>
      Done
    </Button>
  );
  return (
    <TableRow>
      <LabelTableCell size="small" padding="none">
        {!isEditable && <BodyText>{label}</BodyText>}
        {isEditable && <Link onClick={({ currentTarget = null }) => setAccountAnchorEl(currentTarget)}>{label}</Link>}
      </LabelTableCell>
      <ValueTableCell>
        <MoveSizeInfo Icon={iconsByType[sizeType as SizingStrategy] as any} weight={activeWeight} size={activeCuFt} />
      </ValueTableCell>
      {Boolean(accountAnchorEl) && (
        <Popper
          minWidth="150px"
          title={label}
          anchorEl={accountAnchorEl}
          actions={actions}
          onClose={() => setAccountAnchorEl(null)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                type="number"
                fullWidth
                autoFocus
                onFocus={onFocusCuft}
                name={cuftName}
                shrink
                label="Cubic Feet"
                value={currentOverrideCuFt}
                onChange={handleCustomValueChanges(setOverriddenCuFt)}
                disabled={disableInputs}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                type="number"
                shrink
                fullWidth
                label="Pounds"
                onFocus={onFocusPounds}
                name={weightName}
                onBlur={() => setWeightInputInFocus(false)}
                value={
                  weightInputInFocus && currentOverrideWeight !== null
                    ? currentOverrideWeight || ''
                    : currentOverrideWeight || (currentOverrideCuFt ?? 0) * (cuFtToPounds ?? 0) || ''
                }
                onChange={handleCustomValueChanges(setOverriddenWeight)}
                disabled={disableInputs}
              />
            </Grid>
          </Grid>
        </Popper>
      )}
    </TableRow>
  );
};

export default MoveSizeRow;
