import React, { FC, ReactNode, useEffect } from 'react';

import { CollisionDescriptor, CollisionDetection, DndContext } from '@dnd-kit/core';
import { ClientRect, Coordinates, DragEndEvent } from '@dnd-kit/core/dist/types';
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { BodyBigText, BodyText, Button, IconButton, Order, Service, toBackEndDate } from '@elromcoinc/react-shared';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { List } from 'immutable';
import { useSnackbar } from 'notistack';

import { MultiDayButtonData } from 'admin/components/OrderWindow/MultiDayView/MultiDayButtonData';
import { SortableMultiDayButton } from 'admin/components/OrderWindow/MultiDayView/SortableMultiDayButton';
import { DELETE_SERVICE_KEY } from 'admin/components/OrderWindow/OrderWindowConstants';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { AllServicesIndex } from 'admin/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    multiDayContainer: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '4px',
      margin: '4px',
      padding: '6px 12px',
      flexDirection: 'column',
      display: 'flex',
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
        padding: '2px',
      },
    },
    multiDayHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '5px 0',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    multiDay: {
      width: '2rem',
      height: '2rem',
      '&:not(:last-child)': {
        marginRight: '.5rem',
      },
    },
    allDayButton: {
      backgroundColor: theme.palette.grey.A100,
      borderRadius: '50%',
    },
    deleteDayBtn: {
      textTransform: 'none',
    },
  }),
);

interface MultiDayViewProps {
  children: ReactNode;
  order: Order;
}

const moveItem = (list: List<Service>, fromIndex: number, toIndex: number) => {
  if (fromIndex === toIndex) {
    return list;
  }

  const item = list.get(fromIndex);

  if (item === undefined) {
    throw new Error('Item not found at the fromIndex');
  }

  const listWithoutItem = list.delete(fromIndex);
  return listWithoutItem.insert(toIndex, item);
};

const swapItems = (list: List<Service>, fromIndex: number, toIndex: number) => {
  const fromItem = list.get(fromIndex);
  const toItem = list.get(toIndex);

  if (fromItem === undefined || toItem === undefined) {
    return list;
  }

  return list.set(fromIndex, toItem).set(toIndex, fromItem);
};

const zeroCoordinates: Coordinates = { x: 0, y: 0 };

function distanceBetween(p1: Coordinates, p2: Coordinates) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

function sortCollisionsAsc({ data: { value: a } }: CollisionDescriptor, { data: { value: b } }: CollisionDescriptor) {
  return a - b;
}

function centerOfRectangle(rect: ClientRect, left = rect.left, top = rect.top): Coordinates {
  return {
    x: left + rect.width * 0.5,
    y: top + rect.height * 0.5,
  };
}

function isHovered(pointer: Coordinates | null, clientRect: DOMRect | undefined) {
  if (!pointer || !clientRect) {
    return false;
  }

  return (
    pointer.x > clientRect.x &&
    pointer.x < clientRect.x + clientRect.width &&
    pointer.y > clientRect.y &&
    pointer.y < clientRect.y + clientRect.height
  );
}

let previosValue: CollisionDescriptor[] = [];
let initialValue: CollisionDescriptor[] = [];

const closestCenter: CollisionDetection = ({
  collisionRect,
  droppableRects,
  droppableContainers,
  active,
  pointerCoordinates,
}) => {
  const centerRect = centerOfRectangle(collisionRect, collisionRect.left, collisionRect.top);

  const currentIndex = initialValue.findIndex((v) => v.id === active.id);
  const currentId = previosValue[currentIndex]?.id || active.id;
  let collisions: CollisionDescriptor[] = [];

  const currentRect = droppableRects.get(currentId);

  const centerCurrectRect = currentRect ? centerOfRectangle(currentRect) : zeroCoordinates;

  const spaceSize = 16;
  const isEnabled =
    Math.abs(centerCurrectRect.x - centerRect.x) > collisionRect.width + spaceSize ||
    Math.abs(centerCurrectRect.y - centerRect.y) > collisionRect.height + spaceSize;

  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer;
    const rect = droppableRects.get(id);
    const clientRect = droppableContainer.node.current?.getBoundingClientRect();

    if (rect) {
      let distBetween = distanceBetween(centerOfRectangle(rect), isEnabled ? centerRect : centerCurrectRect);

      collisions.push({
        id,
        data: {
          droppableContainer,
          value: distBetween,
          hovered: active.id !== id ? isHovered(pointerCoordinates, clientRect) : false,
        },
      });
    }
  }

  previosValue = collisions.sort(sortCollisionsAsc);

  if (initialValue.length === 0) {
    initialValue = previosValue;
  }

  return previosValue;
};

const MultiDayView: FC<MultiDayViewProps> = ({ children, order }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { onChange } = useOrderChangeSet();
  const { setOrder } = useOrderState();
  const { isClosing } = useOrderClosingContext();
  const { selectedServiceIndex, setServiceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const [serviceIndexes, setServiceIndexes] = React.useState<{ index: number; id: number }[]>([]);

  useEffect(() => {
    setServiceIndexes(
      order.services
        .map((service, index) => ({
          index,
          id: service.quote.originalId ?? service.id,
        }))
        .toArray(),
    );
  }, [order.services.size]);

  const handleChangeServiceIndex = (index: number) => () => {
    if (isClosing && !order.closingOrderDetail.serviceRosterClosingsDto?.get(index)) {
      enqueueSnackbar('Please save changes', { variant: 'error' });
      return;
    }
    setServiceIndex(index);
  };

  const onServiceDelete = (index: number) => () => {
    if (index >= 0 && order.services.get(index)?.id) {
      onChange({ name: DELETE_SERVICE_KEY, value: order.services.get(index).id });
    }
  };

  const onDragEnd = ({ active, over, collisions }: DragEndEvent) => {
    const activeIndex = active?.data?.current?.sortable.index;
    const overIndex = over?.data.current?.sortable.index;
    const hovered = collisions?.find((collision) => collision?.data?.hovered);
    const hoveredData = hovered?.data?.droppableContainer.data.current.data as Pick<
      MultiDayButtonData,
      'index' | 'activeIndex' | 'id'
    >;
    initialValue = [];

    if (activeIndex !== overIndex || hoveredData) {
      enqueueSnackbar('Service days was sorted', { variant: 'success' });
      const updatedOrderServices = hoveredData
        ? swapItems(order.services as List<Service>, activeIndex, hoveredData.activeIndex)
        : moveItem(order.services as List<Service>, activeIndex, overIndex);

      setTimeout(() => {
        setOrder(order.set('services', updatedOrderServices));
      });

      const sortedServices = (order.services as List<Service>)
        .map((service, index) => {
          const newIndex = updatedOrderServices.findIndex(
            (s) => s.id === service.id || s.quote?.originalId === service.id,
          );
          const previousService = (order.services as List<Service>).get(newIndex)!;
          return { name: `services.${index}.date`, value: toBackEndDate(previousService.date)! };
        })
        .toArray();

      onChange(sortedServices);

      if (selectedServiceIndex === activeIndex) {
        setServiceIndex(hoveredData?.activeIndex ?? overIndex);
      }
    }
  };

  return (
    <Box className={classes.multiDayContainer} data-testId={`dayView-${selectedServiceIndex}`}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box className={classes.multiDayHeader}>
          <Box mr={2}>
            <BodyBigText>
              <b>Multi Day Move</b>
            </BodyBigText>
          </Box>
          <Box mr={2}>
            <BodyBigText>
              <b>View Day:</b>
            </BodyBigText>
          </Box>
          <Box flexDirection="row" display="flex">
            <DndContext onDragEnd={onDragEnd} collisionDetection={closestCenter}>
              <SortableContext
                disabled={order.services.some((s) => !s.id) || isClosing}
                items={order.services.toArray() as any}
                strategy={horizontalListSortingStrategy}
              >
                {order.services.map((service, idx) => (
                  <SortableMultiDayButton
                    id={service.id}
                    index={idx}
                    key={service.id}
                    serviceIndex={
                      serviceIndexes.find((s) => s.id === service.id || s.id === service.quote?.originalId)?.index || 0
                    }
                    onClick={handleChangeServiceIndex(idx)}
                  />
                ))}
              </SortableContext>
              {/*<DraggableOverlay />*/}
            </DndContext>
            <Box className={classes.allDayButton}>
              <IconButton
                color={isSelectedAllServices ? 'primary' : 'default'}
                onClick={handleChangeServiceIndex(AllServicesIndex)}
                size="small"
                variant="default"
                classes={{ root: classes.multiDay }}
              >
                <BodyText>ALL</BodyText>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box flexDirection="row" display="flex" flexWrap="wrap">
        {children}
      </Box>
      <Box mt={1} textAlign="end">
        {!isSelectedAllServices && (
          <Button
            size="small"
            className={classes.deleteDayBtn}
            variant="text"
            color="primary"
            onClick={onServiceDelete(selectedServiceIndex)}
          >
            Delete this Day <DeleteIcon />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MultiDayView;
