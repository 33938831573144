import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { AuthorizedImage, BodyBigText, Modal, PaymentActivityDto } from '@elromcoinc/react-shared';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { EditCashPayment } from './EditCashPayment';
import { EditCheckPayment } from './EditCheckPayment';
import { EditCreditCardOnline } from './EditCreditCardOnline';
import { EditCreditCardRecord } from './EditCreditCardRecord';
import { EditCustomPayment } from './EditCustomPayment';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    paymentCardImage: {
      '& img': {
        maxWidth: 300,
      },
    },
  }),
);

interface EditPaymentProps {
  onSave: () => void;
  onCancel: () => void;
  payment: PaymentActivityDto;
}

const EditPayment: FC<EditPaymentProps> = ({ onSave, onCancel, payment }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [inFlight, setInFlight] = useState(false);
  const mountedHandler = useRef<(() => Promise<any>) | null>(null);

  const handleOnSave = useCallback(() => {
    if (mountedHandler.current) {
      setInFlight(true);
      mountedHandler
        .current()
        .then(() => {
          enqueueSnackbar('Payment was updated.', { variant: 'success' });
          onSave();
        })
        .catch(() => {
          enqueueSnackbar('There was an error updating your payment information.', { variant: 'error' });
        })
        .then(() => {
          setInFlight(false);
        });
    }
  }, [enqueueSnackbar, mountedHandler.current]);

  const actions = useMemo(
    () => [
      { label: 'Cancel', onClick: onCancel },
      {
        label: 'Save',
        onClick: handleOnSave,
        loading: inFlight,
      },
    ],
    [inFlight, onCancel, handleOnSave],
  );

  function renderEditForm() {
    if (payment.activityType === 'OnlinePaymentTransaction') {
      return <EditCreditCardOnline payment={payment} onMount={(trigger) => (mountedHandler.current = trigger)} />;
    } else if (payment.activityType === 'PaymentCash') {
      return <EditCashPayment payment={payment} onMount={(trigger) => (mountedHandler.current = trigger)} />;
    } else if (payment.activityType === 'PaymentCheck') {
      return <EditCheckPayment payment={payment} onMount={(trigger) => (mountedHandler.current = trigger)} />;
    } else if (payment.activityType === 'PaymentCreditCardRecord') {
      return <EditCreditCardRecord payment={payment} onMount={(trigger) => (mountedHandler.current = trigger)} />;
    } else if (payment.activityType === 'PaymentCustom') {
      return <EditCustomPayment payment={payment} onMount={(trigger) => (mountedHandler.current = trigger)} />;
    }
    return null;
  }

  return (
    <Modal
      open
      color="grey"
      title="Edit Payment"
      onClose={onCancel}
      disabledInProcessing={inFlight}
      actions={actions}
      maxWidth="sm"
    >
      {renderEditForm()}
      <Box display="flex" flexWrap="wrap" className={classes.paymentCardImage}>
        {!!payment.fileSignatureId && (
          <Box maxWidth={300} mt={1}>
            <BodyBigText>Signature</BodyBigText>
            <AuthorizedImage imageId={payment.fileSignatureId} imageProps={{ alt: 'signature' }} />
          </Box>
        )}
        {!!payment.filePhotoId && (
          <Box maxWidth={300}>
            <BodyBigText>Photo</BodyBigText>
            <AuthorizedImage imageId={payment.filePhotoId} imageProps={{ alt: 'photo' }} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default EditPayment;
