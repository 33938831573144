import React, { ChangeEvent, useEffect, useState } from 'react';

import { BodyText, Order, OverridableValue } from '@elromcoinc/react-shared';
import { TableCell, TableRow } from '@material-ui/core';

import { ratePerDistanceProperty } from 'admin/components/OrderWindow/OrderWindowContants';
import { ViewSalesInfo } from 'admin/components/OrderWindow/blocks/MoveDetails/ViewSalesInfo';
import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderSettingUnits,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import { getServicePropertyName as getPropertyName } from 'admin/utils';

const resetDecimalManual = (property: OverridableValue) => {
  return property?.setManual(property?.manual ? +property?.manual || 0 : null);
};

export const RatePerDistanceUnitRow = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { serviceIndex } = useOrderServiceIndex();
  const { isCompleted, isLockSales } = useOrderClosingContext();
  const getServicePropertyName = useGetServicePropertyName();
  const { currentDistanceUnitLabel } = useOrderSettingUnits();
  const ratePerDistanceBaseName = getServicePropertyName('flatRateQuoteDetailConfig.ratePerDistanceBase');
  const ratePerDistanceBaseQuote = order.getIn(ratePerDistanceBaseName.split('.')) as OverridableValue;

  const salesRatePerDistanceBaseName = getPropertyName(serviceIndex, 'flatRateQuoteDetailConfig.ratePerDistanceBase');
  const salesRatePerDistanceBase = order.getIn(salesRatePerDistanceBaseName.split('.')) as OverridableValue;

  const [ratePerDistance, setRatePerDistance] = useState<OverridableValue>(ratePerDistanceBaseQuote);

  useEffect(() => {
    setRatePerDistance(ratePerDistanceBaseQuote);
  }, [ratePerDistanceBaseQuote]);

  const handleOnBlur =
    (value: OverridableValue | string | number, previousValue?: OverridableValue | string | number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name },
      } = event;

      if (value !== previousValue) {
        onChange({ name, value });
      }
    };

  const name = getServicePropertyName(ratePerDistanceProperty);

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>{`Rate per ${currentDistanceUnitLabel}`}</BodyText>
      </TableCell>
      <ValueTableCell>
        <FlexWithNegativeMargin $manual={ratePerDistance?.manual != null}>
          <CurrencyInput
            disabled={isCompleted || isLockSales}
            fullWidth={false}
            onChange={(value: string) => {
              setRatePerDistance(ratePerDistance.setManual(value !== '' ? (value as any) : null));
            }}
            value={ratePerDistance?.value() ?? 0}
            name={name}
            onBlur={handleOnBlur(resetDecimalManual(ratePerDistance))}
          />
        </FlexWithNegativeMargin>
      </ValueTableCell>
      <ViewSalesInfo label={`$${salesRatePerDistanceBase?.value() ?? 0}`} />
    </TableRow>
  );
};
