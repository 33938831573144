import { ReactNode, useState } from 'react';

import { OrderWindowSalesInfoContext } from 'admin/components/OrderWindow/context/OrderWindowSalesInfoContext';

export const OrderWindowSalesInfoProvider = ({ children }: { children: ReactNode }) => {
  const [viewMoveDetailsSalesInfo, setViewMoveDetailsSalesInfo] = useState(false);
  const [viewQuoteDetailsSalesInfo, setViewQuoteDetailsSalesInfo] = useState(false);

  return (
    <OrderWindowSalesInfoContext.Provider
      value={{
        viewMoveDetailsSalesInfo,
        setViewMoveDetailsSalesInfo,
        viewQuoteDetailsSalesInfo,
        setViewQuoteDetailsSalesInfo,
      }}
    >
      {children}
    </OrderWindowSalesInfoContext.Provider>
  );
};
