import React from 'react';

import { UI_TIME_FORMAT, toDateString } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { CrewLogStatus, StatusesInformation } from 'admin/components/CrewStatusLog/types';
import {
  ArrivedIcon,
  CompleteUnloadingIcon,
  InRouteIcon,
  JobCompletedIcon,
  JobPaidIcon,
  LoadingIcon,
  OnTheWayIcon,
  UnloadingIcon,
} from 'common/components/icons';

import { IconWithTitle } from './IconWithTitle';

const jobStatusTitle = {
  [CrewLogStatus.EN_ROUTE]: 'En route',
  [CrewLogStatus.ARRIVED]: 'Arrived',
  [CrewLogStatus.LOADING]: 'Loading',
  [CrewLogStatus.TO_DESTINATION]: 'On the way',
  [CrewLogStatus.UNLOADING]: 'Unloading',
  [CrewLogStatus.UNLOADING_STARTED]: 'Unloading',
  [CrewLogStatus.UNLOADING_COMPLETED]: 'Unloading',
  [CrewLogStatus.PAYMENT]: 'Payment',
  [CrewLogStatus.COMPLETED]: 'Completed',
};

const jobStatusesIcon = {
  [CrewLogStatus.EN_ROUTE]: <InRouteIcon fontSize="inherit" />,
  [CrewLogStatus.ARRIVED]: <ArrivedIcon fontSize="inherit" />,
  [CrewLogStatus.LOADING]: <LoadingIcon fontSize="inherit" />,
  [CrewLogStatus.TO_DESTINATION]: <OnTheWayIcon fontSize="inherit" />,
  [CrewLogStatus.UNLOADING]: <UnloadingIcon fontSize="inherit" />,
  [CrewLogStatus.UNLOADING_STARTED]: <UnloadingIcon fontSize="inherit" />,
  [CrewLogStatus.UNLOADING_COMPLETED]: <CompleteUnloadingIcon fontSize="inherit" />,
  [CrewLogStatus.PAYMENT]: <JobPaidIcon fontSize="inherit" />,
  [CrewLogStatus.COMPLETED]: <JobCompletedIcon fontSize="inherit" />,
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    height: '100%',
  },
}));

interface IconGroupsProps {
  statuses: StatusesInformation[];
  widthCoefficient: number;
  startDayOffsetInMinutes: number;
  whiteIcons: boolean;
}

export const IconGroups = ({ statuses, widthCoefficient, startDayOffsetInMinutes, whiteIcons }: IconGroupsProps) => {
  const classes = useStyles();
  const offsetTotal = statuses.map(
    (statusInformation) => (statusInformation.time - startDayOffsetInMinutes) * widthCoefficient,
  );
  const offsetAverage = offsetTotal.reduce((acc, cur) => acc + cur, 0) / offsetTotal.length;

  return (
    <Box left={offsetAverage} className={clsx(classes.root)}>
      {statuses.map((statusInformation, index) => (
        <IconWithTitle
          key={`${statusInformation.status}-${widthCoefficient * statusInformation.time}`}
          title={`${statusInformation.title || ''} ${
            jobStatusTitle[statusInformation.status as keyof typeof jobStatusTitle]
          }: ${toDateString(statusInformation.date!, UI_TIME_FORMAT)}`}
          lineOffsetLeft={offsetTotal[index] - offsetAverage}
          zIndex={index + 1}
          index={index}
          status={statusInformation.status}
          length={statuses.length}
          whiteIcons={whiteIcons}
        >
          {jobStatusesIcon[statusInformation.status as keyof typeof jobStatusesIcon]}
        </IconWithTitle>
      ))}
    </Box>
  );
};
