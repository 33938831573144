import { ActivitySourceType } from '@elromcoinc/react-shared';
import axios from 'axios';

import { AutomationRule, AutomationRuleConfigurationDto, BaseTemplateDTO, BlockDTO, TemplateDTO } from 'common-types';
import AutomationRuleName from 'common-types/template-block/AutomationRuleName';

const emailNotificationName = 'New Email notification';
const smsNotificationName = 'New Sms notification';
class TemplateApi {
  getAllBlocks() {
    return axios.get<BlockDTO[], BlockDTO[]>('/api/communications/templateBlock/all');
  }

  createBlock(block: BlockDTO) {
    return axios.put<BlockDTO, BlockDTO>('/api/communications/templateBlock', block);
  }

  deleteBlock(id: number) {
    return axios.delete(`/api/communications/templateBlock/${id}`);
  }

  getTemplatesByRules(automationRules: AutomationRuleName[]) {
    return axios.get<TemplateDTO[], TemplateDTO[]>('/api/communications/template/all', { params: { automationRules } });
  }

  async getAllTemplatesRecursively(
    pageIndex = 0,
    pageSize = 50,
    accumulatedTemplates: TemplateDTO[] = [],
  ): Promise<TemplateDTO[]> {
    const page = await axios.get<PageDTO<TemplateDTO>, PageDTO<TemplateDTO>>(
      '/api/communications/template/all-pageable',
      {
        params: { pageIndex, pageSize },
      },
    );

    const templates = accumulatedTemplates.concat(page.pageElements);

    if (page.pageElements.length < pageSize) {
      return templates;
    }

    return this.getAllTemplatesRecursively(pageIndex + 1, pageSize, templates);
  }

  getAllFullTemplates(page = 0, pageSize = 50) {
    return this.getAllTemplatesRecursively(page, pageSize).then((templates) =>
      templates.filter(
        (message) =>
          !message.name.toLowerCase().includes(emailNotificationName) &&
          !message.name.toLowerCase().includes(smsNotificationName),
      ),
    );
  }

  getAllTemplates() {
    return axios
      .get<BaseTemplateDTO, BaseTemplateDTO[]>('/api/communications/template/base-all')
      .then((templates) =>
        templates.filter(
          (message) =>
            !message.name.toLowerCase().includes(emailNotificationName) &&
            !message.name.toLowerCase().includes(smsNotificationName),
        ),
      );
  }

  createTemplate(template: TemplateDTO) {
    return axios.post<TemplateDTO, TemplateDTO>('/api/communications/template', template);
  }

  updateTemplate(template: TemplateDTO) {
    return axios.put<TemplateDTO, TemplateDTO>('/api/communications/template', template);
  }

  getTemplate(templateId: number) {
    return axios.get<TemplateDTO, TemplateDTO>(`/api/communications/template/${templateId}`);
  }

  setTemplateFolder(templateId: number, folderId: number | null) {
    return axios.put<TemplateDTO, TemplateDTO>(`/api/communications/template/${templateId}/folder/${folderId}`, {});
  }

  setActiveTemplate(templateId: number, active: boolean) {
    return axios.put<TemplateDTO, TemplateDTO>(`/api/communications/template/${templateId}/active/${active}`, {});
  }

  updateTemplates(templates: TemplateDTO[]) {
    return axios.put<TemplateDTO[], TemplateDTO[]>('/api/communications/template/all', templates);
  }

  deleteTemplate(templateId: number) {
    return axios.delete(`/api/communications/template/${templateId}`);
  }

  preview(sourceEntityId: number, activitySourceType: ActivitySourceType, template: TemplateDTO) {
    return axios.post<string, string>(
      `/api/moving/activitySourceType/${activitySourceType}/entity/${sourceEntityId}/templatePreview`,
      template,
    );
  }

  saveTemplateFolder(templateFolder: TemplateFolder) {
    return axios.post<TemplateFolder, TemplateFolder>('/api/communications/templateFolder', templateFolder);
  }

  deleteTemplateFolder(templateFolderId: number) {
    return axios.delete(`/api/communications/templateFolder/${templateFolderId}`);
  }

  getAutomationRules() {
    return axios
      .get<AutomationRule[], AutomationRule[]>('/api/communications/automationRules')
      .then((rules) => rules.filter((rule) => !rule.shortName.includes('JUNK_REMOVAL')));
  }

  getAutomationRuleConfigurations<T = AutomationRuleConfigurationDto[]>() {
    return axios.get<T, T>('/api/communications/automationRuleConfiguration');
  }

  saveAutomationRuleConfiguration<T = AutomationRuleConfigurationDto>(configuration: AutomationRuleConfigurationDto) {
    const url = '/api/communications/automationRuleConfiguration';
    return configuration.id ? axios.put<T, T>(url, configuration) : axios.post<T, T>(url, configuration);
  }

  deleteAutomationRuleConfiguration(id: number) {
    return axios.delete(`/api/communications/automationRuleConfiguration/${id}`);
  }
}

export default new TemplateApi();
