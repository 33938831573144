import { ChangeEvent, useEffect, useState } from 'react';

import { SettingName } from '@elromcoinc/moveboard-setting-react';
import {
  MoveType,
  Order,
  SizingStrategy,
  getMoveSizeMainDesc,
  getMoveSizeSubDescAsArray,
  isLongDistanceService,
  isMoveSizeDescriptionsAreEqual,
  longDistanceMethods,
} from '@elromcoinc/react-shared';
import CustomIcon from '@material-ui/icons/BorderColor';
import DefaultIcon from '@material-ui/icons/Home';
import InventoryIcon from '@material-ui/icons/Weekend';
import { List, Map, Set } from 'immutable';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { getMoveSizes, getRooms } from 'admin/selectors';
import { roundTo2 } from 'admin/utils';
import { UnitSizeSpec } from 'common-types';

const { UNIT_SIZE_SPEC } = SettingName;

type MoveSizeUnitType = 'CUBIC_FEET' | 'POUNDS';
const iconsByType = {
  [SizingStrategy.MOVE_SIZING]: DefaultIcon,
  [SizingStrategy.INVENTORY_SIZING]: InventoryIcon,
  [SizingStrategy.EMPLOYEE_OVERRIDE_SIZING]: CustomIcon,
};
const cuFtToWeight = (cuFt: number, cuFtToPounds: number) => roundTo2(+cuFt * +cuFtToPounds);
const weightToCuFt = (lbs: number, cuFtToPounds: number) => Math.round(+lbs / +cuFtToPounds);
const toSizeWeight = (activeCuFt: number, activeWeight: number) => ({
  activeCuFt: +activeCuFt,
  activeWeight: +activeWeight,
});

const getMoveSizeAndDescription = (site?: UnitSizeSpec, roomsMap?: Map<number, UnitSizeSpec>): [number, string] => {
  if (!site || !roomsMap) {
    return [0, ''];
  }

  const roomNames = roomsMap
    .valueSeq()
    .map((room) => room.name)
    .sort()
    .join(', ');

  const roomsSize = roomsMap
    .valueSeq()
    .map((room) => +room.size || 0)
    .reduce((sum, size) => sum + size, 0);

  const moveSize = (site.size || 0) + roomsSize;

  return [moveSize, `${site.name || ''}${roomNames ? ` (${roomNames})` : ''}`];
};

const moveSizeBased = (moveSize: number, cuFtToPounds: number, unit: MoveSizeUnitType) => {
  const isCuFt = unit === 'CUBIC_FEET';
  return isCuFt ? [moveSize, cuFtToWeight(moveSize, cuFtToPounds)] : [weightToCuFt(moveSize, cuFtToPounds), moveSize];
};

const defaultSiteSettings = Object.keys(MoveType).reduce(
  (map, type) => map.set(type as MoveType, Map<number, UnitSizeSpec>()),
  Map() as Map<MoveType, Map<number, UnitSizeSpec>>,
);
const defaultRoomSettings = Object.keys(MoveType).reduce(
  (map, type) => map.set(type as MoveType, List()),
  Map() as Map<MoveType, List<UnitSizeSpec>>,
);

const useOrderSizing = (order: Order) => {
  const { isClosing } = useOrderClosingContext();
  const settings = useOrderWindowSettings();
  const siteName = getMoveSizeMainDesc(order.sizeDescription);
  const [sitesSettings, setSitesSettings] = useState(defaultSiteSettings);
  const [roomsSettings, setRoomsSettings] = useState(defaultRoomSettings);
  const [currentSite, setCurrentSite] = useState<UnitSizeSpec | undefined>(undefined);
  const [currentRooms, setCurrentRooms] = useState(Map<number, UnitSizeSpec>());
  const [siteOptions, setSiteOptions] = useState<SelectOptions>([]);
  const [overriddenCuFt, setOverriddenCuFt] = useState(order.overriddenCuFt || null);
  const [overriddenWeight, setOverriddenWeight] = useState(order.overriddenWeight || null);
  const currentManualCuft = (order.closingOrderDetail?.manualActiveCuFt ?? order.activeCuFt) || null;
  const [manualCuFt, setManualCuFt] = useState<number | null>(currentManualCuft);
  const currentManualWeight = (order.closingOrderDetail?.manualActiveWeight ?? order.activeWeight) || null;
  const [manualWeight, setManualWeight] = useState<number | null>(currentManualWeight);
  const [cuFtToPounds, setCuFtToPoundsConversion] = useState(order.cuFtToPounds);
  const [sizeType, setSizeType] = useState(order.sizingStrategy);
  const [moveUnit, setMoveUnit] = useState(order.moveUnit);
  const isLongDistanceMileageTariff =
    isLongDistanceService(order?.getServiceType()) &&
    order?.getServiceQuote()?.longDistanceTariffDetails?.calculationMethod === longDistanceMethods.MILEAGE;

  useEffect(() => {
    setMoveUnit(order.moveUnit);
  }, [order.moveUnit]);

  useEffect(() => {
    setOverriddenCuFt((oldValue) => (order.overriddenCuFt !== oldValue ? order.overriddenCuFt : oldValue));
    setOverriddenWeight((oldValue) => (order.overriddenWeight !== oldValue ? order.overriddenWeight : oldValue));
    setCuFtToPoundsConversion((oldValue) => (order.cuFtToPounds !== oldValue ? order.cuFtToPounds : oldValue));
    setSizeType((oldValue: SizingStrategy) => (order.sizingStrategy !== oldValue ? order.sizingStrategy : oldValue));
  }, [order.overriddenCuFt, order.overriddenWeight, order.cuFtToPounds, order.sizingStrategy]);

  useEffect(() => {
    setManualCuFt(currentManualCuft);
    setManualWeight(currentManualWeight);
  }, [currentManualCuft, currentManualWeight]);

  useEffect(() => {
    setSitesSettings(getMoveSizes(settings));
    setRoomsSettings(getRooms(settings));
  }, [settings]);

  useEffect(() => {
    const roomNames = Set(getMoveSizeSubDescAsArray(order.sizeDescription));
    const sitesByName = sitesSettings.get(order.moveType, Map<number, UnitSizeSpec>()).mapKeys((_, site) => site.name);
    setCurrentRooms(
      Map(
        roomsSettings
          .get(order.moveType, List() as List<UnitSizeSpec>)
          .filter((room) => roomNames.has(room.name))
          .map((room) => [room.id, room]),
      ),
    );
    if (sitesByName.has(siteName)) {
      setCurrentSite(sitesByName.get(siteName));
    }
  }, [sitesSettings, roomsSettings, order.sizeDescription]);

  useEffect(() => {
    const currentSizeOptions: SelectOptions =
      settings?.[UNIT_SIZE_SPEC]?.filter((it) => it.moveUnitType === 'SITE' && it.moveType === order.moveType)?.map(
        (it) => [it.id, it.name],
      ) ?? [];

    setSiteOptions(currentSizeOptions);

    if (currentSite && !sitesSettings.get(order.moveType, Map()).has(currentSite.id)) {
      setCurrentSite(sitesSettings.get(order.moveType, Map<number, UnitSizeSpec>()).first());
      setCurrentRooms(Map());
    }
  }, [sitesSettings, order.moveType]);

  const activeCuFt = order.activeCuFt || 0;
  const activeWeight = order.activeWeight || 0;

  const { inventoryCuFt, inventoryPounds: inventoryWeight } = order.inventoryTotals;
  const [moveSizeCuFt, moveSizeWeight] = moveSizeBased(order.moveSize, cuFtToPounds, moveUnit);

  const getActiveSizeWeight = () => {
    if (isClosing && isLongDistanceMileageTariff) {
      return toSizeWeight(
        order.closingOrderDetail?.manualActiveCuFt || activeCuFt || 0,
        order.closingOrderDetail?.manualActiveWeight || activeWeight || 0,
      );
    }

    const isCuFt = moveUnit === 'CUBIC_FEET';
    const [updatedMoveSize, updatedSizeDescription] = getMoveSizeAndDescription(currentSite!, currentRooms);

    if (
      SizingStrategy.MOVE_SIZING === sizeType &&
      !isMoveSizeDescriptionsAreEqual(order.sizeDescription, updatedSizeDescription) &&
      !!currentSite
    ) {
      const [moveSizeCuFt, moveSizeWeight] = moveSizeBased(updatedMoveSize, cuFtToPounds, moveUnit);
      return toSizeWeight(moveSizeCuFt, moveSizeWeight);
    }
    switch (sizeType) {
      case SizingStrategy.MOVE_SIZING:
        const [moveSizeCuFt, moveSizeWeight] = moveSizeBased(updatedMoveSize, cuFtToPounds, moveUnit);
        return toSizeWeight(moveSizeCuFt, moveSizeWeight);
      case SizingStrategy.INVENTORY_SIZING:
        return toSizeWeight(inventoryCuFt, inventoryWeight || activeWeight);
      case SizingStrategy.EMPLOYEE_OVERRIDE_SIZING:
        return toSizeWeight(
          overriddenCuFt || (isCuFt ? order.moveSize : weightToCuFt(order.moveSize, cuFtToPounds)),
          overriddenWeight || (isCuFt ? cuFtToWeight(overriddenCuFt || order.moveSize, cuFtToPounds) : order.moveSize),
        );
      default:
        return toSizeWeight(activeCuFt, activeWeight);
    }
  };

  const setCurrentMoveSite = (siteId: number) => {
    const newSite = sitesSettings.get(order.moveType, Map<number, UnitSizeSpec>()).get(siteId, null);

    if (!newSite) {
      return getMoveSizeAndDescription();
    }

    setCurrentSite(newSite);
    const allRooms = roomsSettings.get(order.moveType, List<UnitSizeSpec>());
    const newCurrentRooms = Map<number, UnitSizeSpec>(
      (newSite.tags as UnitSizeSpec[])
        .filter((r) => r.isDefault)
        .map((room) => [room.id, allRooms.filter((it) => it.id === room.id).first()]),
    );
    setCurrentRooms(newCurrentRooms);

    return getMoveSizeAndDescription(newSite, newCurrentRooms);
  };

  const toggleCurrentRooms = (room: UnitSizeSpec) => (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;
    setCurrentRooms((rooms) => (checked ? rooms.set(room.id, room) : rooms.delete(room.id)));
  };

  return {
    moveUnit,
    siteName,
    sizeType,
    setSizeType,
    moveSizeCuFt,
    moveSizeWeight,
    ...getActiveSizeWeight(),
    inventoryCuFt,
    moveSize: order.moveSize,
    inventoryWeight,
    iconsByType,
    overriddenCuFt,
    setOverriddenCuFt,
    overriddenWeight,
    setOverriddenWeight,
    cuFtToPounds,
    setCuFtToPoundsConversion,
    siteOptions,
    currentSite,
    currentRooms,
    setCurrentRooms,
    setCurrentMoveSite,
    toggleCurrentRooms,
    getMoveSizeAndDescription: () => getMoveSizeAndDescription(currentSite!, currentRooms),
    roomOptions: roomsSettings.get(order.moveType, List<UnitSizeSpec>()).sortBy((it) => it.name),
    manualCuFt,
    setManualCuFt,
    manualWeight,
    setManualWeight,
  };
};

export default useOrderSizing;
