import React from 'react';

import { BodyText, IconButton } from '@elromcoinc/react-shared';
import { createStyles, makeStyles } from '@material-ui/core';

import { useOrderServiceIndex } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: '2rem',
      height: '2rem',
    },
  }),
);

interface DayButtonProps {
  index: number;
  serviceIndex: number;
}

export const DayButton = ({ index, serviceIndex }: DayButtonProps) => {
  const classes = useStyles();
  const { selectedServiceIndex } = useOrderServiceIndex();

  return (
    <IconButton
      color={index === selectedServiceIndex ? 'primary' : 'default'}
      variant="default"
      size="small"
      className={classes.button}
    >
      <BodyText data-testId={`dayNumber-${serviceIndex}`}>{serviceIndex + 1}</BodyText>
    </IconButton>
  );
};
