import { FC } from 'react';

import { ActivitySourceType } from '@elromcoinc/react-shared';

import { ActivityLogProvider } from 'admin/components/OrderWindow/blocks/ActivityManager/ActivityLogProvider';
import GoogleApiProvider from 'admin/components/OrderWindow/context/GoogleApiProvider';
import { OrderClosingProvider } from 'admin/components/OrderWindow/context/OrderClosingProvider';
import { OrderComposeEmailProvider } from 'admin/components/OrderWindow/context/OrderComposeEmailProvider';
import { OrderSchedulerManagerProvider } from 'admin/components/OrderWindow/context/OrderSchedulerManagerProvider';
import { OrderServiceIndexProvider } from 'admin/components/OrderWindow/context/OrderServiceIndexProvider';
import { OrderSettingUnitsProvider } from 'admin/components/OrderWindow/context/OrderSettingUnitsProvider';
import { OrderStateProvider } from 'admin/components/OrderWindow/context/OrderStateProvider';
import { OrderWindowEditAddressIndexProvider } from 'admin/components/OrderWindow/context/OrderWindowEditAddressIndexProvider';
import { OrderWindowEditModalProvider } from 'admin/components/OrderWindow/context/OrderWindowEditModalProvider';
import { OrderWindowSalesInfoProvider } from 'admin/components/OrderWindow/context/OrderWindowSalesInfoProvider';
import { PaymentClientKeyProvider } from 'admin/components/OrderWindow/context/PaymentClientKeyProvider';
import { PayrollProvider } from 'admin/components/OrderWindow/context/PayrollProvider';
import { SendingTemplatesProvider } from 'admin/components/OrderWindow/context/SendingTemplatesProvider';
import { UnreadMessagesProvider } from 'admin/components/OrderWindow/context/UnreadMessagesProvider';
import { UpdateOrderContextProvider } from 'admin/components/OrderWindow/context/UpdateOrderContextProvider';
import { ValidateStorageOnBeProvider } from 'admin/components/OrderWindow/context/ValidateStorageOnBeProvider';

import { UnsubscribeMessageProvider } from './UnsubscribeMessageProvider';

interface OrderWindowProviderProps {
  sourceId: number;
}

const OrderWindowProvider: FC<OrderWindowProviderProps> = ({ children, sourceId }) => {
  return (
    <OrderStateProvider>
      <PayrollProvider>
        <GoogleApiProvider>
          <OrderSchedulerManagerProvider>
            <UnsubscribeMessageProvider>
              <OrderComposeEmailProvider>
                <SendingTemplatesProvider>
                  <UpdateOrderContextProvider>
                    <PaymentClientKeyProvider>
                      <OrderWindowEditAddressIndexProvider>
                        <UnreadMessagesProvider sourceId={sourceId} activitySource={ActivitySourceType.ORDER}>
                          <OrderServiceIndexProvider>
                            <OrderSettingUnitsProvider>
                              <OrderClosingProvider>
                                <ActivityLogProvider sourceId={sourceId} activitySource={ActivitySourceType.ORDER}>
                                  <OrderWindowEditModalProvider>
                                    <OrderWindowSalesInfoProvider>
                                      <ValidateStorageOnBeProvider>{children}</ValidateStorageOnBeProvider>
                                    </OrderWindowSalesInfoProvider>
                                  </OrderWindowEditModalProvider>
                                </ActivityLogProvider>
                              </OrderClosingProvider>
                            </OrderSettingUnitsProvider>
                          </OrderServiceIndexProvider>
                        </UnreadMessagesProvider>
                      </OrderWindowEditAddressIndexProvider>
                    </PaymentClientKeyProvider>
                  </UpdateOrderContextProvider>
                </SendingTemplatesProvider>
              </OrderComposeEmailProvider>
            </UnsubscribeMessageProvider>
          </OrderSchedulerManagerProvider>
        </GoogleApiProvider>
      </PayrollProvider>
    </OrderStateProvider>
  );
};

export { OrderWindowProvider };
