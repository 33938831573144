import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { GeneralService } from '@elromcoinc/moveboard-setting-react';
import { BACKEND_DATE_FORMAT, Order, SettingNames, Truck } from '@elromcoinc/react-shared';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { List } from 'immutable';

import settingsAPI from 'admin/api/SettingsAPI';
import truckApi from 'admin/api/truckApi';
import Job from 'admin/components/OrderWindow/SchedulerBox/Job';
import { calculateVerticalLevel } from 'admin/components/OrderWindow/SchedulerBox/common';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';

const { GENERAL_SERVICES } = SettingNames;

const parseJob = (settings: { GeneralService: GeneralService[] }) => (otherJobs: List<Job>, job: Job) => {
  const services = settings[GENERAL_SERVICES] || [];
  const color = (services.filter((s) => s.name === job.serviceType)[0] || {}).rgbColor;
  return otherJobs.push(Job.create(job, color));
};

interface SchedulerContextProps {
  trucks: Truck[];
  jobs: Job[];
  fetchJobs: (dayMode: boolean, date: Date) => () => void;
  fetchTrucks: (dayMode: boolean, date: Date) => () => void;
  dayMode: boolean;
  setDayMode: Dispatch<SetStateAction<boolean>>;
  isLoadingJobs: boolean;
  isLoadingTrucks: boolean;
  isDeliveryMode: boolean;
  setIsDeliveryMode: Dispatch<SetStateAction<boolean>>;
}

const SchedulerContext = createContext<SchedulerContextProps>({} as SchedulerContextProps);
export const useSchedulerContext = () => useContext(SchedulerContext);

const fetchTruckInfo = async (dayMode: boolean, date: Date) => {
  const trucks = (await truckApi.getTrucks()).filter((it) => !it.showOnlyOnDispatch).map(Truck.createTruck);
  const start = format(dayMode ? date : startOfWeek(date), BACKEND_DATE_FORMAT);
  const end = format(dayMode ? date : endOfWeek(date), BACKEND_DATE_FORMAT);
  const unavailabilityInfo = await truckApi.trucksUnavailableDatesForGivenDateRange(start, end);
  return trucks.map((it) => (unavailabilityInfo[it.id!] ? it.setUnavailability(unavailabilityInfo[it.id!]) : it));
};

interface SchedulerManagerProps {
  originalOrder?: Order;
}

let cachedTrucks: Truck[] = [];

// need to refactor this component with improving performance
const SchedulerManager: FC<SchedulerManagerProps> = ({ children, originalOrder }) => {
  const windowSettings = useOrderWindowSettings();
  const [generalServices, setGeneralServices] = useState<GeneralService[]>([]);
  const settings = {
    [GENERAL_SERVICES]: (windowSettings[GENERAL_SERVICES] || generalServices) as GeneralService[],
  };
  const [jobs, setJobs] = useState<Job[]>([]);
  const [trucks, setTrucks] = useState<Truck[]>(cachedTrucks);
  const [dayMode, setDayMode] = useState(true);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const [isLoadingTrucks, setIsLoadingTrucks] = useState(false);
  const [isDeliveryMode, setIsDeliveryMode] = useState(false);
  const jobsRef = useRef<CancelablePromise<Job[]> | null>(null);

  useEffect(() => {
    if (!windowSettings[GENERAL_SERVICES] && !originalOrder) {
      settingsAPI.getSettingNode<{ GeneralService: GeneralService[] }>(GENERAL_SERVICES).then((data) => {
        setGeneralServices(data[GENERAL_SERVICES] || []);
      });
    }
  }, [windowSettings[GENERAL_SERVICES], originalOrder]);

  const fetchJobs = useCallback(
    (dayMode, date) => () => {
      setJobs([]);
      setIsLoadingJobs(true);

      if (jobsRef.current) {
        jobsRef.current.cancel();
      }

      jobsRef.current = truckApi.getJobs(dayMode ? date : startOfWeek(date), dayMode ? date : endOfWeek(date));

      jobsRef.current?.promise
        .then((it) => {
          return it.reduce<List<Job>>(parseJob(settings), List()).toArray();
        })
        .then(calculateVerticalLevel(trucks))
        .then(setJobs)
        .then(() => setIsLoadingJobs(false));
    },
    [settings[GENERAL_SERVICES], trucks],
  );

  const fetchTrucks = useCallback(
    (dayMode, date) => () => {
      setIsLoadingTrucks(true);
      fetchTruckInfo(dayMode, date)
        .then((t) => {
          cachedTrucks = t;
          setTrucks(t);
        })
        .catch(() => false)
        .then(() => setIsLoadingTrucks(false));
    },
    [],
  );

  return (
    <SchedulerContext.Provider
      value={{
        trucks,
        jobs,
        fetchJobs,
        fetchTrucks,
        dayMode,
        setDayMode,
        isLoadingJobs,
        isLoadingTrucks,
        isDeliveryMode,
        setIsDeliveryMode,
      }}
    >
      {children}
    </SchedulerContext.Provider>
  );
};

export default SchedulerManager;
