import React from 'react';

import { BodyText, Duration, Order, Select, TravelTimeManagementConfig } from '@elromcoinc/react-shared';
import { MenuItem, TableCell, TableRow, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { ViewSalesInfo } from 'admin/components/OrderWindow/blocks/MoveDetails/ViewSalesInfo';
import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import useDurations from 'admin/components/OrderWindow/hooks/useDurations';
import settingName from 'admin/constants/SettingName';
import { getServicePropertyName as getPropertyName, getServiceClosingPropertyName } from 'admin/utils';

const useStyles = makeStyles(() =>
  createStyles({
    displayIcon: {
      display: 'block !important',
    },
  }),
);

const propertyName = 'finalBillableTravelTimeForQuote';
const propertyNameOriginalSecounds = `${propertyName}.originalSeconds`;

export const TravelTimeRow = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex } = useOrderServiceIndex();
  const { onChange } = useOrderChangeSet();
  const settings = useOrderWindowSettings();
  const { isClosing, isCompleted, isLockSales } = useOrderClosingContext();
  const getServicePropertyName = useGetServicePropertyName();
  const [, travelTimeDuration15mins] = useDurations(null, 6);
  const doubleDrivingTimeEnabledName = isClosing
    ? getServiceClosingPropertyName(serviceIndex, 'doubleDrivingTimeEnabled')
    : getServicePropertyName('doubleDrivingTimeEnabled');
  const doubleDrivingTimeEnabled = order.getIn(doubleDrivingTimeEnabledName.split('.')) as boolean;
  const adjustedTravelTimeName = getServicePropertyName('adjustedTravelTime', '', false);
  const travelTimeManagementConfigName = getServicePropertyName('travelTimeManagementConfig');
  const travelTimeManagementConfig = order.getIn(
    travelTimeManagementConfigName.split('.'),
  ) as TravelTimeManagementConfig;
  const adjustedTravelTime = isClosing
    ? null
    : (order.getIn(adjustedTravelTimeName.split('.')) as Duration).getRoundedSeconds();

  const salesTravelTimeName = getPropertyName(serviceIndex, propertyName);
  const salesTravelTime = order.getIn(salesTravelTimeName.split('.')) as Duration;
  const getSalesTravelTimeLabel = travelTimeDuration15mins.filter(
    ([value]) => value === salesTravelTime?.getRoundedSeconds(),
  )?.[0]?.[1];

  // if double driving time is enabled, we need to show the closing travel time
  const name = isClosing
    ? getServiceClosingPropertyName(serviceIndex, propertyNameOriginalSecounds)
    : getServicePropertyName(propertyNameOriginalSecounds);
  const finalBillableTravelTimeForQuoteName = isClosing
    ? getServiceClosingPropertyName(serviceIndex, propertyName)
    : getServicePropertyName(propertyName);
  const finalBillableTravelTimeForQuote = order.getIn(finalBillableTravelTimeForQuoteName.split('.')) as Duration;

  const handleChangeTravelTime = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    onChange({
      name: event.target.name as string,
      value: event.target.value as number,
    });
  };

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>{settings[settingName.TRAVEL_TIME_LABEL]}</BodyText>
      </TableCell>
      <ValueTableCell>
        <FlexWithNegativeMargin>
          <Select
            value={finalBillableTravelTimeForQuote?.getRoundedSeconds()}
            hiddenLabel
            InputProps={{
              disableUnderline: true,
            }}
            name={name}
            onChange={handleChangeTravelTime}
            disabled={doubleDrivingTimeEnabled || isCompleted || isLockSales}
          >
            {travelTimeDuration15mins.map(([value, label]) => (
              <MenuItem key={value} value={value}>
                <BodyText color={adjustedTravelTime !== null && value !== adjustedTravelTime ? 'error' : 'default'}>
                  <b>{label}</b>
                </BodyText>
              </MenuItem>
            ))}
          </Select>
          {!doubleDrivingTimeEnabled && (
            <Tooltip
              title={
                <ul>
                  {travelTimeManagementConfig?.calculationRules?.map((rule: string) => (
                    <li key={rule}>
                      <BodyText>{rule}</BodyText>
                    </li>
                  ))}
                </ul>
              }
            >
              <HelpOutlineIcon color="primary" classes={{ root: classes.displayIcon }} />
            </Tooltip>
          )}
        </FlexWithNegativeMargin>
      </ValueTableCell>
      <ViewSalesInfo label={getSalesTravelTimeLabel} />
    </TableRow>
  );
};
