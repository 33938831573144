import { toCamelCase } from 'common/utils';

export const TemplateBuilderTokens = [
  // 'ACCOUNT_BILL_OF_LANDING_RECEIPT_URL',
  // 'ACCOUNT_CONFIRMATION_PAGE_URL',
  // 'ACCOUNT_FOREMAN_URL',
  // 'ACCOUNT_MESSAGES_PAGE_URL',
  'ACCOUNT_PAGE_URL',
  // 'ACCOUNT_PROPOSAL_LINK_ADDITIONAL_PAGE',
  // 'ACCOUNT_PROPOSAL_LINK_MAIN_PAGE',
  // 'ACCOUNT_RECEIPT_URL',
  // 'ACCOUNT_REVIEW_URL',
  // 'ACCOUNT_REVIEW_URL_STARS',
  'ACCOUNT_SHORT_LINK',
  'COMPANY_EMAIL',
  'COMPANY_NAME',
  'COMPANY_LEGAL_NAME',
  'COMPANY_PHONE',
  'COMPANY_SITE_URL',
  'CUSTOMER_EMAIL',
  'CUSTOMER_FIRST_NAME',
  'CUSTOMER_LAST_NAME',
  'CUSTOMER_OTHER_PHONE',
  'CUSTOMER_PHONE',
  'FOREMAN_PAGE_URL',
  'CREW_SCHEDULE_REPORT_AT_TIME',
  'CREW_SCHEDULE_REPORT_AT_DATE',
  'INVOICE_ID',
  'INVOICE_ADDRESS',
  'INVOICE_CONTACT_EMAIL',
  'INVOICE_CONTACT_PHONE',
  'INVOICE_NUMBER',
  'INVOICE_CONTACT_FIRST_NAME',
  'INVOICE_CONTACT_LAST_NAME',
  'INVOICE_ISSUE_DATE',
  'INVOICE_STATUS',
  'INVOICE_TERMS',
  'INVOICE_DISCOUNT',
  'INVOICE_TAX',
  'INVOICE_PAID_AMOUNT',
  'INVOICE_BALANCE',
  'INVOICE_TOTAL',
  'INVOICE_LINK',
  'INVOICE_SHORT_LINK',
  'INVOICE_MESSAGE',
  // 'MESSAGE_SENDING_TIME',
  'ORDER_APARTMENT_FROM',
  'ORDER_APARTMENT_TO',
  // 'ORDER_BALANCE',
  // 'ORDER_CLOSING_ADDITIONAL_SERVICES',
  // 'ORDER_CLOSING_BALANCE',
  // 'ORDER_CLOSING_CUFT',
  // 'ORDER_CLOSING_DISCOUNT',
  // 'ORDER_CLOSING_FUEL',
  // 'ORDER_CLOSING_GRAND_TOTAL',
  // 'ORDER_CLOSING_PACKING',
  // 'ORDER_CLOSING_PAYMENTS',
  // 'ORDER_CONTRACT_PDF_URL',
  'ORDER_CONTACT_EMAIL',
  'ORDER_CONTACT_FIRST_NAME',
  'ORDER_CONTACT_LAST_NAME',
  'ORDER_CONTACT_OTHER_PHONE',
  'ORDER_CONTACT_PHONE',
  'ORDER_LABOR_QUOTE',
  'ORDER_CREATED_DATE',
  'ORDER_CREW_SIZE',
  'ORDER_CUBIC_FEET',
  'ORDER_CUSTOMER_NOTES',
  'ORDER_DISCOUNT',
  'ORDER_EMPLOYEE',
  'ORDER_ESTIMATED_QUOTE',
  'ORDER_ESTIMATED_JOB_TIME',
  'ORDER_EXTRA_SERVICES',
  'ORDER_FLAT_RATE_QUOTE',
  'ORDER_FOREMAN_NOTES',
  'ORDER_FUEL',
  'ORDER_GRAND_TOTAL',
  'ORDER_HOURLY_RATE',
  'ORDER_IN_HOME_ESTIMATE_SURVEY_DATE',
  'ORDER_IN_HOME_ESTIMATE_START_TIME_WINDOW_START',
  'ORDER_IN_HOME_ESTIMATE_START_TIME_WINDOW_END',
  'ORDER_IN_HOME_ESTIMATE_STATUS',
  'ORDER_IN_HOME_ESTIMATOR',
  'ORDER_VIRTUAL_SURVEY_START_TIME_WINDOW_START',
  'ORDER_VIRTUAL_SURVEY_START_TIME_WINDOW_END',
  'ORDER_VIRTUAL_SURVEY_SURVEY_DATE',
  'ORDER_VIRTUAL_SURVEY_STATUS',
  'ORDER_VIRTUAL_SURVEY_ESTIMATOR',
  'ORDER_LABOR_TIME',
  'ORDER_LD_AVAILABLE_DELIVERY_DATES',
  'ORDER_LD_MIN_CUFT',
  'ORDER_LIABILITY_AMOUNT',
  'ORDER_MINIMUM_HOURS',
  'ORDER_MOVE_DATE',
  'ORDER_MOVE_SIZE',
  'ORDER_NUMBER',
  'ORDER_NUMBER_OF_TRUCKS',
  'ORDER_ORIGIN_TO_DESTINATION_DISTANCE',
  'ORDER_PACKING',
  'ORDER_PAYMENTS_TOTAL',
  'ORDER_RATE_PER_UNIT',
  'ORDER_ROOMS',
  'ORDER_SALES_FIRST_NAME',
  'ORDER_SALES_FULL_NAME',
  'ORDER_SALES_NOTES',
  'ORDER_SERVICE_TYPE',
  'ORDER_SOURCE',
  'ORDER_START_WINDOW',
  'ORDER_START_WINDOW_MAX',
  'ORDER_START_WINDOW_MIN',
  'ORDER_TRAVEL_TIME',
  'ORDER_VALUATION',
  'ORDER_VALUATION_COST',
  'ORDER_VALUATION_DEDUCTIBLE',
  'ORDER_WAYPOINT_DESTINATION',
  'ORDER_WAYPOINT_ORIGIN',
  'ORDER_WAYPOINT_DESTINATION_ELEVATION',
  'ORDER_WAYPOINT_DESTINATION_PARKING_TO_ENTRANCE',
  'ORDER_WAYPOINT_ORIGIN_ELEVATION',
  'ORDER_WAYPOINT_ORIGIN_PARKING_TO_ENTRANCE',
  // 'SALES_SIGNATURE',
  // 'SALES_SIGNATURE_PHOTO',
  // 'STORAGE_STATEMENT_URL',
  // 'SETTINGS_ADDITIONAL_SERVICES_TABLE',
  // 'SETTINGS_VALUATION_TABLE',
  // 'ORDER_CLOSING_PAYMENTS_TABLE',
  'ORDER_PAYMENTS_TABLE',
  'ORDER_PACKING_TABLE',
  'ORDER_ADDITIONAL_SERVICES_TABLE',
  'ORDER_INVENTORY_TABLE_WITHOUT_CF',
  'ORDER_INVENTORY_TABLE_NOT_INCLUDING_NOT_SHIPPED_ITEMS',
  'ORDER_INVENTORY_TABLE_WITHOUT_CF_NOT_INCLUDING_NOT_SHIPPED_ITEMS',
  'ORDER_QUOTE_DETAILS',
  'ORDER_MOVE_DETAILS',
  'ORDER_VALUATION_TABLE',
  'ORDER_INVENTORY_TABLE',
  'NEW_NOTIFICATION',
  'ORDER_MOVE_TYPE',
  'ORDER_TRAVEL_RATE',
  'ORDER_MOVING_CHARGE',
  'ORDER_TRAVEL_TIME_CHARGE',
  'ORDER_ADDITIONAL_WAYPOINTS',
  'ORDER_FLAT_RATE_PER_DISTANCE',
  'ORDER_FLAT_RATE_PER_SIZE_UNIT',
  'ORDER_FLAT_RATE_CHARGE',
  'ORDER_SALES_PHONE',
  'ORDER_SALES_EMAIL',
  'CONVERSATION_MESSAGE_BODY',
  'ORDER_ADDITIONAL_FEES_TABLE',
  'ORDER_ADDITIONAL_FEES',
  'ORDER_OTHER_FEES',
  'ORDER_OTHER_FEES_TABLE',
  'ORDER_TOTAL_TAXES',
  'ORDER_TOTAL_SERVICE_TAXES',
  'ORDER_TOTAL_ADDITIONAL_SERVICES',
  // 'ORDER_TOTAL_BALANCE',
  'ORDER_TOTAL_DISCOUNT',
  'ORDER_TOTAL_ESTIMATED_QUOTE',
  'ORDER_TOTAL_ESTIMATED_JOB_TIME',
  'ORDER_TOTAL_FLAT_RATE_QUOTE',
  'ORDER_TOTAL_FLAT_RATE_CHARGE',
  'ORDER_TOTAL_TRAVEL_TIME_CHARGE',
  'ORDER_TOTAL_FUEL',
  'ORDER_TOTAL_GRAND_TOTAL',
  'ORDER_TOTAL_LABOR_QUOTE',
  'ORDER_TOTAL_LABOR_TIME',
  'ORDER_TOTAL_MOVING_CHARGE',
  'ORDER_TOTAL_PACKING',
  'ORDER_TOTAL_TRAVEL_TIME',
  'ORDER_ESTIMATED_DELIVERY_DATE_LONG_DISTANCE',
  'LINK_TO_SIGN_THE_BILL_OF_LADING_PRIOR_TO_START_JOB',
  'ORDER_HOURLY_RATE_PROMOTION',
  'ORDER_WEIGHT_RATE_PROMOTION',
  'ORDER_TOTAL_GRAND_TOTAL_PROMOTION',
  'ORDER_TOTAL_PROMOTION_AND_SAVINGS',
  'ORDER_TOTAL_GRAND_TOTAL_PROMOTION_AND_SAVINGS',
  'ORDER_TRAVEL_RATE_PROMOTION',
  'LINK_INVENTORY_PAGE',
  'LINK_ORDER_OF_SERVICE',
  'LINK_COMMUNICATIONS',
].map((token) => {
  const name = token.replace(/_/g, ' ').toLowerCase();
  return { token, name: `${name[0].toUpperCase()}${name.slice(1)}`, simpleToken: toCamelCase(token.toLowerCase()) };
});
