import { ActivitySourceType } from '@elromcoinc/react-shared';
import { FileDTO } from '@elromcoinc/react-shared';

import { emailAPI } from 'admin/api';
import communicationAPI from 'admin/api/CommunicationsAPI';
import fileAPI from 'admin/api/FileAPI';
import templateApi from 'admin/api/templateApi';
import { CommunicationModality, OrderInfo, Template } from 'common-types';
import { MessageSenderType } from 'common-types/MessageSenderType';

const sendMessagesToOrder = (
  order: OrderInfo,
  templates: Template[],
  fromEmployee: boolean,
  attachments?: FileDTO[],
) => {
  const templatesWithBody = templates.filter((t) => t.body);
  const { orderId, email, phoneNumber, activitySources } = order;

  if (!templatesWithBody.length && !attachments?.length) {
    return Promise.resolve();
  }

  return Promise.all(
    (!!attachments?.length ? templates : templatesWithBody).map((t) => {
      return templateApi
        .preview(orderId, ActivitySourceType.ORDER, t.toDTO({ useExistingBody: true }))
        .catch(() => t.body)
        .then((preview) => t.set('body', preview));
    }),
  ).then((response) => {
    response.forEach(async (template) => {
      if (template.modality === CommunicationModality.EMAIL && email) {
        const formData = new FormData();
        formData.append(
          'emailDto',
          new Blob(
            [
              JSON.stringify(
                template.toEmailDto({
                  subject: '',
                  recipients: [email],
                  ccList: [],
                  bccList: [],
                  body: '',
                  sourceId: orderId,
                  activitySource: ActivitySourceType.ORDER,
                  activitySources: activitySources,
                  senderType: MessageSenderType.DIRECT,
                  customerAddressed: true,
                  automationRuleId: template?.automationRuleId,
                }),
              ),
            ],
            {
              type: 'application/json',
            },
          ),
        );

        if (!!attachments?.length) {
          await Promise.all(
            attachments.map(async (pdf) => {
              const fileBlob = await fileAPI.downloadFile(pdf?.id);
              formData.append('attachments', fileBlob, pdf?.originalFileName || 'document.pdf');
            }),
          );
        }

        return fromEmployee ? emailAPI.sendEmailFromCurrentEmployee(formData) : emailAPI.sendEmailFromCompany(formData);
      }

      if (template.modality === CommunicationModality.SMS) {
        return communicationAPI.sendSms({
          sourceDescriptor: { referencedEntityId: orderId, activitySource: ActivitySourceType.ORDER },
          body: template.body,
          toPhone: phoneNumber,
          activitySources: activitySources,
          automationRuleId: template?.automationRuleId,
        });
      }
    });
  });
};

export { sendMessagesToOrder };
