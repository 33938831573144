import React, { FC, useState } from 'react';

import { Address } from '@elromcoinc/react-shared';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Map, Marker } from 'google-maps-react';

import { useGoogleApiContext } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    mapContent: {
      minWidth: '270px',
      minHeight: '370px',
      position: 'relative',
      width: '100%',
      height: '100%',
      [theme.breakpoints.between('sm', 'md')]: {
        minWidth: '310px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        minWidth: '370px',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '450px',
      },
      [theme.breakpoints.down('md')]: {
        height: '370px',
      },
    },
  }),
);

interface MapBarProps {
  position: Partial<Address>;
  label?: string;
}

const MapBar: FC<MapBarProps> = ({ position, label = '' }) => {
  const classes = useStyles();
  const { google, loadedGoogle } = useGoogleApiContext();
  const [mapReady, setMapReady] = useState(false);

  const handleMapReady = () => {
    setTimeout(() => {
      setMapReady(true);
    }, 500);
  };

  return (
    <Box className={classes.mapContent}>
      {!!position?.lat && !!position?.lng && loadedGoogle && google ? (
        <Map
          onReady={handleMapReady}
          // @ts-ignore
          mapTypeControl
          streetViewControl
          panControl
          mapType="hybrid"
          google={google!}
          zoom={17}
          containerStyle={{ width: '100%', height: '100%' }}
          // @ts-ignore
          center={mapReady ? position : {}}
        >
          {mapReady && (
            <Marker
              // @ts-ignore
              name="Test"
              // @ts-ignore
              position={position}
              label={label}
            />
          )}
        </Map>
      ) : (
        <Skeleton variant="rect" width="100%" height="100%" />
      )}
    </Box>
  );
};

export default MapBar;
