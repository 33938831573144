import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import {
  BodyText,
  Button,
  IconButton,
  Order,
  Phone,
  Switch,
  TextInput,
  UI_DATE_TIME_FORMAT,
  toDateString,
  useConfirm,
} from '@elromcoinc/react-shared';
import { Box, TableCell as LabelTableCell, TableBody, TableRow, Tooltip, makeStyles } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import FaceIcon from '@material-ui/icons/Face';
import { TargetElement } from '@testing-library/user-event';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import templateApi from 'admin/api/templateApi';
import { getCurrentEmployee } from 'admin/autodux/CurrentEmployeeAutodux';
import AccountLink from 'admin/components/OrderWindow/blocks/AccountLink';
import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import AddAdditionalContactsModal from 'admin/components/OrderWindow/blocks/AddAdditionalContactsModal';
import AddContact from 'admin/components/OrderWindow/blocks/AddContact';
import BestContact from 'admin/components/OrderWindow/blocks/BestContact';
import EmailWithValidation from 'admin/components/OrderWindow/blocks/EmailWithValidation';
import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { sendMessagesToOrder } from 'admin/components/OrderWindow/sendMessagesToOrder';
import {
  EMAIL_SERVER_SETTINGS,
  USE_OUT_GOING_EMAIL,
} from 'admin/components/Settings/components/Users/ContentPanels/UserFormConstants';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import ActivityType from 'admin/constants/ActivityType';
import { ConversationMessageType } from 'admin/constants/NotificationStatusDto';
import EmployeeRecord from 'admin/entities/Employee';
import { AccountEntity, AutomationRuleName, BaseTemplate, CommunicationModality, Template } from 'common-types';
import Card from 'common/components/Card';
import { FullWidthBox, OverviewInfoTable } from 'common/components/Widgets';

import { OrderForServiceModal } from '../modals';
import CustomerInfo from '../modals/CustomerInfo';
import { FlexWithNegativeMargin, ValueTableCell } from './CommonComponents';
import PhoneRow from './PhoneRow';
import ViewCustomerPageLink from './ViewCustomerPageLink';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  table: {
    '& td:first-child': {
      width: '100px!important',
    },
    '& tbody tr td': {
      height: theme.spacing(3),
    },
    '& tbody tr td input:not(.MuiSwitch-input), & tbody tr td .MuiSelect-root': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      marginTop: '0 !important',
      marginBottom: '0 !important',
    },
    '& tbody tr td .MuiSwitch-root': {
      marginTop: `${theme.spacing(-1)}px !important`,
      marginBottom: `${theme.spacing(-1)}px !important`,
    },
  },
  successColor: {
    color: theme.palette.success.main,
  },
  nonClickCursor: {
    cursor: 'initial',
  },
}));

const FIRST_NAME = 'contactInfo.firstName';
const LAST_NAME = 'contactInfo.lastName';
const ADDITIONAL_INFO = 'additionalInfo';
const validateFields = [FIRST_NAME, LAST_NAME];

enum MODALS {
  CUSTOMER_INFO,
  ORDER_FOR_SERVICE,
  ADDITIONAL_CONTACTS,
}

const validateRequired = (value: string, name: string) => (value ? null : `${name} Name is a required field`);
const validateMaxLength = (value: string, name: string) =>
  value.length > 32 ? `${name} Name must be at most 32 characters` : null;
const validateMinLength = (value: string, name: string) =>
  value.length < 2 ? `${name} Name must be at least 2 characters` : null;

interface CustomerProps {
  order: Order;
  onChange: (value: { name: string; value: string | number | boolean | Phone } | TargetElement) => void;
  isNew: boolean;
  isLoading: boolean;
  account?: AccountEntity;
  setLoadedOrder: (order: Order) => void;
}

const Customer: FC<CustomerProps> = ({ order, onChange, isNew, account, isLoading, setLoadedOrder, ...rest }) => {
  const classes = useStyles();
  const { closingTitle, isClosing } = useOrderClosingContext();
  const { templates, automationRules } = useComposeEmail();
  const { orderLogs, reload } = useActivityLogContext();
  const newLeadAutomationRules = automationRules.filter(
    (ar) => AutomationRuleName.WHEN_A_NEW_CUSTOMER_IS_CREATED_MOVEBOARD === ar.shortName,
  );

  const { confirm: confirmSms, renderDialog: renderConfirmationSms } = useConfirm({
    title: 'Warning',
    message:
      'Please ensure that the customer details are correct. Do you wish to proceed to send the Greeting New Customer SMS?',
    confirmTitle: 'Yes, proceed',
    cancelTitle: 'No, cancel',
  });

  const { confirm: confirmEmail, renderDialog: renderConfirmationEmail } = useConfirm({
    title: 'Warning',
    message:
      'Please ensure that the customer details are correct. Do you wish to proceed to send the Greeting New Customer Email?',
    confirmTitle: 'Yes, proceed',
    cancelTitle: 'No, cancel',
  });

  const filteredEmailsTemplatesByEventType = templates.find(
    (t) =>
      t.active &&
      t.modality === CommunicationModality.EMAIL &&
      newLeadAutomationRules.find((it) => it.id === t.automationRuleId),
  );
  const filteredSmsTemplatesByEventType = templates.find(
    (t) =>
      t.active &&
      t.modality === CommunicationModality.SMS &&
      newLeadAutomationRules.find((it) => it.id === t.automationRuleId),
  );

  const sendEmail = orderLogs?.find(
    (activity) =>
      activity?.activityType === ActivityType.EMAIL &&
      //@ts-ignore because OrderLog is not a ts-file
      activity?.activity?.to === order?.contactInfo?.email &&
      //@ts-ignore because OrderLog is not a ts-file
      activity?.automationRuleId === filteredEmailsTemplatesByEventType?.automationRuleId,
  );

  const sendSms = orderLogs?.find(
    (activity) =>
      //@ts-ignore because OrderLog is not a ts-file
      activity?.type === ConversationMessageType.SMS &&
      //@ts-ignore because OrderLog is not a ts-file
      activity?.activity?.primaryPhone === order?.contactInfo?.primaryPhone?.number &&
      //@ts-ignore because OrderLog is not a ts-file
      activity?.automationRuleId === filteredSmsTemplatesByEventType?.automationRuleId,
  );

  const [openModal, setOpenModal] = useState<MODALS | null>(null);
  const [inFlightSendingTemplates, setInFlightSendingTemplates] = useState(false);
  const [firstName, setFirstName] = useState(order.contactInfo.firstName || '');
  const [lastName, setLastName] = useState(order.contactInfo.lastName || '');
  const [info, setInfo] = useState<string>(order.additionalInfo || '');
  const [errorObj, setErrorObj] = useState<{
    [FIRST_NAME]?: string | null;
    [ADDITIONAL_INFO]?: string | null;
    [LAST_NAME]?: string | null;
  }>({});
  const disableInputs = isClosing;
  const { enqueueSnackbar } = useSnackbar();
  const currentEmployee = useSelector(getCurrentEmployee) as EmployeeRecord | null;

  const handleOnSave = () => {
    setOpenModal(null);
  };

  useEffect(() => {
    setFirstName(order.contactInfo.firstName);
  }, [order.contactInfo.firstName]);

  useEffect(() => {
    setLastName(order.contactInfo.lastName);
  }, [order.contactInfo.lastName]);

  useEffect(() => {
    setInfo(order.additionalInfo!);
  }, [order.additionalInfo]);

  useEffect(() => {
    const values = [firstName, lastName];

    setErrorObj(
      validateFields.reduce(
        (accumulator, field, index) => ({
          ...accumulator,
          [field]:
            validateRequired(values[index], index === 0 ? 'First' : 'Last') ||
            validateMaxLength(values[index], index === 0 ? 'First' : 'Last') ||
            validateMinLength(values[index], index === 0 ? 'First' : 'Last'),
        }),
        errorObj,
      ),
    );
  }, [firstName, lastName]);

  useEffect(() => {
    setErrorObj({
      ...errorObj,
      [ADDITIONAL_INFO]: info && info.length > 2000 ? 'Additional Info must be at most 2000 characters' : null,
    });
  }, [info]);

  const handleOnBlur =
    (value: string | number | boolean) =>
    ({ target: { name } }: ChangeEvent<HTMLInputElement>) =>
      onChange({ name, value });

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const handleOpenModal = (modalName: MODALS) => () => {
    setOpenModal(modalName);
  };

  const handleSendWelcomeLetter = (template: BaseTemplate, type?: CommunicationModality) => async () => {
    if ((type === CommunicationModality.SMS && !!sendSms) || (type === CommunicationModality.EMAIL && !!sendEmail)) {
      return;
    }
    if (type === CommunicationModality.SMS) {
      const answer = await confirmSms();
      if (!answer) {
        return;
      }
    }

    if (type === CommunicationModality.EMAIL) {
      const answer = await confirmEmail();
      if (!answer) {
        return;
      }
    }

    const isSms = type === CommunicationModality.SMS;
    try {
      setInFlightSendingTemplates(true);
      const t = await templateApi.getTemplate(template.id!);
      const templateToSend = new Template(t);
      await sendMessagesToOrder(
        {
          orderId: order.orderId,
          email: order?.contactInfo?.email,
          phoneNumber: order?.contactInfo?.primaryPhone?.number,
        },
        [templateToSend],
        !!currentEmployee?.[EMAIL_SERVER_SETTINGS]?.[USE_OUT_GOING_EMAIL],
      );
      reload();
      enqueueSnackbar(`Welcome ${isSms ? 'sms' : 'email'} sent success`, { variant: 'success' });
    } catch {
      enqueueSnackbar(`Welcome ${isSms ? 'sms' : 'email'} failed sent, please try again`, { variant: 'error' });
    } finally {
      setInFlightSendingTemplates(false);
    }
  };

  return (
    <Card
      mode="EDIT"
      title={`Customer Details${closingTitle}`}
      icon={<FaceIcon />}
      onAction={handleOpenModal(MODALS.CUSTOMER_INFO)}
      className="use-input-fixes"
      {...rest}
    >
      {!disableInputs && <VideoButton position="absolute" video="OA5eyQWhGmY" bottom={0} left={0} />}
      <FullWidthBox my={2} className={classes.table}>
        <OverviewInfoTable>
          <TableBody>
            <TableRow>
              <LabelTableCell
                size="small"
                padding="none"
                className={classes.tableCell}
                data-testid="customerDetails-name"
              >
                <BodyText>Name</BodyText>
              </LabelTableCell>
              <ValueTableCell data-testId="fullName">
                <FlexWithNegativeMargin>
                  <TextInput
                    data-testid="customerDetails-FirstName"
                    value={firstName}
                    name={FIRST_NAME}
                    className="fix-input-margin"
                    placeholder="First name"
                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setFirstName(value)}
                    onBlur={!errorObj[FIRST_NAME] && handleOnBlur(firstName)}
                    formErrors={errorObj}
                    disabled={disableInputs}
                    hiddenLabel
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <TextInput
                    value={lastName}
                    name={LAST_NAME}
                    placeholder="Last name"
                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setLastName(value)}
                    onBlur={!errorObj[LAST_NAME] && handleOnBlur(lastName)}
                    formErrors={errorObj}
                    disabled={disableInputs}
                    hiddenLabel
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </FlexWithNegativeMargin>
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <LabelTableCell size="small" padding="none" className={classes.tableCell}>
                <BodyText>Email</BodyText>
              </LabelTableCell>
              <ValueTableCell>
                <FlexWithNegativeMargin>
                  <EmailWithValidation value={order.contactInfo.email} onChange={onChange} />
                  {filteredEmailsTemplatesByEventType && (
                    <Box>
                      <Tooltip
                        title={
                          !!sendEmail
                            ? `Sent welcome email ${toDateString(sendEmail?.dateTime!, UI_DATE_TIME_FORMAT)}`
                            : 'Send welcome email'
                        }
                      >
                        <Box>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={handleSendWelcomeLetter(
                              filteredEmailsTemplatesByEventType,
                              CommunicationModality.EMAIL,
                            )}
                            className={clsx({
                              [classes.successColor]: !!sendEmail,
                              [classes.nonClickCursor]: !!sendEmail,
                            })}
                            disabled={inFlightSendingTemplates}
                          >
                            <EmailIcon />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  )}
                </FlexWithNegativeMargin>
              </ValueTableCell>
            </TableRow>
            <PhoneRow
              onChange={onChange}
              namePrefix="contactInfo.primaryPhone"
              phone={order.contactInfo.primaryPhone}
              placeholder="Primary Phone"
              disabled={disableInputs}
              template={filteredSmsTemplatesByEventType}
              sendSms={sendSms}
              handleSendWelcomeLetter={handleSendWelcomeLetter}
              inFlightSendingTemplates={inFlightSendingTemplates}
            />
            <PhoneRow
              isOptional
              onChange={onChange}
              namePrefix="contactInfo.otherPhone"
              phone={order.contactInfo.otherPhone}
              placeholder="Additional Phone"
              disabled={disableInputs}
            />
            <BestContact onChange={onChange} contactInfo={order.contactInfo} disabled={disableInputs} />
            <AddContact onClick={handleOpenModal(MODALS.ADDITIONAL_CONTACTS)} order={order} />
            <TableRow>
              <LabelTableCell size="small" padding="none" className={classes.tableCell}>
                <BodyText>Info</BodyText>
              </LabelTableCell>
              <ValueTableCell>
                <FlexWithNegativeMargin>
                  <TextInput
                    value={info}
                    placeholder="Additional Info"
                    name={ADDITIONAL_INFO}
                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setInfo(value)}
                    onBlur={!errorObj[ADDITIONAL_INFO] && handleOnBlur(info)}
                    inputProps={{ className: clsx(Boolean(errorObj[ADDITIONAL_INFO]) && 'error') }}
                    formErrors={errorObj}
                    hiddenLabel
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={disableInputs}
                  />
                </FlexWithNegativeMargin>
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <LabelTableCell size="small" padding="none">
                <BodyText>SMS Status</BodyText>
              </LabelTableCell>
              <ValueTableCell>
                <FlexWithNegativeMargin>
                  <Switch
                    color="primary"
                    checked={order.enableSmsCustom}
                    disabled={
                      !order.customerInfo.customerId || !order.customerInfo.primaryPhone.number || disableInputs
                    }
                    name="enableSmsCustom"
                    onChange={({ target: { checked: value, name } }) => onChange({ name, value })}
                  />
                </FlexWithNegativeMargin>
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <LabelTableCell size="small" padding="none">
                <BodyText>Quote Visibility</BodyText>
              </LabelTableCell>
              <ValueTableCell>
                <FlexWithNegativeMargin>
                  <Switch
                    color="primary"
                    checked={order.quoteVisibleToCustomer}
                    name="quoteVisibleToCustomer"
                    onChange={({ target: { checked: value, name } }) => onChange({ name, value })}
                    disabled={disableInputs}
                  />
                </FlexWithNegativeMargin>
              </ValueTableCell>
            </TableRow>
            <AccountLink account={account} isLoading={isLoading} order={order} setLoadedOrder={setLoadedOrder} />
          </TableBody>
        </OverviewInfoTable>
      </FullWidthBox>
      <Box textAlign="center">
        <ViewCustomerPageLink order={order} />
      </Box>
      <Box textAlign="center">
        <Button
          textTransform="capitalize"
          variant="text"
          color="primary"
          onClick={handleOpenModal(MODALS.ORDER_FOR_SERVICE)}
        >
          View Order for Service
        </Button>
      </Box>
      {openModal === MODALS.CUSTOMER_INFO && (
        <CustomerInfo
          shortForm={isNew}
          order={order}
          onCancel={handleCloseModal}
          onSave={handleOnSave}
          open
          onChange={onChange}
        />
      )}
      {openModal === MODALS.ORDER_FOR_SERVICE && <OrderForServiceModal onClose={handleCloseModal} />}
      {openModal === MODALS.ADDITIONAL_CONTACTS && (
        <AddAdditionalContactsModal
          order={order}
          onChange={onChange}
          onClose={handleCloseModal}
          onSave={handleOnSave}
        />
      )}
      {renderConfirmationSms()}
      {renderConfirmationEmail()}
    </Card>
  );
};

export default Customer;
