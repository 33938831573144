import React from 'react';

import { Button } from '@elromcoinc/react-shared';
import { Box, Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { get, getIn } from 'immutable';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';

import smsAPI from 'admin/api/SmsAPI';
import { ExistCardsList } from 'admin/components/Settings/components/SmsSettings/components/ExistCardsList';
import { formatCurrency } from 'common/utils/Formatter';

export const ChargeCustomerForExistCard = ({
  allStripeCustomers,
  firstStepValues,
  handleCloseModal,
  isDisabledInProcessing,
  setIsDisabledInProcessing,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const paymentMethods = getIn(allStripeCustomers, [0, 'paymentMethods'], []);
  const stripeCustomer = get(allStripeCustomers, 0);
  const firstPaymentMethod = paymentMethods[0];

  const handleSubmit = () => {
    setIsDisabledInProcessing(true);

    if (firstPaymentMethod && stripeCustomer) {
      smsAPI
        .chargeRegisteredCustomer({
          paymentMethodId: firstPaymentMethod.paymentMethodId,
          paymentType: 'SMS',
          amount: firstStepValues.amountToCharge,
          cardType: firstPaymentMethod.cardType,
          last4: firstPaymentMethod.last4,
          customerId: stripeCustomer.stripeCustomerId,
        })
        .then(() => {
          handleCloseModal({ paymentMethods: [firstPaymentMethod] }, +firstStepValues.amountToCharge);
        })
        .catch(() => {
          enqueueSnackbar('Failed to charge amount. Try again', { variant: 'error' });
        })
        .then(() => {
          setIsDisabledInProcessing(false);
        });
    }
  };

  return (
    <>
      <Grid item sm={6}>
        <Box position="absolute" top="5px">
          <ExistCardsList firstPaymentMethod={firstPaymentMethod} />
        </Box>
      </Grid>
      <Grid item sm={12}>
        {isDisabledInProcessing && (
          <Box mt={2}>
            <LinearProgress />
          </Box>
        )}
        <Box mt={2}>
          <Button
            size="small"
            color="primary"
            type="button"
            onClick={handleSubmit}
            fullWidth
            disabled={isDisabledInProcessing}
            loading={isDisabledInProcessing}
          >
            {`Pay $ ${formatCurrency(firstStepValues.amountToCharge, 2)}`}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

ChargeCustomerForExistCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allStripeCustomers: pt.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  firstStepValues: pt.object.isRequired,
  handleCloseModal: pt.func.isRequired,
  isDisabledInProcessing: pt.bool,
  setIsDisabledInProcessing: pt.func,
};

ChargeCustomerForExistCard.defaultProps = {
  isDisabledInProcessing: false,
  setIsDisabledInProcessing: null,
};
