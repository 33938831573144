import React from 'react';

import { BodyBigText, EstimateType, Order, isLongDistanceService } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { SalesToClosingComparising } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/SalesToClosingComparising';
import { calculateSalesClosingDifference } from 'admin/components/OrderWindow/blocks/ClosingQuoteDetails/calculateSalesClosingDifference';
import { GrandTotalFlatRateInput, GrandTotalLongDistanceInput } from 'admin/components/OrderWindow/common';
import { Text } from 'admin/components/OrderWindow/components';
import { useOrderClosingContext, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    percentage: {
      fontSize: '12px',
      marginLeft: '10px',
      opacity: '0.5',
    },
    costs: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '55%',
    },
    hideInfo: {
      justifyContent: 'end',
    },
    sales: {
      display: 'flex',
      justifyContent: 'end',
      width: '45%',
    },
  }),
);

export const ClosingGrandTotal = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();
  const orderDetail = (isClosing ? order.closingOrderDetail : order)!;
  const service = orderDetail.services.get(serviceIndex);
  const isLongDistance = isLongDistanceService(service.getType());
  const isFlatRate = orderDetail.getServiceQuote(serviceIndex).estimateType === EstimateType.FLAT_RATE;

  const estGrandTotal = order.getGrandTotal(serviceIndex, isSelectedAllServices).maxValue;
  const closingGrandTotal = order.closingOrderDetail?.getGrandTotal(serviceIndex, isSelectedAllServices).maxValue;
  const percent = calculateSalesClosingDifference(estGrandTotal, closingGrandTotal);

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <BodyBigText>
          <b>Total Cost</b>
        </BodyBigText>
        <SalesToClosingComparising
          sales={order.getGrandTotal(serviceIndex, isSelectedAllServices).asHumanReadableString()}
          percent={percent}
        >
          <Box>
            {(isFlatRate || isLongDistance) && !isSelectedAllServices && order.disableAutoCalculation ? (
              <Box mr={-1}>{isLongDistance ? <GrandTotalLongDistanceInput /> : <GrandTotalFlatRateInput />}</Box>
            ) : (
              <Text
                value={orderDetail.getGrandTotal(serviceIndex, isSelectedAllServices).asHumanReadableString()}
                data-testId={'grandTotalValue'}
              />
            )}
          </Box>
        </SalesToClosingComparising>
      </Box>
    </Grid>
  );
};
